<div class="modal-header"
  [ngClass]="((addPermissionRoadModal?._isShown) || (deletePermissionRoadModal?._isShown))?'modal-hide-when-second-open bg-gray-900':''">
  <h4 class="modal-title pull-left">กลุ่มผู้ใช้งาน</h4>
  <button type="button" class="close pull-right text-white" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body text-center"
  [ngClass]="((addPermissionRoadModal?._isShown) || (deletePermissionRoadModal?._isShown))?'modal-hide-when-second-open bg-gray-900':''">
  <div class="row">
    <div class="col-6">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <p class="input-group-text">ชื่อกลุ่มผู้ใช้งาน <span class="red-txt"> *</span></p>
        </div>
        <input type="text" class="form-control" [(ngModel)]="name">
      </div>
    </div>
    <div class="col-6">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text">หน่วยงาน</span>
        </div>
        <kendo-combobox [data]="ins_list" [textField]="'name'" [valueField]="'id'" [(ngModel)]="ins_bindding"
          (valueChange)="handleValueIns($event)" class="form-control">
        </kendo-combobox>
        <!-- (filterChange)="handleFilterIns($event)" -->
        <!-- <input [typeahead]="ins_list" [(ngModel)]="ins_name" typeaheadOptionField="name" (focusout)="blur($event)"
          (typeaheadOnSelect)="onSelect($event)" class="form-control"> -->
      </div>
    </div>
    <div class="col-6">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text">เบอร์โทร</span>
        </div>
        <input type="text" class="form-control" [(ngModel)]="tel">
      </div>
    </div>
    <div class="col-6">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text">Email</span>
        </div>
        <input type="text" class="form-control" [(ngModel)]="email">
      </div>
    </div>
    <div class="col-6">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text">Hashtag</span>
        </div>
        <input type="text" class="form-control" [(ngModel)]="hashtag">
      </div>
    </div>
    <div class="col-6">
      <div class="d-flex justify-content-end">
        <button *ngIf="values" type="button" class="btn btn-info" (click)="save()"
        [disabled]="(!name) ? 'disabled': null">บันทึก</button>
      </div>
    </div>
    <div *ngIf="values" class="col-12 text-left mb-3 pt-2 pb-2 d-flex justify-content-between dgray-BG">
      <p class="m-0" style="line-height: 2.5;">
        <i class="fa fa-bars" aria-hidden="true"></i> ผู้ใช้งาน
      </p>
      <button *ngIf="isAddUser" class="btn btn-info" (click)="addUser()">เพิ่มผู้ใช้งาน</button>
    </div>
    <div *ngIf="values" class="col-12">
      <table class="table-main table mb-0">
        <thead class="thead-dark" style="background-color: black;">
          <tr>
            <th class="text-center text-light table-header"><i class="fa fa-hashtag"></i></th>
            <th class="text-center text-light table-header">ชื่อผู้ใช้งาน</th>
            <th class="text-center text-light table-header">หน่วยงาน</th>
            <th class="text-center text-light table-header">Create date</th>
            <th class="text-center text-light table-header">Action</th>
          </tr>
        </thead>
        <tbody>

          <tr *ngIf="!list || list?.length  < 1">
            <td colspan="10" style="height: 50px;">
              ไม่พบข้อมูล
            </td>
          </tr>

          <tr
            *ngFor="let i of list | slice: (currentPage-1) * itemsPerPage : (currentPage-1) * itemsPerPage + itemsPerPage;index as idx">
            <td>
              {{(idx+1)+(itemsPerPage*(currentPage-1))}}
            </td>
            <td>{{i.firstname}} {{i.lastname}}</td>
            <td>{{i.ins_name}}</td>
            <td>{{i.create_datetime | date:'dd/MM/yyyy HH:mm:ss':'+0700'}}</td>
            <td>
              <button class="ml-1 mr-1 btn btn-danger" (click)="rmUserGroup(i.user_id)">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </button>
            </td>
          </tr>

          <tr>
            <td colspan="12" class="table-footer">
              <div class="table-footer-inside d-flex align-items-center justify-content-between">
                <div class="ml-3 pt-3">

                  <pagination [(ngModel)]="currentPage" [totalItems]="list.length" [itemsPerPage]="itemsPerPage"
                    (pageChanged)="pageChanged($event)" previousText="&lsaquo;" nextText="&rsaquo;"
                    [boundaryLinks]="true" firstText="&laquo;" lastText="&raquo;">></pagination>

                </div>
                <div></div>
                <div class="mr-3 ml-2">total: {{list.length||0}}</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="values" class="col-12 text-left mb-3 pt-2 pb-2 d-flex justify-content-between dgray-BG mt-3">
      <p class="m-0" style="line-height: 2.5;">
        <i class="fa fa-bars" aria-hidden="true"></i> Permission Road
      </p>
      <button class="btn btn-info" (click)="openAddPermissionRoadModal()">เพิ่ม Permission Road</button>
    </div>
    <div *ngIf="values" class="col-12">
      <table class="table-main table mb-0">
        <thead class="thead-dark" style="background-color: black;">
          <tr>
            <th class="text-center text-light table-header"><i class="fa fa-hashtag"></i></th>
            <th class="text-center text-light table-header">Route</th>
            <th class="text-center text-light table-header">ST KM</th>
            <th class="text-center text-light table-header">EN KM</th>
            <th class="text-center text-light table-header">Action</th>
          </tr>
        </thead>
        <tbody>

          <tr *ngIf="!permission_routes || permission_routes?.length == 0">
            <td colspan="10" style="height: 50px;">
              ไม่พบข้อมูล
            </td>
          </tr>

          <tr *ngFor="let item of permission_routes ;index as i">
            <td>
              {{(i+1)}}
            </td>
            <td>{{item?.route}}</td>
            <td>{{item?.st_km}}</td>
            <td>{{item?.en_km}}</td>
            <td>
              <div class="d-flex align-items-center justify-content-center">
                <button (click)="openEditPermissionRoadModal(item)" title="แก้ไข" type="button"
                  class="btn btn-sm btn-warning mr-1 text-light">
                  <i class="cui-note icons"></i>
                </button>
                <button (click)="openDeletePermissionRoadModal(item)" title="ลบ" type="button"
                  class="btn btn-sm btn-danger ml-1">
                  <i class="cui-trash icons"></i>
                </button>
              </div>
            </td>
          </tr>

          <tr>
            <td colspan="12" class="table-footer">
              <div class="table-footer-inside d-flex align-items-center justify-content-between">
                <div class="ml-3 pt-3">
                </div>
                <div></div>
                <div class="mr-3 ml-2">total: {{totalPermissionRoadItems}}</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="values" class="col-12 text-left mt-3 pt-2 pb-2 d-flex justify-content-between dgray-BG">
      <p class="m-0" style="line-height: 2.5;">
        <i class="fa fa-bars" aria-hidden="true"></i> Permission
      </p>
    </div>
    <div *ngIf="values" class="col-12 mt-3">
      <div class="box-BG">
        <ngx-treeview [config]="config" [items]="items_menu" (filterChange)="onFilterChange($event)"
          (selectedChange)="onSelectedChange($event);values_tree = $event">
        </ngx-treeview>
        <!-- <button class="btn btn-info mt-3" (click)="save_per()">
          ยืนยัน Permission
        </button> -->

      </div>
    </div>
    <div *ngIf="values && false" class="col-6 mt-3">
      <div class="box-BG">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">ทางหลวง</span>
          </div>

          <input [typeahead]="route_list" [(ngModel)]="route_name" typeaheadOptionField="name" (focusout)="blur($event)"
            (typeaheadOnSelect)="onSelectR($event)" class="form-control">
        </div>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">หลักกิโลเมตร</span>
          </div>
          <input type="text" class="form-control" [(ngModel)]="s_km">
          <!-- <div class="input-group-prepend"> -->
          <span class="input-group-text br-0"> - </span>
          <!-- </div> -->
          <input type="text" class="form-control" [(ngModel)]="e_km">

          <div class="input-group-append">
            <button class="btn btn-info" (click)="s_route()">
              ค้นหา
            </button>
          </div>
        </div>
        <ngx-treeview [config]="configDevice" [items]="items_device" (filterChange)="onFilterChange($event)"
          (selectedChange)="onSelectedChange($event);values_device = $event">
        </ngx-treeview>
        <button class="btn btn-info mt-3" (click)="save_device()">
          ยืนยัน Device
        </button>

      </div>
    </div>
  </div>
</div>
<div class="modal-footer"
  [ngClass]="((addPermissionRoadModal?._isShown) || (deletePermissionRoadModal?._isShown))?'modal-hide-when-second-open bg-gray-900':''">
  <!-- <button type="button" class="btn btn-primary btn-sm" (click)="config.animated = !config.animated">{{config.animated ? 'Disable' : 'Enable'}} animation</button> -->
  <button type="button" class="btn btn-danger" (click)="close()">ปิด</button>
  <!-- <button type="button" class="btn btn-danger" (click)="cancel()">No</button> -->
  <button *ngIf="!values" type="button" class="btn btn-info" (click)="save()" [disabled]="(!name) ? 'disabled': null">บันทึก</button>
  <button *ngIf="values" class="btn btn-info" (click)="save_per()">
    ยืนยัน Permission
  </button>
</div>


<div bsModal #addPermissionRoadModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="clearPermissionRoadData()"
  [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i><span *ngIf="!selectPermissionRoadItem?.id">เพิ่ม
            Permission Road {{selectPermissionRoadItem?.st_km}}</span><span *ngIf="selectPermissionRoadItem?.id">แก้ไข
            Permission Road</span></h4>
        <button type="button" class="close text-light" (click)="addPermissionRoadModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal mt-2 mb-2">
          <div class="form-group row">
            <div class="col-md-4">
              <div>Route</div>
              <kendo-combobox class="w-100" [data]="data_route" [allowCustom]="allowCustom" [textField]=""
                [valueField]="" [value]="select_route" (valueChange)="handleValueRoute($event)"
                (filterChange)="handleFilterRoute($event)" [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
            <div class="col-md-4">
              <div>ST KM</div>
              <input type="text" [(ngModel)]="selectPermissionRoadItem.st_km" class="form-control" placeholder=""
                maxlength="50" [ngModelOptions]="{standalone: true}" numbersOnly maxlength="4">
            </div>
            <div class="col-md-4">
              <div>EN KM</div>
              <input type="text" [(ngModel)]="selectPermissionRoadItem.en_km" class="form-control" placeholder=""
                maxlength="50" [ngModelOptions]="{standalone: true}" numbersOnly maxlength="4">
            </div>
          </div>
        </form>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary" (click)="addPermissionRoadModal.hide()">ยกเลิก</button>
          <button [disabled]="!(select_route && selectPermissionRoadItem?.st_km && selectPermissionRoadItem?.en_km)"
            *ngIf="selectPermissionRoadItem?.id" type="button" class="btn btn-primary text-white ml-2"
            (click)="editPermissionRoad()">แก้ไข</button>
          <button [disabled]="!(select_route && selectPermissionRoadItem?.st_km && selectPermissionRoadItem?.en_km)"
            *ngIf="!selectPermissionRoadItem?.id" type="button" class="btn btn-primary text-white ml-2"
            (click)="addPermissionRoad()">เพิ่ม</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div bsModal #deletePermissionRoadModal="bs-modal" class="modal fade modal-md" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="clearPermissionRoadData()"
  [config]="{backdrop: 'static'}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i>ลบ Permission Road</h4>
        <button type="button" class="close text-light" (click)="deletePermissionRoadModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mt-2 text-center">
          <h4>คุณต้องการลบใช่หรือไม่</h4>
        </div>
        <div class="mt-2 mb-4 text-center">

        </div>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary" (click)="deletePermissionRoadModal.hide()">ยกเลิก</button>
          <button type="button" class="btn btn-primary text-white ml-2" (click)="deletePermissionRoad()">ยืนยัน</button>
        </div>
      </div>
    </div>
  </div>
</div>
<swal #swalSuccess title="<div class='text-light'>สำเร็จ</div>" type="success" text="" icon="success"></swal>
<swal #swalSuccessEditGroup title="<div class='text-light'>แก้ไขสำเร็จ</div>" type="success" text="" icon="success"></swal>
<swal #swalSuccessAddGroup title="<div class='text-light'>เพิ่มกลุ่มผู้ใช้งานสำเร็จ</div>" type="success" text=""
  icon="success"></swal>
<swal #swalSuccessAddPermission title="<div class='text-light'>แก้ไข Permission สำเร็จ</div>" type="success" text=""
  icon="success"></swal>
<swal #swalSuccessRmGroup title="<div class='text-light'>ลบผู้ใช้งานสำเร็จ</div>" type="success" text="" icon="success">
</swal>
<swal #swalError title="<div class='text-light'>ไม่สำเร็จ</div>" html="<div class='text-light'>{{res_message}}</div>"
  type="error" icon="error"></swal>
