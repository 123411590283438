<div class="modal-header">
  <h4 class="modal-title pull-left">แก้ไข Smart micro
    <small>{{(deviceDetail.device_name)? '['+deviceDetail.device_name+']':''}}</small>
  </h4>
  <button type="button" class="close pull-right text-white" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body text-center pt-0 pb-0">
  <div class="row pt-3 txtblog-BG">
    <div class="col-6">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">ทางหลวง</span>
        </div>
        <input type="text" class="form-control" [disabled]="_Notedit" [(ngModel)]="deviceDetail.route">
      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">Station</span>
        </div>
        <input type="text" class="form-control" [disabled]="_Notedit" [(ngModel)]="deviceDetail.station_str">
      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">ประเภทอุปกรณ์</span>
        </div>
        <input type="text" class="form-control" [disabled]="_Notedit" [(ngModel)]="deviceDetail.device_type_str">
      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">จำนวนชนิดรถ</span>
        </div>
        <input type="text" class="form-control" [disabled]="_Notedit" [(ngModel)]="deviceDetail.model_name">
      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">PVR Stream</span>
        </div>
        <div class="d-flex align-items-center chkbox-form-control" style="flex: 1 1 auto;">
          <div style="line-height: 1;" class="d-flex align-items-center ml-3 mr-3">
            on
            <ui-switch class="ml-1 mr-1" [(ngModel)]="_Prv" size="small"></ui-switch>
            off
          </div>
          <!-- <input class="ml-2" type="checkbox" name="pvr" id="p1">
          <label style="margin-top: 5px;" class="ml-2" for="p1">
            ขาเข้า
          </label>
          <input class="ml-2" type="checkbox" name="pvr" id="p2">
          <label style="margin-top: 5px;" class="ml-2" for="p2">
            ขาออก
          </label> -->
        </div>

      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">จำนวนช่องทาง<span class="text-danger">*</span></span>
        </div>
        <kendo-combobox class="form-control" [data]="laneList" [allowCustom]="allowCustom" [suggest]="true"
          [(ngModel)]="lane_total" [filterable]="true" (valueChange)="changeLaneTotal($event)"
          [placeholder]="'โปรดระบุ'">
        </kendo-combobox>
      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">จุดติดตั้งบนโครงสร้าง<span class="text-danger">*</span></span>
        </div>
        <kendo-combobox class="form-control" [data]="dirList" [allowCustom]="allowCustom" [suggest]="true"
          [(ngModel)]="dir_all" [filterable]="true" [textField]="'name'" [valueField]="'id'"
          [placeholder]="'โปรดระบุ'">
        </kendo-combobox>
      </div>
    </div>
    <div class="col-6">

      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">ช่วงกิโลเมตร</span>
        </div>
        <input type="text" class="form-control" [disabled]="_Notedit" [(ngModel)]="deviceDetail.km">
        <div class="input-group-prepend">
          <span class="input-group-text">+</span>
        </div>
        <input type="text" class="form-control" [disabled]="_Notedit" [(ngModel)]="deviceDetail.m">
      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text"> สถานะ</span>
        </div>
        <input type="text" class="form-control" [disabled]="_Notedit" [(ngModel)]="deviceDetail.stauts_name">
      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text"> ประเภทย่อยอุปกรณ์</span>
        </div>
        <input type="text" class="form-control" [disabled]="_Notedit" [(ngModel)]="deviceDetail.device_sub_type_str">
      </div>
      <p>Set '+' Degree</p>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text"> ระหว่าง</span>
        </div>
        <input type="number" class="form-control" [(ngModel)]="_min_heading" min="-360" max="360"
          (change)="_min_heading = checkDegree($event)">
        <div class="input-group-prepend">
          <span class="input-group-text">และ</span>
        </div>
        <input type="number" class="form-control" [(ngModel)]="_max_heading" min="-360" max="360"
          (change)="_max_heading = checkDegree($event)">
      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">ประเภทถนน<span class="text-danger">*</span></span>
        </div>
        <kendo-combobox class="form-control" [data]="roadList" [allowCustom]="allowCustom" [suggest]="true"
          [(ngModel)]="road_type" [filterable]="true" [textField]="'name'" [valueField]="'id'"
          [placeholder]="'โปรดระบุ'">
        </kendo-combobox>
      </div>
    </div>
  </div>
</div>
<div class="modal-header lane-BG">
  <div>
    <!-- <h4 class="modal-title pull-left">Smart Micro</h4> -->
    <!-- <button class="btn lane-Bt" (click)="changeLane(1)" [ngClass]="{'btn-info': lane == 1}">Lane1</button>
    <button class="btn lane-Bt" (click)="changeLane(2)" [ngClass]="{'btn-info': lane == 2}">Lane2</button>
    <button class="btn lane-Bt" (click)="changeLane(3)" [ngClass]="{'btn-info': lane == 3}">Lane3</button>
    <button class="btn lane-Bt" (click)="changeLane(4)" [ngClass]="{'btn-info': lane == 4}">Lane4</button>
    <button class="btn lane-Bt" (click)="changeLane(5)" [ngClass]="{'btn-info': lane == 5}">Lane5</button>
    <button class="btn lane-Bt" (click)="changeLane(6)" [ngClass]="{'btn-info': lane == 6}">Lane6</button>
    <button class="btn lane-Bt" (click)="changeLane(7)" [ngClass]="{'btn-info': lane == 7}">Lane7</button>
    <button class="btn lane-Bt" (click)="changeLane(8)" [ngClass]="{'btn-info': lane == 8}">Lane8</button> -->

    <button class="btn lane-Bt" *ngFor="let l of values_list" (click)="changeLaneTab(l.lane_no)"
      [ngClass]="{'btn-info': l.lane_no == lane}">Lane{{l.lane_no}}</button>
  </div>
  <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button> -->
</div>
<div class="modal-body text-center pt-0">
  <div *ngFor="let l of values_list;index as i;trackBy:trackByIndex;">
    <div *ngIf="l.lane_no == lane" class="blog-body">
      <div class="row">
        <div class="col-4 d-flex justify-content-around align-items-center">
          <h4>รถที่อนุญาติให้วิ่ง</h4>
        </div>
        <div class="col-4">
          <h4>ความเร็ว</h4>
          <p class="d-flex justify-content-around">
            <span>แนะนำ</span>
            <span>เกิน Limit</span>
          </p>
        </div>
        <div class="col-4">
          <h4>ฝนตก</h4>
          <p class="d-flex justify-content-around">
            <span>แนะนำ</span>
            <span>เกิน Limit</span>
          </p>
        </div>
      </div>
      <div class="row configVehClass-BG" *ngFor="let c of values_list[i]?.veh_class;index as ii;">
        <div class="col-4">
          <div>
            <input class="mr-1" type="checkbox" [(ngModel)]="c.allow" name="allow_type{{i}}{{ii}}"
              [id]="c.veh_class_name+c.class_no+i+ii">
            <label [for]="c.veh_class_name+c.class_no+i+ii">{{values_list[i]?.veh_class[ii].veh_class_name}}</label>
          </div>
        </div>
        <div class="col-4 d-flex justify-content-around">
          <input type="number" class="form-control" [(ngModel)]="values_list[i]?.veh_class[ii].suggest_speed" min="0" max="200"
            (change)="values_list[i]?.veh_class[ii].suggest_speed = checkDegree($event)">
          <input type="number" class="form-control" [(ngModel)]="values_list[i]?.veh_class[ii].normal_speed" min="0" max="200"
            (change)="values_list[i]?.veh_class[ii].normal_speed = checkDegree($event)">
        </div>
        <div class="col-4 d-flex justify-content-around">
          <input type="number" class="form-control" [(ngModel)]="values_list[i]?.veh_class[ii].rain_suggest_speed" min="0" max="200"
            (change)="values_list[i]?.veh_class[ii].rain_suggest_speed = checkDegree($event)">
          <input type="number" class="form-control" [(ngModel)]="values_list[i]?.veh_class[ii].rain_speed" min="0" max="200"
            (change)="values_list[i]?.veh_class[ii].rain_speed = checkDegree($event)">
        </div>
      </div>
  
    </div>
    <div *ngIf="l.lane_no == lane" class="blog-foot">
      <div class="row">
        <!-- <div class="col-12">
          <hr />
        </div> -->
        <div class="col-12 d-flex justify-content-around">
          <h4>Signal</h4>
          <div class="mb-2 d-flex align-items-center">
            -
            <div class="mt-1 ml-1 mr-1">
              <ui-switch id="ui-switch-{{i}}" name="ui-switch--{{i}}" size="small" checkedLabel="-" uncheckedLabel="+" [(ngModel)]="values_list[i].signal_direction_"
              (ngModelChange)="switchChange($event,l)"></ui-switch>
  
            </div>
            +
            ทิศการวิ่ง
          </div>
        </div>
  
        <div class="col-6">
  
          <div>
            <input class="mr-1" type="checkbox" name="signal{{i}}" [id]="'s1_'+i" [(ngModel)]="values_list[i].signal_wrong_way_"
            (ngModelChange)="wrongWayChange($event,l)">
            <label [for]="'s1_'+i">ผิดช่องทาง</label>
          </div>
          <div>
            <input class="mr-1" type="checkbox" name="signal{{i}}" [id]="'s2'+i" [(ngModel)]="values_list[i].signal_over_speed_"
            (ngModelChange)="overSpeedChange($event,l)">
            <label [for]="'s2'+i">ความเร็วเกิน</label>
          </div>
        </div>
        <div class="col-6 text-center">
          <div class="input-group mb-3 justify-content-center">
            <div class="input-group-prepend">
              <label class="input-group-text"><input type="checkbox" name="qm{{i}}" id="qm{{i}}" [(ngModel)]="values_list[i].queue_length_trigger_allow"
                (ngModelChange)="queueallowChange($event,l)"
                  class="mr-2"> Q</label>
            </div>
            <input type="number" class="form-control" [(ngModel)]="values_list[i].queue_length_trigger">
            <div class="input-group-append">
              <span class="input-group-text">เมตร</span>
            </div>
          </div>
        </div>
        <!-- <div class="col-6 text-center">
    
          <div class="input-group mb-3 justify-content-center">
            <div class="input-group-prepend">
              <span class="input-group-text">Q</span>
            </div>
            <input type="text" class="form-control" (click)="saveBT=!saveBT" [(ngModel)]="qqM">
            <div class="input-group-append">
              <span class="input-group-text">เมตร</span>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>


  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-primary btn-sm" (click)="config.animated = !config.animated">{{config.animated ? 'Disable' : 'Enable'}} animation</button> -->
    <button type="button" class="btn btn-danger" (click)="close()">ปิด</button>
    <!-- <button type="button" class="btn btn-danger" (click)="cancel()">No</button> -->
    <button type="button" class="btn btn-info" (click)="save2()"
      [disabled]="(disabledSave_BT || values_list?.length==0 || lane_total == null || road_type == null || dir_all == null) ? 'disabled': null">ยืนยัน</button>
  </div>
</div>
<!-- <swal #swalSuccess title="<div class='text-light'>สำเร็จ</div>" type="success"
  html="<div class='text-light'>{{ResMessage}}</div>"></swal>
<swal #swalError title="<div class='text-light'>ไม่สำเร็จ</div>" html="<div class='text-light'>{{ResMessage}}</div>"
  type="error"></swal> -->