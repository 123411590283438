import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  rout_list : any = []
  private subject = new Subject<any>();

  constructor(
    private api : ApiService,
  ) { }
  async getBoardsign(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getBoardsign(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getBoardsignCamera(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getBoardsignCamera(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getEvtDashboardRank(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getEvtDashboardRank(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getWeather(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getWeather(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getEvtReportpdf(req?:any){
    return new Promise(async(reslove,reject)=>{
      let res : any = await this.api.getEvtReportpdf(req)
      // this.api.getEvtReportpdf(req).subscribe((res:any)=>{
      //   if(res && res.code == 1){
      //     return reslove(res.data);
      //   }else{
      //     return reslove(false);
      //   }
      // })
    })
  }
  async geticonMarker(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.geticonMarker().subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      },(err) => {console.log(err);return reslove(false);})
    })
  }
  async getEvtEffType(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getEvtEffType().subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  
  async addeEvtEffType(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addEvtEffType(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getSrc(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getMapEvtSrc().subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  route_list : any = []
  async getRoute(req?:any){
    return new Promise((reslove,reject)=>{
      if(this.route_list.length) return reslove(this.route_list)
      this.api.getMapEvtRoute().subscribe((res:any)=>{
        if(res && res.code == 1){
          this.route_list = res.data;
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getProvince(){
    return new Promise((reslove,reject)=>{
      if(this.rout_list.length) return reslove(this.rout_list)
      this.api.getProvince().subscribe((res:any)=>{
        if(res && res.code == 1){
          this.rout_list = res.data;
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getDistrict(){
    return new Promise((reslove,reject)=>{
      if(this.rout_list.length) return reslove(this.rout_list)
      this.api.getDistrict().subscribe((res:any)=>{
        if(res && res.code == 1){
          this.rout_list = res.data;
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getDepartment(){
    return new Promise((reslove,reject)=>{
      if(this.rout_list.length) return reslove(this.rout_list)
      this.api.getDepartment().subscribe((res:any)=>{
        if(res && res.code == 1){
          this.rout_list = res.data;
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getmapEvt(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getmapEvt(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getmapEvtSubType(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getmapEvtSubType(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getmapEvtStatus(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getmapEvtStatus(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getEvtNoti(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getEvtNoti(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getEvtById(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getEvtById(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getEvt(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getEvt(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      },(err) => {console.log(err);return reslove(false);})
    })
  }
  async getEvtCount(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getEvtCount(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      },(err) => {console.log(err);return reslove(false);})
    })
  }
  async addevt(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addEvt(req).subscribe((res:any)=>{
        if(res.code == 1){
          this.subject.next({ chk: true });
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async rmEvt(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.rmEvt(req).subscribe((res:any)=>{
        if(res.code == 1){
          this.subject.next({ chk: true });
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getEvtConfirm(req:any){
    return new Promise((reslove,reject)=>{
      this.api.getEvtConfirm(req).subscribe((res:any)=>{
        if(res.code == 1){
          this.subject.next({ chk: true });
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  clearMessages() {
    this.subject.next();
  }
  getMessage(): Observable<any> {
      return this.subject.asObservable();
  }
  async getEvtType(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getEvtType(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getEvtSubType(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getEvtSubType(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addEvtSubType(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addEvtSubType(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async rmEvtSubType(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.rmEvtSubType(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getEvtGroup(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getEvtGroup(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getEvtImg(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getEvtImg(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getEvtEff(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getEvtEff(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addevtEff(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addEvtEff(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async rmevtEff(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.rmevtEff(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getRain(req:any){
    return new Promise((reslove,reject)=>{
      this.api.getRain(req).subscribe((res:any)=>{
        if(res.code == 1){
          this.subject.next({ chk: true });
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addRain(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addRain(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async rmRain(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.rmRain(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async updateEvtApprove(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.updateEvtApprove(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async updateEvtSignature(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.updateEvtSignature(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  // RM VVV
  async addIncident(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addIncident(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getIncidentById(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getIncidentById(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getIncident(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getIncident(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getIncidentName(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getIncidentName(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getIncidentType(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getIncidentType(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(res.data.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addIncidentType(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addIncidentType(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async rmIncident(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.rmIncident(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async rmIncidentType(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.rmIncidentType(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async uploadIconImg(data){
    return new Promise((reslove,reject)=>{
      this.api.uploadIconImg(data).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async uploadDoc(data){
    return new Promise((reslove,reject)=>{
      this.api.uploadDoc(data).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async uploadImages(image: File, event_id: number){
    return new Promise((reslove,reject)=>{
      this.api.uploadImages(image,event_id).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async rmImages(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.rmImages(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addIncidentAct(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addIncidentAct(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getRainConfig(){
    return new Promise((reslove,reject)=>{
      this.api.getRainConfig().subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async updateRainConfig(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.updateRainConfig(req).subscribe((res:any)=>{
        if(res.code == 1){
          this.subject.next({ chk: true });
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  getInfo(){
    return this.api.getInfo();
  }
  async getEvtMsg(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getEvtMsg(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getEvtLog(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getEvtLog(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addEvtMsg(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addEvtMsg(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addImgEvt(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addImgEvt(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(res);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addImgEvtLog(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addImgEvtLog(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addSignLog(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addSignLog(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getLocation(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getLocation(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getLocationPlanet(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getLocationPlanet(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  
  async getRainDevices(req?:any){
    return new Promise((reslove,reject)=>{
      req.device_group_id = 1023
      req.device_type_id = 1026
      req.device_sub_type_id = 1050
      this.api.getRainDevices(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  
  async getRainAlert(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getRainAlert(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addRainAlert(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addRainAlert(req).subscribe((res:any)=>{
        if(res.code == 1){
          this.subject.next({ chk: true });
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getSpeedDirectionDevices(req:any){
    return new Promise((reslove,reject)=>{
      this.api.getSpeedDirectionDevices(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addUpdateSpeedDirectionDevices(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addUpdateSpeedDirectionDevices(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getFlowDetectionDevices(req:any){
    return new Promise((reslove,reject)=>{
      this.api.getFlowDetectionDevices(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addUpdateFlowDetectionDevices(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addUpdateFlowDetectionDevices(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getOccupanyDetactionDevices(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getOccupanyDetactionDevices(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addUpdateOccupanyDetactionDevices(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addUpdateOccupanyDetactionDevices(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getTravalTimeDetactionDevices(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getTravalTimeDetactionDevices(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addUpdateTravalTimeDetactionDevices(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addUpdateTravalTimeDetactionDevices(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getQueueingDetactionDevices(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getQueueingDetactionDevices(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addUpdateQueueingDetactionDevices(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addUpdateQueueingDetactionDevices(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }

  async getEventConfigSetting(){
    return new Promise((reslove,reject)=>{
      this.api.getEventConfigSetting().subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async updateEventConfigSetting(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.updateEventConfigSetting(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getconfigSettingList(){
    return new Promise((reslove,reject)=>{
      this.api.getconfigSettingList().subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getVerifyMethod(){
    return new Promise((reslove,reject)=>{
      this.api.getVerifyMethod().subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async updateEventVerifyMethod(data?: any){
    return new Promise((reslove,reject)=>{
      this.api.updateEventVerifyMethod(data).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
}
