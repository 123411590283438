import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertpopupComponent } from '../../views/user-management/alertpopup/alertpopup.component';
import { Subject } from 'rxjs';

import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class FuncService {

  a_modal: BsModalRef;
  constructor(
    
    private modalService: BsModalService,
    private router: Router,
  ) { }
  
  public alertpopup(data?:any,header?:string,footer?:any){
    return new Promise((resolve, reject) => {
      const initialState = {
        body: data,
        footer : footer,
        header :header
      };
      this.a_modal = this.modalService.show(AlertpopupComponent, {initialState,id: 9999,backdrop: 'static',class: 'alertW-BG'});
      this.a_modal.content.event.subscribe(data => {
        return resolve(data);
      });
    
    })
  }
  
  alertError(txt:string){
    Swal.fire({
      title: 'Error!',
      text: txt,
      icon: 'error',
      confirmButtonText: 'OK'
    })
  }
  alertSuccess(){
    Swal.fire(
      'Processing success',
      '',
      'success'
    )
  }
  alertLoading(){
    Swal.fire({
      title: 'Please wait for process',
      text: '',
      allowOutsideClick:false,
      // icon: 'error',
      // confirmButtonText: 'OK'
      // timer: 1500,
      showConfirmButton: false,
      imageUrl: './assets/event/loading2.gif',
      // imageWidth: 400,
      imageHeight: 200,
    })
  }
  alertClose(){
    Swal.close(); return false;
  }
  public dt(dt){
    return moment(dt).format('DD/MM/YYYY HH:mm:ss');
    
  }
  public _isNumber(value) {
    var patt = new RegExp("^[0-9]+$");
    var res = patt.test(value);
    // console.log(res);
    return res;

  }
  public _isTel(value) {
    // var patt = new RegExp("^([01][0-9][0-9]|2[0-4][0-9]|25[0-5])$");
    // var res = patt.test(value);
    
    var patt = /\(?\b[0-9]{3}\)?[-. ]?[0-9]{3}[-. ]?[0-9]{4}\b/;​​
    var res = true
    if(!value.match(patt)){
      res = false;
          // alert('please enter a valid phone number');
      }
      
      return res;
  }
  public _isTelhome(value) {
    // var patt = new RegExp("^([01][0-9][0-9]|2[0-4][0-9]|25[0-5])$");
    // var res = patt.test(value);
    
    var patt = /\(?\b[0-9]{9,10}\)?\b/;​​
    var res = true
    if(!value.match(patt)){
      res = false;
          // alert('please enter a valid phone number');
      }
      
      return res;
  }

  public _istextCom(value) {
    // var patt = new RegExp("^[a-zA-Z]+$");
    if (value) {
      var patt = new RegExp("^[-0-9.,a-zA-Zก-ฮะ-์ ]+$")
      var res = patt.test(value);
      // console.log(res);
      return res;
    }

  }

  public _istext(value) {
    // var patt = new RegExp("^[a-zA-Z]+$");
    if (value) {
      var patt = new RegExp("^[a-zA-Zก-ฮะ-์ ]+$")
      var res = patt.test(value);
      // console.log(res);
      return res;
    }

  }
  public _istextEN(value) {
    if (value) {
      var patt = new RegExp("^[a-zA-Z]+$");
      var res = patt.test(value);
      // console.log(res);
      return res;
    }

  }
  public _isPassword(value) {
    if (value) {
      var patt = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
      var res = patt.test(value);
      // console.log(res);
      return res;

    }
  }
  public rm_lasttext(val) {
    var lastIndex = val.length - 1;
    return val.substring(0, lastIndex);

  }
  public validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  public checkTextEmail(value) {
    if (value) {
      var patt = new RegExp("^[a-zA-Z0-9.@_-]+$");
      var res = patt.test(value);
      // console.log(res);
      return res;
    }

  }

  public _isPrice(val) {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  public goto(txt?:string){
    this.router.navigate([txt])
  }
}
