<div class="row pt-3 pr-3 pl-3">
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>ชื่อ :</div>
    <div>
      <input autocomplete="nope" type="text" placeholder="" maxlength="100" class="k-textbox form-control w-100"
        [(ngModel)]="search_name" [ngModelOptions]="{standalone: true}" />
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>ประเภทของภาพ :</div>
    <div>
      <kendo-combobox class="w-100" [data]="media_type_list" [allowCustom]="allowCustom" [textField]="'name'"
        [valueField]="'id'" [value]="search_media_type" (valueChange)="handleValueMediaType($event)"
        [placeholder]="'ทั้งหมด'" [filterable]="true">
      </kendo-combobox>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>ประเภทการใช้งาน :</div>
    <div>
      <kendo-combobox class="w-100" [data]="use_type_list" [allowCustom]="allowCustom" [textField]="'name'"
        [valueField]="'id'" [value]="search_use_type" (valueChange)="handleValueUseType($event)"
        [placeholder]="'ทั้งหมด'" [filterable]="true">
      </kendo-combobox>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>ชนิดป้าย :</div>
    <div>
      <kendo-combobox class="w-100" [data]="data_sign_type" [allowCustom]="allowCustom" [textField]="'name'"
        [valueField]="'id'" [value]="search_sign_type" (valueChange)="handleValueSignType($event)"
        (filterChange)="handleFilterSignType($event)" [placeholder]="'ทั้งหมด'" [filterable]="true">
      </kendo-combobox>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>ขนาด :</div>
    <div>
      <kendo-combobox class="w-100" [data]="data_size" 
      [allowCustom]="allowCustom" 
      textField="size" 
      valueField="size"
      [(ngModel)]="search_size" 
      (valueChange)="handleValueSize($event)" (filterChange)="handleFilterSize($event)" [placeholder]="'ทั้งหมด'"
        [filterable]="true">
      </kendo-combobox>
    </div>
  </div>
  <!-- <div class="col-lg-4 col-md-4 col-sm-6">
    <div>อัตราส่วน :</div>
    <div>
      <kendo-combobox class="w-100" [data]="data_ratio" [allowCustom]="allowCustom" [value]="search_ratio"
        (valueChange)="handleValueRatio($event)" (filterChange)="handleFilterRatio($event)" [placeholder]="'ทั้งหมด'"
        [filterable]="true">
      </kendo-combobox>
    </div>
  </div> -->

  <div class="col-lg-4 col-md-4 d-flex align-items-end justify-content-end">
    <div class="mt-1">
      <button type="button" (click)="search()" class="btn btn-primary pull-right text-white ml-1"> <i
          class="fa fa-search"></i>
        ค้นหา </button>
    </div>
  </div>

  <div class="col-12 mt-3 table-header pl-3 pr-3 pt-2 pb-2 d-flex align-items-center justify-content-between">
    <div class="font-2">
      <i class="icon-picture mr-2"></i> Media
    </div>
    <div>
      <button *ngIf="isAdd" type="button" class="btn btn-primary text-white mr-1" (click)="openAddImageModal()"> <i
          class="fa fa-plus"></i>
        เพิ่มภาพ</button>
      <button *ngIf="isCreate" type="button" class="btn btn-primary text-white ml-1" (click)="openCreateModal()"> <i
          class="fa fa-plus"></i>
        สร้างภาพ</button>
    </div>
  </div>
  <div class="col-12 table-panel-body-height scrollbar-style">
    <div *ngIf="is_loading" class="d-flex justify-content-center align-items-center w-100 h-100 ">
      <div class="lds-dual-ring"></div>
    </div>
    <div *ngIf="!is_loading" class="d-flex align-content-start flex-wrap">
      <div *ngFor="let media of data_list">
        <div>
          <div class="p-2">
            <!-- <img class="pointer" [src]="media?.path" [style.height.px]="medie_sign_height"
              [style.width.px]="medie_sign_width(media?.sign_type_obj?.aspect_ratio,medie_sign_height)"
              (click)="openDeleteImageModal(media)" /> -->
            <app-sign-image-item (click)="openDeleteImageModal(media)" [media]="media" [sign_height]="medie_sign_height"
              [ratio]="media?.sign_type_obj?.aspect_ratio" [resize]="(medie_sign_height/media?.sign_type_obj?.height)"
              *ngIf="media?.path && (media?.media_type == mediaType.Image || media?.media_type == mediaType.Gif)">
            </app-sign-image-item>
            <!-- <div class="pointer" [style.height.px]="medie_sign_height" (click)="openDeleteImageModal(media)"
              style="background-color: black;position: relative;"
              [style.width.px]="medie_sign_width(media?.sign_type_obj?.aspect_ratio,medie_sign_height)">
              <img *ngIf="media?.path" [src]="media?.path" width="100%" height="100%"
                style="position: absolute;left: 0px;top: 0px;width: 100%;height: 100%;" />
              <div style="position: absolute;left: 0px;top: 0px;width: 100%;height: 100%;"
                *ngFor="let item of media?.special_property?.text_items;index as i;"
                [ngClass]="(item?.is_center)?'d-flex align-items-center justify-content-center':''">
                <span *ngIf="item?.is_center && item?.text_type == specialTextType.FreeText"
                  [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" [style.color]="item?.color"
                  [style.font-size.px]="item?.font_size" [style.family]="item?.font_family">{{item?.text}}</span>
                <span *ngIf="!item?.is_center && item?.text_type == specialTextType.FreeText"
                  [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" style="position: absolute"
                  [style.top.px]="item?.y" [style.left.px]="item?.x" [style.color]="item?.color"
                  [style.font-size.px]="item?.font_size" [style.family]="item?.font_family">{{item?.text}}</span>
                <span
                  *ngIf="item?.is_center && (item?.text_type == specialTextType.SpeedSuggest || item?.text_type == specialTextType.SpeedLimit)"
                  [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" [style.color]="item?.color"
                  [style.font-size.px]="item?.font_size" [style.family]="item?.font_family">{{speed_text}}</span>
                <span
                  *ngIf="!item?.is_center && (item?.text_type == specialTextType.SpeedSuggest || item?.text_type == specialTextType.SpeedLimit)"
                  [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" style="position: absolute"
                  [style.top.px]="item?.y" [style.left.px]="item?.x" [style.color]="item?.color"
                  [style.font-size.px]="item?.font_size" [style.family]="item?.font_family">{{speed_text}}</span>
              </div>
            </div> -->
            <div class="clearfix m0 text-center text-light"
              [ngStyle]="getSignNameTextStyles(medie_sign_width(media?.sign_type_obj?.aspect_ratio,medie_sign_height))">
              <small title="{{media?.name}}">{{media?.name}}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 p-0 table-header d-flex align-items-center justify-content-between">
    <div class="mr-2 ml-3">
      <pagination [totalItems]="totalItems" [maxSize]="5" [itemsPerPage]="itemsPerPage" [(ngModel)]="currentPage"
        (pageChanged)="pageChanged($event)" class="pagination p-0 m-0" previousText="&lsaquo;" nextText="&rsaquo;"
        [boundaryLinks]="true" firstText="&laquo;" lastText="&raquo;"></pagination>
    </div>
    <div></div>
    <div class="mr-3 ml-2">total: {{totalItems}}</div>
  </div>
</div>

<div bsModal #addImageModal="bs-modal" class="modal fade modal" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="closeAddImage()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i><span>เพิ่มภาพ</span></h4>
        <button type="button" class="close text-light" (click)="addImageModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div *ngIf="is_loading_add" class="modal-body d-flex align-items-center justify-content-center">
        <div class="lds-dual-ring"></div>
      </div>
      <div *ngIf="!is_loading_add" class="modal-body">
        <form class="form-horizontal mt-2 mb-2">
          <div class="form-group row">
            <div class="col-md-12">
              <div class="d-flex align-items-center justify-content-center mt-1" style="min-height: 35px;">
                <label class="radio-container m-1">ใช้ได้กับทุกป้าย
                  <input id="isPubilcImRadio1" type="radio" name="isPubilcImRadio" [value]="true"
                    [(ngModel)]="is_public" [ngModelOptions]="{standalone: true}">
                  <span class="radio-checkmark"></span>
                </label>
                <label class="radio-container m-1">ระบุป้าย
                  <input id="isPubilcImRadio1" type="radio" name="isPubilcImRadio" [value]="false"
                    [(ngModel)]="is_public" [ngModelOptions]="{standalone: true}">
                  <span class="radio-checkmark"></span>
                </label>
              </div>
            </div>
          </div>

          <div *ngIf="!is_public" class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">ป้าย
                :</span></label>
            <div class="col-md-6">
              <kendo-combobox class="w-100" [data]="data_boardsign_modal" [allowCustom]="allowCustom"
                [textField]="'name'" [valueField]="'id'" [value]="boardsign_modal"
                (valueChange)="handleValueBoardsignModal(($event))" (filterChange)="handleFilterBoardsignModal($event)"
                [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">ชื่อภาพ
                :</span></label>
            <div class="col-md-6">
              <input type="text" [(ngModel)]="selectItem.name" class="form-control" placeholder="" maxlength="50"
                [ngModelOptions]="{standalone: true}">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">ชนิดป้าย
                :</span></label>
            <div class="col-md-6">
              <kendo-combobox class="w-100" [data]="data_sign_type_modal" [allowCustom]="allowCustom"
                [textField]="'name'" [valueField]="'id'" [value]="sign_type_modal"
                (valueChange)="handleValueSignTypeModal($event)" (filterChange)="handleFilterSignTypeModal($event)"
                [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="text-input"> <span class="float-sm-left float-md-right">รูปภาพ
                :</span></label>
            <div class="col-md-6">
              <input type="file" name="file-input" #imInput (change)="detectFiles($event)" accept=".png,.jpg,.gif">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="text-input"> <span class="float-sm-left float-md-right">
                ขนาดภาพ
                :</span></label>
            <label *ngIf="img_w && img_h" class="col-md-6 col-form-label" for="text-input"> <span>{{img_w}} x
                {{img_h}}</span></label>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="text-input"> <span class="float-sm-left float-md-right">
                ประเภทของภาพ
                :</span></label>
            <label *ngIf="img_type != null" class="col-md-6 col-form-label" for="text-input">
              <span *ngIf="img_type == mediaType.Image">ภาพนิ่ง</span>
              <span *ngIf="img_type == mediaType.Gif">ภาพเคลื่อนไหว</span>
            </label>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="select1"> <span
                class="float-sm-left float-md-right">ประเภทการใช้งาน
                :</span></label>
            <div class="col-md-6">
              <kendo-combobox class="w-100" [data]="use_type_list" [allowCustom]="allowCustom" [textField]="'name'"
                [valueField]="'id'" [value]="use_type_modal" (valueChange)="handleValueUseTypeModal($event)"
                [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="select1"> <span class="float-sm-left float-md-right">สถานะ
                :</span></label>
            <div class="col-md-6">
              <kendo-combobox class="w-100" [data]="status_list" [allowCustom]="allowCustom" [textField]="'name'"
                [valueField]="'id'" [value]="status_modal" (valueChange)="handleValueStatusModal($event)"
                [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>
        </form>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary mr-1" (click)="addImageModal.hide()">ยกเลิก</button>
          <button type="button" class="btn btn-primary text-white ml-1" (click)="addImageAsyn(this.sign_type_modal.id)"
            [disabled]="!(selectItem?.name && this.img_file && sign_type_modal != null && status_modal != null) || (!is_public && !boardsign_modal)">ยืนยัน</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal modal1 fade1" bsModal #deleteMediaModal="bs-modal" tabindex="-1" role="dialog"
  aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal2-dialog" [style.min-width.px]="250">
    <div class="modal-content">
      <div class="modal-body">
        <div class="mt-1">
          <div class="panel-body  text-center">
            <!-- <img *ngIf="im_select?.path" [src]="im_select?.path" [style.height.px]="medie_sign_height"
              [style.width.px]="medie_sign_width(im_select?.sign_type_obj?.aspect_ratio,medie_sign_height)" /> -->
            <div class="w-100 d-flex justify-content-center">
              <app-sign-image-item [media]="im_select" [sign_height]="medie_sign_height"
                [ratio]="im_select?.sign_type_obj?.aspect_ratio"
                [resize]="(medie_sign_height/im_select?.sign_type_obj?.height)"
                *ngIf="im_select?.path && (im_select?.media_type == mediaType.Image || im_select?.media_type == mediaType.Gif)">
              </app-sign-image-item>
            </div>
            <!-- <div class="w-100 d-flex justify-content-center">
              <div [style.height.px]="medie_sign_height" style="background-color: black;position: relative;"
                [style.width.px]="medie_sign_width(im_select?.sign_type_obj?.aspect_ratio,medie_sign_height)">
                <img *ngIf="im_select?.path" [src]="im_select?.path" width="100%" height="100%"
                  style="position: absolute;left: 0px;top: 0px;width: 100%;height: 100%;" />
                <div style="position: absolute;left: 0px;top: 0px;width: 100%;height: 100%;"
                  *ngFor="let item of im_select?.special_property?.text_items;index as i;"
                  [ngClass]="(item?.is_center)?'d-flex align-items-center justify-content-center':''">
                  <span *ngIf="item?.is_center && item?.text_type == specialTextType.FreeText"
                    [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" [style.color]="item?.color"
                    [style.font-size.px]="item?.font_size" [style.family]="item?.font_family">{{item?.text}}</span>
                  <span *ngIf="!item?.is_center && item?.text_type == specialTextType.FreeText"
                    [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" style="position: absolute"
                    [style.top.px]="item?.y" [style.left.px]="item?.x" [style.color]="item?.color"
                    [style.font-size.px]="item?.font_size" [style.family]="item?.font_family">{{item?.text}}</span>
                  <span
                    *ngIf="item?.is_center && (item?.text_type == specialTextType.SpeedSuggest || item?.text_type == specialTextType.SpeedLimit)"
                    [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" [style.color]="item?.color"
                    [style.font-size.px]="item?.font_size" [style.family]="item?.font_family">{{speed_text}}</span>
                  <span
                    *ngIf="!item?.is_center && (item?.text_type == specialTextType.SpeedSuggest || item?.text_type == specialTextType.SpeedLimit)"
                    [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" style="position: absolute"
                    [style.top.px]="item?.y" [style.left.px]="item?.x" [style.color]="item?.color"
                    [style.font-size.px]="item?.font_size" [style.family]="item?.font_family">{{speed_text}}</span>
                </div>
              </div>
            </div> -->
            <div class="clearfix mt-1">
              <p>{{im_select?.name}}</p>
            </div>
            <button [disabled]="!isAdd && !isCreate" class="btn btn-danger btn-block mt-1"
              (click)="OpenConfirmDeleteMediaModal()">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" bsModal #confirmDeleteMediaModal="bs-modal" tabindex="-1" role="dialog"
  aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div class="mt-3">
          <div class="panel-body text-center">
            <div class="d-flex justify-content-center align-items-center" style="min-height: 100px">
              <h5>คุณต้องการลบ <span class="text-primary"> {{im_select?.name}} </span> ใช่หรือไม่</h5>
            </div>
            <div class="d-flex justify-content-end mt-1">
              <button class="btn btn-secondary mr-1" (click)="confirmDeleteMediaModal.hide()"> ยกเลิก </button>
              <button class="btn btn-primary text-light ml-1" (click)="deleteImage(im_select)"> ตกลง </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div bsModal #createImageModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true" (onHide)="closeAddImage()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i><span>สร้างภาพ</span></h4>
        <button type="button" class="close text-light" (click)="createImageModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div *ngIf="is_loading_add" class="modal-body d-flex align-items-center justify-content-center">
        <div class="lds-dual-ring"></div>
      </div>
      <div *ngIf="!is_loading_add" class="modal-body">
        <form class="form-horizontal mt-2 mb-2">
          <div class="form-group row">
            <div class="col-md-12">
              <div class="d-flex align-items-center justify-content-center mt-1" style="min-height: 35px;">
                <label class="radio-container m-1">ใช้ได้กับทุกป้าย
                  <input id="isPubilcImRadio1" type="radio" name="isPubilcImRadio" [value]="true"
                    [(ngModel)]="is_public" [ngModelOptions]="{standalone: true}" (change)="setImagePublicType($event)">
                  <span class="radio-checkmark"></span>
                </label>
                <label class="radio-container m-1">ระบุป้าย
                  <input id="isPubilcImRadio1" type="radio" name="isPubilcImRadio" [value]="false"
                    [(ngModel)]="is_public" [ngModelOptions]="{standalone: true}" (change)="setImagePublicType($event)">
                  <span class="radio-checkmark"></span>
                </label>
              </div>
            </div>
          </div>

          <div *ngIf="!is_public" class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">ป้าย
                :</span></label>
            <div class="col-md-6">
              <kendo-combobox class="w-100" [data]="data_boardsign_modal" [allowCustom]="allowCustom"
                [textField]="'name'" [valueField]="'id'" [value]="boardsign_modal"
                (valueChange)="handleValueBoardsignModal(($event))" (filterChange)="handleFilterBoardsignModal($event)"
                [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="text-input"> <span class="float-sm-left float-md-right">ชื่อภาพ
                :</span></label>
            <div class="col-md-6">
              <input type="text" [(ngModel)]="selectItem.name" class="form-control" placeholder="" maxlength="50"
                [ngModelOptions]="{standalone: true}">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="select1"> <span class="float-sm-left float-md-right">ชนิดป้าย
                :</span></label>
            <div class="col-md-6">
              <kendo-combobox class="w-100" [data]="data_sign_type_create" [allowCustom]="allowCustom"
                [textField]="'name'" [valueField]="'id'" [value]="sign_type_create"
                (valueChange)="handleValueSignTypeModalCreate($event)"
                (filterChange)="handleFilterSignTypeModalCreate($event)" [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="text-input"> <span class="float-sm-left float-md-right">Template
                :</span></label>
            <div class="col-md-6">
              <div class="d-flex flex-wrap">
                <div *ngFor="let temp of template_list" class="d-flex align-items-center m-1 pointer"
                  (click)="selectTemplate(temp)">
                  <i class="fa fa-circle mr-1" [ngClass]="{ 'text-primary': temp?.id == select_template?.id }"></i>
                  <div class="position-relative m-1 bg-gray-600" [style.width.px]="temp?.width/4"
                    [style.height.px]="temp?.height/4">
                    <div *ngFor="let chil of temp?.children" class="position-absolute border border-dark"
                      [style.width.px]="chil?.width/4" [style.height.px]="chil?.height/4" [style.top.px]="chil?.top/4"
                      [style.left.px]="chil?.left/4">

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="text-input"> <span class="float-sm-left float-md-right">ตั้งค่า
                :</span></label>
            <div class="col-md-6">
              <div class="position-relative border border-dark" [style.width.px]="this.select_template?.width"
                [style.height.px]="this.select_template?.height">
                <div *ngFor="let chil of this.select_template?.children"
                  class="position-absolute border border-dark pointer"
                  [ngClass]="chil?.id == select_template_chil_id? 'bg-primary':'bg-gray-600'"
                  [style.width.px]="chil?.width" [style.height.px]="chil?.height" [style.top.px]="chil?.top"
                  [style.left.px]="chil?.left" (click)="selectChildrenTemplate(chil)">
                </div>
              </div>
              <div class="d-flex align-items-center flex-wrap mt-1" style="min-height: 35px;">
                <label class="radio-container">รูปภาพ
                  <input id="controlRadio1" type="radio" name="controlRadio" [value]="this.mediaSettingType.Image"
                    [(ngModel)]="select_setting_type" [ngModelOptions]="{standalone: true}"
                    (change)="setMediaTypeItem($event,select_template_chil_id,this.mediaSettingType.Image)">
                  <span class="radio-checkmark"></span>
                </label>
                <button *ngIf="select_setting_type == this.mediaSettingType.Image" title="เลือกรูป"
                  (click)="openMediaListCreateModal()" type="button" class="btn btn-sm btn-primary ml-2"><i
                    class="fa fa-plus"></i></button>
              </div>
              <div class="d-flex align-items-center flex-wrap mb-1" style="min-height: 35px;">
                <label class="radio-container">ข้อความ
                  <input id="controlRadio2" type="radio" name="controlRadio" [value]="this.mediaSettingType.Text"
                    [(ngModel)]="select_setting_type" [ngModelOptions]="{standalone: true}"
                    (change)="setMediaTypeItem($event,select_template_chil_id,this.mediaSettingType.Text)">
                  <span class="radio-checkmark"></span>
                </label>
                <textarea *ngIf="select_setting_type == this.mediaSettingType.Text" class="form-control ml-2"
                  style="width: 150px; min-width: 150px;" rows="1" [(ngModel)]="item_set_text" placeholder="ข้อความ"
                  [ngModelOptions]="{standalone: true}" (input)="genImTextChange($event.target.value)"></textarea>
                <button *ngIf="select_setting_type == this.mediaSettingType.Text" title="ลดขนาดข้อความ" type="button"
                  class="btn btn-sm btn-pill btn-danger ml-1 mr-1"
                  (click)="changeTextSize(this.select_template_chil_id,-1)"><i class="fa fa-minus"></i></button>
                <span title="ขนาดข้อความ {{text_size}} CM"
                  *ngIf="select_setting_type == this.mediaSettingType.Text">{{text_size}} CM</span>
                <button *ngIf="select_setting_type == this.mediaSettingType.Text" title="เพิ่มขนาดข้อความ" type="button"
                  class="btn btn-sm btn-pill btn-success ml-1 mr-1"
                  (click)="changeTextSize(this.select_template_chil_id,1)"><i class="fa fa-plus"></i></button>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="text-input"> <span class="float-sm-left float-md-right">ตัวอย่าง
                :</span></label>
            <div class="col-md-6">
              <div id="preview1" title="{{this.select_template?.width}}x{{this.select_template?.height}}"
                class="position-relative" [style.width.px]="this.select_template?.width"
                [style.height.px]="this.select_template?.height">
                <div *ngFor="let chil of this.select_template?.children" class="position-absolute"
                  [style.width.px]="chil?.width" [style.height.px]="chil?.height" [style.top.px]="chil?.top"
                  [style.left.px]="chil?.left" style="background-color: black">
                  <div class="item-preview danaya-font">
                    <div *ngIf="chil?.type == this.mediaSettingType.Text && chil?.text != null"
                      class="item-preview-text" style="white-space: pre-line"
                      [style.font-size.px]="chil?.text_size /(sign_type_create.pit/10)"
                      [style.line-height.px]="(chil?.text_size /(sign_type_create.pit/10))" [innerHTML]="chil?.text"
                      [style.max-height.px]="((chil?.text_size /(sign_type_create.pit/10)) * 3)"></div>
                    <img *ngIf="chil?.type == this.mediaSettingType.Image && chil?.img_url != null"
                      [src]="chil?.img_url" class="item-preview-img">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">ประเภทการใช้งาน
                :</span></label>
            <div class="col-md-6">
              <kendo-combobox class="w-100" [data]="use_type_list" [allowCustom]="allowCustom" [textField]="'name'"
                [valueField]="'id'" [value]="use_type_modal" (valueChange)="handleValueUseTypeModal($event)"
                [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">สถานะ
                :</span></label>
            <div class="col-md-6">
              <kendo-combobox class="w-100" [data]="status_list" [allowCustom]="allowCustom" [textField]="'name'"
                [valueField]="'id'" [value]="status_modal" (valueChange)="handleValueStatusModal($event)"
                [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>
        </form>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary" (click)="createImageModal.hide()">ยกเลิก</button>
          <button type="button" class="btn btn-primary text-white ml-2"
            (click)="clickGenerate(this.sign_type_create.id)"
            [disabled]="!(selectItem.name && sign_type_create && status_modal != null) || (!is_public && !boardsign_modal)">สร้าง</button>
        </div>

      </div>
    </div>
  </div>
</div>

<div bsModal #mediaListCreateModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i><span>Media</span></h4>
        <button type="button" class="close text-light" (click)="mediaListCreateModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="this.createImageModal.isShown">
          <app-media-selector [sign_height]="medie_sign_height" [media_type]="mediaType.Image"
            [ratio]="sign_type_create?.aspect_ratio" (onComponentClosed)="closeMediaCreateImModal($event)"
            [toggle]="toggleOpenMediaModal">
          </app-media-selector>
        </div>
      </div>
    </div>
  </div>
</div>
<swal #swalSuccess title="<div class='text-light'>สำเร็จ</div>" type="success" text="" icon="success"></swal>
<swal #swalError title="<div class='text-light'>ไม่สำเร็จ</div>" html="<div class='text-light'>{{res_message}}</div>"
  type="error" icon="error"></swal>