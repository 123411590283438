<div class="panel-layer-body bg-gray-900">
  <div [ngClass]="is_toggle ? 'panel-layer' : 'panel-layer-full'">
    <div class="search-penal bg-gray-700 position-reletive scrollbar-samll-style">
      <div class="d-flex align-items-center justify-content-between search-penal-inside scrollbar-samll-style">
        <div class="d-flex search-BG">
          <div #scrollSearch class="search-filter-BG">
            <div *ngFor="let i of arr_search;index as idx" class="inner-BG">
              <div class="ml-3 mr-2">หมายเลขทางหลวง</div>
              <div class="select-route">
                <kendo-combobox class="w-100" [data]="data_route" [allowCustom]="allowCustom" [textField]="" [valueField]=""
                  [value]="i.route" (valueChange)="handleValueRoute($event,idx)" (filterChange)="handleFilterRoute($event)"
                  [placeholder]="'โปรดระบุ'" [filterable]="true">
                </kendo-combobox>
              </div>
    
              <div class="ml-3 mr-1">กิโลเมตร เริ่มต้น</div>
              <div>
                <input type="number" [min]="min" [max]="max" [(ngModel)]="i.st_km" name="text-st-km"  (focusout)="i.st_km=chkKm(i.st_km)" class="form-control input-km text-center"
                  placeholder="">
              </div>
              <div class="ml-2 mr-1">สิ้นสุด</div>
              <div>
                <input type="number" [min]="min" [max]="max" [(ngModel)]="i.en_km" name="text-en-km" class="form-control input-km text-center"
                  placeholder="">
              </div>
              <div class="">
                <button type="button" class="btn btn-sm btn-outline-primary ml-2 bt-search remove-BT"
              (click)="arr_search.splice(idx, 1)"> <i class="fa fa-times"></i></button>
              </div>
            </div>
          </div>
          <div>
            <button type="button" class="btn btn-sm btn-primary ml-2 bt-search"
              (click)="addArrSearch()"> <i class="fa fa-plus"></i></button>
            <button type="button" class="btn btn-sm btn-primary ml-2 bt-search"
              (click)="search(1)"> <i class="fa fa-search"></i></button>
          </div>
        </div>
        
        <div class="d-flex align-items-center">
          <img src="assets/img/social-icon.png" width="35" height="35" class="mr-2 pointer"
            (click)="openSocialsPanel()">
          <img src="assets/img/google-maps.png" width="40" height="40" class="mr-2 pointer" (click)="openGoogleMap()">
          <div class="d-flex align-items-center">
            <i class="fa fa-caret-left ml-1 mr-2 pointer bt-caret-icon" (click)="clickLeft()"></i>
            <i class="fa fa-caret-right mr-3 pointer bt-caret-icon" (click)="clickRight()"></i>
          </div>

        </div>
      </div>

    </div>
    <div class="o-scroll" [ngClass]="{'mh-87': arr_search.length>1}">

      <div class="traffic-layer-BG">
        <!-- <div [style.min-width.px]="this.arr_w_corridor[0]">
          1
        </div>
        <div class="tag-BG">
          <div class="">
            <i class="fa fa-caret-left ml-1 mr-2"></i>
            <i class="fa fa-caret-right ml-1 mr-2"></i>
          </div>
        </div> -->
        <div class="innerTraffic-BG" *ngFor="let arr_i of clicked_search_arr; let idx=index" [style.min-width.px]="this.arr_w_corridor[idx]">

          <!-- START -->

          <div #road class="position-reletive inner-corridor-BG"
          [style.height.px]="road_layer_height+((speed_time_height*predict_speed?.length)+(speed_time_margin*2))+(speed_time_margin)+speed_time_margin-200">
          <div class="position-absolute" [style.left.px]="km_width_px*(i)" [style.width.px]="km_width_px"
            [style.top.px]="height_plus+speed_time_margin" *ngFor="let item of arr_road_list[idx]; let i = index">
            <div [style.height.px]="km_point_height"
              class="d-flex align-items-center justify-content-center bg-normal-gray">
              <!-- <i *ngIf="i!=0" class="fa fa-circle pointer" [ngClass]="item.inc_out ? 'text-red' : 'text-warning'"></i> -->
              <i title="Incident" *ngIf="i!=0 && item.inc_out" class="fa fa-circle pointer text-red"
                (click)="clickIncKmList($event,item,directionEnum.OUT)"></i>
              <i title="" *ngIf="i!=0 && !item.inc_out" class="fa fa-circle pointer text-warning"
                (click)="clickAddInc(2,directionEnum.OUT,item.km)"></i>
              <div *ngIf="i==0" style="font-size:12px">OUT</div>
            </div>
            <div [style.height.px]="lane_height"
              [style.top.px]="height_plus+((lane_height)*i2)+km_point_height+speed_time_margin" class="road-lane-out"
              *ngFor="let l of item?.lanes; let i2 = index">

            </div>

            <div (mouseenter)="hoverKm(i,item)" [style.height.px]="center_km_height"
              [style.top.px]="height_plus+((lane_height)*max_lane)+km_point_height+speed_time_margin"
              class="d-flex align-items-center justify-content-center bg-normal-gray">
              <div *ngIf="i!=0">{{item?.km}}</div>
              <div *ngIf="i==0" style="font-size:16px">KM</div>
            </div>
            <div [style.height.px]="lane_height"
              [style.top.px]="height_plus+(((lane_height)*max_lane)+km_point_height+center_km_height)+((lane_height)*i2)+speed_time_margin"
              class="road-lane-in" *ngFor="let l of item?.lanes; let i2 = index">

            </div>
            <div [style.top.px]="height_plus+((lane_height)*max_lane*2)+km_point_height+speed_time_margin"
              [style.height.px]="km_point_height" class="d-flex align-items-center justify-content-center bg-normal-gray">
              <!-- <i *ngIf="i!=0" class="fa fa-circle pointer" [ngClass]="item.inc_in ? 'text-red' : 'text-warning'"></i> -->
              <i title="Incident" *ngIf="i!=0 && item.inc_in" class="fa fa-circle pointer text-red"
                (click)="clickIncKmList($event,item,directionEnum.IN)"></i>
              <i title="" *ngIf="i!=0 && !item.inc_in" class="fa fa-circle pointer text-warning"
                (click)="clickAddInc(2,directionEnum.IN,item.km)"></i>
              <div *ngIf="i==0" style="font-size:12px">IN</div>
            </div>
          </div>
          <div #TrafficSpeed class="position-absolute traffic-speed">
            <div *ngFor="let item of arr_traffic_out[idx]; let i = index">
              <div *ngFor="let l of item?.by_lane; let ii = index" style="display: inline-block;position: absolute"
                [style.width.px]="km_width_px" [style.height.px]="lane_height"
                [style.left.px]="km_width_px*(item.km-clicked_search_arr[idx].st_km+1)"
                [style.top.px]="height_plus+((lane_height)*ii)+km_point_height+speed_time_margin"
                [style.background-color]="l.color?.replace('#FF', '#')">
              </div>
            </div>
            <div *ngFor="let item of arr_traffic_in[idx]; let i = index">
              <div *ngFor="let l of item?.by_lane; let ii = index" style="display: inline-block;position: absolute"
                [style.width.px]="km_width_px" [style.height.px]="lane_height"
                [style.left.px]="km_width_px*(item.km-clicked_search_arr[idx].st_km+1)"
                [style.top.px]="height_plus+(lane_height*(max_lane-l.lane))+(lane_height*(max_lane))+km_point_height+center_km_height+speed_time_margin"
                [style.background-color]="l.color?.replace('#FF', '#')">
              </div>
            </div>
          </div>
          <div #LMS class="position-absolute">
            <div *ngFor="let item of arr_lmsList[idx];index as i">

              <div #LMSOUT class="lms-station-out" *ngIf="item?.station.direction == this.directionEnum.OUT || item?.station.direction==3"
                [style.left.px]="km_width_px*(item?.station.km-clicked_search_arr[idx].st_km+1) + ((km_width_px/1000)*(station?.m?station?.m:0)) + (km_width_px/2)"
                [style.top.px]="0+height_plus+((lane_height)*0)+km_point_height+speed_time_margin+item?.setY"
                style="display: inline-block;position: absolute">

                <div *ngFor="let layer_b of item?.traffic_info_view?.back_layer;index as i2">
                  <div class="d-flex justify-content-end">
                    <div class="d-flex"
                      [ngClass]="{'justify-content-center': layer_b.length>0 && layer_b.length<=2, 'justify-content-start': layer_b.length>2}">
                      <div class="lms-sign-box-back pointer" [style.height.px]="sign_height"
                        [style.width.px]="get_sign_width(sign?.ratio,sign_height)"
                        *ngFor="let sign of layer_b;index as i3" (click)="clickOpenInfomodal(item)"
                        title="{{item?.name}}"
                        [ngClass]="{'position-absolute':item.group_id==1027}"
                        [style.top.px]="item.group_id==1027? (sign.sign_no==1? -2:-1)*get_sign_width(sign?.ratio,sign_height):0"
                        [style.left.px]="item.group_id==1027? lane_height*(sign.cover_lane-1):0">

                        <app-sign-image-item [media]="sign?.media?.media" [sign_height]="sign_height"
                          [ratio]="sign?.ratio" [is_board]="true" [config_road]="p?.config_road" [rain]="p?.rain"
                          [resize]="(sign_height/sign?.sign_type_object?.height)"
                          *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                        </app-sign-image-item>
                        <app-playlist-item [show_count]="false" [config_road]="p?.config_road" [rain]="p?.rain"
                          [resize]="(sign_height/sign?.sign_type_object?.height)"
                          *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist" [is_board]="true"
                          [playlist]="sign?.media?.items" [height]="sign_height"
                          [width]="get_sign_width(sign?.ratio,sign_height)">
                        </app-playlist-item>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngFor="let layer_f of item?.traffic_info_view?.front_layer;index as i2">
                  <div class="d-flex"
                    [ngClass]="{'justify-content-center': layer_f.length>0 && layer_f.length<=2, 'justify-content-start': layer_f.length>2}">
                    <div class="lms-sign-box pointer" [style.height.px]="sign_height"
                      [style.width.px]="get_sign_width(sign?.ratio,sign_height)" *ngFor="let sign of layer_f;index as i3"
                      (click)="clickOpenInfomodal(item)" title="{{item?.name}}" [ngClass]="{'position-absolute':item.group_id==1027}"
                        [style.top.px]="item.group_id==1027? (sign.sign_no==1? -2:-1)*get_sign_width(sign?.ratio,sign_height):0"[style.left.px]="item.group_id==1027? lane_height*(sign.cover_lane-1):0">

                      <app-sign-image-item [media]="sign?.media?.media" [sign_height]="sign_height" [ratio]="sign?.ratio"
                        [is_board]="true" [config_road]="p?.config_road" [rain]="p?.rain"
                        [resize]="(sign_height/sign?.sign_type_object?.height)"
                        *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                      </app-sign-image-item>
                      <app-playlist-item [show_count]="false" [config_road]="p?.config_road" [rain]="p?.rain"
                        [resize]="(sign_height/sign?.sign_type_object?.height)"
                        *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist" [is_board]="true"
                        [playlist]="sign?.media?.items" [height]="sign_height"
                        [width]="get_sign_width(sign?.ratio,sign_height)">
                      </app-playlist-item>
                    </div>
                  </div>
                </div>
                <div *ngIf="item?.station?.station_type == 1" class="text-center text-black"><i class="fa fa-car"></i>
                </div>
              </div>
              <!-- [style.top.px]="0+height_plus+(lane_height*(max_lane-item.lane_count))+(lane_height*(max_lane))+km_point_height+center_km_height+speed_time_margin" -->
              <div #LMSIN class="lms-station-in" *ngIf="item?.station.direction == this.directionEnum.IN || item?.station.direction==3"
                [style.left.px]="km_width_px*(item?.station.km-clicked_search_arr[idx].st_km+1) + ((km_width_px/1000)*(station?.m?station?.m:0)) + (km_width_px/2)"
                [style.top.px]="0+height_plus+(lane_height*(max_lane-(item?.traffic_info_view?.front_layer.length==1?1:item.lane_count)))+(lane_height*(max_lane))+km_point_height+center_km_height+speed_time_margin-item?.setY"
                style="display: inline-block;position: absolute">
                <div *ngFor="let layer_b of item?.traffic_info_view?.back_layer;index as i2">
                  <div class="d-flex justify-content-end">
                    <div class="d-flex"
                      [ngClass]="{'justify-content-center': layer_b.length>0 && layer_b.length<=2, 'justify-content-start': layer_b.length>2}">
                      <div class="lms-sign-box-back pointer" [style.height.px]="sign_height"
                        [style.width.px]="get_sign_width(sign?.ratio,sign_height)"
                        *ngFor="let sign of layer_b;index as i3" (click)="clickOpenInfomodal(item)"
                        title="{{item?.name}}"
                        [ngClass]="{'position-absolute':item.group_id==1027}"
                        [style.top.px]="item.group_id==1027? (sign.sign_no==1? -2:-1)*get_sign_width(sign?.ratio,sign_height):0"[style.left.px]="item.group_id==1027? (lane_height*(sign.cover_lane-1)-lane_height):0">

                        <app-sign-image-item [media]="sign?.media?.media" [sign_height]="sign_height"
                          [ratio]="sign?.ratio" [is_board]="true" [config_road]="p?.config_road" [rain]="p?.rain"
                          [resize]="(sign_height/sign?.sign_type_object?.height)"
                          *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                        </app-sign-image-item>
                        <app-playlist-item [show_count]="false" [config_road]="p?.config_road" [rain]="p?.rain"
                          [resize]="(sign_height/sign?.sign_type_object?.height)"
                          *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist" [is_board]="true"
                          [playlist]="sign?.media?.items" [height]="sign_height"
                          [width]="get_sign_width(sign?.ratio,sign_height)">
                        </app-playlist-item>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngFor="let layer_f of item?.traffic_info_view?.front_layer;index as i2">
                  <div class="d-flex"
                    [ngClass]="{'justify-content-center': layer_f.length>0 && layer_f.length<=2, 'justify-content-start': layer_f.length>2}">
                    <div class="lms-sign-box pointer" [style.height.px]="sign_height"
                      [style.width.px]="get_sign_width(sign?.ratio,sign_height)" *ngFor="let sign of layer_f;index as i3"
                      (click)="clickOpenInfomodal(item)" title="{{item?.name}}" [ngClass]="{'position-absolute':item.group_id==1027}"
                      [style.top.px]="item.group_id==1027? (sign.sign_no==1? -2:-1)*get_sign_width(sign?.ratio,sign_height):0"
                      [style.left.px]="item.group_id==1027? (lane_height*(sign.cover_lane-1)-lane_height):0">

                      <app-sign-image-item [media]="sign?.media?.media" [sign_height]="sign_height" [ratio]="sign?.ratio"
                        [is_board]="true" [config_road]="p?.config_road" [rain]="p?.rain"
                        [resize]="(sign_height/sign?.sign_type_object?.height)"
                        *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                      </app-sign-image-item>
                      <app-playlist-item [show_count]="false" [config_road]="p?.config_road" [rain]="p?.rain"
                        [resize]="(sign_height/sign?.sign_type_object?.height)"
                        *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist" [is_board]="true"
                        [playlist]="sign?.media?.items" [height]="sign_height"
                        [width]="get_sign_width(sign?.ratio,sign_height)">
                      </app-playlist-item>
                    </div>
                  </div>
                </div>
                <div *ngIf="item?.station?.station_type == 1" class="text-center text-black"><i class="fa fa-car"></i>
                </div>
              </div>
            </div>

          </div>
          <div #graph class="position-absolute"
            [style.height.px]="(speed_time_height*arr_predict_speed[idx]?.length)+(speed_time_margin*2)+dir_tab_height+ search_header_height"
            [style.top.px]="height_plus+road_layer_height+(speed_time_margin)+speed_time_margin">
            <div class="d-flex align-items-center">
              <div class="dir-menu d-flex align-items-center justify-content-center" [style.min-width.px]="km_width_px"
                (click)="toggleDir(1)" [ngClass]="{'dir-tab': toggle_dir ==1 ,'dir-tab-non-ac': toggle_dir !=1}">
                <div class="text-center">
                  IN
                </div>
              </div>
              <div class="dir-menu d-flex align-items-center justify-content-center" [style.min-width.px]="km_width_px"
                (click)="toggleDir(2)" [ngClass]="{'dir-tab': toggle_dir ==2 ,'dir-tab-non-ac': toggle_dir !=2}">
                <div class="text-center">
                  OUT
                </div>
              </div>
              <div class="rounded-circle d-flex justify-content-center align-items-center pointer ml-2"
                style="width: 25px;height: 25px;background-color:black;" [tooltip]="popTemplate" placement="right"
                containerClass="label-speed-panel-tooltip">
                <i class="fa fa-info"></i>
              </div>
              <ng-template #popTemplate>
                <div class="d-flex ml-label-speed-panel text-dark">
                  <div class="label-speed text-light d-flex justify-content-center align-items-center rounded-left"
                    style="background-color:black">
                    <div><i class="icon-speedometer mr-1"></i>Speed</div>
                  </div>
                  <div class="label-speed speed-color-red d-flex justify-content-center align-items-center">
                    {{speed[0]}} - {{speed[1]}}
                  </div>
                  <div class="label-speed speed-color-orange d-flex justify-content-center align-items-center">
                    {{speed[1]}} - {{speed[2]}}
                  </div>
                  <div class="label-speed speed-color-yellow d-flex justify-content-center align-items-center">
                    {{speed[2]}} - {{speed[3]}}
                  </div>
                  <div
                    class="label-speed speed-color-green d-flex justify-content-center align-items-center rounded-right">
                    {{speed[3]}} +
                  </div>
                </div>
              </ng-template>

            </div>
            <div #IN *ngIf="toggle_dir == 1" class="d-flex dir-tab"
              [style.width.px]="(clicked_search_arr[idx].en_km-clicked_search_arr[idx].st_km+2)*km_width_px"
              [style.height.px]="(speed_time_height*arr_predict_speed[idx]?.length)+(speed_time_margin*2)">
              <div [style.margin-top.px]="speed_time_margin" [style.margin-bottom.px]="speed_time_margin">
                <div *ngFor="let item of arr_predict_speed[idx];index as i">
                  <div class="position-relative">
                    <div class="km-box position-absolute" [style.width.px]="km_width_px"
                      [style.height.px]="speed_time_height" [style.top.px]="(speed_time_height*i)" [style.left.px]="0">
                      <div [style.height.px]="speed_time_height" class="d-flex justify-content-center align-items-center"
                        [ngClass]="{'current-time-text': i == 5 }">
                        <small>{{item.ts | date:'HH:mm'}}</small>
                      </div>
                    </div>
                    <div class="km-box position-absolute" [style.width.px]="km_width_px"
                      *ngFor="let data of item?.data_in" [style.left.px]="(data.km+1-clicked_search_arr[idx].st_km)*km_width_px"
                      [style.height.px]="speed_time_height" [style.top.px]="(speed_time_height*i)"
                      [style.background-color]="data.color?.replace('#FF', '#')">
                      <div class="d-flex justify-content-center align-items-center text-dark"
                        [style.height.px]="speed_time_height"
                        [ngClass]="{'border-light border-top border-bottom': (i == 5)}">
                        <small> {{data.speed | number:'1.0-0'}}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div #OUT *ngIf="toggle_dir == 2" class="d-flex dir-tab"
              [style.width.px]="(clicked_search_arr[idx].en_km-clicked_search_arr[idx].st_km+2)*km_width_px"
              [style.height.px]="(speed_time_height*arr_predict_speed[idx]?.length)+(speed_time_margin*2)">
              <div [style.margin-top.px]="speed_time_margin" [style.margin-bottom.px]="speed_time_margin">
                <div *ngFor="let item of arr_predict_speed[idx];index as i">
                  <div class="position-relative">
                    <div class="km-box position-absolute" [style.width.px]="km_width_px"
                      [style.height.px]="speed_time_height" [style.top.px]="(speed_time_height*i)" [style.left.px]="0">
                      <div [style.height.px]="speed_time_height" class="d-flex justify-content-center align-items-center"
                        [ngClass]="{'current-time-text': i == 5 }">
                        <small>{{item.ts | date:'HH:mm'}}</small>
                      </div>
                    </div>
                    <div class="km-box position-absolute" [style.width.px]="km_width_px"
                      *ngFor="let data of item?.data_out" [style.left.px]="(data.km+1-clicked_search_arr[idx].st_km)*km_width_px"
                      [style.height.px]="speed_time_height" [style.top.px]="(speed_time_height*i)"
                      [style.background-color]="data.color?.replace('#FF', '#')">
                      <div class="d-flex justify-content-center align-items-center text-dark"
                        [style.height.px]="speed_time_height"
                        [ngClass]="{'border-light border-top border-bottom': (i == 5)}">
                        <small> {{data.speed | number:'1.0-0'}}</small>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div *ngIf="is_inc_list_layer && false" [style.top.px]="inc_list_layer_y" [style.left.px]="inc_list_layer_x"
            class="bg-gray-900 position-absolute" style="height: auto;z-index:1000 ;">
            <div class="inc-map-list-header bg-gray-900 d-flex align-items-center justify-content-between">
              <div style="font-size:15px">
                <i class="fa fa-road mr-1 ml-2"></i>
                ทล.
                {{inc_select_item_route?.routes[0]?.loc0?.route}}
                ({{inc_select_item_route?.routes[0]?.loc0?.km}}+000)
              </div>
              <div class="d-flex mr-3" style="font-size:18px">
                <i title="Add Incident" (click)="clickAddInc(1,inc_click_dir)" class="fa fa-plus pointer ml-1 mr-3"></i>
                <div class="close-inc-list pointer text-center" title="Close" style="width:25px"
                  (click)="clickCloseIncList()">
                  <i style="font-size:14px;margin-top:10px" class="fa fa-close"></i>
                </div>
              </div>
            </div>
            <div class="inc-map-list-body bg-gray-700 scrollbar-style-popup"
              style="height: auto;max-height: 160px;overflow-y: auto">
              <div *ngFor="let inc of inc_select_item_list; let i = index"
                class="d-flex justify-content-start align-items-stretch inc-item-panel-map">
                <!-- <div title="${status_text}" class="inc-status-tab h-100 border-bottom border-dark ${status}"></div> -->
                <div class="inc-status-tab h-100 border-bottom border-dark" [ngClass]="{'bg-danger': inc?.status == this.incStatusEnum.Created,
                  'bg-primary': inc?.status == this.incStatusEnum.Approved,
                  'bg-warning': inc?.status == this.incStatusEnum.Operated,
                  'bg-success': inc?.status == this.incStatusEnum.Clear,
                  'bg-gray-400': inc?.status == this.incStatusEnum.Rejected}">
                  <div *ngIf="inc?.status == this.incStatusEnum.Created" title="Created" class="w-100 h-100"></div>
                  <div *ngIf="inc?.status == this.incStatusEnum.Approved" title="Approved" class="w-100 h-100"></div>
                  <div *ngIf="inc?.status == this.incStatusEnum.Operated" title="Operating" class="w-100 h-100"></div>
                  <div *ngIf="inc?.status == this.incStatusEnum.Clear" title="Clear" class="w-100 h-100"></div>
                  <div *ngIf="inc?.status == this.incStatusEnum.Rejected" title="Rejected" class="w-100 h-100"></div>
                </div>
                <div class="ml-auto inc-item-info d-flex border-top border-bottom border-dark">
                  <div class="icon-panel h-100 d-flex align-items-center justify-content-center">
                    <div class="position-relative icon-item-body">
                      <!-- <i class="fab fa-line icon-size text-success bg-light"></i> -->
                      <img title="Line" *ngIf="inc?.src_type == incSrcTypeEnum.Line" class="icon-inc"
                        src="assets/img/line-icon.png" width="20" height="20" />
                      <img title="Facebook" *ngIf="inc?.src_type == incSrcTypeEnum.Facebook" class="icon-inc"
                        src="assets/img/fb-icon.png" width="20" height="20" />
                      <img title="Twitter" *ngIf="inc?.src_type == incSrcTypeEnum.Twitter" class="icon-inc"
                        src="assets/img/tw-icon.png" width="20" height="20" />
                      <img title="Jarvis" *ngIf="inc?.src_type == incSrcTypeEnum.JARVIS" class="icon-inc"
                        src="assets/img/jarvis-icon.png" width="20" height="20" />
                      <div title="{{inc?.occur_type_obj?.type_name}}"
                        class="rounded-circle icon-item bg-light d-flex justify-content-center align-items-center text-dark">
                        <!--Image URL-->
                        <img
                          *ngIf="inc?.occur_type_obj?.icon && inc?.occur_type_obj?.icon_type == incTypeIconEnum.ImageUrl"
                          style="width: 40px; height: 40px;" [src]="inc?.occur_type_obj?.icon">
                        <!--Image Base64-->
                        <img
                          *ngIf="inc?.occur_type_obj?.icon && inc?.occur_type_obj?.icon_type == incTypeIconEnum.ImageBase64"
                          style="width: 40px; height: 40px;" [src]="'data:image/png;base64,' + inc?.occur_type_obj?.icon">
                        <!--Image Font Awesome-->
                        <span
                          *ngIf="inc?.occur_type_obj?.icon && inc?.occur_type_obj?.icon_type == incTypeIconEnum.FontAwesome"
                          [class]="inc?.occur_type_obj?.icon"></span>
                      </div>
                    </div>
                  </div>
                  <div class="desc-panel h-100 position-relative">
                    <div class="pt-2 pb-2 h-100">
                      <div class="desc">
                        {{inc.name}}
                      </div>
                      <div class="km-time">
                        <div style="margin-top:2px"><i class="fa fa-clock-o mr-1" style="font-size:17px"></i>{{inc?.ts|
                          date:'HH:mm dd/MM/yyyy'}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div title="Information" (click)="openIncPanel(inc)"
                  class="click-info-tab d-flex align-items-center justify-content-center h-100 pointer border border-left-0 border-dark">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>

          <!--END-->

        </div>
        <div class="tag-BG" [style.top.px]="height_plus+speed_time_margin-50">
          <div>
            <div class="txt-route">ทล.{{+clicked_search_arr[idx].route||'-'}}</div>
            <div class="txt-route">ทล.{{+clicked_search_arr[idx].route||'-'}}</div>
          </div>
        </div>
        </div>
      </div>
      
    </div>

  </div>

  <div id="map-traffic-info" [ngClass]="{'map-box': is_toggle, 'map-box-none': !is_toggle}">

  </div>

  <app-socials-information [toggle]="socials_panel_toggle" (onClose)="closeSocialsPanel()"></app-socials-information>
  <!-- <app-incident-information [toggle]="inc_toggle" [action_type]="inc_action_type" [tab_id]="inc_tab_id"
      [item]="inc_select_item" (onClose)="closeIncPanel($event)" [point]="point_data"></app-incident-information>
    <app-boardsign-list [toggle]="lms_toggle" [item]="lms_select_item" (onClose)="closeLMSPanel($event)">
    </app-boardsign-list> -->
</div>

<button *ngIf="values_fromMonitoring" class="xBT" (click)="event.emit(true);bsModalRef.hide()">&times;</button>

<!-- MAIMAI -->
<div bsModal #boardsingInfoModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" style="z-index: 1041;"
  aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="onHideInfoModal()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-950">
        <div class="modal-title d-flex justify-content-center align-items-center w-100"> <i
            class="icon-grid icon mr-2 h5"></i><span class="h4">{{boardsignInfo?.name}}
          </span>
        </div>
        <button *ngIf="!values_fromMonitoring" type="button" class="close text-light" (click)="clickCloseInfoModal()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-0 m-0 modal-body-h scrollbar-style" style="overflow: auto;">
        <div class="rounded modal-panel-h pt-3">
          <div class="d-flex justify-content-center">
            <div class="mr-2">
              <div class="sign-dir-text mb-1 text-dark d-flex justify-content-center"><span
                  class="small text-light">ป้ายหน้า</span></div>
              <div *ngFor="let l of boardsignInfo?.layers;index as ii;">
                <div class="d-flex justify-content-center flex-wrap">
                  <div *ngFor="let sign of l;index as iii;">
                    <div *ngIf="sign?.dir == signDirection.Front">
                      <div class="sign-box" [style.width.px]="get_sign_width(sign?.ratio,set_sign_height_modal)"
                        [style.height.px]="set_sign_height_modal" *ngIf="sign?.dir == signDirection.Front">
                        <app-sign-image-item [media]="sign?.media?.media" [sign_height]="set_sign_height_modal"
                          [ratio]="sign?.ratio" [is_board]="true" [config_road]="config_road"
                          [rain]="boardsignInfo?.rain" [resize]="(set_sign_height_modal/sign?.sign_type_object?.height)"
                          *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                        </app-sign-image-item>
                        <app-playlist-item [show_count]="true" [config_road]="config_road" [rain]="boardsignInfo?.rain"
                          [resize]="(set_sign_height_modal/sign?.sign_type_object?.height)"
                          *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                          [playlist]="sign?.media?.items" [height]="set_sign_height_modal"
                          [width]="get_sign_width(sign?.ratio,set_sign_height_modal)">
                        </app-playlist-item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="boardsignInfo?.is_dir_back" class="ml-2">
              <div class="sign-dir-text mb-1 text-dark d-flex justify-content-center"><span
                  class="small text-light">ป้ายหลัง</span></div>
              <div *ngFor="let l of boardsignInfo?.layers;index as ii;" [style.height.px]="set_sign_height_modal+4">
                <div class="d-flex justify-content-center flex-wrap">
                  <div *ngFor="let sign of l;index as iii;">
                    <div *ngIf="sign?.dir == signDirection.Back">
                      <div class="sign-box" [style.width.px]="get_sign_width(sign?.ratio,set_sign_height_modal)"
                        [style.height.px]="set_sign_height_modal" *ngIf="sign?.dir == signDirection.Back">
                        <app-sign-image-item [media]="sign?.media?.media" [sign_height]="set_sign_height_modal"
                          [ratio]="sign?.ratio" [is_board]="true" [config_road]="config_road"
                          [rain]="boardsignInfo?.rain" [resize]="(set_sign_height_modal/sign?.sign_type_object?.height)"
                          *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                        </app-sign-image-item>
                        <app-playlist-item [show_count]="true" [config_road]="config_road" [rain]="boardsignInfo?.rain"
                          [resize]="(set_sign_height_modal/sign?.sign_type_object?.height)"
                          *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                          [playlist]="sign?.media?.items" [height]="set_sign_height_modal"
                          [width]="get_sign_width(sign?.ratio,set_sign_height_modal)">
                        </app-playlist-item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="boardsignInfo?.group_id!==1012" class="bg-gray-900 w-100 text-center mt-3 mb-1 d-flex justify-content-center align-items-center"
            style="height: 45px">
            ข้อมูลอุปกรณ์
          </div>
          <div *ngIf="boardsignInfo?.group_id!==1012">
            <div *ngFor="let de of devices;index as i;">
              <div class="d-flex justify-content-center mt-2">
                <!-- <div class="bg-gray-900 pl-3 pr-3 pt-1 pb-1 rounded">
                    {{de?.view}}
                  </div> -->
                <div class="bg-gray-900 pl-3 pr-3 pt-1 pb-1 rounded">
                  {{de?.name}}
                </div>
              </div>
              <div class="d-flex justify-content-center align-items-center mt-2">
                <i *ngIf="(de?.url)" (click)="clickCameraImg(de,lms_item?.station)" title="+"
                  class="fa fa-image pointer text-info mr-2" style="font-size: 20px"></i>
                <i *ngIf="de?.rtsp_link" (click)="clickCameraRtsp(de,lms_item?.station)" title="Stream Rtsp"
                  class="fa fa-play-circle-o fa-2x pointer text-info"></i>
                <i *ngIf="de?.cctv_flv" (click)="clickCameraFlv(de,lms_item?.station)" title="Stream FLV"
                  class="fa fa-play-circle-o fa-2x pointer text-info"></i>
              </div>
              <div class="p-2 d-flex justify-content-center">
                <img *ngIf="de?.url" [src]="de?.url|linktime" width="100%" height="auto" style="max-width: 600px" />
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer bg-gray-950 panel-layer-footer d-flex justify-content-end align-items-center">
        <div>
          <button class="btn btn-outline-danger mr-2" *ngIf="values_fromMonitoring" (click)="gotoBoardsign()">เปลี่ยนป้าย</button>
          <button type="button" class="btn btn-danger mr-1 text-white" *ngIf="values_fromMonitoring" (click)="event.emit(true);bsModalRef.hide()">
            ปิด
          </button>
          <button type="button" class="btn btn-danger mr-1 text-white" *ngIf="!values_fromMonitoring" (click)="clickCloseInfoModal()">
            ปิด
          </button>
        </div>
        <div>
         <button *ngIf="_chgSign" type="button" class="btn btn-primary text-white ml-1"
            (click)="openChangeMediaModal(boardsignInfo?.id)"><i class="fa fa-eye"></i>
            เปลี่ยนป้าย</button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-change-media-panel [clickOpen]="clickOpenChangeMedia" [board_select_list]="board_select_list" [isCorridorControl]="true"
  (onClose)="closeChangeMediaModal($event)"></app-change-media-panel>

<div *ngIf="is_toggle_camera_rtsp">
  <app-cctv-display-rtsp [item]="this.camera_rtsp_item" [station]="boardsignInfo?.station"
    [toggle]="is_toggle_camera_rtsp" (onClose)="closeCameraRtsp($event)"></app-cctv-display-rtsp>
</div>

<div *ngIf="is_toggle_camera_flv">
  <app-cctv-display-flv [item]="this.camera_flv_item" [station]="boardsignInfo?.station" [toggle]="is_toggle_camera_flv"
    (onClose)="closeCameraFlv($event)"></app-cctv-display-flv>
</div>

<div *ngIf="is_toggle_camera_img">
  <app-cctv-display-img [item]="this.camera_img_item" [station]="boardsignInfo?.station" [toggle]="is_toggle_camera_img"
    (onClose)="closeCameraImg($event)"></app-cctv-display-img>
</div>
