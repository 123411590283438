<div class="modal-header">
    <div class="headstep-BG">
        <h4 class="modal-title pull-left">การบริหารจัดการเหตุการณ์</h4>
        <div class="status-BG">
            <div class="status" [ngClass]="{'active': menu==1}" (click)="menu=1;chkMap()">
                <div class="step">1</div>
                ข้อมูลเหตุการณ์
            </div>
            <div *ngIf="values?.id" class="hr-BG"><hr></div>
            <div *ngIf="values?.id" class="status" [ngClass]="{'active': menu==2}" (click)="menu=2">
                <div class="step">2</div>
                การดำเนินงาน
            </div>
        </div>
    </div>
    <button type="button" class="close pull-right x-BT" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div *ngIf="menu==1" class="modal-body detail-BG menu1">
    <div>

        <div class="d-flex">
            <div class="w-50">
                เหตุการณ์ 
                <!-- <span class="red-txt">*</span> -->
                <!-- groupSubtype -->
                <!-- <select [(ngModel)]="event_type_id" class="form-control">
                    <option value="" selected disabled>โปรดเลือกประเภทเหตุการณ์</option>
                    <option *ngFor="let i of type_list" [value]="i.id">{{i.descript}}</option>
                </select> -->
                <kendo-combobox [(ngModel)]="event_type_id" 
                [data]="type_list" (valueChange)="chgType($event)"
                textField="event_type_name"
                valueField="event_type_id" [allowCustom]="false" class="form-control" placeholder="เลือกเหตุการณ์"
                [disabled]="values?.id">
                </kendo-combobox>
                <!-- <kendo-combobox [(ngModel)]="event_type_id" 
                [data]="groupSubtype" (valueChange)="chgType($event)"
                textField="name"
                valueField="event_subtype_id" [allowCustom]="false" class="form-control" placeholder="เลือกเหตุการณ์"
                [disabled]="values?.id">
                </kendo-combobox> -->
            </div>
            <div class="w-50">
                ช่วงเวลาเกิดเหตุ 
                <!-- <span class="red-txt">*</span> -->
                <!-- <input type="date" class="form-control"> -->
                <kendo-datetimepicker [(ngModel)]="occur_datetime" class="form-control" [format]="'dd-MMM-yyyy HH:mm'" placeholder="เลือกช่วงเวลาเกิดเหตุ" [disabled]="_success"></kendo-datetimepicker>
            </div>
        </div>
        <div class="d-flex">
            <div class="w-50">
                ประเภทเหตุการณ์ 
                <!-- <span class="red-txt">*</span> -->
                <kendo-combobox [(ngModel)]="event_subtype_id" 
                [data]="subtype_list"
                textField="event_subtype_name"
                valueField="event_subtype_id" [allowCustom]="false" class="form-control" placeholder="เลือกประเภทเหตุการณ์"
                [disabled]="values?.id || !event_type_id">
                </kendo-combobox>

            </div>
            <div class="w-50">
                เลือกพื้นที่การเกิดเหตุ
                <select [(ngModel)]="location_type" class="form-control" (change)="en_road='';en_km='';clearMarker2();" [disabled]="values?.id">
                    <option value="" selected disabled>โปรดเลือกพื้นที่การเกิดเหตุ</option>
                    <option [value]="1">แบบจุด</option>
                    <option [value]="2">แบบเส้น</option>
                </select>
            </div>
        </div>
        <div class="d-flex route-BG" *ngIf="location_type">
            <p class="">จุดเกิดเหตุ <span *ngIf="location_type==2">(เริ่มต้น)</span></p>
            <select [(ngModel)]="st_road" class="form-control" [disabled]="values?.id" (focusout)="getLoc(st_road,st_km,st_m,1)">
                <option value="" selected disabled>หมายเลขสายทาง</option>
                <option *ngFor="let i of route_list" [value]="i">{{i}}</option>
            </select>
            <p class="">-</p>
            <input type="number" [(ngModel)]="st_km" class="form-control" placeholder="หลักกิโลเมตร" [disabled]="!st_road||values?.id" (focusout)="getLoc(st_road,st_km,st_m,1)" numbersOnly>
            <p class="">+</p>
            <input type="number" [(ngModel)]="st_m" class="form-control" placeholder="หลักเมตร" (focusout)="st_m = chkNumberm(st_m)" [disabled]="values?.id||(!st_km&&st_km!=0)" (focusout)="getLoc(st_road,st_km,st_m,1)" numbersOnly>
            <p class="">ทิศทาง</p>
            <select [(ngModel)]="st_direction" class="form-control" [disabled]="values?.id">
                <option value="" selected disabled>เลือกทิศทาง</option>
                <option [value]="1">ขาเข้า</option>
                <option [value]="2">ขาออก</option>
            </select>
        </div>
        <div class="d-flex route-BG" *ngIf="location_type==2">
            <p class="">จุดเกิดเหตุ <span *ngIf="location_type==2">(สิ้นสุด)</span></p>
            <select [(ngModel)]="en_road" class="form-control" [disabled]="values?.id" (focusout)="getLoc(en_road,en_km,en_m,2)">
                <option value="" selected disabled>หมายเลขสายทาง</option>
                <option *ngFor="let i of route_list" [value]="i">{{i}}</option>
            </select>
            <p class="">-</p>
            <input type="number" [(ngModel)]="en_km" class="form-control" placeholder="หลักกิโลเมตร" [disabled]="!en_road||values?.id" (focusout)="getLoc(en_road,en_km,en_m,2)" numbersOnly>
            <p class="">+</p>
            <input type="number" [(ngModel)]="en_m" class="form-control" placeholder="หลักเมตร" (keyup)="en_m = chkNumberm(en_m)" [disabled]="values?.id||(!en_km&&en_km!=0)" (focusout)="getLoc(en_road,en_km,en_m,2)" numbersOnly>
            <p class="">ทิศทาง</p>
            <select [(ngModel)]="en_direction" class="form-control" [disabled]="values?.id">
                <option value="" selected disabled>เลือกทิศทาง</option>
                <option [value]="1">ขาเข้า</option>
                <option [value]="2">ขาออก</option>
            </select>
        </div>
        <div class="d-flex">
            <div class="w-50">
                แหล่งที่มาของข้อมูล
                <select [(ngModel)]="event_src" class="form-control" [disabled]="values?.id">
                    <option value="" selected disabled>โปรดเลือกแหล่งที่มาของข้อมูล</option>
                    <option *ngFor="let i of src_list" [value]="i.id">{{i.descript}}</option>
                </select>
            </div>
            <div class="w-50">
                อื่นๆโปรดระบุ 
                <input type="text" class="form-control" [(ngModel)]="other_src" placeholder="กรอกอื่นๆ" [disabled]="_success">
            </div>
        </div>
        <div class="d-flex">
            <div class="w-50 overflow-auto">
                รายะละเอียดประกอบ
                <!-- <div [innerHTML]="sys_desc | safeHtml"></div> -->
                <div class="sys_desc-BG" [innerHTML]="sys_desc"></div>
                <!-- <textarea [(ngModel)]="sys_desc" cols="30" rows="10" class="form-control" [disabled]="values?.id" placeholder="กรอกรายะละเอียดประกอบ"></textarea> -->
            </div>
            <div class="w-50">
                บันทึกของเจ้าหน้าที่
                <textarea [(ngModel)]="basic_desc" cols="30" rows="10" class="form-control" placeholder="กรอกรายะละเอียดประกอบ" [disabled]="_success"></textarea>
            </div>
        </div>
    </div>
    <div style="max-width: 30%;">
        <div class="w-100">
            <div *ngIf="menu==1" class="map" id="map-detail">
            </div>
        </div>
        <div class="upload-BG">
            
            <div>
                <!-- 1 -->
                <label for="uploadImg2">
                    <img src="./assets/event/upload{{imgsPath.length>1? '-max':''}}.png" alt="upload">
                    <input type="file" name="" id="uploadImg2" accept="image/*" (change)="ImgBase64($event)" onerror="this.src='assets/noimage.png'" #Images [disabled]="_success" [disabled]="imgsPath.length>1">
                </label>
            </div>

            <!-- <label for="uploadImg1"> -->
                <!-- <img src="./assets/event/upload.png" alt="upload">
                <input type="file" name="" id="uploadImg1"> -->
                <!-- <img [src]="imgsPath[0]" alt="Imgs" onerror="this.src='assets/noimage.png'"> -->
            <!-- </label> -->
            <div class="img-groups-BG">
                <div *ngFor="let i of imgs">
                    <img [src]="i" alt="Imgs" onerror="this.src='assets/noimage.png'" (click)="openImg(i)">
                </div>
                <div *ngFor="let i of imgsPath;index as idx;" class="position-relative">
                    <img [src]="i" alt="Imgs" onerror="this.src='assets/noimage.png'" (click)="openImg(i)">
                    <button class="rmImage-BT" (click)="rmImage(idx)"> x</button>
                </div>

            </div>
        </div>
        <div class="chk-BG" *ngIf="values?.id">
            <label for="confirm1">
                <img src="./assets/event/{{_confirmEvt==1? '':'un'}}chk.png" alt="">
                <input type="radio" name="confirm" id="confirm1" [(ngModel)]="_confirmEvt" value="1" (change)="chkConfirm()" [disabled]="_success">
                ยืนยันเหตุการณ์
            </label>
            <label for=confirm2>
                <img src="./assets/event/{{_confirmEvt==1? 'un':''}}chk.png" alt="">
                <input type="radio" name=confirm id=confirm2 [(ngModel)]="_confirmEvt" value="0" (change)="chkConfirm()" [disabled]="_success">
                ไม่พบเหตุการณ์
            </label>
        </div>
        <div *ngIf="!_success">
            <button class="btn btn-primary w-100" (click)="save()" [disabled]="_clickSave">
                บันทึกข้อมูล
            </button>
        </div>
    </div>
</div>
<div *ngIf="menu==2" class="modal-body detail-BG menu2">
    <div *ngIf="msg_list.length" class="msg-BG">
        <div *ngFor="let i of msg_list" class="user-BG">
            <div>
                <p class="name-BG">
                    {{i.user_name}}
                </p>
                <small>
                    {{i.created_datetime | date :'dd/MM/yyyy HH:mm:ss'}} น. <br>
                </small>
            </div>
            <div>{{i.department||''}}</div>
            <div>
                <div class="msg-detail-BG" [innerHTML]="i.text"></div>

                <!-- <div class="log-img-BG">
                    <div><img src="./assets/event/cctv1.jpg" alt=""></div>
                    <div><img src="./assets/event/cctv2.jpg" alt=""></div>
                    <div><img src="./assets/event/cctv3.jpg" alt=""></div>
                    <div><img src="./assets/event/cctv1.jpg" alt=""></div>
                    <div><img src="./assets/event/cctv1.jpg" alt=""></div>
                    <div><img src="./assets/event/cctv2.jpg" alt=""></div>
                    <div><img src="./assets/event/cctv3.jpg" alt=""></div>
                    <div><img src="./assets/event/cctv1.jpg" alt=""></div>
                </div> -->
            </div>
        </div>
    </div>
    <div class="send-BG">
        <div>
            <p *ngIf="!_success" style="margin: 20px 0 -12px 0;">อัพเดตสถานะเหตุการณ์</p>
            <div *ngIf="!_success" class="txt-BG">
                <input type="text" [(ngModel)]="msg" (keydown.enter)="sendMsg()">
                <button (click)="sendMsg()">ส่ง</button>
            </div>
            <p class="m-0">คำสั่งด่วน</p>
            <div class="short-txt-BG">
                <button (click)="openManageModal(1)">เปลี่ยนภาพแสดงบนจอ LED</button>
                <button (click)="openManageModal(2)">เรียกดูภาพจากกล้อง CCTV ใกล้เคียง</button>
                <button (click)="openSensor(values)">เรียกดูข้อมูลสภาพจราจร</button>
                <button (click)="log_msg=0;openSendLog(template)">การตรวจสอบเหตุการณ์</button>
                <!-- <select>
                    <option value="" selected disabled>การตรวจสอบเหตุการณ์</option>
                    <option>เรียกดูจากกล้อง CCTV ใกล้เคียง</option>
                    <option>เรียกดูข้อมูลสภาพจราจร</option>
                    <option>ไม่มีข้อมูลให้ตรวจสอบ</option>
                </select> -->
            </div>
        </div>
        <div>
            <button class="btn btn-primary w-100" (click)="openManageModal(3)">
                เพิ่มผลกระทบจากเหตุการณ์
            </button>
            <button class="btn btn-primary w-100" (click)="save(true)">
                สิ้นสุดเหตุการณ์
            </button>
            <button class="btn btn-success w-100" (click)="getReport()">
                ออกรายงานเหตุการณ์
            </button>
        </div>
    </div>
</div>

<ng-template #template style="background: #a9a9a957;">
    <div class="modal-header">
      <h4 class="modal-title pull-left">การตรวจสอบเหตุการณ์</h4>
      <button type="button" class="btn-close close pull-right x-BT" aria-label="Close" (click)="modalRef?.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
    </div>
    <div class="modal-body log-form">
        <button class="log-BT" *ngFor="let i of verify_method_list" [ngClass]="{'active': i.checked==1}" (click)="i.checked=!i.checked">{{i.name}}</button>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" (click)="sendMsgLog()">ส่ง</button>
    </div>
</ng-template>