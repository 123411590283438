<div class="modal-header">
    <h5>เงือนไขการแจ้งเตือน</h5>
    <p>โปรดเลือกตำแหน่งจุดติดตั้งที่ต้องการเพิ่มเงื่อนไข</p>
</div>
<div class="search-BG">
    หมายเลขสายทาง
    <select [(ngModel)]="route" class="form-control">
      <option value="" selected>เลือกสายทาง</option>
      <option *ngFor="let i of routeItems" [value]="i">{{i}}</option>
    </select>
    ช่องสายทาง กิโลเมตรที่
    <input type="text" class="form-control" [(ngModel)]="st_km" numbersOnly>
    ถึง
    <input type="text" class="form-control" [(ngModel)]="en_km" numbersOnly>
    Config
    <input type="text" class="form-control" [(ngModel)]="config" numbersOnly>
    <button class="btn btn-primary search-BT" (click)="getQueueingDetactionDevices()">
        <img src="./assets/event/search.png" alt="">
        ค้นหา
    </button>
</div>
<div class="modal-body">
    <table class="table">
        <thead>
            <tr>
                <th>#</th>
                <th>หมายเลขสายทาง</th>
                <th>ช่วงกิโลเมตร</th>
                <th>Last Config</th>
                <th>ชื่อผู้ใช้</th>
                <th>Config</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let i of deviceLists | slice: (currentPage-1) * itemsPerPage : (currentPage-1) * itemsPerPage + itemsPerPage;index as idx">

            <!-- </tr>
            <tr *ngFor="let i of deviceLists; let idx=index" (click)="ids[i.id]=!ids[i.id]"> -->
                <td (click)="ids[i.id]=!ids[i.id]" class="chk-BG" >
                    <input type="checkbox" [(ngModel)]="ids[i.id]" >
                    <img *ngIf="ids[i.id]" src="./assets/event/cb-chk.png" height="36">
                    <img *ngIf="!ids[i.id]" src="./assets/event/cb-unchk.png" height="36">
                </td>
                <td (click)="ids[i.id]=!ids[i.id]">{{i.route||''}}</td>
                <td (click)="ids[i.id]=!ids[i.id]">{{i.st_km||''}} - {{i.en_km}}</td>
                <td (click)="ids[i.id]=!ids[i.id]">{{i.last_config_datetime||''}}</td>
                <td (click)="ids[i.id]=!ids[i.id]">{{i.user_name||''}}</td>
                <td (click)="openModel(i)">{{i.id||''}}</td>
            </tr>
        </tbody>
    </table>
</div>
<div class="modal-footer">
    <div>
        <pagination [(ngModel)]="currentPage" [totalItems]="deviceLists.length"
        [itemsPerPage]="itemsPerPage"
        previousText="&lsaquo;" nextText="&rsaquo;" [boundaryLinks]="true"
        firstText="&laquo;" lastText="&raquo;">></pagination>
        <!-- <pagination [totalItems]="10"
        [itemsPerPage]="1" (pageChanged)="pageChanged($event)"
        previousText="&lsaquo;" nextText="&rsaquo;" [boundaryLinks]="true"
        firstText="&laquo;" lastText="&raquo;">></pagination> -->
    </div>
    <div>
        <button class="btn btn-primary config-BT" (click)="openRoute()">
            เพิ่มช่วงสายทาง
        </button>
        <button class="btn btn-primary config-BT" (click)="openModel()">
            ตั้งค่าเงื่อนไข
        </button>
        <button class="btn btn-primary config-BT" (click)="back()">
            กลับ
        </button>
    </div>
</div>