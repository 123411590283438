<div class="modal-header">
    <div class="headstep-BG">
        <h4 class="modal-title pull-left">เพิ่มเหตุการณ์</h4>
    </div>
    <button type="button" class="close pull-right x-BT" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body detail-BG menu1">
    <div class="form-BG">
        <div>
            ประเภทเหตุการณ์
            <select class="form-control" [(ngModel)]="type_id">
                <option value="" selected disabled>เลือกประเภทเหตุการณ์</option>
                <option *ngFor="let i of type_list" [value]="i.id">{{i.descript}}</option>
            </select>
        </div>
        <div>
            ชื่อย่อเหตุการณ์
            <input type="text" class="form-control" placeholder="ระบุ" [(ngModel)]="short_name">
        </div>
        <div>
            ชื่อเหตุการณ์
            <input type="text" class="form-control" placeholder="ระบุ" [(ngModel)]="name">
            <div class="upload-BG">
                <div>
                    ไอคอนเหตุการณ์
                    <label class="file-img" for="file-img">
                        <img *ngIf="!imgIcon" src="./assets/event/upload.png" alt="upload">
                        <div *ngIf="imgIcon">
                            <img [src]="imgIcon" alt="upload" onerror="this.src='assets/noimage.png'">
                        </div>
                        <input type="file" id="file-img" accept="image/*" (change)="ImgBase64($event)">
                    </label>
                </div>
                <div>
                    ไฟล์แนบคู่มือการปฏิบัติงาน
                    <label for="file-pdf">
                        <input type="file" id="file-pdf" accept="application/pdf" (change)="DocBase64($event)">
                    </label>
                    <a *ngIf="att_file" [href]="att_file">Pdf</a>
                </div>
            </div>
        </div>
        <div>
            รายละเอียด
            <textarea cols="30" rows="10" class="form-control" placeholder="คำอธิบาย" [(ngModel)]="auto_detection"></textarea>
        </div>
    </div>
</div>
<div class="modal-footer text-right">
    <button class="btn btn-primary" [disabled]="!type_id" (click)="save()">ยืนยัน</button>
</div>