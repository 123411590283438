<div class="row p-3">
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>หัวข้อ :</div>
    <div>
      <input autocomplete="nope" type="text" placeholder="" maxlength="100" class="k-textbox form-control w-100"
        [(ngModel)]="search_title" [ngModelOptions]="{standalone: true}" />
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>ประเภทข่าวสาร :</div>
    <div>
      <kendo-combobox class="w-100" [data]="data_news_type" [allowCustom]="allowCustom" [textField]="'name'"
        [valueField]="'id'" [value]="search_news_type" (valueChange)="handleValueNewsType($event)"
        (filterChange)="handleFilterNewsType($event)" [placeholder]="'ทั้งหมด'" [filterable]="true">
      </kendo-combobox>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>Hashtag :</div>
    <div>
      <input autocomplete="nope" type="text" placeholder="" maxlength="100" class="k-textbox form-control w-100"
        [(ngModel)]="search_hashtag" [ngModelOptions]="{standalone: true}" />
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>วันที่เริ่ม :</div>
    <div>
      <kendo-datepicker class="w-100" [format]="'dd/MM/yyyy'" [(value)]="search_st_date"
        [formatPlaceholder]="formatPlaceholder">
      </kendo-datepicker>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>วันที่สิ้นสุด :</div>
    <div>
      <kendo-datepicker class="w-100" [format]="'dd/MM/yyyy'" [(value)]="search_en_date"
        [formatPlaceholder]="formatPlaceholder">
      </kendo-datepicker>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>สถานะ :</div>
    <div>
      <kendo-combobox class="w-100" [data]="status_list" [allowCustom]="allowCustom" [textField]="'name'"
        [filterable]="true" [valueField]="'id'" [value]="search_status" (valueChange)="handleValueStatus($event)"
        [placeholder]="'ทั้งหมด'">
      </kendo-combobox>
    </div>
  </div>
  <div class="col-lg-12 col-md-12 d-flex align-items-end justify-content-end">
    <div class="mt-1">
      <button type="button" (click)="search()" class="btn btn-primary pull-right text-white ml-1"> <i
          class="fa fa-search"></i>
        ค้นหา </button>
    </div>
  </div>
  <div class="col-12 mt-3 table-header pl-3 pr-3 pt-2 pb-2 d-flex align-items-center justify-content-between">
    <div class="font-2">
      <i class="fa fa-bars mr-2"></i> ข่าวสาร
    </div>
    <button *ngIf="isAdd" (click)="openAddModal()" type="button" class="btn btn-primary text-white"> <i
        class="fa fa-plus"></i>
      เพิ่มข่าวสาร</button>
  </div>
  <div class="col-12 mt-3">
    <table class="table table-outline mb-0">
      <thead class="thead-dark" style="background-color: black;">
        <tr>
          <th class="text-center text-light table-header"><i class="fa fa-hashtag"></i></th>
          <th class="text-center text-light table-header">สถานะ</th>
          <th class="text-center text-light table-header">หัวข้อ</th>
          <th class="text-center text-light table-header">ประเภทข่าวสาร</th>
          <th class="text-center text-light table-header">Create date</th>
          <th *ngIf="isEdit" class="text-center text-light table-header">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="!this.is_loading && data_list?.length == 0">
          <td colspan="10" style="height: 50px;">
            ไม่พบข้อมูล
          </td>
        </tr>
        <tr *ngIf="this.is_loading">
          <td colspan="10" style="height: 50px;">
            กำลังโหลด ..
          </td>
        </tr>
        <tr *ngFor="let item of data_list;index as i;">
          <td class="text-center">
            <div>
              {{(i+1)+((this.currentPage - 1)*itemsPerPage)}}
            </div>
          </td>

          <td class="text-center">
            <i tooltip="Active" placement="top" *ngIf="item?.status == statusEnum.Active"
              class="fa fa-circle text-success"></i>
            <i tooltip="Inctive" placement="top" *ngIf="item?.status == statusEnum.Inactive"
              class="fa fa-circle text-danger"></i>
          </td>
          <td class="text-center">
            {{item?.title}}
          </td>
          <td class="text-center">
            {{item?.news_type_str}}
          </td>
          <td class="text-center">
            <div style="font-size: 12px;">{{item?.created_datetime | date: 'dd/MM/yyyy'}}</div>
          </td>

          <td *ngIf="isEdit || isAddInc" class="text-center">
            <div class="d-flex align-items-center justify-content-center">
              <!-- <button *ngIf="isAddInc" (click)="openAddIncident(item)" title="Add to incident" type="button"
                class="btn btn-sm btn-info mr-1 text-light">
                <i class="fa fa-exclamation-triangle"></i>
              </button> -->
              <button *ngIf="isEdit" (click)="openEditModal(item)" title="แก้ไข" type="button"
                class="btn btn-sm btn-warning mr-1 ml-1 text-light">
                <i class="cui-note icons"></i>
              </button>
              <button *ngIf="isEdit" (click)="openDeleteModal(item)" title="ลบ" type="button"
                class="btn btn-sm btn-danger ml-1">
                <i class="cui-trash icons"></i>
              </button>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12" class="table-footer">
            <div class="table-footer-inside d-flex align-items-center justify-content-between">
              <div class="ml-3 pt-3">
                <pagination [totalItems]="totalItems" [maxSize]="5" [itemsPerPage]="itemsPerPage"
                  [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)" class="pagination"
                  previousText="&lsaquo;" nextText="&rsaquo;" [boundaryLinks]="true" firstText="&laquo;"
                  lastText="&raquo;"></pagination>
              </div>
              <div></div>
              <div class="mr-3 ml-2">total: {{totalItems}}</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div bsModal #addModal="bs-modal" class="modal fade modal-md" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true" (onHide)="clearData()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i><span *ngIf="!selectItem?.id">เพิ่มข่าวสาร</span><span
            *ngIf="selectItem?.id">แก้ไขข่าวสาร</span></h4>
        <button type="button" class="close text-light" (click)="addModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal mt-2 mb-2">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-form-label" for="select1"> <span
                class="float-sm-left float-md-right">ประเภทข่าวสาร<span class="text-danger">*</span>
                :</span></label>
            <div class="col-md-8 col-lg-8 col-xl-7">
              <kendo-combobox class="w-100" [data]="data_news_type_modal" [allowCustom]="allowCustom"
                [textField]="'name'" [valueField]="'id'" [value]="news_type_modal"
                (valueChange)="handleValueNewsTypeModal($event)" (filterChange)="handleFilterNewsTypeModal($event)"
                [filterable]="true" [placeholder]="'โปรดระบุ'">
              </kendo-combobox>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-form-label" for="select1"> <span
                class="float-sm-left float-md-right">จังหวัด
                :</span></label>
            <div class="col-md-8 col-lg-3">
              <kendo-combobox class="w-100" [data]="data_prov_modal" [allowCustom]="allowCustom"
                [textField]="'province_name'" [valueField]="'province_id'" [value]="prov_modal"
                (valueChange)="handleValueProvModal($event)" (filterChange)="handleFilterProvModal($event)"
                [filterable]="true" [placeholder]="'โปรดระบุ'">
              </kendo-combobox>
            </div>
            <label class="col-md-4 col-lg-2 col-xl-1 col-form-label mt-md-3 mt-lg-0" for="select1"> <span
                class="float-sm-left float-md-right">เขต
                :</span></label>
            <div class="col-md-8 col-lg-3 mt-md-3 mt-lg-0">
              <kendo-combobox class="w-100" [data]="data_dist_modal" [allowCustom]="allowCustom"
                [textField]="'dist_name'" [valueField]="'dist_id'" [value]="dist_modal"
                (valueChange)="handleValueDistModal($event)" (filterChange)="handleFilterDistModal($event)"
                [filterable]="true" [placeholder]="'โปรดระบุ'">
              </kendo-combobox>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-form-label" for="select1"> <span
                class="float-sm-left float-md-right">ทางหลวง
                :</span></label>
            <div class="col-md-8 col-lg-3">
              <kendo-combobox class="w-100" [data]="data_route_modal" [allowCustom]="allowCustom" [textField]=""
                [valueField]="" [value]="route_modal" (valueChange)="handleValueRouteModal($event)"
                (filterChange)="handleFilterRouteModal($event)" [filterable]="true" [placeholder]="'โปรดระบุ'">
              </kendo-combobox>
            </div>
            <label class="col-md-4 col-lg-2 col-xl-1 col-form-label mt-md-3 mt-lg-0"> <span
                class="float-sm-left float-md-right">Hashtag
                :</span></label>
            <div class="col-md-8 col-lg-3 mt-md-3 mt-lg-0">
              <input type="text" [(ngModel)]="selectItem.hashtag" class="form-control w-100" placeholder=""
                maxlength="50" [ngModelOptions]="{standalone: true}">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-form-label" for="text-input"> <span
                class="float-sm-left float-md-right">หัวข้อ<span class="text-danger">*</span>
                :</span></label>
            <div class="col-md-8  col-lg-8 col-xl-7">
              <input type="text" [(ngModel)]="selectItem.title" class="form-control" placeholder="" maxlength="50"
                [ngModelOptions]="{standalone: true}">
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-form-label" for="select1"> <span
                class="float-sm-left float-md-right">การแจ้งเตือน
                :</span></label>
            <div class="col-md-8 col-lg-3">
              <kendo-combobox class="w-100" [data]="noti_type_list" [allowCustom]="allowCustom" [textField]="'name'"
                [valueField]="'id'" [value]="noti_type" (valueChange)="handleValueNotiTypeModal($event)"
                [filterable]="true" [placeholder]="'โปรดระบุ'">
              </kendo-combobox>
            </div>
            <label class="col-md-4 col-lg-2 col-xl-1 col-form-label mt-md-3 mt-lg-0" for="select1"> <span
                class="float-sm-left float-md-right">สถานะ<span class="text-danger">*</span>
                :</span></label>
            <div class="col-md-8 col-lg-3 mt-md-3 mt-lg-0">
              <kendo-combobox class="w-100" [data]="status_list" [allowCustom]="allowCustom" [textField]="'name'"
                [valueField]="'id'" [value]="status_modal" (valueChange)="handleValueStatusModal($event)"
                [filterable]="true" [placeholder]="'โปรดระบุ'">
              </kendo-combobox>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-form-label" for="text-input"> <span
                class="float-sm-left float-md-right">รายละเอียด
                :</span></label>
            <div class="col-md-8 col-lg-8 col-xl-7">
              <ckeditor [(ngModel)]="selectItem.desc" [ngModelOptions]="{standalone: true}"></ckeditor>
              <button type="button" [disabled]="!selectItem.desc" class="btn btn-primary text-white mt-1"
                (click)="openPreview(selectItem.desc)">Preview</button>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-form-label" for="select1"> <span
                class="float-sm-left float-md-right">รูปภาพประกอบ
                :</span></label>
            <div class="col-md-8 col-lg-8 col-xl-7">
              <div class="d-flex justify-content-start align-items-center flex-wrap">
                <div title="เพิ่มรูปภาพ" class="pointer btn-file-plus m-1">
                  <i class="fa fa-plus"></i>
                  <input #imInput type="file" (change)="detectFiles($event)" accept=".png,.jpg,.gif">
                </div>
                <div *ngFor="let f of images;index as i2;">
                  <div *ngIf="f?.status == statusEnum.Active" class="up-im-boder d-flex flex-wrap">
                    <div *ngIf="f?.dataUrl" class="up-img">
                      <img height="58" width="58" [src]="f?.dataUrl | safe">
                      <i title="ลบ" class="fa fa-close up-im-delete text-light" (click)="deleteFileItem(f)"></i>
                    </div>
                    <div *ngIf="!f?.dataUrl" (click)="clickFile(f?.url)" class="up-img">
                      <img *ngIf="f?.url" height="58" width="58" [src]="f?.url">
                      <i title="ลบ" class="fa fa-close up-im-delete text-light" (click)="deleteFileItem(f)"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-form-label" for="text-input"> <span
                class="float-sm-left float-md-right">Notification text
                :</span></label>
            <div class="col-md-8  col-lg-8 col-xl-7">
              <input type="text" [(ngModel)]="selectItem.noti_text" class="form-control" placeholder="" maxlength="50"
                [ngModelOptions]="{standalone: true}">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-form-label" for="text-input"> <span
                class="float-sm-left float-md-right">Short messages
                :</span></label>
            <div class="col-md-8  col-lg-8 col-xl-7">
              <textarea [(ngModel)]="selectItem.short_message" rows="3" class="form-control" placeholder=""
                [ngModelOptions]="{standalone: true}"></textarea>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-form-label" for="text-input"> <span
                class="float-sm-left float-md-right">Broadcast channel
                :</span></label>
            <div class="col-md-8  col-lg-8 col-xl-7">
              <!-- <div class="pointer bt-rounded-circle m-1 bg-success rounded d-flex justify-content-center align-items-center">
                <i class="fa fa-plus"></i>
              </div> -->
              <div class="d-flex align-items-center flex-wrap">
                <div class="d-flex justify-content-start align-items-center" *ngFor="let ch of broadcast_channel_list;index as i2;">
                  <label class="checkbox-container mr-2">
                    <span>{{ch?.name}}</span>
                    <input type="checkbox" [(ngModel)]="ch.check"
                      [ngModelOptions]="{standalone: true}">
                    <span class="checkmark-box"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-form-label" for="text-input"> <span
                class="float-sm-left float-md-right">Broadcast time
                :</span></label>
            <div class="col-md-8 col-lg-8 col-xl-7">
              <kendo-datetimepicker class="w-100" [format]="'dd/MM/yyyy HH:mm'" [(value)]="broadcast_time_modal"
              [formatPlaceholder]="formatPlaceholder">
              </kendo-datetimepicker>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-form-label" for="text-input"> <span
                class="float-sm-left float-md-right">User notification
                :</span></label>
            <div class="col-md-8  col-lg-8 col-xl-7">
              <kendo-combobox class="w-100"  [data]="data_user_modal" [allowCustom]="allowCustom" [textField]="'name'"
              [valueField]="'id'" [value]="user_modal" (valueChange)="handleValueUserModal($event)"
              (filterChange)="handleFilterUserModal($event)" [placeholder]="''" [filterable]="true">
             </kendo-combobox>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-form-label" for="text-input"> <span
                class="float-sm-left float-md-right">Location
                :</span></label>
            <div class="col-md-8  col-lg-8 col-xl-7">
              <div class="d-flex justify-content-start align-items-center flex-wrap">
                <div (click)='openMapModal()' *ngIf="!(selectItem?.location_name && selectItem?.lat && selectItem?.lng)" class="pointer text-white bt-rounded-circle m-1 bg-success rounded-circle d-flex justify-content-center align-items-center">
                  <i class="fa fa-plus"></i>
                </div>
                <div (click)='openMapModal()' *ngIf="selectItem?.location_name && selectItem?.lat && selectItem?.lng" class="pointer text-white  bt-rounded-circle m-1 btn-warning rounded-circle d-flex justify-content-center align-items-center">
                  <i class="cui-note icons"></i>
                </div>
                <div *ngIf="selectItem?.lat && selectItem?.lng" class="ml-2">
                  {{selectItem?.location_name}} {{selectItem?.lat}},{{selectItem?.lng}}
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-form-label col-sm-4" for="text-input"> <span
                class="float-sm-left float-md-right">Ask to confirm
                :</span></label>
            <div class="col-md-8 col-lg-8 col-xl-7 col-sm-7">
              <div class="d-flex justify-content-start align-items-center" style="height: 20px;">
                <div>
                  <label class="checkbox-container">
                    <span></span>
                    <input type="checkbox" [(ngModel)]="selectItem.is_ask_confirm"
                      [ngModelOptions]="{standalone: true}">
                    <span class="checkmark-box"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary" (click)="addModal.hide()">ยกเลิก</button>
          <button [disabled]="!(selectItem?.title && news_type_modal != null && status_modal != null)"
            *ngIf="selectItem?.id" type="button" class="btn btn-primary text-white ml-2"
            (click)="editDataAsyn()">แก้ไข</button>
          <button [disabled]="!(selectItem?.title && news_type_modal != null && status_modal != null)"
            *ngIf="!selectItem?.id" type="button" class="btn btn-primary text-white ml-2"
            (click)="addDataAsyn()">เพิ่ม</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div bsModal #deleteModal="bs-modal" class="modal fade modal-md" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="clearData()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i>ลบข่าวสาร</h4>
        <button type="button" class="close text-light" (click)="deleteModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mt-2 text-center">
          <h4>คุณต้องการลบใช่หรือไม่</h4>
        </div>
        <div class="mt-2 mb-4 text-center">
          <h4>{{selectItem?.title}}</h4>
        </div>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary" (click)="deleteModal.hide()">ยกเลิก</button>
          <button type="button" class="btn btn-primary text-white ml-2" (click)="deleteData()">ยืนยัน</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div bsModal #mapModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
aria-hidden="true"(onHide)="clearMap()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="fa fa-map-marker mr-2"></i>ค้นหาสถานที่เพื่อระบุตำแหน่ง</h4>
        
        <button type="button" class="close text-light" (click)="mapModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="map" id="map-modal-news"></div>
      </div>
      <div class="modal-footer bg-dark bg-gray-900">
        <div class="d-flex justify-content-between mt-2 mb-2 align-items-center">
          <div *ngIf="map_lat">{{this.map_loc_name}} {{this.map_lat}},{{this.map_lng}}</div>
          <div>
            <button type="button" class="btn btn-secondary ml-2" (click)="mapModal.hide()">ยกเลิก</button>
            <button type="button" [disabled]="!(this.map_lat && this.map_lng)" class="btn btn-primary text-white ml-2" (click)="setLatLng()">ยืนยัน</button>
          </div>
        </div> 
      </div>
    </div>
  </div>
</div>
<swal #swalSuccess title="<div class='text-light'>สำเร็จ</div>" type="success" text="" icon="success"></swal>
<swal #swalError title="<div class='text-light'>ไม่สำเร็จ</div>" html="<div class='text-light'>{{res_message}}</div>"
  type="error" icon="error"></swal>
