export const mainRouteUrl = "device-monitoring";
export const id = 1600;

export const menuItems = [
    // {
    //     id: 1600,
    //     name: 'Device Monitoring',
    //     url: `/${mainRouteUrl}`,
    //     icon: 'fa fa-clone',
    //     parent_id: id
    // },
    {
        id: 1600,
        name: 'ATIS Gen Service',
        url: `/${mainRouteUrl}/monitoring`,
        icon: 'fa fa-clone',
        parent_id: id
    },
    {
        id: 1600,
        name: 'ATIS Gen Service',
        // url: `/${mainRouteUrl}/monitoring`,
        url: '/device-monitoring/atis-gen-service',
        icon: 'fa fa-clone',
        parent_id: id
    },
];