import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiResponse, PagableData } from '../../models/common';
import { BoardsignInfo, AddBoardsign, ReqAddSign, Structure, ReqAutoSuggestion, SignInfo, FirmwareInfo, BoardSignBrightness } from '../../models/sign-control/boardsign';
import * as _ from 'lodash';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import { StatusEnum } from '../../models/common-enum';
import { ReqChangeMediaManyBoard } from 'src/app/models/sign-control/media';

@Injectable({
  providedIn: 'root'
})
export class BoardsignService {
  domain: any = environment.api.domain_sign_control;
  uri = 'boardsign';
  constructor(private http: HttpClient, private authService: AuthService) {
  }
  changeMediaManyBoard(req?: ReqChangeMediaManyBoard[]) {
    const uri = `boardsign/change/media`;
    const url = `${this.domain}/${uri}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.authService.user_token
      })
    };
    return this.http.post<ApiResponse<any>>(url, req, httpOptions);
  }
  getBoardsign(page?: number, limit?: number, name?: string, route?: string, short?: number, status?: number,
    st_km?: number, en_km?: number, st_m?: boolean, en_m?: boolean,ids?:number[],group_id?:any, typeName?: string, radius?: number,st_size?:any)
    : Observable<ApiResponse<PagableData<BoardsignInfo[]>>> {
    const uri = `boardsign`;
    const url = `${this.domain}/${uri}`;
    let params = new HttpParams();
    if (!_.isNil(page)) {
      params = params.append('page', page.toString());
    }
    if (!_.isNil(limit)) {
      params = params.append('limit', limit.toString());
    }
    if (!_.isNil(name)) {
      params = params.append('name', name.toString());
    }
    if (!_.isNil(route)) {
      params = params.append('route', route.toString());
    }
    if (!_.isNil(short)) {
      params = params.append('short', short.toString());
    }
    if (!_.isNil(status)) {
      params = params.append('status', status.toString());
    }
    if (!_.isNil(st_km)) {
      params = params.append('st_km', st_km.toString());
    }
    if (!_.isNil(en_km)) {
      params = params.append('en_km', en_km.toString());
    }
    if (!_.isNil(st_m)) {
      params = params.append('st_m', st_m.toString());
    }
    if (!_.isNil(en_m)) {
      params = params.append('en_m', en_m.toString());
    }
    if (!_.isNil(ids) && ids.length) {
      params = params.append('ids', ids.join(','));
    }

    if(Array.isArray(group_id)){
      let g = group_id.map(x=>x.id)
      params = params.append('group_id', g.toString());
    }else{
      if (!_.isEmpty(group_id)||group_id) {
        params = params.append('group_id', group_id.toString());
      }
    }
    if (!_.isNil(typeName)) {
      params = params.append('type_name', typeName.toString());
    }
    if (!_.isNil(radius)) {
      params = params.append('radius', radius.toString());
    }
    if (!_.isNil(st_size)) {
      if(st_size.width) params = params.append('st_width', st_size.width);
      if(st_size.height) params = params.append('st_height', st_size.height);
      if(st_size.st_id) params = params.append('st_id', st_size.st_id);
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<PagableData<BoardsignInfo[]>>>(url, httpOptions);
  }
  getBoardsign1(skip?: number, take?: number, clicked?: number, routes?: string[], lat?: number, lng?: number, radius?: number,
    connection_status?: number, status?: number, st_km?: number, en_km?: number, station_type?: number)
    : Observable<ApiResponse<any>> {
    const uri = `boardsign`;
    const url = `${this.domain}/${uri}`;
    let params = new HttpParams();
    let para = [];
    if (!_.isNil(skip)) {
      params = params.append('skip', skip.toString());
      para.push('skip=' + skip.toString())
    }
    if (!_.isNil(take)) {
      params = params.append('take', take.toString());
      para.push('take=' + take.toString())
    }
    if (!_.isNil(routes)) {
      params = params.append('routes', routes.join('|'));
      para.push('routes=' + routes.join('|'))
    }
    if (!_.isNil(lat)) {
      params = params.append('lat', lat.toString());
      para.push('lat=' + lat.toString())
    }
    if (!_.isNil(lng)) {
      params = params.append('lng', lng.toString());
      para.push('lng=' + lng.toString())
    }
    if (!_.isNil(radius)) {
      params = params.append('radius', radius.toString());
      para.push('radius=' + radius.toString())
    }
    if (!_.isNil(connection_status)) {
      params = params.append('connection_status', connection_status.toString());
      para.push('connection_status=' + connection_status.toString())
    }
    if (!_.isNil(status)) {
      params = params.append('status', status.toString());
      para.push('status=' + status.toString())
    }
    if (!_.isNil(st_km)) {
      params = params.append('st_km', st_km.toString());
      para.push('st_km=' + st_km.toString())
    }
    if (!_.isNil(en_km)) {
      params = params.append('en_km', en_km.toString());
      para.push('en_km=' + en_km.toString())
    }
    if (!_.isNil(station_type)) {
      params = params.append('station_type', station_type.toString());
      para.push('station_type=' + station_type.toString())
    }
    if (!_.isNil(clicked)) {
      params = params.append('clicked', clicked.toString());
      para.push('clicked=' + clicked.toString())
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    //return this.http.get<ApiResponse<PagableData<BoardsignInfo[]>>>(url, httpOptions);

    let url1 = 'assets/data/boardsign.json';
    return this.http.get<ApiResponse<any>>(url1);
  }
  getBoardsignSuggest(id?: number[]): Observable<ApiResponse<PagableData<BoardsignInfo[]>>> {
    const uri = `boardsign/suggest`;
    const url = `${this.domain}/${uri}`;
    let params = new HttpParams();
    if (!_.isNil(id)) {
      params = params.append('ids', id.join(','));
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<PagableData<BoardsignInfo[]>>>(url, httpOptions);
  }
  getBoardsignCamera(id?: number[]): Observable<ApiResponse<PagableData<BoardsignInfo[]>>> {
    const uri = `boardsign/camera`;
    const url = `${this.domain}/${uri}`;
    let params = new HttpParams();
    if (!_.isNil(id)) {
      params = params.append('ids', id.join(','));
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<PagableData<BoardsignInfo[]>>>(url, httpOptions);
  }
  getBoardsignCameraByDeviceId(id?: number[]): Observable<ApiResponse<PagableData<BoardsignInfo[]>>> {
    const uri = `boardsign/camera_device`;
    const url = `${this.domain}/${uri}`;
    let params = new HttpParams();
    if (!_.isNil(id)) {
      params = params.append('ids', id.join(','));
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<PagableData<BoardsignInfo[]>>>(url, httpOptions);
  }

  getBoardsignByID(id?: number) {
    const uri = `boardsign/${id}`;
    const url = `${this.domain}/${uri}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.get<ApiResponse<BoardsignInfo>>(url, httpOptions);
  }

  changeAutoSuggestMode(is_autosuggest?: boolean, boardsign_id?: number) {
    const uri = `boardsign/${boardsign_id}/suggestmode`;
    const url = `${this.domain}/${uri}`;
    const req = { auto_suggestion: is_autosuggest };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.authService.user_token
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }
  addBoardsign(req?: AddBoardsign) {
    const uri = `boardsign`;
    const url = `${this.domain}/${uri}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.post<ApiResponse<any>>(url, req, httpOptions);
  }

  editBoardsign(req: AddBoardsign, id?: number): Observable<ApiResponse<any>> {
    const uri = `boardsign/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }
  deleteBoardsign(id: number): Observable<ApiResponse<any>> {
    const uri = `boardsign/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.delete<ApiResponse<any>>(url, httpOptions);
  }
  deleteBoardsignEditStatus(req: AddBoardsign, id?: number): Observable<ApiResponse<any>> {
    req.status = StatusEnum.Delete;
    const uri = `boardsign/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }

  getSign(boardsign_id?: number) {
    const uri = `boardsign/${boardsign_id}/sign`;
    const url = `${this.domain}/${uri}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.get<ApiResponse<SignInfo[]>>(url, httpOptions);
  }
  addSign(req?: ReqAddSign,boardsign_id?:number) {
    const uri = `boardsign/${boardsign_id}/sign`;
    const url = `${this.domain}/${uri}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.post<ApiResponse<any>>(url, req, httpOptions);
  }

  editSign(req?: ReqAddSign,sign_id?:number,boardsign_id?:number) {
    const uri = `boardsign/${boardsign_id}/sign/${sign_id}`;
    const url = `${this.domain}/${uri}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }

  deleteSign(sign_id?:number,boardsign_id?:number) {
    const uri = `boardsign/${boardsign_id}/sign/${sign_id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.delete<ApiResponse<any>>(url, httpOptions);
  }
  deleteSignEditStatus(req: AddBoardsign, sign_id?: number,boardsign_id?:number): Observable<ApiResponse<any>> {
    req.status = StatusEnum.Delete;
    const uri = `boardsign/${boardsign_id}/sign/${sign_id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }

  getBoardsignStructure() {
    const uri = `boardsign/structure`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.get<ApiResponse<Structure[]>>(url, httpOptions);
    // "0-1-1:1-0_1-2-5:2-0|2-1-1:1-0_3-1-1:1-0_4-1-1:1-0_5-1-1:1-1
    // | แบ่ง Layer
    // _ แบ่งป้าย
    // - แบ่งประเภทข้อมูล
    // ตัวอย่าง 0-1-1:1-0 ==> 0 ตัวแรก คือลำดับ, 1 คือประเภทของBoardsign, 1:1 คือ Ratio,  0 ตัวสุดท้าย คือdirection 0ป้ายหน้า 1ป้ายหลัง
  }

  getFirmware(boardsign_ids?: number[]): Observable<ApiResponse<FirmwareInfo[]>> {
    const uri = `firmware`;
    const url = `${this.domain}/${uri}`;
    let params = new HttpParams();
    if (!_.isNil(boardsign_ids)) {
      params = params.append('boardsign_ids', boardsign_ids.join(','));
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<FirmwareInfo[]>>(url, httpOptions);
  }

  uploadFirmware(firmware: File, version: string, desc: string, boardsign_ids?: number[]): Observable<ApiResponse<any>> {
    const uri = `firmware`;
    const url = `${this.domain}/${uri}`;
    let formData = new FormData();
    formData.append('firmware', firmware);
    formData.append('version', version.toString());
    formData.append('desc', desc.toString());
    formData.append('boardsign_ids', boardsign_ids.join(','));
    return this.http.post<ApiResponse<any>>(url, formData);
  }

  getBrightness(boardsign_id?: number): Observable<ApiResponse<BoardSignBrightness[]>> {
    const uri = `brightness/${boardsign_id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.get<ApiResponse<BoardSignBrightness[]>>(url, httpOptions);
  }

  addBrightness(req?: BoardSignBrightness[],boardsign_id?:number) {
    const uri = `brightness/${boardsign_id}`;
    const url = `${this.domain}/${uri}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.post<ApiResponse<any>>(url, req, httpOptions);
  }
}
