<div class="d-flex" style="gap:5px;padding: 10px 70px;">
    <kendo-datetimepicker class="form-control" [(ngModel)]="st_dt" [format]="'dd-MMM-yyyy HH:mm'"
        placeholder="เลือกช่วงเวลาเริ่มต้น"></kendo-datetimepicker>
    <kendo-datetimepicker class="form-control" [(ngModel)]="en_dt" [format]="'dd-MMM-yyyy HH:mm'"
        placeholder="เลือกช่วงเวลาสิ้นสุด"></kendo-datetimepicker>
    <div class="pl-3 pr-3"><button class="btn btn-outline-warning" (click)="search()">Search</button></div>
</div>
<div class="map" id="map-dashboard"></div>
<div>
    <div class="p-3">
        <kendo-chart *ngIf="graphData.length">
            
            <kendo-chart-category-axis>
                <kendo-chart-category-axis-item
                [categories]="xGroupName"
                [title]="{ text: 'Groups name' }"
                >
                </kendo-chart-category-axis-item>
            </kendo-chart-category-axis>
            <kendo-chart-series>
                <kendo-chart-title text="Rank of incident"></kendo-chart-title>
                <kendo-chart-legend [position]="'bottom'" [visible]="true"></kendo-chart-legend>
                <!-- <kendo-chart-tooltip [visible]="true"></kendo-chart-tooltip> -->
                <kendo-chart-series-item 
                *ngFor="let i of graphData; let idx=index" 
                [name]="gLabel[idx]" [color]="color[idx]"
                type="column" [stack]="{ group: 'a' }" 
                [data]="graphData[idx]">
                    <!-- <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip> -->
                    <kendo-chart-series-item-tooltip color="#000" format="{{ gLabel[idx] }}: {0}"></kendo-chart-series-item-tooltip>
                    <!-- <kendo-chart-series-item-tooltip color="#000">
                        <ng-template let-value="idx">
                          {{ gLabel[idx] }} :: {{ value}}
                        </ng-template>
                    </kendo-chart-series-item-tooltip> -->
                </kendo-chart-series-item>
                <!-- <kendo-chart-series-item type="column" [data]="[1, 1, 1]" [stack]="{ group: 'a' }">
                </kendo-chart-series-item>
                <kendo-chart-series-item type="column" [data]="[1, 1, 12]" [stack]="{ group: 'a' }">
                </kendo-chart-series-item>
                <kendo-chart-series-item type="column" [data]="[1, 9,1, 12]" [stack]="{ group: 'a' }">
                </kendo-chart-series-item> -->
            </kendo-chart-series>
        </kendo-chart>
    </div>
</div>