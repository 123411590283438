import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PeopleService } from 'src/app/services/user/people.service';

import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { GroupService } from 'src/app/services/user/group.service';
import * as _ from 'lodash';
import { isEmpty } from 'rxjs/operators';
import { InstitutionService } from 'src/app/services/user/institution.service';

import * as md5 from 'md5'
import { FuncService } from 'src/app/services/user/func.service';
import { CommonService } from 'src/app/services/common.service';
import { ActionEnum } from 'src/app/models/common-enum';
import { ApiService } from 'src/app/services/user/api.service';
import { SwalComponent, } from '@sweetalert2/ngx-sweetalert2';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { PeopleComponent } from '../people.component'

@Component({
  selector: 'app-people-modal',
  templateUrl: './people-modal.component.html',
  styleUrls: ['./people-modal.component.scss']
})
export class PeopleModalComponent implements OnInit {

  @ViewChild('swalSuccess', { static: false }) private swalSuccess: SwalComponent;
  @ViewChild('swalSuccessEdit', { static: false }) private swalSuccessEdit: SwalComponent;
  @ViewChild('swalConfirm', { static: false }) private swalConfirm: SwalComponent;

  modalRef: BsModalRef;
  public alertOption: SweetAlertOptions = {};
  public event: EventEmitter<any> = new EventEmitter();
  PeopleComponent: any
  group_binding: any = []
  ins_bindding: any
  values: any;
  ins_list: any;
  username: string
  password: string
  firstname: string
  lastname: string
  tel: string
  rank: string
  email: string
  hashtag: string
  ins_id: number
  ins_name: string
  group: any = []
  group_id: number
  group_list: any = []
  id: number;
  g_id: number;
  _isDisabled: boolean = false
  user_group: any = [];
  _changed: boolean = false
  res_message: any
  constructor(
    private func: FuncService,
    public bsModalRef: BsModalRef,
    private peoS: PeopleService,
    private grpS: GroupService,
    private insS: InstitutionService,
    private commonService: CommonService,
    private apiService: ApiService,
    private modalService: BsModalService,
  ) {
    // this.PeopleComponent = new PeopleComponent()
    // this.alertOption = {
    //   // title: "ต้องการเพิ่มผู้ใช้?",
    //   // text: "Do you want to save changes",
    //   cancelButtonColor: "#6c757d",
    //   showCancelButton: true,
    //   cancelButtonText: "ไม่ใช่",
    //   confirmButtonColor: "#ff8f00",
    //   confirmButtonText: 'ใช่',
    //   showLoaderOnConfirm: true,
    //   focusCancel: true,
    //   background: '#0f6c47',
    //   preConfirm: () => {
    //     return new Promise((resolve) => {
    //       setTimeout(() => {
    //         console.log("Doing async operation");
    //         // resolve
    //       }, 2000)
    //     })
    //   },
    //   allowOutsideClick: () => !Swal.isLoading()
    // }
  }

  ngOnInit(): void {
    this.get_Data()
    // this.swalSuccessEdit.fire();

    // this.PeopleComponent = new PeopleComponent()

  }

  get_Data() {
    this.getData();
    if (this.values) {
      // console.log(this.values.ins_id);
      this._isDisabled = true
      this.id = this.values.id || null
      this.username = this.values.username || ''
      // this.password = this.values.password || ''
      this.firstname = this.values.firstname || ''
      this.lastname = this.values.lastname || ''
      this.tel = this.values.tel || ''
      this.rank = this.values.rank || ''
      this.email = this.values.email || ''
      this.hashtag = this.values.hashtag || ''
      this.ins_id = this.values.ins_id || null
      this.ins_name = this.values.ins_name || null
      if (this.values.group_list.length > 0) this.group_list = this.values.group_list
    }
  }

  firters_group_list_and_ins(all_grouop, group, ins_id) {
    this.ins_bindding = this.ins_list.filter(ins => ins.id == ins_id)[0]
    group.forEach(data => {
      const result = all_grouop.filter(gr => gr.id == data.group_id)[0]
      this.group_binding.push(result)
    })
  }

  async handleFilterGroup(value) {

    if (this.values && value.length > 0) {
      let v: any = value.pop()
      let u_group = {
        group_id: v.id,
        user_id: this.values.id
      }
      this.group_binding.push(v)
    } else {
      console.log(this.group_list, this.group_binding);
    }
  }
  handleValueGroup(value) {
    // this.onSelectGroup(value)

    let checkHadData = value.map(s => {
      let u_group = {
        group_id: s.id,
        group_name: s.name
      }
      return u_group
    })
    this.group_list = checkHadData
  }

  rm_group_list(value) {

    let remainingArr = this.group_list.filter(data => data.group_id != value);
    let remainingArr_combo = this.group_binding.filter(data => data.id != value);

    this.group_list = remainingArr
    this.group_binding = remainingArr_combo
    if (this.values) {
      // Number(value) ? this.rmUserGroup(value) : this.rmUserGroup(value.dataItem.id)
    }

  }

  handleValueIns(value) {
    // console.log(value);
    this.ins_id = value.id
  }
  handleFilterIns(value) {
    // console.log(1234 + value);
    // this.ins_id = 1
  }


  async getData() {
    if (!this.ins_list) this.ins_list = await this.insS.getIns();
    this.group = await this.grpS.getGroup();

    if (this.values) {
      this.firters_group_list_and_ins(this.group, this.values.group_list, this.values.ins_id)
    }

    if (this.g_id) {
      // console.log(this.g_id)
      let ckGroup = _.filter(this.group, { id: this.g_id })

      if (ckGroup) this.group_list.push({
        group_id: ckGroup[0].id,
        group_name: ckGroup[0].name,
      })

      if (ckGroup) this.group_binding = ckGroup
    }
  }

  async save() {
    let val: any = {}
    let is_confirm: boolean = false
    if (this.id) val.id = this.id || ''
    if (this.username) val.username = this.username || ''
    // if(this.password && !this.values) val.password = md5(this.password) || ''
    if (this.password) val.password = md5(this.password)
    if (this.firstname) val.firstname = this.firstname || ''
    if (this.lastname) val.lastname = this.lastname || ''
    if (this.tel) {
      if (!this.func._isTelhome(this.tel)) return this.func.alertpopup('กรุณากรอกเบอร์โทรให้ถูกต้อง')
      val.tel = this.tel || ''
    }
    if (this.rank) val.rank = this.rank || ''
    if (this.email) val.email = this.email || ''
    if (this.hashtag) val.hashtag = this.hashtag || ''
    if (this.ins_id) val.ins_id = Number(this.ins_id) || null

    if (!this.id && this.group_list.length > 0) {
      val.user_group = this.group_list
    }

    const req1 = _.cloneDeep(val);
    let txt_req = _.cloneDeep(req1);

    if (!this.values) {

      let res: any = await this.peoS.addUser(val)
      if (res.id) {
        this.confirm();
        let action = ActionEnum.Add;
        let request_data = { url: `${this.apiService.api.users}`, request: req1 };
        if (req1.id) {
          action = ActionEnum.Edit;
          delete txt_req.id
          delete txt_req.username
          request_data = { url: `${this.apiService.api.users}/${req1.id}`, request: txt_req };
        }
        setTimeout(() => {
          this.swalSuccess.fire()
          this.close()
        }, 900);
        this.commonService.activityLog(action, 'User Management ➡ ผู้ใช้งาน', JSON.stringify(request_data)).subscribe(res => { }, error => { console.log(error); });
      }
    } else if (this.values) {
      val.user_group = this.group_list
      let res: any = await this.peoS.updateUser(val)
      if (res.id) {
        setTimeout(() => {
          this.confirm();
          this.close()
        }, 1000)

        setTimeout(() => {
          this.swalSuccessEdit.fire()
        }, 1500)

      }
    }
  }
  close() {
    if (this._changed && !_.isEmpty(this.values)) {
      this.cancel()
    } else {
      this.bsModalRef.hide();
    }
  }

  confirm() {
    this.event.emit(true);
    this.bsModalRef.hide();
  }
  cancel() {
    console.log(this._changed)
    this.event.emit(this._changed);
    this.bsModalRef.hide();
  }

  onSelect(event: TypeaheadMatch): void {
    this.ins_id = event.item.id
  }
  blur(evt?: any) {
    if (!this.ins_id || !this.ins_name) {
      this.ins_name = null
      this.ins_id = null
    } else if (this.ins_id && !this.ins_name) {
      this.ins_name = null
      this.ins_id = null

    }
  }
  g_blur(evt?: any) {

  }
  async rmUserGroup(g_id?: number) {
    let res: any = await this.grpS.rmUGroup({
      user_id: this.id,
      group_id: g_id
    })
    if (!_.isEmpty(res)) {
      this._changed = true
      this.group_list = _.remove(this.group_list, function (e) {
        return e.group_id !== g_id;
      });

    }

  }
  async onSelectGroup(evt?: any) {
    console.log(evt.item)
    let checkHadData = _.filter(this.group_list, {
      "group_id": evt.item.
        id
    })
    if (checkHadData.length == 0) {
      if (this.id) {
        let u_group = {
          group_id: evt.item.id,
          user_id: this.id
        }
        let res: any = await this.grpS.addUGroup(u_group)
        if (_.isEmpty(res)) this._changed = true

      }
      this.group_list.push({
        group_id: evt.item.id,
        group_name: evt.item.name
      })
    }
    this.group_id = null
  }

  is_confirm(e: any) {
    console.log(e);
  }
}
