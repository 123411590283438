import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiResponse, PagableData } from '../../models/common';
import * as _ from 'lodash';
import { ReportTraveltime, ReportUserActivityLog, ReportRawSensor, ReportDeviceWorkHour, ReportAvgSpeed, ReportVolume, ReportSpeedDetection, ReportTraffic, VehicleClass, DeviceType, StationNameInfo } from '../../models/report/report';
import { Observable, of, Subject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private subject = new Subject<any>();
  domain: any;
  api_domain: any;
  req_api_url: any;
  token = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjIjoiNWIwZTQzODM0NTc3OTQxOTAxMWU2Yzk1IiwicyI6WyJUTUMiXSwiciI6Ikh3SnVpNkRmdUxEZ05TM1lyUWZtNnZIV2hqWU1qeE02VjFtTDFCV0JvbWVTIiwiaWF0IjoxNjA3OTM0MDgwfQ.9MhE1LIdqTx1xiG6lspi_QOWF6YP7EiDhQ6v3y9KOu8`;
  domain_report: any;
  constructor(private http: HttpClient, private authService: AuthService) {
    this.domain = environment.api.domain_old_jarvis_report_export_api;
    this.api_domain = 'https://jarvis.shw.transcode.co.th/api';
    this.domain_report = environment.api.domain_report;
  }
  setUrl(id: number, url: string) {
    this.subject.next({ id: id, url: url });
  }
  getUrl(): Observable<any> {
    return this.subject.asObservable();
  }
  getStationList() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })
    };
    const uri = `station/list`;
    const url = `${this.api_domain}/${uri}`;
    return this.http.get<ApiResponse<StationNameInfo[]>>(url, httpOptions);
  }
  
  getVehicleClassList(model: number) {
    const uri = `vehicle-class?model=` + model;
    const url = `${this.api_domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })
    };
    return this.http.get<ApiResponse<VehicleClass[]>>(url, httpOptions);
  }

  getDeviceType(deviceTypeID?: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })
    };
    let uri = `device/type/?status=1`;
    if (deviceTypeID) {
      uri += `&id=${deviceTypeID}`;
    }
    const url = `${this.api_domain}/${uri}`;
    return this.http.get<ApiResponse<DeviceType[]>>(url, httpOptions);
  }
  getReportTraveltime(region_code?: string, st_date?: number, en_date?: number, menu_id?: number) {
    let uri = `traveltime`;
    const url = `${this.domain}/${uri}`;
    let para = [];
    let params = new HttpParams();

    if (!_.isNil(region_code)) {
      params = params.append('region_code', region_code.toString());
      para.push('region_code=' + region_code.toString())
    }
    if (!_.isNil(st_date)) {
      params = params.append('st_date', st_date.toString());
      para.push('st_date=' + st_date.toString())
    }
    if (!_.isNil(en_date)) {
      params = params.append('en_date', en_date.toString());
      para.push('en_date=' + en_date.toString())
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      }),
      params: params
    };

    this.req_api_url = url;
    let api_url_export = `${url}/export`;
    if (para.length > 0) {
      this.req_api_url = `${url}?${_.join(para, '&')}`;
      api_url_export = `${url}/export?${_.join(para, '&')}`;
    }
    this.setUrl(menu_id, api_url_export);
    return this.http.get<ApiResponse<ReportTraveltime[]>>(url, httpOptions);
  }

  getReportTraveltimeSensor(region_code?: string, st_date?: number, en_date?: number, menu_id?: number) {
    let uri = `sensor/traveltime`;
    const url = `${this.domain}/${uri}`;
    let para = [];
    let params = new HttpParams();

    if (!_.isNil(region_code)) {
      params = params.append('region_code', region_code.toString());
      para.push('region_code=' + region_code.toString())
    }
    if (!_.isNil(st_date)) {
      params = params.append('st_date', st_date.toString());
      para.push('st_date=' + st_date.toString())
    }
    if (!_.isNil(en_date)) {
      params = params.append('en_date', en_date.toString());
      para.push('en_date=' + en_date.toString())
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      }),
      params: params
    };

    this.req_api_url = url;
    let api_url_export = `${url}/export`;
    if (para.length > 0) {
      this.req_api_url = `${url}?${_.join(para, '&')}`;
      api_url_export = `${url}/export?${_.join(para, '&')}`;
    }
    this.setUrl(menu_id, api_url_export);
    return this.http.get<ApiResponse<ReportTraveltime[]>>(url, httpOptions);
  }

  getReportUserActivityLog(st_date?: number, en_date?: number, menu_id?: number) {
    let uri = `user/activity`;
    const url = `${this.domain_report}/${uri}`;
    let para = [];
    let params = new HttpParams();

    if (!_.isNil(st_date)) {
      params = params.append('st_date', st_date.toString());
      para.push('st_date=' + st_date.toString())
    }
    if (!_.isNil(en_date)) {
      params = params.append('en_date', en_date.toString());
      para.push('en_date=' + en_date.toString())
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    this.req_api_url = url;
    let api_url_export = `${url}/export`;
    if (para.length > 0) {
      this.req_api_url = `${url}?${_.join(para, '&')}`;
      api_url_export = `${url}/export?${_.join(para, '&')}`;
    }
    this.setUrl(menu_id, api_url_export);
    return this.http.get<ApiResponse<ReportUserActivityLog[]>>(url, httpOptions);
  }

  getReportVswStatusLog(st_date?: string, en_date?: string, menu_id?: number) {
    let uri = `vsw/log`;
    const url = `${this.domain_report}/${uri}`;
    let para = [];
    let params = new HttpParams();

    if (!_.isNil(st_date)) {
      params = params.append('st_date', st_date.toString());
      para.push('st_date=' + st_date.toString())
    }
    if (!_.isNil(en_date)) {
      params = params.append('en_date', en_date.toString());
      para.push('en_date=' + en_date.toString())
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    this.req_api_url = url;
    let api_url_export = `${url}/export`;
    if (para.length > 0) {
      this.req_api_url = `${url}?${_.join(para, '&')}`;
      api_url_export = `${url}/export?${_.join(para, '&')}`;
    }
    this.setUrl(menu_id, api_url_export);
    return this.http.get<ApiResponse<ReportUserActivityLog[]>>(url, httpOptions);
  }

  getReportTTSecurityLog(st_date?: string, en_date?: string, menu_id?: number) {
    let uri = `tt-security/log`;
    const url = `${this.domain_report}/${uri}`;
    let para = [];
    let params = new HttpParams();

    if (!_.isNil(st_date)) {
      params = params.append('st_date', st_date.toString());
      para.push('st_date=' + st_date.toString())
    }
    if (!_.isNil(en_date)) {
      params = params.append('en_date', en_date.toString());
      para.push('en_date=' + en_date.toString())
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    this.req_api_url = url;
    let api_url_export = `${url}/export`;
    if (para.length > 0) {
      this.req_api_url = `${url}?${_.join(para, '&')}`;
      api_url_export = `${url}/export?${_.join(para, '&')}`;
    }
    this.setUrl(menu_id, api_url_export);
    return this.http.get<ApiResponse<ReportUserActivityLog[]>>(url, httpOptions);
  }

  getReportRawSensor(route?: number, km?: number, m?: number, st_date?: number, en_date?: number, menu_id?: number, dir?: number) {
    let uri = `sensor/raw`;
    const url = `${this.domain}/${uri}`;
    let para = [];
    let params = new HttpParams();
    if (!_.isNil(route)) {
      params = params.append('route', route.toString());
      para.push('route=' + route.toString())
    }
    if (!_.isNil(km)) {
      params = params.append('km', km.toString());
      para.push('km=' + km.toString())
    }
    if (!_.isNil(m)) {
      params = params.append('m', m.toString());
      para.push('m=' + m.toString())
    }
    if (!_.isNil(st_date)) {
      params = params.append('st_date', st_date.toString());
      para.push('st_date=' + st_date.toString())
    }
    if (!_.isNil(en_date)) {
      params = params.append('en_date', en_date.toString());
      para.push('en_date=' + en_date.toString())
    }
    if (dir && !_.isNil(dir)) {
      params = params.append('dir', dir.toString());
      para.push('dir=' + dir.toString())
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      }),
      params: params
    };
    this.req_api_url = url;
    let api_url_export = `${url}/export`;
    if (para.length > 0) {
      this.req_api_url = `${url}?${_.join(para, '&')}`;
      api_url_export = `${url}/export?${_.join(para, '&')}`;
    }
    this.setUrl(menu_id, api_url_export);
    return this.http.get<ApiResponse<ReportRawSensor[]>>(url, httpOptions);
  }

  getReportAvgSpeed(route?: number, km?: number, m?: number, st_date?: number, en_date?: number, menu_id?: number) {
    let uri = `sensor/speed`;
    const url = `${this.domain}/${uri}`;
    let para = [];
    let params = new HttpParams();
    if (!_.isNil(route)) {
      params = params.append('route', route.toString());
      para.push('route=' + route.toString())
    }
    if (!_.isNil(km)) {
      params = params.append('km', km.toString());
      para.push('km=' + km.toString())
    }
    if (!_.isNil(m)) {
      params = params.append('m', m.toString());
      para.push('m=' + m.toString())
    }
    if (!_.isNil(st_date)) {
      params = params.append('st_date', st_date.toString());
      para.push('st_date=' + st_date.toString())
    }
    if (!_.isNil(en_date)) {
      params = params.append('en_date', en_date.toString());
      para.push('en_date=' + en_date.toString())
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      }),
      params: params
    };
    this.req_api_url = url;
    let api_url_export = `${url}/export`;
    if (para.length > 0) {
      this.req_api_url = `${url}?${_.join(para, '&')}`;
      api_url_export = `${url}/export?${_.join(para, '&')}`;
    }
    this.setUrl(menu_id, api_url_export);
    return this.http.get<ApiResponse<ReportAvgSpeed[]>>(url, httpOptions);
  }
  getReportAvgDensity(route?: number, km?: number, m?: number, st_date?: number, en_date?: number, menu_id?: number, model?: number) {
    let uri = `sensor/density`;
    const url = `${this.domain}/${uri}`;
    let para = [];
    let params = new HttpParams();
    if (!_.isNil(route)) {
      params = params.append('route', route.toString());
      para.push('route=' + route.toString())
    }
    if (!_.isNil(km)) {
      params = params.append('km', km.toString());
      para.push('km=' + km.toString())
    }
    if (!_.isNil(m)) {
      params = params.append('m', m.toString());
      para.push('m=' + m.toString())
    }
    if (!_.isNil(st_date)) {
      params = params.append('st_date', st_date.toString());
      para.push('st_date=' + st_date.toString())
    }
    if (!_.isNil(en_date)) {
      params = params.append('en_date', en_date.toString());
      para.push('en_date=' + en_date.toString())
    }
    if (!_.isNil(model)) {
      params = params.append('model', model.toString());
      para.push('model=' + model.toString())
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      }),
      params: params
    };
    this.req_api_url = url;
    let api_url_export = `${url}/export`;
    if (para.length > 0) {
      this.req_api_url = `${url}?${_.join(para, '&')}`;
      api_url_export = `${url}/export?${_.join(para, '&')}`;
    }
    this.setUrl(menu_id, api_url_export);
    return this.http.get<ApiResponse<ReportAvgSpeed[]>>(url, httpOptions);
  }

  getReportVolume(route?: number, km?: number, m?: number, st_date?: number, en_date?: number, menu_id?: number, model?: number) {
    let uri = `sensor/volume`;
    const url = `${this.domain}/${uri}`;
    let para = [];
    let params = new HttpParams();
    if (!_.isNil(route)) {
      params = params.append('route', route.toString());
      para.push('route=' + route.toString())
    }
    if (!_.isNil(km)) {
      params = params.append('km', km.toString());
      para.push('km=' + km.toString())
    }
    if (!_.isNil(m)) {
      params = params.append('m', m.toString());
      para.push('m=' + m.toString())
    }
    if (!_.isNil(st_date)) {
      params = params.append('st_date', st_date.toString());
      para.push('st_date=' + st_date.toString())
    }
    if (!_.isNil(en_date)) {
      params = params.append('en_date', en_date.toString());
      para.push('en_date=' + en_date.toString())
    }

    if (!_.isNil(model)) {
      params = params.append('model', model.toString());
      para.push('model=' + model.toString())
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      }),
      params: params
    };
    this.req_api_url = url;
    let api_url_export = `${url}/export`;
    if (para.length > 0) {
      this.req_api_url = `${url}?${_.join(para, '&')}`;
      api_url_export = `${url}/export?${_.join(para, '&')}`;
    }
    this.setUrl(menu_id, api_url_export);
    return this.http.get<ApiResponse<ReportVolume[]>>(url, httpOptions);
  }
  getReportSpeedDetection(route?: number, km?: number, m?: number, st_date?: number, en_date?: number, menu_id?: number, type?: number) {
    let uri = `sensor/detection/speed`;
    const url = `${this.domain}/${uri}`;
    let para = [];
    let params = new HttpParams();
    if (!_.isNil(route)) {
      params = params.append('route', route.toString());
      para.push('route=' + route.toString())
    }
    if (!_.isNil(km)) {
      params = params.append('km', km.toString());
      para.push('km=' + km.toString())
    }
    if (!_.isNil(m)) {
      params = params.append('m', m.toString());
      para.push('m=' + m.toString())
    }

    if (!_.isNil(st_date)) {
      params = params.append('st_date', st_date.toString());
      para.push('st_date=' + st_date.toString())
    }
    if (!_.isNil(en_date)) {
      params = params.append('en_date', en_date.toString());
      para.push('en_date=' + en_date.toString())
    }
    if (type && !_.isNil(type)) {
      params = params.append('type', type.toString());
      para.push('type=' + type.toString())
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      }),
      params: params
    };
    this.req_api_url = url;
    let api_url_export = `${url}/export`;
    if (para.length > 0) {
      this.req_api_url = `${url}?${_.join(para, '&')}`;
      api_url_export = `${url}/export?${_.join(para, '&')}`;
    }
    this.setUrl(menu_id, api_url_export);
    return this.http.get<ApiResponse<ReportSpeedDetection[]>>(url, httpOptions);
  }

  getReportDeviceWorkHour(route?: number, km?: number, m?: number, st_date?: number, en_date?: number, device_type?: number, menu_id?: number) {
    let uri = `device/hour`;
    const url = `${this.domain}/${uri}`;
    let para = [];
    let params = new HttpParams();
    if (!_.isNil(route)) {
      params = params.append('route', route.toString());
      para.push('route=' + route.toString())
    }
    if (!_.isNil(device_type)) {
      params = params.append('device_type', device_type.toString());
      para.push('device_type=' + device_type.toString())
    }
    if (!_.isNil(km)) {
      params = params.append('km', km.toString());
      para.push('km=' + km.toString())
    }
    if (!_.isNil(m)) {
      params = params.append('m', m.toString());
      para.push('m=' + m.toString())
    }
    if (!_.isNil(st_date)) {
      params = params.append('st_date', st_date.toString());
      para.push('st_date=' + st_date.toString())
    }
    if (!_.isNil(en_date)) {
      params = params.append('en_date', en_date.toString());
      para.push('en_date=' + en_date.toString())
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      }),
      params: params
    };
    this.req_api_url = url;
    let api_url_export = `${url}/export`;
    if (para.length > 0) {
      this.req_api_url = `${url}?${_.join(para, '&')}`;
      api_url_export = `${url}/export?${_.join(para, '&')}`;
    }
    this.setUrl(menu_id, api_url_export);
    return this.http.get<ApiResponse<ReportDeviceWorkHour[]>>(url, httpOptions);
  }

  getReportTrafficVolume(agg_type?: number, station_id?: number, json_type?: number, data_type?: number, st_date?: number, en_date?: number, menu_id?: number, model?: number) {
    let uri = `report/sensor/aggregate`;
    const url = `${this.domain}/${uri}`;
    let para = [];
    let params = new HttpParams();
    if (!_.isNil(agg_type)) {
      params = params.append('agg_type', agg_type.toString());
      para.push('agg_type=' + agg_type.toString())
    }
    if (!_.isNil(station_id)) {
      params = params.append('station_id', station_id.toString());
      para.push('station_id=' + station_id.toString())
    }
    if (!_.isNil(json_type)) {
      params = params.append('json_type', json_type.toString());
      para.push('json_type=' + json_type.toString())
    }
    if (!_.isNil(data_type)) {
      params = params.append('data_type', data_type.toString());
      para.push('data_type=' + data_type.toString())
    }
    if (!_.isNil(st_date)) {
      params = params.append('st_date', st_date.toString());
      para.push('st_date=' + st_date.toString())
    }
    if (!_.isNil(en_date)) {
      params = params.append('en_date', en_date.toString());
      para.push('en_date=' + en_date.toString())
    }
    if (!_.isNil(model)) {
      params = params.append('model', model.toString());
      para.push('model=' + model.toString())
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      }),
      params: params
    };
    this.req_api_url = url;
    let api_url_export = `${url}/export`;
    if (para.length > 0) {
      this.req_api_url = `${url}?${_.join(para, '&')}`;
      api_url_export = `${url}/export?${_.join(para, '&')}`;
    }
    this.setUrl(menu_id, api_url_export);
    return this.http.get<ApiResponse<ReportTraffic[]>>(url, httpOptions);
  }


}
