import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiResponse, PagableData } from '../../models/common';
import { TrafficInfo } from '../../models/dashboard/traffic';
import * as _ from 'lodash';
import { Observable, of, Subject } from 'rxjs';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class TrafficService {
  data_key = 'DATAROUTEKEYJARVIS';
  traffic_domain: any;

  subject = new Subject<any>();

  km_count = 40;
  traffic_url: any;
  t = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjIjoiNWIwZTQzODM0NTc3OTQxOTAxMWU2Yzk1IiwicyI6WyJUTUMiXSwiciI6Ikh3SnVpNkRmdUxEZ05TM1lyUWZtNnZIV2hqWU1qeE02VjFtTDFCV0JvbWVTIiwiaWF0IjoxNjA3OTM0MDgwfQ.9MhE1LIdqTx1xiG6lspi_QOWF6YP7EiDhQ6v3y9KOu8`;
  constructor(private http: HttpClient, private router: Router) {
    this.traffic_domain = environment.api.traffic_api;
  }

  getTraffic(route?: string, st_km?: number, en_km?: number, clicked?: number) {
    // const uri = `road/info`;
    // const url = `${this.traffic_domain}/${uri}`;
    const uri = `road-info`;
    const url = `${this.traffic_domain}/${uri}`;
    let para = [];
    let params = new HttpParams();
    if (!_.isNil(route)) {
      params = params.append('route', route.toString());
      para.push('route=' + route.toString())
    }
    if (!_.isNil(st_km)) {
      params = params.append('st_km', st_km.toString());
      para.push('st_km=' + st_km.toString())
    }
    if (!_.isNil(en_km)) {
      params = params.append('en_km', en_km.toString());
      para.push('en_km=' + en_km.toString())
    }
    if (!_.isNil(clicked)) {
      params = params.append('clicked', clicked.toString());
      para.push('clicked=' + clicked.toString())
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + this.t
      }),
      params: params
    };
    this.traffic_url = url;
    if (para.length>0) {
      this.traffic_url = `${url}?${_.join(para, '&')}`;
    }
    return this.http.get<ApiResponse<TrafficInfo>>(url, httpOptions);
  }

  changeRoute(route?: string, km?: number) {
    let st_km = km - (this.km_count / 2);
    if (st_km < 0) { st_km = 0; }
    let en_km = st_km + this.km_count;
    const data = { route: route, st_km: st_km, en_km: en_km }
    localStorage.setItem(this.data_key, JSON.stringify(data));
    this.router.navigate(['/manage/traffic-info']);
    this.subject.next(data);
  }

  getRoute(): Observable<any> {
    return this.subject.asObservable();
  }

  saveData(route?: string, st_km?: any, en_km?: any) {
    const data = { route: route, st_km: st_km, en_km: en_km };
    localStorage.setItem(this.data_key, JSON.stringify(data));
  }
  saveRoute(vals:any){
    // let s = (JSON.stringify(vals)).replace('[','').replace('[','')
    localStorage.setItem(this.data_key, JSON.stringify(vals));
  }
  st_km: number = 1;
  en_km: number = 100;
  route: string = '0002';
  getData(per_road?:any) {
    return new Promise((reslove,reject)=>{
      // per_road
      if(per_road){
        this.route = per_road[0].route 
        this.st_km = per_road[0].st_km
        this.en_km = per_road[0].en_km
      }
      let data = localStorage.getItem(this.data_key);
      if (data) {
        return reslove(JSON.parse(localStorage.getItem(this.data_key).toString()));
      } else {
        const data2 = { route: this.route, st_km: this.st_km, en_km: this.en_km };
        return reslove([data2])
      }

    })
  }
}
