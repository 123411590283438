import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { ActionEnum } from 'src/app/models/common-enum';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-atis-gen-service',
  templateUrl: './atis-gen-service.component.html',
  styleUrls: ['./atis-gen-service.component.scss']
})
export class AtisGenServiceComponent implements OnInit {
  url : string = 'https://monitor.transcode.co.th/d/ca0fd1b9-b270-4795-82f0-49b6064b7072/atis-gen-service?orgId=1&refresh=5s&from=now-24h&to=now'
  constructor(
    private commonService: CommonService,
    private sanitizer: DomSanitizer,
    ) {
    this.commonService.activityLog(ActionEnum.Get, 'ATIS gen service', JSON.stringify({ url: this.url})).subscribe(res => { }, error => { console.log(error); });
  }

  ngOnInit(): void {
  }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
