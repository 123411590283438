import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { StationService } from '../../../services/assets-management/station.service';
import * as mapboxgl from 'mapbox-gl';
import { environment } from '../../../../environments/environment';
import { StatusEnum, RouteDirectionEnum, StationTypeEnum, ActionEnum, ConnectionStatusEnum } from '../../../models/common-enum';
import * as _ from 'lodash';
import * as turf_bbox from '@turf/bbox';
import { Owner } from 'src/app/models/assets-management/owner';
import { Vendor } from 'src/app/models/assets-management/vendor';
import { Province } from 'src/app/models/assets-management/province'
import { HighwayDistrict } from 'src/app/models/assets-management/highway_district'
import { HighwayDepartment } from 'src/app/models/assets-management/highway_department'
import { OwnerService } from 'src/app/services/assets-management/owner.service';
import { VendorService } from 'src/app/services/assets-management/vendor.service';
import { TrafficInfoComponent } from '../../dashboard/traffic-info/traffic-info.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { RlwinfoComponent } from '../rlwinfo/rlwinfo.component';

@Component({
  selector: 'app-device-monitoring',
  templateUrl: './device-monitoring.component.html',
  styleUrls: ['./device-monitoring.component.scss']
})
export class DeviceMonitoringComponent implements OnInit {
  map: mapboxgl.Map;
  toggle_info = false;

  search_route: string;
  data_route: Array<string> = [];
  route_list: string[] = [];
  allowCustom: boolean = false;

  search_km_st: any;
  search_km_en: any;
  connectionStatusEnum = ConnectionStatusEnum;
  statusEnum = StatusEnum;
  status_list = [
    { id: ConnectionStatusEnum.Online, name: "ออนไลน์" },
    { id: ConnectionStatusEnum.Offline, name: "ออฟไลน์" }
    // { id: StatusEnum.Ma, name: "MA" }
  ];
  search_status: any;

  search_owner: any;
  data_owner: Owner[] = [];
  owner_list: Owner[] = [];
  search_vendor: any;
  data_vendor: Vendor[] = [];
  vendor_list: Vendor[] = [];

  search_year: any;
  year_list: number[] = [];
  data_year: number[] = [];

  search_province: any;
  province_list: Province[] = []
  data_province: Province[] = []

  search_highway_district: any;
  highway_district_list: HighwayDistrict[] = []
  data_highway_district: HighwayDistrict[] = []

  search_highway_department: any;
  highway_department_list: HighwayDepartment[] = []
  data_highway_department: HighwayDepartment[] = []

  is_loading = false;

  select_station_id: any;
  select_station: any;
  sub_toggle_sidebar: any;

  adv_search: boolean = false;

  req: any = {};

  modalRef: BsModalRef;
  constructor(private commonService: CommonService, private stationService: StationService, private ownerService: OwnerService, private vendorService: VendorService,
    private modalService: BsModalService,
  ) {
    mapboxgl.accessToken = environment.mapbox.accessToken;
    this.sub_toggle_sidebar = this.commonService.getToggleSidebar().subscribe(res => {
      setTimeout(() => this.map.resize(), 400);
    });
    this.commonService.activityLog(ActionEnum.Get, `Device Monitoring`).subscribe(res => { }, error => { console.log(error); });
  }

  ngOnInit(): void {
    this.getHighwayDepartment();
    this.getHighwayDistrict();
    this.getProvince();
    this.getYear();
    this.getRoute();
    this.getOwner();
    this.getVendor();
    this.initMap();
    this.getDeviceStation();
  }
  clickStation(item?: any) {
    this.select_station_id = item?.station_id;
    this.toggle_info = true;
    this.map.flyTo({ center: [item?.lng, item?.lat], zoom: 15 });
    this.getDeviceByStationId();
  }

  getRoute() {
    this.commonService.getRoute().subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.route_list = _.cloneDeep(res.data);
              this.data_route = _.cloneDeep(res.data);
            }
          }
        }
      },
      error => { });
  }

  getProvince() {
    this.commonService.getProvince().subscribe(res => {
      if (res) {
        if (res.code == 1) {
          if (res.data) {
            this.province_list = _.cloneDeep(res.data)
            this.data_province = _.cloneDeep(res.data)
          }
        }
      }
    })
  }
  handleFilterProvince(value) {
    if (value.length > 0) {
      this.data_province = this.province_list.filter((s) => s.province_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_province = _.cloneDeep(this.province_list);
    } else {
      this.data_province = [];
    }
  }
  handleValueProvince(value) {
    if (value) {
      this.search_province = value;
      this.data_highway_district = this.highway_district_list.filter(h => h.id_province == value.province_id)
    } else {
      this.search_province = null;
      this.search_highway_department = null;
      this.search_highway_district = null;
      this.data_highway_district = []
      this.data_highway_department = []
    }
  }

  getHighwayDistrict() {
    this.commonService.getHighwayDistrict().subscribe(res => {
      if (res) {
        if (res.code == 1) {
          if (res.data) {
            this.highway_district_list = _.cloneDeep(res.data)
            // this.data_highway_district = _.cloneDeep(res.data)
          }
        }
      }
    })
  }
  handleFilterHighwayDistrict(value) {
    if (value.length > 0) {
      this.data_highway_district = this.data_highway_district.filter((s) => s.highway_district.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_highway_district = this.highway_district_list.filter(h => h.id_province == this.search_province?.province_id)
    } else {
      this.data_highway_district = this.highway_district_list.filter(h => h.id_province == this.search_province?.province_id)
      this.data_highway_department = [];
    }
  }
  handleValueHighwayDistrict(value) {
    if (value) {
      this.search_highway_district = value;
      this.data_highway_department = this.highway_department_list.filter(h => h.id_highway_district == value.id)
    } else {
      this.search_highway_district = null;
      this.data_highway_district = this.highway_district_list.filter(h => h.id_province == this.search_province?.province_id)
      this.data_highway_department = [];
      this.search_highway_department = null
    }
  }

  getHighwayDepartment() {
    this.commonService.getHighwayDepartment().subscribe(res => {
      if (res) {
        if (res.code == 1) {
          if (res.data) {
            this.highway_department_list = _.cloneDeep(res.data)
            // this.data_highway_department = _.cloneDeep(res.data)
          }
        }
      }
    })
  }

  handleFilterHighwayDepartment(value) {
    if (value.length > 0) {
      this.data_highway_department = this.data_highway_department.filter((s) => s.highway_department.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_highway_department = this.highway_department_list.filter(h => h.id_highway_district == this.search_highway_district.id)
    } else {
      this.data_highway_department = this.highway_department_list.filter(h => h.id_highway_district == this.search_province?.province_id)
      this.data_highway_department = [];
    }
  }
  handleValueHighwayDepartment(value) {
    if (value) {
      this.search_highway_department = value;
    } else {
      this.search_highway_department = null;
    }
  }

  handleFilterRoute(value) {
    if (value.length > 0) {
      this.data_route = this.route_list.filter((s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_route = _.cloneDeep(this.route_list);
    } else {
      this.data_route = [];
    }
  }

  handleValueRoute(value) {
    if (value) {
      this.search_route = value;
    } else {
      this.search_route = null;
    }
  }
  handleValueStatus(value) {
    if (value) {
      this.search_status = value;
    } else {
      this.search_status = null;
    }
  }

  getYear() {
    this.commonService.getYear().subscribe(
      res => {
        if (res) {
          if (res.code === 1) {
            if (res.data) {
              this.year_list = _.cloneDeep(res.data)
              this.data_year = _.cloneDeep(res.data)
            }
          }
        }
      }
    )
  }
  handleValueYear(value) {
    if (value) {
      this.search_year = value;
    } else {
      this.search_year = null;
    }
  }
  handleFilterYear(value) {
    if (value.length > 0) {
      this.data_year = this.year_list.filter((s) => s == value);
    } else if (value.length == 0) {
      this.data_year = _.cloneDeep(this.year_list);
    } else {
      this.data_year = [];
    }
  }

  getOwner() {
    this.ownerService.getOwner(null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.owner_list = _.cloneDeep(res.data.data);
              this.data_owner = _.cloneDeep(res.data.data);
            }
          }
        }
      },
      error => { });
  }
  handleFilterOwner(value) {
    if (value.length > 0) {
      this.data_owner = this.owner_list.filter((s) => s.company_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_owner = _.cloneDeep(this.owner_list);
    } else {
      this.data_owner = [];
    }
  }
  handleValueOwner(value) {
    if (value) {
      this.search_owner = value;
    } else {
      this.search_owner = null;
    }
  }

  getVendor() {
    this.vendorService.getVendor(null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.vendor_list = _.cloneDeep(res.data);
              this.data_vendor = _.cloneDeep(res.data);

            }
          }
        }
      },
      error => { });
  }
  handleFilterVendor(value) {
    if (value.length > 0) {
      this.data_vendor = this.vendor_list.filter((s) => s.company_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_vendor = _.cloneDeep(this.vendor_list);
    } else {
      this.data_vendor = [];
    }
  }
  handleValueVendor(value) {
    if (value) {
      this.search_vendor = value;
    } else {
      this.search_vendor = null;
    }
  }

  toggleBoxLeft() {
    this.toggle_info = !this.toggle_info;
    setTimeout(() => {
      this.map.resize();
    }, 300);
  }

  station_layer: any = 'DevieStationLayer';
  station_src: any = 'DeviceStationSrc';
  stationListFeatures: any[] = [];
  stationList: any = [];

  getDeviceStation() {
    this.toggle_info = false;
    this.select_station_id = null;
    this.select_station = null;

    this.is_loading = true;
    this.stationList = [];
    this.stationListFeatures = [];

    this.req = {
      route: this.search_route != null ? parseInt(this.search_route) : null,
      st_km: this.search_km_st != null ? parseInt(this.search_km_st) : null,
      en_km: this.search_km_en != null ? parseInt(this.search_km_en) : null,
      connection_status: this.search_status != null ? this.search_status.id : null,
      vendor_id: this.search_vendor != null ? this.search_vendor.vendor_id : null,
      owner_id: this.search_owner != null ? this.search_owner.id : null,
      year: this.search_year != null ? parseInt(this.search_year) : null,
      province_id: this.search_province != null ? parseInt(this.search_province.province_id) : null,
      highway_district_id: this.search_highway_district != null ? parseInt(this.search_highway_district.id) : null,
      highway_department_id: this.search_highway_department != null ? parseInt(this.search_highway_department.id) : null
    };

    this.stationService.getDeviceStationMonitoring(this.req).subscribe(
      res => {
        if (res) {
          res?.data?.forEach(item => {
            item?.station_list?.forEach(station => {
              if (station.lng && station.lat) {
                const coordinates = [station.lng, station.lat];
                const geometry = { type: 'Point', coordinates: coordinates };
                const feature = { type: 'Feature', geometry: geometry, properties: station };
                this.stationListFeatures.push(feature);
              }
            });

          });

          this.stationListFeatures = _.uniqBy(this.stationListFeatures, 'properties.station_id');
          this.stationList = res.data;
          this.stationList.forEach(item => {
            item.isCollapsed = true;
          });
          setTimeout(() => {
            this.updateMap();
          }, 1000);

        }
        this.is_loading = false;
      },
      err => {
        this.is_loading = false;
        console.log(JSON.stringify(err.statusText));
      });

    setTimeout(() => {
      this.map.resize();
    }, 500);
  }

  getDeviceByStationId() {
    this.select_station = null;
    this.stationService.getDeviceByStationId(this.select_station_id, this.req.owner_id, this.req.vendor_id, this.req.year, this.req.connection_status).subscribe(
      res => {
        if (res) {
          this.select_station = res.data;
          this.select_station?.groups.forEach(g => {
            g?.device_list.forEach(item => {
              item.isCollapsed = true;
            });
          });
        }
      },
      err => {
        console.log(JSON.stringify(err.statusText));
      });
  }
  collapseDevice(device_id) {
    this.select_station?.groups.forEach(g => {
      g?.device_list.forEach(item => {
        if (item?.device_id === device_id) {
          item.isCollapsed = !item.isCollapsed;
        }
      });
    });
  }
  collapse(group_id) {
    this.stationList.forEach(item => {
      if (item?.group_id === group_id) {
        item.isCollapsed = !item.isCollapsed;
      }
    });
  }
  initMap() {
    this.map = new mapboxgl.Map({
      container: 'map-device-monitoring',
      style: 'mapbox://styles/mapbox/dark-v10',
      zoom: 7,
      center: [100.523186, 13.736717],
    });
    this.map.dragRotate.disable();

    setTimeout(() => {
      this.map.resize();
    }, 500);

    this.map.on('load', (event: mapboxgl.MapEvent) => {

      this.map.loadImage('assets/img/marker/pin-red.png', (error, image) => {
        if (error) { throw error };
        this.map.addImage(`pin-${ConnectionStatusEnum.Offline}`, image);
      });
      this.map.loadImage('assets/img/marker/pin-green.png', (error, image) => {
        if (error) { throw error };
        this.map.addImage(`pin-${ConnectionStatusEnum.Online}`, image);
      });
      this.map.loadImage('assets/img/marker/pin-yellow.png', (error, image) => {
        if (error) { throw error };
        this.map.addImage(`pin-${StatusEnum.Ma}`, image);
      });
      // this.map.loadImage('assets/img/marker/pin-gray.png', (error, image) => {
      //   if (error) { throw error };
      //   this.map.addImage('pin-null', image);
      // });
      // this.map.loadImage('assets/img/marker/pin-gray.png', (error, image) => {
      //   if (error) { throw error };
      //   this.map.addImage('pin-', image);
      // });

      //------------------------------------------------------------------------------------------//

      this.map.addSource(this.station_src, {
        "type": 'geojson', "data": {
          "type": "FeatureCollection",
          "features": this.stationListFeatures
        },
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: 50
      });

      this.map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: this.station_src,
        filter: ['has', 'point_count'],
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 20
          //   * Yellow, 30px circles when point count is between 20 and 150
          //   * Pink, 40px circles when point count is greater than or equal to 150
          // 'circle-color': [
          //   'step',
          //   ['get', 'point_count'],
          //   '#51bbd6',
          //   20,
          //   '#f1f075',
          //   150,
          //   '#f28cb1'
          // ],
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#51bbd6',
            20,
            '#51bbd6',
            150,
            '#51bbd6'
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40
          ]
        }
      });

      this.map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: this.station_src,
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        }
      });

      //icon w-40 h-40
      this.map.addLayer({
        id: this.station_layer,
        source: this.station_src,
        type: 'symbol',
        layout: {
          'icon-allow-overlap': true,
          'icon-image': 'pin-{connection_status}',
          "icon-size": 0.8,
          "icon-offset": [0, 0], //[x,y]
          'text-offset': [0, 0],
          'text-field': '{device_count}',
          'text-size': 12,
          'text-allow-overlap': true,
          'text-transform': 'uppercase',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-optional': true,
          'symbol-z-order': 'source', // to order by symbol-sort-key
          'symbol-sort-key': ['get', 'index'], //index should be defined in source feature properties
        },
        paint: {
          "text-color": "#000000"
        },
        "filter": ["==", "$type", "Point"],
      });

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false, anchor: 'top', offset: [0, 22]
      });
      // inspect a cluster on click
      this.map.on('click', 'clusters', (e) => {
        const features = this.map.queryRenderedFeatures(e.point, {
          layers: ['clusters']
        });
        const clusterId = features[0].properties.cluster_id;
        this.map.getSource(this.station_src).getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (err) return;

            this.map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom
            });
          }
        );
      });

      this.map.on('click', this.station_layer, (event: mapboxgl.MapEvent) => {
        this.map.flyTo({ center: event.features[0].geometry.coordinates, zoom: 15 });
        const station_prop = event.features[0].properties;
        this.clickStation(station_prop);
      });
      this.map.on('mouseenter', this.station_layer, (e: mapboxgl.MapEvent) => {
        this.map.getCanvas().style.cursor = 'pointer';
        const p = e.features[0].properties;
        let dir_text = '';
        if (p.dir) {
          dir_text = `${p.dir == 1 ? '(ขาเข้า)' : p.dir == 2 ? '(ขาออก)' : p.dir == 3 ? '(เกาะกลาง)' : ''}`
        }
        let groups_txt = '';
        JSON.parse(p.groups).forEach(element => {
          groups_txt = groups_txt + `<div class="d-flex justify-content-between align-items-center" style="color: black;background-color: #aeacac;border-radius: 5px;padding: 5px;margin: 2px;">${element}</div>`
        });
        const htmltext = `<div class="font-3" style="padding-top: 10px;padding-left: 10px;padding-right: 10px;">
        <h6>ทางหลวงหมายเลข ${p.route}</h6>
        <h6>กม. ${p.km} + ${p.m.toString().padStart(3, '0')} ${dir_text}</h6>
        ${p.structure_type_name && p.structure_type_name != 'null' ? `<h6>${p.structure_type_name}</h6>` : ''}
        <div class="d-flex mb-2 flex-wrap">${groups_txt}</div>
        </div>`;
        popup.setLngLat(e.features[0].geometry.coordinates)
          .setHTML(htmltext)
          .addTo(this.map);
      });
      this.map.on('mouseleave', this.station_layer, (e: mapboxgl.MapEvent) => {
        this.map.getCanvas().style.cursor = '';
        popup.remove();
      });

      this.map.on('mouseenter', 'clusters', () => {
        this.map.getCanvas().style.cursor = 'pointer';
      });
      this.map.on('mouseleave', 'clusters', () => {
        this.map.getCanvas().style.cursor = '';
      });
      if (this.stationListFeatures.length) {
        this.updateMap();
      }
    });

  }
  updateMap() {
    if (this.map.getSource(this.station_src) != null) {
      this.map.getSource(this.station_src).setData({
        'type': 'FeatureCollection',
        'features': this.stationListFeatures
      });
      let bbox = turf_bbox.default({
        type: 'FeatureCollection',
        features: this.stationListFeatures
      });
      this.map.fitBounds(bbox, { padding: 50 });
    }
  }

  switch_search() {
    this.adv_search = !this.adv_search;

    if(!this.adv_search){
      this.search_vendor = null;
      this.search_owner = null;
      this.search_year = null;
      this.search_highway_department = null;
      this.search_highway_district = null;
      this.search_province = null;
      this.search_status = null;
    }else if(this.adv_search){
      this.search_km_st = null;
      this.search_km_en = null;
      this.search_route = null;
      this.search_status = null;
    }
    

  }
  async openInfoDevice(data?:any){
    console.log(data)
    
    const initialState = {
      values: data,
    };

    this.modalRef = this.modalService.show(RlwinfoComponent, { 
      initialState, class: 'markerD-BG',
      // backdrop: 'static',
      id:5 
    });
    this.modalRef.content.event.subscribe(data => {
      console.log(data)
      // sessionStorage.removeItem('event_id')
      // if (data) this.getEventMap();
    });
    
  }
  showInfo(data?:any){
    console.log(data)
    
    const initialState = {
      values_fromMonitoring: data,
    };

    this.modalRef = this.modalService.show(TrafficInfoComponent, { 
      initialState, class: 'markerD-BG w-80',
      // backdrop: 'static',
      id:5 
    });
    this.modalRef.content.event.subscribe(data => {
      console.log(data)
      sessionStorage.removeItem('event_id')
      // if (data) this.getEventMap();
    });
    
  }
}

