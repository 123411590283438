import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-rlwinfo',
  templateUrl: './rlwinfo.component.html',
  styleUrls: ['./rlwinfo.component.scss']
})
export class RlwinfoComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();
  values: any;
  vsw: any;
  constructor(
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
    console.log(this.values)
    if(this.values.vsw) this.vsw=this.values.vsw
  }

  close() {
    this.bsModalRef.hide();
  }

  confirm() {
    this.event.emit(true);
    this.bsModalRef.hide();
  }
  cancel() {
    this.event.emit(false);
    this.bsModalRef.hide();
  }
}
