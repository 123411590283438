export const navItems = [
    {
        id: 1200,
        name: 'Dashboard',
        url: `/dashboard`,
        icon: 'icon-speedometer',
    },
    {
        id : 1100,
        name: 'Traffic Information',
        url: '/traffictool',
        icon: 'fa fa-road',
    },{
        id: 100,
        name: 'Event Management ',
        url: `/event`,
        icon: 'fa fa-exclamation-triangle',
        children:[
            {
                id:100,
                name: 'Event Monitoring ',
                url: `/event/map`,
                icon: 'fa fa-television', 
            },
            {
                id:100,
                name: 'Event Lists ',
                url: `/event/list`,
                icon: 'fa fa-tasks', 
            },
            {
                id:100,
                name: 'Event Dashboard ',
                url: `/event/dashboard`,
                icon: 'fa fa-bar-chart', 
            },
            {
                id:100,
                name: 'Event Setting ',
                url: `/event/setting`,
                icon: 'fa fa-cog', 
            },
        ]
    },
    {
        id: 1600,
        name: 'Device Monitoring',
        url: `/device-monitoring`,
        icon: 'icon icon-screen-desktop',
        children:[
            {
                id:1600,
                name: 'Monitoring',
                url: `/device-monitoring/monitoring`,
                icon: 'fa fa-television', 
            },
            {
                id:1600,
                name: 'ATIS Gen Service',
                url: `/device-monitoring/atis-gen-service`,
                icon: 'fa fa-tasks', 
            },
        ]
    },
    {
        id : 200,
        name: 'Sign Control',
        url: '/sign-control',
        icon: 'fa fa-clone',
    },
    {
        id : 800,
        name: 'Assets Management',
        url: '/assets-management',
        icon: 'fa fa-cog',
    },
    {
        id : 1000,
        name: 'User Management',
        url: '/user-management',
        icon: 'fa fa-user-circle-o',
    },
    {
        id: 900,
        name: 'Information',
        url: `/information`,
        icon: 'icon-info',
    },
    {
        id: 1300,
        name: 'Report',
        url: `/report`,
        icon: 'icon icon-graph',
    },
    {
        id: 1400,
        name: 'Previous Version',
        url: `/previous-version`,
        icon: 'icon icon-screen-desktop',
    }
];
