import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, ignoreElements } from 'rxjs/operators';
import { ApiResponse, PagableData, ImgUploadResponse } from '../../models/common';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../../environments/environment';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CorridorControlService {

  domain: string;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.domain = environment.api.domain_assets;
  }

  getHeaders() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    }
  }

  getRoute(): Observable<ApiResponse<any[]>> {
    const httpOptions = this.getHeaders();
    const uri = `corridor-control/route`;
    const url = `${this.domain}/${uri}`;
    return this.http.get<ApiResponse<any[]>>(url, httpOptions);
  }

  getRoadway(req?: any[]) {
    const httpOptions = this.getHeaders();
    const uri = `corridor-control/roadway`;
    const url = `${this.domain}/${uri}`;
    return this.http.post<ApiResponse<any[]>>(url, req, httpOptions);
  }

  getIntersection(req?: any[]) {
    const httpOptions = this.getHeaders();
    const uri = `corridor-control/intersection`;
    const url = `${this.domain}/${uri}`;
    return this.http.post<ApiResponse<any[]>>(url, req, httpOptions);
  }

}
