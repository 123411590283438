import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EventService } from 'src/app/services/user/event.service';
import { ModalComponent } from './modal/modal.component';
import * as _ from 'lodash';
import { Router } from '@angular/router';

@Component({
  selector: 'app-config-rain',
  templateUrl: './config-rain.component.html',
  styleUrls: ['./config-rain.component.scss']
})
export class ConfigRainComponent implements OnInit {
  modalRef: BsModalRef;
  routeItems : any = []
  route : any
  st_km : number
  en_km : number
  config : number
  deviceLists : any = []
  ids : any = []
  itemsPerPage : number = 10
  currentPage : number = 1
  constructor(
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    public eS: EventService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getRoute();
    this.getRainDevices();
  }
  async getRainDevices(){
    this.deviceLists = []
    let data : any = {}
    if(Number(this.route)>=0) data.route = Number(this.route)
    if(this.st_km>=0) data.st_km = this.st_km
    if(this.en_km>=0) data.en_km = this.en_km
    if(this.config>=0) data.config = this.config
    let res : any = await this.eS.getRainDevices(data)
    if(res.length) this.deviceLists = res
  }
  async getRoute() {
    let res : any = await this.eS.getRoute();
    if(res) this.routeItems = res
  }
  openModel(data?:any){
    if(!data) {
      data = {device_id:[],device_loc:[]}
      for (const key in this.ids) {
        if(this.ids[key]===true) {
          data.device_id.push(key)
        }
      }
      data.device_loc = _.filter(this.deviceLists,(v)=>{
        return data.device_id.find(x=>x==v.id)
      })
    }
    const initialState = {
      values: data,
    };

    this.modalRef = this.modalService.show(ModalComponent, { initialState, class: 'w-80',backdrop: 'static',id:5 });
    this.modalRef.content.event.subscribe(data => {
      // if (data) this.getEventMap();
      this.getRainDevices();
    });
  }
  back(){
    this.router.navigateByUrl('event/setting');
  }
}
