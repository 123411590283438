<div class="row p-3">
  <div class="col-lg-3 col-md-3 col-sm-6">
    <div>เหตุการณ์ :</div>
    <div class="d-flex align-items-center">
      <kendo-combobox [data]="typeList" [placeholder]="'ทั้งหมด'" [textField]="'event_type_name'" [valueField]="'event_type_id'"
      (valueChange)="handleValueType($event)" class="form-control">
      </kendo-combobox>
      <!-- <select [(ngModel)]="type_id" name="type_id" id="" class="form-control">
        <option value="null">Select</option>
        <option *ngFor="let i of typeList" [value]="i.id">{{ i.name }}</option>
      </select> -->
    </div>

  </div>
  <div class="col-lg-3 col-md-3 col-sm-6">
    <div>ประเภทเหตุการณ์ :</div>
    <div class="d-flex align-items-center">
      <kendo-combobox [data]="subtypeList" [placeholder]="'ทั้งหมด'" [textField]="'event_subtype_name'" [valueField]="'event_subtype_id'" [disabled]="!type_id"
      (valueChange)="subtype_id = ($event.event_subtype_id)? $event.event_subtype_id:null" class="form-control">
      </kendo-combobox>
      <!-- <select [(ngModel)]="type_id" name="type_id" id="" class="form-control">
        <option value="null">Select</option>
        <option *ngFor="let i of typeList" [value]="i.id">{{ i.name }}</option>
      </select> -->
    </div>
    <!-- <div class="d-flex align-items-center">
            <select [(ngModel)]="src_group_id" name="src_group_id" id="" class="form-control">
                <option value=null>Select</option>
                <option value=1>เพิ่มโดยผู้ใช้</option>
                <option value=2>ระบบตรวจจับ</option>
            </select>
        </div> -->
  </div>
  <div class="col-lg-3 col-md-3 col-sm-6">
    <div>แหล่งที่มาของข้อมูล :</div>
    <div class="d-flex align-items-center">
      <kendo-combobox [data]="srcItems" textField="descript"
      valueField="id" [allowCustom]="false" placeholder="แหล่งที่มาของข้อมูล" (valueChange)="handleValueSrc($event)" class="form-control">
      </kendo-combobox>
    </div>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-6">
    <div>สถานะการดำเนินการ :</div>
    <div class="d-flex align-items-center">
      <kendo-combobox [data]="statusList" [placeholder]="'ทั้งหมด'" [textField]="'name'" [valueField]="'id'"
        (valueChange)="handleValueStatus($event)" class="form-control">
      </kendo-combobox>

            <!-- <select [(ngModel)]="status" name="status" id="" class="form-control">
        <option value="null">Select</option>
        <option *ngFor="let i of statusList" [value]="i.id">
          {{ i.name }}
        </option>
      </select> -->
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6 mt-2">
    <div>วันที่ :</div>
    <div class="input-group" style="flex-wrap: inherit">
      <div class="input-group-prepend">
        <span class="input-group-text">เริ่ม</span>
        <kendo-datepicker style="max-width: 300px" [(ngModel)]="st_date" class="w-100" [format]="'dd/MM/yyyy'"
          [formatPlaceholder]="formatPlaceholder"></kendo-datepicker>
      </div>
      <div class="input-group-prepend">
        <span class="input-group-text">ถึง</span>
        <kendo-datepicker style="max-width: 300px" [(ngModel)]="en_date" class="w-100" [format]="'dd/MM/yyyy'"
          [formatPlaceholder]="formatPlaceholder"></kendo-datepicker>
      </div>
    </div>
  </div>
  
  <!-- <div class="col-lg-4 col-md-4 col-sm-6 mt-2">
    <div>จังหวัด :</div>
    <div class="d-flex align-items-center">
      <kendo-combobox class="form-control" 
      [data]="province_list" 
      [allowCustom]="allowCustom" 
      [suggest]="true"
      [textField]="'province_name'" 
      [valueField]="'province_id'" 
      [value]="search_province" 
      [filterable]="true" 
      [placeholder]="'ทั้งหมด'" (filterChange)="FilterProvince($event)"
        (valueChange)="ChageProvince($event)" placeholder="กรุณาเลือกจังหวัด"></kendo-combobox>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6 mt-2">
    <div>แขวงทางหลวง :</div>
    <div class="d-flex align-items-center">
      <kendo-combobox class="form-control" 
      [data]="district_list" 
      [allowCustom]="allowCustom" 
      [suggest]="true"
      [textField]="'highway_district'" 
      [valueField]="'id'" 
      [value]="search_district" 
      [filterable]="true" 
      [placeholder]="'ทั้งหมด'" (filterChange)="FilterDistrict($event)" [disabled]="!search_province" 
        (valueChange)="ChageDistrict($event)" placeholder="กรุณาเลือกแขวงทางหลวง"></kendo-combobox>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6 mt-2">
    <div>หมวดทางหลวง :</div>
    <div class="d-flex align-items-center">
      <kendo-combobox class="form-control" 
      [data]="department_list" 
      [allowCustom]="allowCustom" 
      [suggest]="true"
      [textField]="'highway_department'" 
      [valueField]="'id'" 
      [value]="search_department" 
      [filterable]="true" 
      [placeholder]="'ทั้งหมด'" (filterChange)="FilterDepartment($event)" [disabled]="!search_district" 
        (valueChange)="ChageDepartment($event)" placeholder="กรุณาเลือกหมวดทางหลวง"></kendo-combobox>
    </div>
  </div> -->
  <div class="col-lg-4 col-md-4 col-sm-6 mt-2">
    <div>ทางหลวง :</div>
    <div class="d-flex align-items-center">
      <kendo-combobox class="form-control" [data]="route_list" [allowCustom]="allowCustom" [suggest]="true"
        [(ngModel)]="search_route" [filterable]="true" [placeholder]="'ทั้งหมด'" (filterChange)="FilterRoute($event)"
        (valueChange)="ChageRoute($event)"></kendo-combobox>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6 mt-2">
    <div>หลักกิโลเมตร :</div>
    <div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text">เริ่มต้น</span>
        </div>
        <input type="text" class="form-control" [(ngModel)]="st_km" placeholder="กม." numbersOnly maxlength="4" />
        <div class="input-group-prepend">
          <span class="input-group-text">สิ้นสุด</span>
        </div>
        <input type="text" class="form-control" [(ngModel)]="en_km" placeholder="กม." numbersOnly maxlength="4" />
      </div>
    </div>
  </div>
  <div class="col-lg-12 col-md-12 col-sm-12 mt-2 d-flex justify-content-end align-items-center">
    <button type="button" (click)="search()" class="btn btn-primary pull-right text-white">
      <i class="fa fa-search"></i> ค้นหา
    </button>
  </div>

  <div class="col-12 mt-3 table-header pl-3 pr-3 pt-2 pb-2 d-flex align-items-center justify-content-between">
    <div>Event list</div>
    <div>
      <button [disabled]="!data_list || data_list?.length < 1" class="btn btn-outline-info text-white mr-2" (click)="genExcel()">
        Export Excel
      </button>
      <button *ngIf="isAdd" class="btn btn-info text-white" (click)="openModal()">
        + เพิ่ม Event
      </button>
    </div>
  </div>

  <div class="col-12 mt-3">
    <table class="table-main table table-outline mb-0">
      <thead class="thead-dark" style="background-color: black">
        <tr>
          <th class="text-center text-light table-header">
            <i class="fa fa-hashtag"></i>
          </th>
          <th class="text-center text-light table-header">เหตุการณ์</th>
          <th class="text-center text-light table-header">ประเภทเหตุการณ์</th>
          <th class="text-center text-light table-header">สถานะการดำเนินการ</th>
          <th class="text-center text-light table-header">
            ตำแหน่งของเหตุการณ์
          </th>
          <th class="text-center text-light table-header">Create datetime</th>
          <th *ngIf="isDetail || isDelete" class="text-center text-light table-header">
            Action
          </th>
        </tr>
      </thead>
      <tbody *ngIf="!data_list || data_list?.length < 1">
        <tr>
          <td colspan="10" style="height: 50px">ไม่พบข้อมูล</td>
        </tr>
      </tbody>
      <tbody *ngIf="data_list?.length">
        <tr *ngFor="let i of data_list; index as idx">
          <td>
            {{ idx + 1 + itemsPerPage * (currentPage - 1) }}
          </td>
          <td class="text-left">
            <h5 *ngIf="i.event_group_name" class="pri-txt">
              {{ i.event_group_name ? "" + i.event_group_name + "" : "" }}
            </h5>
            <p class="m-0">{{ i.event_type_name }}</p>
          </td>
          <td class="text-left">
            <p class="m-0">{{ i.event_subtype_name }}</p>
          </td>
          <!-- <td [ngSwitch]="i.src_group_id">
                        <p class="m-0" *ngSwitchCase="1">เพิ่มโดยผู้ใช้</p>
                        <p class="m-0" *ngSwitchCase="2">ระบบตรวจจับ
                        
                        </p>
                        <p class="m-0" *ngSwitchDefault></p>
                    </td> -->
          <td>
            {{ i.status_name }}
          </td>
          <td>
            <!-- ทล.2 กม.46+856 (ขาออก) -->
            <!-- {{i.route | number:'4.0-0' | noComma}}
                        {{(i.km||i.m)? '('+(i.km||0)+(i.m? '+'+i.m:'')+')' : ''}} -->
            <!-- {{ i.st_dir == 1 ? "ขาเข้า" : "" }}
            {{ i.st_dir == 2 ? " ขาออก" : "" }}
            {{ i.st_dir == 3 ? "ขาเข้า-ขาออก" : "" }} -->
            {{
              i.st_road
                ? "ทล."+i.st_road + " กม." + i.st_km + " + " + i.st_m
                : ""
            }}
            {{ i.st_dir == 1 ? " (ขาเข้า)" : "" }}

            {{ i.st_dir &&i.en_dir ? "/ " : "" }}

            {{
              i.en_road
                ? "ทล."+i.en_road + " กม." + i.en_km + " + " + i.en_m
                : "-"
            }}
            {{ en_road&&i.st_dir == 2 ? " ขาออก" : "" }}
          </td>
          <td>
            {{ i.occur_datetime | date: "dd/MM/yyyy HH:mm:ss" }}
          </td>
          <td *ngIf="isDetail || isDelete || isReport">
            <button *ngIf="isDetail" class="ml-1 mr-1 btn btn-info" (click)="openModal(i)">
              <i class="icon-list" aria-hidden="true"></i>
            </button>
            <!-- <button *ngIf="isDelete" class="ml-1 mr-1 btn btn-danger" (click)="rmlist(i.id)">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                        </button> -->
          </td>
        </tr>
        <tr>
          <td colspan="12" class="table-footer">
            <div class="table-footer-inside d-flex align-items-center justify-content-between">
              <div *ngIf="total_items" class="ml-3 pt-3">
                <pagination [(ngModel)]="currentPage" [maxSize]="10" [totalItems]="total_items"
                  [itemsPerPage]="itemsPerPage" (pageChanged)="pageChanged($event)" previousText="&lsaquo;"
                  nextText="&rsaquo;" [boundaryLinks]="true" firstText="&laquo;" lastText="&raquo;">></pagination>
              </div>
              <div></div>
              <div class="mr-3 ml-2">total: {{ total_items || 0 }}</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
