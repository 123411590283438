import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, ignoreElements } from 'rxjs/operators';
import { ApiResponse, PagableData, ImgUploadResponse } from '../models/common';

import { environment } from '../../environments/environment';
import * as _ from 'lodash';
import { AuthService } from './auth/auth.service';
import { Station, ReqAddStation } from '../models/assets-management/station';
import { StatusEnum } from '../models/common-enum';
import { FuncService } from './user/func.service';

import { menuItems as menuItems1 } from 'src/app/views/assets-management/_menu';
import { menuItems as menuItems2 } from 'src/app/views/user-management/menu';
import { menuItems as menuItems3 } from 'src/app/views/sign-control/_menu';
import { menuItems as menuItems4 } from 'src/app/views/traffic-tool/menu';
import { menuItems as menuItems5 } from 'src/app/views/event/menu';
import { menuItems as menuItems6 } from 'src/app/views/information/_menu';
import { menuItems as menuItems7 } from 'src/app/views/dashboard/_menu';
import { menuItems as menuItems8 } from 'src/app/views/report/_menu';
import { menuItems as menuItems9 } from 'src/app/views/previous-version/_menu';
import { menuItems as menuItems10 } from 'src/app/views/device-monitoring/_menu';
import { navItems } from 'src/app/_nav';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  menuItems = [];
  domain: string;
  domain_traffic: string;
  domain_auth: string;
  domain_sign_control: string;
  private subject = new Subject<any>();
  private subject_sidebar = new Subject<any>();
  constructor(private http: HttpClient, private authService: AuthService, private func: FuncService) {
    this.domain = environment.api.domain_assets;
    this.domain_traffic = environment.api.traffic_api;
    this.domain_auth = environment.api.domain_auth;
    this.domain_sign_control = environment.api.domain_sign_control;
    this.menuItems = _.concat(menuItems1, menuItems2, menuItems3, menuItems4, menuItems5, menuItems6, menuItems7, menuItems8, menuItems9, menuItems10);
  }
  getUsers(short?: number) {
    let params = new HttpParams();
    if (!_.isNil(short)) {
      params = params.append('short', short.toString());
    }
    const uri = `users`;
    const url = `${environment.api.domain_auth}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<any>>(url, httpOptions);
  }
  getDataByUrl(url?: string): Observable<any> {
    return this.http.get<any>(url);
  }
  getRoute(): Observable<ApiResponse<any[]>> {
    // let url = 'assets/data/route.json';
    // return this.http.get<ApiResponse<any[]>>(url);

    const uri = `route`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.get<ApiResponse<any[]>>(url, httpOptions).pipe(catchError(this.errorHandler("", null)));
  }

  getOperation(): Observable<ApiResponse<any[]>> {
    // let url = 'assets/data/route.json';
    // return this.http.get<ApiResponse<any[]>>(url);

    const uri = `operation/zones`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.get<ApiResponse<any[]>>(url, httpOptions).pipe(catchError(this.errorHandler("", null)));
  }

  getYear(): Observable<ApiResponse<any[]>> {
    // let url = 'assets/data/route.json';
    // return this.http.get<ApiResponse<any[]>>(url);

    const uri = `year/installation`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.get<ApiResponse<any[]>>(url, httpOptions).pipe(catchError(this.errorHandler("", null)));
  }

  getHighwayDistrict(): Observable<ApiResponse<any[]>> {
    const uri = `highway-district`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.get<ApiResponse<any[]>>(url, httpOptions);
  }

  getHighwayDepartment(): Observable<ApiResponse<any[]>> {
    const uri = `highway-department`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.get<ApiResponse<any[]>>(url, httpOptions);
  }


  getProvince(): Observable<ApiResponse<any[]>> {
    const uri = `province`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.get<ApiResponse<any[]>>(url, httpOptions);
  }

  getDistrict(province_id?: number): Observable<ApiResponse<any[]>> {
    let params = new HttpParams();
    if (!_.isNil(province_id)) {
      params = params.append('province_id', province_id.toString());
    }

    const uri = `district`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<any[]>>(url, httpOptions);
  }

  uploadImages(image: File[], bucket_name: string, path: string): Observable<ApiResponse<ImgUploadResponse>> {
    const uri = `image`;
    const url = `${this.domain}/${uri}`;
    let formData = new FormData();
    formData.append('bucket_name', bucket_name.toString());
    formData.append('path', path.toString());
    image.forEach(item => {
      formData.append('image', item);
    });
    return this.http.post<ApiResponse<ImgUploadResponse>>(url, formData);
  }

  deleteImage(link: string): Observable<ApiResponse<any>> {
    let params = new HttpParams();
    if (!_.isNil(link)) {
      params = params.append('link', link.toString());
    }
    const uri = `image`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      params: params
    };
    return this.http.delete<ApiResponse<any>>(url, httpOptions);
  }

  getPermission(id?: number): Observable<boolean> {
    const permission = this.authService.user_permission;
    const find = permission.find(x => x == id);
    if (find) {
      return of(true);
    } else {
      return of(false);
    }
  }
  getRedirect(parent_id?: number, parent_url?: string): Observable<string> {
    const permission = this.authService.user_permission;
    let path = null;
    const menus = this.menuItems.filter(x => x.parent_id == parent_id);
    //console.log(menus);
    if (menus.length > 0) {
      if (permission.length > 0) {
        const m_id = menus.map(x => x.id);
        const ids = _.intersection(m_id, permission);
        const m = _.chain(menus).keyBy('id').at(ids).value();
        if (m.length > 0)
          path = m[0].url.replace(`/${parent_url}/`, "");
      }
    }
    //console.log(path);
    return of(path);
  }
  getNavRedirect(): Observable<string> {
    const permission = this.authService.user_permission;
    let path = null;
    if (navItems.length > 0) {
      if (permission.length > 0) {
        const m_id = navItems.map(x => x.id);
        const ids = _.intersection(m_id, permission);
        const m = _.chain(navItems).keyBy('id').at(ids).value();
        if (m.length > 0 && m[0].url)
          path = m[0].url.replace(`/`, "");
      }
    }
    //console.log(path);
    return of(path);
  }
  setToggle(toggle: boolean) {
    this.subject.next(toggle);
  }
  getToggle(): Observable<any> {
    return this.subject.asObservable();
  }

  setToggleSidebar(toggle: boolean) {
    this.subject_sidebar.next(toggle);
  }
  getToggleSidebar(): Observable<any> {
    return this.subject_sidebar.asObservable();
  }

  count_err_pop: number = 0
  public errorHandler<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      if (this.count_err_pop == 0) {
        //this.func.alertpopup(`error code ${error.status} : ${error.statusText || error.message}`);
        if (error.status == 401 || error.status == 403) {
          this.func.alertpopup(`กรุณาทำการ Login ใหม่อีกครั้ง`);
        } else {
          this.func.alertpopup(`error code ${error.status} : ${error.statusText || error.message}`);
        }
      }
      this.count_err_pop++
      setTimeout(() => {
        this.count_err_pop = 0
      }, 5000);

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  //-------------------------------------------------------------------------------------------//
  activityLog(action?: string, page?: string, request_data?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    const uri = `user/activity`;
    const url = `${this.domain_auth}/${uri}`;
    const data = { action: action, page: page, request_data: request_data };
    return this.http.post<ApiResponse<string[]>>(url, data, httpOptions);
    // return of({ code: 1, message:'OK'});
  }

  getTraffic(): Observable<ApiResponse<any[]>> {
    let uri = `road-analytic?json=1`;
    const url = `${this.domain_traffic}/${uri}`;
    return this.http.get<ApiResponse<any>>(url);
  }

  getSchemaic(id?: any): Observable<ApiResponse<any[]>> {
    let uri = `schematic`;
    if (id) uri = `schematic/${id}`;
    const url = `${this.domain}/${uri}`;
    return this.http.get<ApiResponse<any>>(url);
  }

  uploadTopleftSchematicImage(image: File, bucket_name: string, path: string, img_name?: string, schematic_id?: number): Observable<ApiResponse<ImgUploadResponse>> {
    const uri = `schematic/upload/top-left-image`;
    const url = `${this.domain}/${uri}`;
    let formData = new FormData();
    formData.append('bucket_name', bucket_name.toString());
    formData.append('path', path.toString());
    formData.append('image', image);
    formData.append('img_name', img_name);
    formData.append('schematic_id', schematic_id.toString());
    return this.http.post<ApiResponse<ImgUploadResponse>>(url, formData);
  }

  updateShowTopleftImgSchemaic(schematic_id?: number, is_show?: number): Observable<ApiResponse<any[]>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    const uri = `schematic/show-top-left-image`;
    const req = { schematic_id: schematic_id, is_show: is_show };
    const url = `${this.domain}/${uri}`;
    return this.http.post<ApiResponse<any>>(url, req, httpOptions);
  }
  
  getSubTypeSize() {
    const uri = `sign/type/detail`;
    const url = `${this.domain_sign_control}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.get<ApiResponse<string[]>>(url, httpOptions);
    // let url1 = 'assets/data/ratio.json';
    // return this.http.get<ApiResponse<string[]>>(url1);
  }
  getBoardsignGroups() {
    const uri = `boardsign/groups`;
    const url = `${this.domain_sign_control}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.get<ApiResponse<string[]>>(url, httpOptions);
    // let url1 = 'assets/data/ratio.json';
    // return this.http.get<ApiResponse<string[]>>(url1);
  }
}
