import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EventService } from 'src/app/services/user/event.service';
@Component({
  selector: 'app-model-flow-detection',
  templateUrl: './model-flow-detection.component.html',
  styleUrls: ['./model-flow-detection.component.scss']
})
export class ModelFlowDetectionComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();
  values: any;
  alert:any;
  icon: any;
  normalTrafficSd: any;
  maximumTrafficFlowDiff: any;
  normalTrafficFlowDiff: any;
  constructor(
    public bsModalRef: BsModalRef,
    public eS : EventService,
  ) { }

  ngOnInit(): void {
    if(this.values.id) this.getFlowDetectionDevices({id:this.values.id})
  }
  async getFlowDetectionDevices(data:any){
    let res : any = await this.eS.getFlowDetectionDevices(data)
    if(res){
      this.normalTrafficSd = res.normal_traffic_sd
      this.maximumTrafficFlowDiff = res.maximum_traffic_flow_diff
      this.normalTrafficFlowDiff = res.normal_traffic_flow_diff
      this.icon = res.icon_url
    }
  }
  ImgBase64(evt?: any,imgPath? : any){
    var reader = new FileReader();
    reader.readAsDataURL(evt.target.files[0]);
    reader.onload = async (_event) => {
      let res : any = await this.eS.uploadIconImg({image:reader.result})
      if(res) this.icon = res.img_url 
    }
  }
  chgChk(){
    // console.log(this.alert)
  }
  async save(){
    if(this.normalTrafficSd != null && this.maximumTrafficFlowDiff != null && this.normalTrafficFlowDiff != null){
      let data: any = {
        device_ids: this.values.device_id,
        config: {
          normal_traffic_sd: this.normalTrafficSd,
          maximum_traffic_flow_diff: this.maximumTrafficFlowDiff,
          normal_traffic_flow_diff: this.normalTrafficFlowDiff
        },
        icon: this.icon
      }
      let res : any = await this.eS.addUpdateFlowDetectionDevices(data)
      if(res) this.confirm();
    }
  }
  
  confirm() {
    this.event.emit(true);
    this.bsModalRef.hide();
  }
  close() {
    this.event.emit(false);
    this.bsModalRef.hide();
  }
}
