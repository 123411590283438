import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { DateInputCustomFormatPlaceholder } from '@progress/kendo-angular-dateinputs';
import { StatusEnum } from 'src/app/models/common-enum';
import { NewsTypeInfo } from 'src/app/models/information/news-type';
import { NewsTypeService } from 'src/app/services/information/news-type.service';
import { CommonService } from 'src/app/services/common.service';
import * as _ from 'lodash';
import { permission } from 'src/app/views/information/_menu';
import { NewsImage, NewsInfo, ReqAddNews } from 'src/app/models/information/news';
import { NewsService } from 'src/app/services/information/news.service';
import { InfoUploadService } from 'src/app/services/information/info-upload.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { District, Province } from 'src/app/models/common';
import * as mapboxgl from 'mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { environment } from 'src/environments/environment';
import { ActionEnum } from 'src/app/models/common-enum';
@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['../boardcast.component.scss']
})
export class NewsComponent implements OnInit {
  @Input() from_event: boolean;
  @ViewChild('mapModal', { static: false }) public mapModal: ModalDirective;
  @ViewChild('addModal', { static: false }) public addModal: ModalDirective;
  @ViewChild('deleteModal', { static: false }) public deleteModal: ModalDirective;
  @ViewChild('swalSuccess', { static: false }) private swalSuccess: SwalComponent;
  @ViewChild('swalError', { static: false }) private swalError: SwalComponent;
  @ViewChild('imInput') imInputVariable: ElementRef;
  public formatPlaceholder: DateInputCustomFormatPlaceholder = {
    day: 'วัน',
    month: 'เดือน',
    year: 'ปี',
    hour: 'ชั่วโมง',
    minute: 'นาที',
    second: 'วินาที'
  };
  public allowCustom: boolean = false;
  statusEnum = StatusEnum;
  status_list = [
    { id: StatusEnum.Active, name: "Active" },
    { id: StatusEnum.Inactive, name: "Inactive" }
  ];
  currentPage: any = 1;
  itemsPerPage: any = 10;
  totalItems: any = 0;
  search_status: any;
  search_st_date: Date;
  search_en_date: Date;
  search_hashtag: any;
  search_title: any;

  noti_type: any;
  noti_type_list = [
    { id: 1, name: "ไม่ยิงแจ้งเตือน" },
    { id: 2, name: "เจาะจงพื้นที่" },
    { id: 3, name: "Broadcast" }
  ];
  data_news_type: NewsTypeInfo[] = [];
  news_type_list: NewsTypeInfo[] = [];
  search_news_type: NewsTypeInfo;
  is_loading = false;
  data_list: any[] = [];
  selectItem: NewsInfo = {
    title: null,
    hashtag: null,
    desc: null,
    noti_text: null,
    short_message: null,
    is_ask_confirm: false,
    lat: null,
    lng: null,
    location_name: null
  };
  res_message: any;
  status_modal: any;
  data_news_type_modal: NewsTypeInfo[] = [];
  news_type_modal: NewsTypeInfo;

  data_route_modal: Array<string> = [];
  route_list: string[] = [];
  route_modal: any;

  data_prov_modal: Province[] = [];
  prov_list: Province[] = [];
  prov_modal: Province;

  data_dist_modal: District[] = [];
  dist_list: District[] = [];
  dist_modal: District;

  data_user_modal: any[] = [];
  user_list: any[] = [];
  user_modal: any;

  images: NewsImage[] = [];

  broadcast_channel_list_default = [
    { id: 1, name: "Line", check: false },
    { id: 2, name: "Facebook", check: false },
    { id: 3, name: "Twitter", check: false },
    { id: 4, name: "Highway traffic", check: false },
    { id: 5, name: "TOC Mobile", check: false },
    { id: 6, name: "TOC Website", check: false }
  ];
  broadcast_channel_list = [];
  broadcast_time_modal: any;

  map: mapboxgl.Map;
  marker: mapboxgl.Marker;
  map_lat: number;
  map_lng: number;
  map_loc_name: string;

  isAdd: boolean = false;
  isEdit: boolean = false;
  isAddInc: boolean = false;

  actionText: string;
  constructor(private newsService: NewsService,
    private newsTypeService: NewsTypeService,
    private commonService: CommonService,
    private infoUploadService: InfoUploadService) {
    mapboxgl.accessToken = environment.mapbox.accessToken;
    
  }

  async ngOnInit() {
    this.getNewsType();
    this.getUsers();
    this.getData();
    this.getRoute();
    this.getProvince();
    this.initMapModal();
    this.isAdd = await this.commonService.getPermission(permission.boardcast.news.add_id).toPromise();
    this.isEdit = await this.commonService.getPermission(permission.boardcast.news.edit_id).toPromise();
    this.isAddInc = await this.commonService.getPermission(permission.boardcast.news.add_inc_id).toPromise();
    this.actionText= 'Information ➡ Broadcast ➡ ข่าวสาร';
    if (this.from_event) {
      this.actionText = 'Event Management ➡ ข่าวสาร';
    }
    this.commonService.activityLog(ActionEnum.Get, this.actionText).subscribe(res => { }, error => { console.log(error); });
  }
  search() {
    this.currentPage = 1;
    this.getData();
  }
  pageChanged(event: any): void {
    if (this.currentPage != event.page) {
      this.currentPage = event.page;
      this.getData();
    }
  }
  handleValueStatus(value) {
    if (value) {
      //console.log(value);
      this.search_status = value;
    } else {
      this.search_status = null;
    }
  }
  handleValueStatusModal(value) {
    if (value) {
      this.status_modal = value;
    } else {
      this.status_modal = null;
    }
  }
  handleValueNotiTypeModal(value) {
    if (value) {
      this.noti_type = value;
    } else {
      this.noti_type = null;
    }
  }

  getNewsType() {
    this.newsTypeService.getNewsType(null, null, null, null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.news_type_list = _.cloneDeep(res.data);
              this.data_news_type = _.cloneDeep(res.data);
              this.data_news_type_modal = _.cloneDeep(res.data);
            }
          }
        }
      },
      error => {
      });
  }
  handleFilterNewsType(value) {
    if (value.length > 0) {
      this.data_news_type = this.news_type_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_news_type = _.cloneDeep(this.news_type_list);
    } else {
      this.data_news_type = [];
    }
  }
  handleValueNewsType(value) {
    if (value) {
      this.search_news_type = value;
    } else {
      this.search_news_type = null;
    }
  }

  handleFilterNewsTypeModal(value) {
    if (value.length > 0) {
      this.data_news_type_modal = this.news_type_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_news_type_modal = _.cloneDeep(this.news_type_list);
    } else {
      this.data_news_type_modal = [];
    }
  }
  handleValueNewsTypeModal(value) {
    if (value) {
      this.news_type_modal = value;
    } else {
      this.news_type_modal = null;
    }
  }

  handleFilterRouteModal(value) {
    if (value.length > 0) {
      this.data_route_modal = this.route_list.filter((s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_route_modal = _.cloneDeep(this.route_list);
    } else {
      this.data_route_modal = [];
    }
  }

  getRoute() {
    this.commonService.getRoute().subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.route_list = _.cloneDeep(res.data);
              this.data_route_modal = _.cloneDeep(res.data);
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }
  getProvince() {
    this.commonService.getProvince().subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.prov_list = _.cloneDeep(res.data);
              this.data_prov_modal = _.cloneDeep(res.data);
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }

  getDistrict(prov_id?: number, is_modal?: boolean) {
    this.commonService.getDistrict(prov_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.dist_list = _.cloneDeep(res.data);
              this.data_dist_modal = _.cloneDeep(res.data);
              if (is_modal) {
                if (this.selectItem.district_id)
                  this.dist_modal = _.cloneDeep(this.dist_list.find(x => x.dist_id == this.selectItem.district_id));
              }
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }

  handleValueRouteModal(value) {
    console.log(value);

    if (value) {
      this.route_modal = value;
    } else {
      this.route_modal = null;
    }
  }

  handleFilterProvModal(value) {
    if (value.length > 0) {
      this.data_prov_modal = this.prov_list.filter((s) => s.province_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_prov_modal = _.cloneDeep(this.prov_list);
    } else {
      this.data_prov_modal = [];
    }
  }
  handleValueProvModal(value) {
    if (value) {
      this.prov_modal = value;
      this.getDistrict(this.prov_modal.province_id);
    } else {
      this.prov_modal = null;
    }
  }

  handleFilterDistModal(value) {
    if (value.length > 0) {
      this.data_dist_modal = this.dist_list.filter((s) => s.dist_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_dist_modal = _.cloneDeep(this.dist_list);
    } else {
      this.data_dist_modal = [];
    }
  }
  handleValueDistModal(value) {
    if (value) {
      this.dist_modal = value;
    } else {
      this.dist_modal = null;
    }
  }
  getUsers() {
    this.commonService.getUsers(1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              res.data.forEach(item => {
                if (!item.name) {
                  item.name = `${item.firstname} ${item.lastname}`
                }
              });
              this.user_list = _.cloneDeep(res.data);
              this.data_user_modal = _.cloneDeep(res.data);
            }
          }
        }
      },
      error => {
      });
  }

  handleFilterUserModal(value) {
    if (value.length > 0) {
      this.data_user_modal = this.user_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_user_modal = _.cloneDeep(this.user_list);
    } else {
      this.data_user_modal = [];
    }
  }
  handleValueUserModal(value) {
    if (value) {
      this.user_modal = value;
    } else {
      this.user_modal = null;
    }
  }

  getData() {
    this.is_loading = true;
    this.data_list = [];
    let sta = null;
    let hash = null;
    let title = null;
    let st = null;
    let en = null;
    let type_id = null;
    if (this.search_status)
      sta = this.search_status.id;
    if (this.search_title)
      title = this.search_title;
    if (this.search_hashtag)
      hash = this.search_hashtag;
    if (this.search_st_date) {
      st = new Date(this.search_st_date.getFullYear(), this.search_st_date.getMonth(), this.search_st_date.getDate(), 0, 0, 0).toISOString();
    }
    if (this.search_en_date) {
      en = new Date(this.search_en_date.getFullYear(), this.search_en_date.getMonth(), this.search_en_date.getDate(), 23, 59, 59).toISOString();
    }
    if (this.search_news_type)
      type_id = this.search_news_type.id;
    this.newsService.getNews(title, sta, st, en, type_id, hash, this.currentPage, this.itemsPerPage).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.data_list = res.data.data;
              this.totalItems = res.data.total_items;
            } else {
              this.totalItems = 0;
            }
          } else {
            this.totalItems = 0;
          }
        } else {
          this.totalItems = 0;
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
        this.totalItems = 0;
      });
  }
  openAddModal() {
    this.selectItem = {
      title: null,
      hashtag: null,
      desc: null,
      noti_text: null,
      short_message: null,
      is_ask_confirm: false,
      lat: null,
      lng: null,
      location_name: null
    };
    this.images = [];
    this.status_modal = this.status_list.find(x => x.id == StatusEnum.Active);
    this.news_type_modal = null;
    this.prov_modal = null;
    this.dist_modal = null;
    this.route_modal = null;
    this.noti_type = null;

    this.user_modal = null;
    this.broadcast_channel_list = _.cloneDeep(this.broadcast_channel_list_default);
    this.broadcast_time_modal = null;
    this.addModal.show();
  }
  openEditModal(item?: any) {
    this.selectItem = item;
    this.images = [];
    this.news_type_modal = null;
    this.prov_modal = null;
    this.dist_modal = null;
    this.route_modal = null;
    this.noti_type = null;
    this.status_modal = this.status_list.find(x => x.id == item.status);
    if (item.images)
      this.images = _.cloneDeep(item.images);
    if (this.selectItem.news_type_id)
      this.news_type_modal = this.news_type_list.find(x => x.id == this.selectItem.news_type_id);
    if (this.selectItem.province_id) {
      this.prov_modal = this.prov_list.find(x => x.province_id == this.selectItem.province_id);
      this.getDistrict(this.selectItem.province_id, true);
    }
    if (this.selectItem.route_id)
      this.route_modal = this.route_list.find(x => parseInt(x) == +this.selectItem.route_id);
    if (this.selectItem.noti_type_id)
      this.noti_type = this.noti_type_list.find(x => x.id == +this.selectItem.noti_type_id);

    if (this.selectItem.noti_user_id)
      this.user_modal = this.user_list.find(x => x.id == +this.selectItem.noti_user_id);
    if (this.selectItem.broadcast_time)
      this.broadcast_time_modal = new Date(this.selectItem.broadcast_time);
    this.broadcast_channel_list = _.cloneDeep(this.broadcast_channel_list_default);
    if (this.selectItem.broadcast_channel) {
      const arr = this.selectItem.broadcast_channel.split(",");
      this.broadcast_channel_list.forEach(item => {
        const find = arr.find(x => parseInt(x) == item.id);
        if (find)
          item.check = true;
      });
    }

    this.addModal.show();
  }
  openDeleteModal(item?: any) {
    this.selectItem = item;
    this.status_modal = this.status_list.find(x => x.id == item.status);
    this.deleteModal.show();
  }
  clearData() {
    this.selectItem = new NewsInfo();
  }
  openAddIncident() {

  }

  openPreview(html?: any) {
    console.log(html);
    let htm = `
    <html dir="ltr">
      <head>
        <title>Preview</title>
        <link type="text/css" rel="stylesheet" href="https://cdn.ckeditor.com/4.6.1/full/contents.css?t=GB8C">
        <link type="text/css" rel="stylesheet" href="https://cdn.ckeditor.com/4.6.1/full/plugins/copyformatting/styles/copyformatting.css">
      </head>
      <body class="cke_editable cke_editable_themed cke_contents_ltr cke_show_borders">
      ${html}
      </body>
    </html>`
    let newWindow = window.open();
    newWindow.document.write(htm);
  }
  detectFiles(event) {
    let acceptedType = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'];
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        if (acceptedType.includes(file.type)) {
          let reader = new FileReader();
          reader.onload = (e: any) => {
            let f = new NewsImage();
            f.file = file;
            f.status = 1;
            f.dataUrl = e.target.result;
            this.images.push(f);
          }
          reader.readAsDataURL(file);
        } else {
          const error_text = "รองรับไฟล์นามกุล JPEG,PNG,JPG,GIF เท่านั้น";
          this.res_message = error_text;
          this.is_loading = false
          setTimeout(() => { this.swalError.fire() }, 500);
        }
      }
    }
  }
  deleteFileItem(item?: NewsImage) {
    if (item.id) {
      const index: number = this.images.indexOf(item);
      if (index !== -1) {
        this.images[index].status = 0;
      }
    } else {
      const index: number = this.images.indexOf(item);
      if (index !== -1) {
        this.images.splice(index, 1);
      }
    }
  }
  clearDataFiles() {
    if (this.imInputVariable) {
      this.imInputVariable.nativeElement.value = "";
    }
  }

  addData(file_list?: NewsImage[]) {
    this.res_message = "";
    this.is_loading = true;
    let req = new ReqAddNews();
    req.title = this.selectItem.title;
    req.desc = this.selectItem.desc;
    req.hashtag = this.selectItem.hashtag;
    req.noti_text = this.selectItem.noti_text;
    req.short_message = this.selectItem.short_message;
    req.status = this.status_modal.id;
    req.images = file_list;
    req.lat = this.selectItem.lat;
    req.lng = this.selectItem.lng;
    req.location_name = this.selectItem.location_name;
    req.is_ask_confirm = this.selectItem.is_ask_confirm;
    if (this.news_type_modal)
      req.news_type_id = this.news_type_modal.id;
    if (this.prov_modal)
      req.province_id = this.prov_modal.province_id;
    if (this.dist_modal)
      req.district_id = this.dist_modal.dist_id;
    if (this.route_modal)
      req.route_id = parseInt(this.route_modal);
    if (this.noti_type)
      req.noti_type_id = this.noti_type.id;

    if (this.user_modal)
      req.noti_user_id = this.user_modal.id;
    if (this.broadcast_time_modal)
      req.broadcast_time = this.broadcast_time_modal;
    req.broadcast_channel = null;
    let ch = this.broadcast_channel_list.filter(x => x.check == true);
    if (ch) {
      let channal = ch.map(x => x.id);
      if (channal) {
        req.broadcast_channel = channal.join(',');
      }
    }

    this.newsService.addNews(req).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `เพิ่มข่าวสารสำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getData();
            }, 100);
            const log_req_data = { request: req, response:res};
            this.commonService.activityLog(ActionEnum.Add, this.actionText, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  editData(file_list?: NewsImage[]) {
    this.res_message = "";
    this.is_loading = true;
    let req = new ReqAddNews();
    req.title = this.selectItem.title;
    req.desc = this.selectItem.desc;
    req.hashtag = this.selectItem.hashtag;
    req.noti_text = this.selectItem.noti_text;
    req.short_message = this.selectItem.short_message;
    req.status = this.status_modal.id;
    req.images = file_list;
    req.lat = this.selectItem.lat;
    req.lng = this.selectItem.lng;
    req.location_name = this.selectItem.location_name;
    req.is_ask_confirm = this.selectItem.is_ask_confirm;
    if (this.news_type_modal)
      req.news_type_id = this.news_type_modal.id;
    if (this.prov_modal)
      req.province_id = this.prov_modal.province_id;
    if (this.dist_modal)
      req.district_id = this.dist_modal.dist_id;
    if (this.route_modal)
      req.route_id = parseInt(this.route_modal);
    if (this.noti_type)
      req.noti_type_id = this.noti_type.id;

    if (this.user_modal)
      req.noti_user_id = this.user_modal.id;
    if (this.broadcast_time_modal)
      req.broadcast_time = this.broadcast_time_modal;
    req.broadcast_channel = null;
    let ch = this.broadcast_channel_list.filter(x => x.check == true);
    if (ch) {
      let channal = ch.map(x => x.id);
      if (channal) {
        req.broadcast_channel = channal.join(',');
      }
    }
    this.newsService.editNews(req, this.selectItem.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `แก้ไขข่าวสารสำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getData();
            }, 100);
            const log_req_data = { id: this.selectItem.id, request: req, response: res };
            this.commonService.activityLog(ActionEnum.Edit, this.actionText, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }

  deleteData() {
    this.is_loading = true;
    let req = new ReqAddNews();
    req.status = this.status_modal.id;
    this.res_message = "";
    this.newsService.deleteNewsEditStatus(req, this.selectItem.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `ลบข่าวสารสำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getData();
            }, 100);
            this.commonService.activityLog(ActionEnum.Delete, this.actionText, JSON.stringify({ id: this.selectItem.id})).subscribe(res => { }, error => { console.log(error); });
            this.deleteModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }

  bucket_name = 'information';
  path = 'news';
  async addDataAsyn() {
    let add_files: NewsImage[] = this.images.filter(x => x.file != null);
    if (add_files.length > 0) {
      const add_file_list = this.images.map(x => x.file)
      let res_fs = await this.infoUploadService.uploadImages(add_file_list, this.bucket_name, this.path).toPromise();
      if (res_fs) {
        if (res_fs.data) {
          let fs: NewsImage[] = [];
          add_files.forEach(async (item, index) => {
            let f = new NewsImage;
            f.status = StatusEnum.Active;
            f.url = res_fs.data.img_urls[index];
            f.name = item.file.name;
            await fs.push(f);
          });
          await this.addData(fs);
        } else {
          this.addData();
        }
      } else {
        this.addData();
      }
    } else {
      this.addData();
    }
  }

  async editDataAsyn() {
    this.images.forEach((item, index) => {
      item.index = index;
    });
    let add_files: NewsImage[] = await this.images.filter(x => x.file != null);

    if (add_files.length > 0) {
      const add_file_list = this.images.map(x => x.file)
      let res_fs = await this.infoUploadService.uploadImages(add_file_list, this.bucket_name, this.path).toPromise();
      if (res_fs) {
        if (res_fs.data) {
          await add_files.forEach(async (item, index) => {
            item.url = res_fs.data.img_urls[index];
          });
          let fs: NewsImage[] = [];
          for (const item of this.images) {
            let f = new NewsImage;
            if (item.file) {
              const find_index = await add_files.find(x => x.index == item.index);
              f.status = StatusEnum.Active;
              f.url = find_index.url;
              f.name = item.file.name;
            } else {
              f.id = item.id;
              f.status = item.status;
              f.url = item.url;
              f.name = item.name;
            }
            await fs.push(f);
          }
          await this.editData(fs);
        } else {
          const files_all = await this.mapRequestDataFile();
          this.editData(files_all);
        }
      } else {
        const files_all = await this.mapRequestDataFile();
        this.editData(files_all);
      }
    } else {
      const files_all = await this.mapRequestDataFile();
      this.editData(files_all);
    }
  }

  async mapRequestDataFile() {
    let fs: NewsImage[] = [];
    if (this.images.length > 0) {
      for (const item of this.images) {
        let f = new NewsImage;
        f.id = item.id;
        f.status = item.status;
        f.url = item.url;
        f.name = item.name;
        await fs.push(f);
      }
    }
    return fs;
  }


  //-------------------------------------------------//
  openMapModal() {
    this.mapModal.show();
    if (this.selectItem.lat && this.selectItem.lng) {
      this.map_lat = this.selectItem.lat;
      this.map_lng = this.selectItem.lng;
      this.map_loc_name = this.selectItem.location_name;
      let l = [this.selectItem.lng, this.selectItem.lat];
      this.marker = new mapboxgl.Marker()
        .setLngLat(l)
        .addTo(this.map);
      setTimeout(() => { this.map.flyTo({ center: l, zoom: 12 }); }, 800);

    }

    setTimeout(() => { this.map.resize() }, 500);


  }
  //---------------------------------------------------------------------------------------------------------------------//
  initMapModal() {
    this.map = new mapboxgl.Map({
      container: 'map-modal-news',
      style: environment.mapbox.style,
      zoom: 12,
      center: [100.523186, 13.736717],
    });
    this.map.dragRotate.disable();
    this.map.on('load', (event: mapboxgl.MapEvent) => {
      this.map.setPaintProperty('water', 'fill-color', '#aad3df');

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false, anchor: 'top', offset: [0, -52]
      });

      this.map.on('click', (e) => {
        if (this.marker) {
          this.marker.remove();
        }
        this.map.flyTo({ center: e.lngLat, zoom: 15 });
        this.map_lat = e.lngLat.lat;
        this.map_lng = e.lngLat.lng;
        this.map_loc_name = null;
        this.marker = new mapboxgl.Marker()
          .setLngLat(e.lngLat)
          .addTo(this.map);

      });
    //   const geocoder = new MapboxGeocoder({
    //     accessToken: mapboxgl.accessToken,
    //     mapboxgl: mapboxgl
    // });

      let geocoder = new MapboxGeocoder({ // Initialize the geocoder
        accessToken: mapboxgl.accessToken, // Set the access token
        mapboxgl: mapboxgl, // Set the mapbox-gl instance
        marker: false, // Do not use the default marker style
      });
      // Add the geocoder to the map
      this.map.addControl(geocoder);
      geocoder.on('result', (e: mapboxgl.MapEvent) => {
        //map.getSource('single-point').setData(e.result.geometry);
        console.log(e);
        this.map_lat = e.result.geometry.coordinates[1];
        this.map_lng = e.result.geometry.coordinates[0];
        this.map_loc_name = e.result.place_name;
        if (this.marker) {
          this.marker.remove();
        }
        this.marker = new mapboxgl.Marker()
          .setLngLat(e.result.geometry.coordinates)
          .addTo(this.map);
      });
    });



  }
  setLatLng() {
    this.selectItem.lat = this.map_lat;
    this.selectItem.lng = this.map_lng;
    this.selectItem.location_name = this.map_loc_name;
    this.mapModal.hide();
  }
  clearMap() {
    this.map_lat = null;
    this.map_lng = null;
    this.map_loc_name = null;
    if (this.marker) {
      this.marker.remove();
    }
  }

}
