import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiResponse, ItemName, PagableData } from '../../models/common';
import * as _ from 'lodash';
import { IncVictim, IncChart, VolumeByClass, VolumeClass, PercentageVolumeClass, StatInfo, TimeseriesInfo, TimeseriesItemInfo, ReversibleTraveltimePointInfo, ReversibleInfo } from '../../models/dashboard/dashboard';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  domain: any;
  domain_old_jarvis: any;
  req_api_url: any;
  t = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjIjoiNWIwZTQzODM0NTc3OTQxOTAxMWU2Yzk1IiwicyI6WyJUTUMiXSwiciI6Ikh3SnVpNkRmdUxEZ05TM1lyUWZtNnZIV2hqWU1qeE02VjFtTDFCV0JvbWVTIiwiaWF0IjoxNjA3OTM0MDgwfQ.9MhE1LIdqTx1xiG6lspi_QOWF6YP7EiDhQ6v3y9KOu8`;
  constructor(private http: HttpClient, private authService: AuthService) {
    this.domain = environment.api.domain_report;
    this.domain_old_jarvis = environment.api.domain_old_jarvis_report_export_api;
  }

  getReversibleTimeseries(reversible_id?: number, pcu?: number): Observable<ApiResponse<ReversibleInfo>> {
    let uri = `dashboard/reversible/timeline`;
    const url = `${this.domain}/${uri}`;
    let params = new HttpParams();

    if (!_.isNil(reversible_id)) {
      params = params.append('reversible_id', reversible_id.toString());
    }
    if (pcu && !_.isNil(pcu)) {
      params = params.append('pcu', pcu.toString());
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<ReversibleInfo>>(url, httpOptions);
  }

  getReversibleList(id?: number, route?: number, start_km?: number, start_m?: number, end_km?: number, end_m?: number, status?: number, skip?: number, take?: number): Observable<ApiResponse<ItemName>> {
    let uri = `reversible`;
    const url = `${this.domain}/${uri}`;
    let params = new HttpParams();

    if (!_.isNil(id)) {
      params = params.append('id', id.toString());
    }
    if (route && !_.isNil(route)) {
      params = params.append('route', route.toString());
    }
    if (!_.isNil(start_km)) {
      params = params.append('start_km', start_km.toString());
    }
    if (!_.isNil(start_m)) {
      params = params.append('start_m', start_m.toString());
    }
    if (!_.isNil(end_km)) {
      params = params.append('end_km', end_km.toString());
    }
    if (!_.isNil(end_m)) {
      params = params.append('end_m', end_m.toString());
    }
    if (!_.isNil(status)) {
      params = params.append('status', status.toString());
    }
    if (!_.isNil(skip)) {
      params = params.append('skip', skip.toString());
    }
    if (!_.isNil(take)) {
      params = params.append('take', take.toString());
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<ItemName>>(url, httpOptions);
  }
  getReversibleTraveltimeRoute() {
    let uri = `dashboard/traveltime/route`;
    const url = `${this.domain}/${uri}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.t
      })
    };

    return this.http.get<ApiResponse<any[]>>(url, httpOptions);
  }

  getReversibleTraveltimePath(route?: number) {
    let uri = `dashboard/traveltime/path`;
    const url = `${this.domain}/${uri}`;
    let params = new HttpParams();
    if (route && !_.isNil(route)) {
      params = params.append('route', route.toString());
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.t
      }),
      params: params
    };

    return this.http.get<ApiResponse<ReversibleTraveltimePointInfo[]>>(url, httpOptions);
  }

  getReversibleTraveltime(code?: string) {
    let uri = `dashboard/traveltime`;
    const url = `${this.domain}/${uri}`;
    let params = new HttpParams();

    if (!_.isNil(code)) {
      params = params.append('code', code);
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.t
      }),
      params: params
    };
    return this.http.get<ApiResponse<TimeseriesItemInfo[]>>(url, httpOptions);
  }

  // Traffic Information --> Sensor

  getSmcStation(dir?: number,) {
    let uri = `smc/station`;
    const url = `${this.domain}/${uri}`;
    let params = new HttpParams();
    if (!_.isNil(dir)) {
      params = params.append('dir', dir.toString());
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };

    return this.http.get<ApiResponse<any>>(url, httpOptions);
  }

  getPercentageVolumeClass(station_id?: number, type?: number) {
    let uri = `dashboard/sensor/volume`;
    const url = `${this.domain}/${uri}`;
    let params = new HttpParams();

    if (!_.isNil(station_id)) {
      params = params.append('station_id', station_id.toString());
    }
    if (type && !_.isNil(type)) {
      params = params.append('type', type.toString());
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<PercentageVolumeClass[]>>(url, httpOptions);
  }

  getDashboardSensorStat(station_id?: number, type?: number) {
    let uri = `dashboard/sensor/statistic`;
    const url = `${this.domain}/${uri}`;
    let params = new HttpParams();

    if (!_.isNil(station_id)) {
      params = params.append('station_id', station_id.toString());
    }
    if (type && !_.isNil(type)) {
      params = params.append('type', type.toString());
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<StatInfo>>(url, httpOptions);
  }

  getTimeseries(station_id?: number, type?: number) {
    let uri = `dashboard/sensor/timeline`;
    const url = `${this.domain}/${uri}`;
    let params = new HttpParams();
    if (!_.isNil(station_id)) {
      params = params.append('station_id', station_id.toString());
    }
    if (type && !_.isNil(type)) {
      params = params.append('type', type.toString());
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<TimeseriesInfo>>(url, httpOptions);
  }
  // get DashboardSensor one year
  getDashbordOneYearAgo(station_id?: number, type?: number) {
    let uri = `dashboard/sensor/volume/oneyearago`;
    const url = `${this.domain}/${uri}`;
    let params = new HttpParams();

    if (!_.isNil(station_id)) {
      params = params.append('station_id', station_id.toString());
    }
    if (type && !_.isNil(type)) {
      params = params.append('type', type.toString());
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<any>>(url, httpOptions);
  }

  getDashbordThreeYearAgo(station_id?: number, type?: number) {
    let uri = `dashboard/sensor/volume/threeyearago`;
    const url = `${this.domain}/${uri}`;
    let params = new HttpParams();

    if (!_.isNil(station_id)) {
      params = params.append('station_id', station_id.toString());
    }
    if (type && !_.isNil(type)) {
      params = params.append('type', type.toString());
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<any>>(url, httpOptions);
  }

  //----------------------------------SMC----------------------------------------//
  searchVehClassPer() {
    // console.log("class_id",class_id)
    let uri = `vehicle-class`;
    const url = `${this.domain}/${uri}?model=68`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
    };

    return this.http.get<ApiResponse<any>>(url, httpOptions);
  }

  searchSpeedMeasureNew(data?: any) {
    let uri = `report/sensor/aggregate`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
    };

    return this.http.post<ApiResponse<any>>(url, data, httpOptions);
  }

  searchSpeedMeasure(data?: any) {
    let uri = `smc/speed/stat/pvr`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
    };

    return this.http.post<ApiResponse<any>>(url, data, httpOptions);
  }
  searchSpeedMeasure_Per(data?: any) {
    let uri = `smc/speed/stat/pvr/per`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
    };

    return this.http.post<ApiResponse<any>>(url, data, httpOptions);
  }
  rawSpeedMeasure(data?: any) {
    let uri = `smc/speed/stat/raw`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
    };

    return this.http.post<ApiResponse<any>>(url, data, httpOptions);
  }

  getStationlistPER(data?: any) {
    let params = ''
    if (data) {
      for (const i in data) {
        if (Object.prototype.hasOwnProperty.call(data, i)) {
          params += (params) ? '&' : `?`
          const element = data[i];
          params += `${i}=${element}`
        }
      }
    }
    let uri = `smc/station`;
    const url = `${this.domain}/${uri}` + params;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
    };

    return this.http.get<ApiResponse<any>>(url, httpOptions);
  }
  getStationPER_(data?: any) {
    let params = ''
    if (data) {
      for (const i in data) {
        if (Object.prototype.hasOwnProperty.call(data, i)) {
          params += (params) ? '&' : `?`
          const element = data[i];
          params += `${i}=${element}`
        }
      }
    }
    let uri = `station/per`;
    const url = `${this.domain}/${uri}` + params;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
    };

    return this.http.get<ApiResponse<any>>(url, httpOptions);
  }

  searchVehClass(station_id?: number, class_id?: number, reversible_id?: number) {
    console.log("class_id", class_id)
    let uri = `smc/class`;
    let url = `${this.domain}/${uri}${(station_id) ? `?station_id=${station_id}` : ''}${(class_id) ? `&class_id=${class_id}` : ''}`;
    if (reversible_id) {
      url = `${this.domain}/${uri}${(reversible_id) ? `?reversible_id=${reversible_id}` : ''}${(class_id) ? `&class_id=${class_id}` : ''}`;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
    };

    return this.http.get<ApiResponse<any>>(url, httpOptions);
  }
}