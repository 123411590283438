import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DeviceMonitoringComponent } from './device-monitoring/device-monitoring.component';
import { AtisGenServiceComponent } from './atis-gen-service/atis-gen-service.component';

export const routes: Routes = [
  {path: '', redirectTo: 'monitoring', pathMatch: 'full'},
  {
    path: 'monitoring',
    component: DeviceMonitoringComponent
  },
  {
    path: 'atis-gen-service',
    component: AtisGenServiceComponent
  },
  {
    path: '',
    component: DeviceMonitoringComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeviceMonitoringRoutingModule { }
