import { Component, OnInit, AfterViewInit, Pipe, ViewChild, ElementRef, OnDestroy,EventEmitter } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import { interval } from 'rxjs';
import { StatInfo, PercentageVolumeClass, TimeseriesInfo, TimeseriesItemInfo } from 'src/app/models/dashboard/dashboard';
import { RouteDirectionEnum } from '../../../models/common-enum';
import { PlotBand } from '@progress/kendo-angular-charts';
import { CommonService } from 'src/app/services/common.service';
import { ActionEnum } from 'src/app/models/common-enum';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-sensor',
  templateUrl: './sensor.component.html',
  styleUrls: ['./sensor.component.scss']
})
export class SensorComponent implements OnInit, OnDestroy {
  public event: EventEmitter<any> = new EventEmitter();
  values_fromEvt: any;

  directionEnum = RouteDirectionEnum;
  dir: number = this.directionEnum.OUT;
  data_stat: StatInfo;
  donut_chart_data: PercentageVolumeClass;
  line_chart_data = { categories: [], current: [], max: [], avg: [], min: [] };
  volume_line = _.cloneDeep(this.line_chart_data);
  speed_line = _.cloneDeep(this.line_chart_data);
  occupancy_line = _.cloneDeep(this.line_chart_data);
  is_show_chart2 = true;
  public allowCustom: boolean = false;

  sta: any;
  public data_sta: Array<{ name: string, id: number }>;
  public staList: Array<{ name: string, id: number }>;
  public style: string = 'smooth';
  public dashType: string = 'dot';
  subscription: any;
  type: any = '';
  marker={size:4}
  // array one year and three year
  arr_one_year_month = [];
  arr_one_year_value = [];
  arr_three_year = [];
  arr_three_year_value = [];
  public categoryPlotBands: PlotBand[] = [{
    from: null,
    to: null,
    color: null,
    opacity: 0
  }];
  public occupancy_plot_current: PlotBand[] = _.cloneDeep(this.categoryPlotBands);
  public volume_plot_current: PlotBand[] = _.cloneDeep(this.categoryPlotBands);
  public speed_plot_current: PlotBand[] = _.cloneDeep(this.categoryPlotBands);
  constructor(private dashboardService: DashboardService, private commonService: CommonService,
    public bsModalRef: BsModalRef,
    ) {
    this.commonService.activityLog(ActionEnum.Get, 'Traffic Information ➡ Sensor').subscribe(res => { }, error => { console.log(error); });
  }

  async ngOnInit() {
    // if(this.values_fromEvt) console.log("fromEvt",this.values_fromEvt)
    await this.getStationList();
    setTimeout(() => {
      this.data_sta = _.cloneDeep(this.staList);
      this.sta = this.data_sta[this.data_sta.length-1];
    }, 1000);
    setTimeout(() => {
      this.getPercentageVolumeClass();
      this.getDashboardSensorStat();
      this.getTimeseries();
      this.getDashBoardOneYearAgo();
      this.getDashBoardThreeYearAgo();
    }, 2000);
    // 2*60000; // 2 minute
    this.subscription = interval(2 * 60000).subscribe((x) => {
      this.getPercentageVolumeClass();
      this.getDashboardSensorStat();
      this.getTimeseries();
      this.getDashBoardOneYearAgo();
      this.getDashBoardThreeYearAgo();
    });

  }
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  handleFilter(value) {
    if (value.length > 0) {
      this.data_sta = this.data_sta.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_sta = _.cloneDeep(this.staList);
    } else {
      this.data_sta = [];
    }
  }
  handleValue(value) {
    if (value) {
      this.sta = value;
    } else {
      this.sta = null;
    }
  }
  async getStationList() {
    this.dashboardService.getSmcStation(this.dir).subscribe(
      res => {
        if (res.code == 1) {
          this.staList = _.cloneDeep(res.data);
          this.data_sta = _.cloneDeep(res.data);
        }
      },
      err => {
        console.log(JSON.stringify(err.statusText));
      });
  }

  search() {
    console.log(this.type)
    this.getPercentageVolumeClass();
    this.getDashboardSensorStat();
    this.getTimeseries();
    // new line
    this.getDashBoardOneYearAgo();
    this.getDashBoardThreeYearAgo();
  }
  changeDir(value?: any) {
    this.getStationList();
    this.sta = null;
  }

  getData() {
    this.getPercentageVolumeClass();
    this.getDashboardSensorStat();
    this.getTimeseries();
    // new line
    this.getDashBoardOneYearAgo();
    this.getDashBoardThreeYearAgo();
  }

  async getPercentageVolumeClass() {
    if (this.sta) {
      this.data_donut = [];
      if(this.values_fromEvt?.station_id) console.log(this.values_fromEvt)
      this.dashboardService.getPercentageVolumeClass(this.sta.id, this.type).subscribe(
        res => {

          if (res.code == 1) {
            if (res.data.length > 0) {
              const total = _.sum(res.data.map(x => x.volume));
              res.data.forEach(item => {
                const per = ((item.volume * 100) / total).toFixed(2);
                item.per = per;
              });
              this.data_donut = res.data.filter(x => x.volume != 0);
              console.log(this.data_donut);
            }
          }
        },
        err => {
          console.log(JSON.stringify(err.statusText));
        });
    }

  }
  async getDashboardSensorStat() {
    if (this.sta) {
      this.data_stat = new StatInfo();
      this.dashboardService.getDashboardSensorStat(this.sta.id, this.type).subscribe(
        res => {
          if (res) {
            if (res.code == 1) {
              this.data_stat = res.data;
            } else {
              this.data_stat = new StatInfo();
            }
          } else {
            this.data_stat = new StatInfo();
          }
        },
        err => {
          this.data_stat = new StatInfo();
          console.log(JSON.stringify(err.statusText));
        });
    }
  }

  async getTimeseries() {
    if (this.sta) {
      this.reLine();
      this.dashboardService.getTimeseries(this.sta.id, this.type).subscribe(
        res => {
          if (res.code == 1) {
            this.volume_line = this.setLineChartData(res.data.volume);
            this.speed_line = this.setLineChartData(res.data.speed);
            this.occupancy_line = this.setLineChartData(res.data.occupancy);


            this.volume_plot_current = this.setCategoryPlotBands(res.data.volume);
            this.speed_plot_current = this.setCategoryPlotBands(res.data.speed);
            this.occupancy_plot_current = this.setCategoryPlotBands(res.data.occupancy);

          } else {
            this.reLine();
          }
        },
        err => {
          console.log(JSON.stringify(err.statusText));
        });
    }

  }

  reLine() {
    this.volume_line = _.cloneDeep(this.line_chart_data);
    this.speed_line = _.cloneDeep(this.line_chart_data);
    this.occupancy_line = _.cloneDeep(this.line_chart_data);
    this.occupancy_plot_current = _.cloneDeep(this.categoryPlotBands);
    this.volume_plot_current = _.cloneDeep(this.categoryPlotBands);
    this.speed_plot_current = _.cloneDeep(this.categoryPlotBands);
  }
  setLineChartData(items: TimeseriesItemInfo[]) {
    let data: any = _.cloneDeep(this.line_chart_data);
    if (items.length) {
      data.categories = _.map(items, 'hour');
      let current = _.map(items, 'current_value');
      data.current = _.take(current, 13);
      data.max = _.map(items, 'max_old_value');
      data.avg = _.map(items, 'avg_old_value');
      data.min = _.map(items, 'min_old_value');
    }
    return data;
  }
  setCategoryPlotBands(items: TimeseriesItemInfo[]) {
    let current_index = items.findIndex(x => x.is_current == 1);
    let categoryPlotBands = [{
      from: current_index,
      to: current_index,
      color: '#28b4c8',
      opacity: 0.8
    }];
    return categoryPlotBands;
  }
  // add one year && three year
  async getDashBoardOneYearAgo() {
    this.arr_one_year_month = []
    this.arr_one_year_value = []
    this.dashboardService.getDashbordOneYearAgo(this.sta.id, this.type).subscribe(res => {
      if (res.code == 1) {
        this.arr_one_year_month = res.data.month
        this.arr_one_year_value = res.data.value

      }

    }, (err) => {
      if (err) {
        // this.arr_one_year_month = []
      }
    })
  }
  async getDashBoardThreeYearAgo() {
    this.arr_three_year = [];
    this.arr_three_year_value = [];
    this.dashboardService.getDashbordThreeYearAgo(this.sta.id, this.type).subscribe(res => {

      if (res.code == 1) {
        this.arr_three_year = res.data.year
        this.arr_three_year_value = res.data.value
      }
    }, (err) => {
      if (err) {
        // this.arr_one_year = []
      }
    })
  }

  //-----------------------------------------------------------------//
  public autofit = true;
  public data_donut: any[] = [];

  public labelContent(e: any): string {
    let n = 'คัน';
    if (e.category == 'คน') {
      n = 'คน';
    }
    //return `${e.category}\n ${e.value} %`;
    return `${e.category}\n${e.dataItem.volume.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${n}\n ${e.value} %`;
  }

}
