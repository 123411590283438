import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule, Injectable, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app-routing.module';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { LoginComponent } from './views/login/login.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
export function provideSwal() {
  return import('sweetalert2/src/sweetalert2.js'); // instead of import('sweetalert2')
}
import { FormsModule } from '@angular/forms';

// Imports the AutoComplete module
import { AutoCompleteModule } from '@progress/kendo-angular-dropdowns';
import { ModalModule } from 'ngx-bootstrap/modal';
// Imports the ComboBox module
import { ComboBoxModule } from '@progress/kendo-angular-dropdowns';

import { DatePipe, registerLocaleData } from '@angular/common';
import localeTh from '@angular/common/locales/global/th';
// the second parameter 'th' is optional
registerLocaleData(localeTh, 'th');

import '@progress/kendo-angular-intl/locales/th/all';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from '../../src/app/services/messaging/messaging.service';
import { environment } from '../environments/environment';
import 'hammerjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FuncService } from './services/user/func.service';
import { ApiService } from './services/user/api.service';
import { PeopleService } from './services/user/people.service';
import { UiSwitchModule } from 'ngx-ui-switch';
import { SharedModule } from 'src/app/shared/shared.module';
import { ThemeService } from '@progress/kendo-angular-charts';


// import * as Sentry from '@sentry/browser'
// import { RewriteFrames } from '@sentry/integrations'

// Sentry.init({
//   dsn: environment.sentry.dsn,
//   integrations: [new RewriteFrames()],
// });

// @Injectable()
// export class SentryErrorHandler implements ErrorHandler {
//   constructor() {}

//   handleError(error: any) {
//     Sentry.captureException(error.originalError || error);
//     console.error(error);
//   }
// }

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    ChartsModule,
    FormsModule,
    AutoCompleteModule,
    ComboBoxModule,
    SweetAlert2Module.forRoot({ provideSwal }),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    UiSwitchModule,
    SharedModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    LoginComponent,
  ],
  providers: [
    FuncService,
    ApiService,
    PeopleService,
    BsModalService,
    ThemeService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    { provide: LOCALE_ID, useValue: 'th-TH' },
    { provide: LOCALE_ID, useValue: 'en-EN' },
    DatePipe,
    MessagingService,
    //{ provide: ErrorHandler, useClass: SentryErrorHandler }
  ],
  bootstrap: [AppComponent]

})
export class AppModule { }
