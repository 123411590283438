import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DateInputCustomFormatPlaceholder } from '@progress/kendo-angular-dateinputs';
import { StatusEnum } from 'src/app/models/common-enum';
import { CommonService } from 'src/app/services/common.service';
import * as _ from 'lodash';
import { permission } from 'src/app/views/information/_menu';
import { NoteTypeInfo } from 'src/app/models/information/note-type';
import { NoteTypeService } from 'src/app/services/information/note-type.service';
import { NoteImage, NoteInfo, ReqAddNote } from 'src/app/models/information/note';
import { NoteService } from 'src/app/services/information/note.service';
import { InfoUploadService } from 'src/app/services/information/info-upload.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { ActionEnum } from 'src/app/models/common-enum';
@Component({
  selector: 'app-note-list',
  templateUrl: './note-list.component.html',
  styleUrls: ['../note.component.scss']
})
export class NoteListComponent implements OnInit {
  @ViewChild('mapModal', { static: false }) public mapModal: ModalDirective;
  @ViewChild('addModal', { static: false }) public addModal: ModalDirective;
  @ViewChild('deleteModal', { static: false }) public deleteModal: ModalDirective;
  @ViewChild('swalSuccess', { static: false }) private swalSuccess: SwalComponent;
  @ViewChild('swalError', { static: false }) private swalError: SwalComponent;
  @ViewChild('imInput') imInputVariable: ElementRef;
  public formatPlaceholder: DateInputCustomFormatPlaceholder = {
    day: 'วัน',
    month: 'เดือน',
    year: 'ปี',
    hour: 'ชั่วโมง',
    minute: 'นาที',
    second: 'วินาที'
  };
  public allowCustom: boolean = false;
  statusEnum = StatusEnum;
  status_list = [
    { id: 1, name: "จดบันทึก" },
    { id: 2, name: "ดำเนินการ" },
    { id: 3, name: "เสร็จสิ้น" }
  ];
  currentPage: any = 1;
  itemsPerPage: any = 10;
  totalItems: any = 0;
  search_status: any;
  search_st_date: Date;
  search_en_date: Date;
  search_hashtag: any;
  search_title: any;
  search_from: any;
  search_to: any;

  data_note_type: NoteTypeInfo[] = [];
  note_type_list: NoteTypeInfo[] = [];
  search_note_type: NoteTypeInfo;
  is_loading = false;
  data_list: NoteInfo[] = [];
  selectItem: NoteInfo = {
    title: null,
    hashtag: null,
    desc: null
  };
  res_message: any;
  status_modal: any;
  data_note_type_modal: NoteTypeInfo[] = [];
  note_type_modal: NoteTypeInfo;

  data_route_modal: Array<string> = [];
  route_list: string[] = [];
  route_modal: any;


  data_user_modal: any[] = [];
  user_list: any[] = [];
  user_modal: any;
  data_user_modal_by: any[] = [];
  data_user_modal_to: any[] = [];
  data_user_search_by: any[] = [];
  data_user_search_to: any[] = [];
  user_modal_by: any;
  user_modal_to: any;
  user_search_by: any;
  user_search_to: any;

  images: NoteImage[] = [];
  noti_time_modal: any;
  tabSelect:any = 1;
  event_logs:NoteInfo[] = [];
  isAdd: boolean = false;
  isEdit: boolean = false;
  constructor(private noteService: NoteService,
    private noteTypeService: NoteTypeService,
    private commonService: CommonService,
    private infoUploadService: InfoUploadService) {
    this.commonService.activityLog(ActionEnum.Get, 'Information ➡ บันทึก ➡ สมุดบันทึก').subscribe(res => { }, error => { console.log(error); });
     }

  async ngOnInit() {
    this.getNoteType();
    this.getUsers();
    this.getData();
    this.isAdd = await this.commonService.getPermission(permission.note.note.add_id).toPromise();
    this.isEdit = await this.commonService.getPermission(permission.note.note.edit_id).toPromise();
  }
  changeTab(t){
    if (this.tabSelect != t) {
      this.tabSelect = t;
    }
  }
  search() {
    this.currentPage = 1;
    this.getData();
  }
  pageChanged(event: any): void {
    if (this.currentPage != event.page) {
      this.currentPage = event.page;
      this.getData();
    }
  }
  handleValueStatus(value) {
    if (value) {
      //console.log(value);
      this.search_status = value;
    } else {
      this.search_status = null;
    }
  }
  handleValueStatusModal(value) {
    if (value) {
      this.status_modal = value;
    } else {
      this.status_modal = null;
    }
  }

  getNoteType() {
    this.noteTypeService.getNoteType(null, null, null, null, null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.note_type_list = _.cloneDeep(res.data);
              this.data_note_type = _.cloneDeep(res.data);
              this.data_note_type_modal = _.cloneDeep(res.data);
            }
          }
        }
      },
      error => {
      });
  }
  handleFilterNoteType(value) {
    if (value.length > 0) {
      this.data_note_type = this.note_type_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_note_type = _.cloneDeep(this.note_type_list);
    } else {
      this.data_note_type = [];
    }
  }
  handleValueNoteType(value) {
    if (value) {
      this.search_note_type = value;
    } else {
      this.search_note_type = null;
    }
  }

  handleFilterNoteTypeModal(value) {
    if (value.length > 0) {
      this.data_note_type_modal = this.note_type_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_note_type_modal = _.cloneDeep(this.note_type_list);
    } else {
      this.data_note_type_modal = [];
    }
  }
  handleValueNoteTypeModal(value) {
    if (value) {
      this.note_type_modal = value;
    } else {
      this.note_type_modal = null;
    }
  }

  handleFilterRouteModal(value) {
    if (value.length > 0) {
      this.data_route_modal = this.route_list.filter((s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_route_modal = _.cloneDeep(this.route_list);
    } else {
      this.data_route_modal = [];
    }
  }

  getUsers() {
    this.commonService.getUsers(1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              res.data.forEach(item => {
                if (!item.name) {
                  item.name = `${item.firstname} ${item.lastname}`
                }
              });
              this.user_list = _.cloneDeep(res.data);
              this.data_user_modal = _.cloneDeep(res.data);
              this.data_user_modal_by = _.cloneDeep(res.data);
              this.data_user_modal_to = _.cloneDeep(res.data);
              this.data_user_search_by = _.cloneDeep(res.data);
              this.data_user_search_to = _.cloneDeep(res.data);
            }
          }
        }
      },
      error => {
      });
  }

  handleFilterUserModal(value) {
    if (value.length > 0) {
      this.data_user_modal = this.user_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_user_modal = _.cloneDeep(this.user_list);
    } else {
      this.data_user_modal = [];
    }
  }
  handleValueUserModal(value) {
    if (value) {
      this.user_modal = value;
    } else {
      this.user_modal = null;
    }
  }
  handleFilterUserModalTo(value) {
    if (value.length > 0) {
      this.data_user_modal_to = this.user_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_user_modal_to = _.cloneDeep(this.user_list);
    } else {
      this.data_user_modal_to = [];
    }
  }
  handleValueUserModalTo(value) {
    if (value) {
      this.user_modal_to = value;
    } else {
      this.user_modal_to = null;
    }
  }
  handleFilterUserModalBy(value) {
    if (value.length > 0) {
      this.data_user_modal_by = this.user_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_user_modal_by = _.cloneDeep(this.user_list);
    } else {
      this.data_user_modal_by = [];
    }
  }
  handleValueUserModalBy(value) {
    if (value) {
      this.user_modal_by = value;
    } else {
      this.user_modal_by = null;
    }
  }

  handleFilterUserSearchTo(value) {
    if (value.length > 0) {
      this.data_user_search_to = this.user_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_user_search_to = _.cloneDeep(this.user_list);
    } else {
      this.data_user_search_to = [];
    }
  }
  handleValueUserSearchTo(value) {
    if (value) {
      this.user_search_to = value;
    } else {
      this.user_search_to = null;
    }
  }
  handleFilterUserSearchBy(value) {
    if (value.length > 0) {
      this.data_user_search_by = this.user_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_user_search_by = _.cloneDeep(this.user_list);
    } else {
      this.data_user_search_by = [];
    }
  }
  handleValueUserSearchBy(value) {
    if (value) {
      this.user_search_by = value;
    } else {
      this.user_search_by = null;
    }
  }

  getData() {
    this.is_loading = true;
    this.data_list = [];
    let sta = null;
    let hash = null;
    let title = null;
    let st = null;
    let en = null;
    let type_id = null;
    let note_by_id = null;
    let note_to_id = null;
    if (this.search_status)
      sta = this.search_status.id;
    if (this.search_title)
      title = this.search_title;
    if (this.search_hashtag)
      hash = this.search_hashtag;
    if (this.search_st_date) {
      st = new Date(this.search_st_date.getFullYear(), this.search_st_date.getMonth(), this.search_st_date.getDate(), 0, 0, 0).toISOString();
    }
    if (this.search_en_date) {
      en = new Date(this.search_en_date.getFullYear(), this.search_en_date.getMonth(), this.search_en_date.getDate(), 23, 59, 59).toISOString();
    }
    if (this.search_note_type)
      type_id = this.search_note_type.id;

    if (this.user_search_by) {
      note_by_id = this.user_search_by.id;
    }
    if (this.user_search_to) {
      note_to_id = this.user_search_to.id;
    }
    this.noteService.getNote(title, sta, st, en, type_id, hash, null, null, note_by_id, note_to_id, this.currentPage, this.itemsPerPage).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.data_list = res.data.data;
              this.totalItems = res.data.total_items;
            } else {
              this.totalItems = 0;
            }
          } else {
            this.totalItems = 0;
          }
        } else {
          this.totalItems = 0;
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
        this.totalItems = 0;
      });
  }
  getDataById(id) {
    this.event_logs = [];
    this.noteService.getNoteById(id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.event_logs = res.data.logs;
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
        this.totalItems = 0;
      });
  }
  openAddModal() {
    this.selectItem = {
      title: null,
      hashtag: null,
      desc: null,
      is_noti: false
    };
    this.images = [];
    this.status_modal = this.status_list.find(x => x.id == StatusEnum.Active);
    this.note_type_modal = null;
    this.user_modal = null;
    this.user_modal_by = null;
    this.user_modal_to = null;
    this.noti_time_modal = null;
    this.tabSelect = 1;
    this.addModal.show();
  }
  openEditModal(item?: any) {
    this.selectItem = item;
    this.images = [];
    this.note_type_modal = null;
    this.status_modal = this.status_list.find(x => x.id == item.status);
    if (item.images)
      this.images = _.cloneDeep(item.images);
    if (this.selectItem.note_type_id)
      this.note_type_modal = this.note_type_list.find(x => x.id == this.selectItem.note_type_id);

    if (this.selectItem.noti_user_id)
      this.user_modal = this.user_list.find(x => x.id == +this.selectItem.noti_user_id);
    if (this.selectItem.noti_time)
      this.noti_time_modal = new Date(this.selectItem.noti_time);

    if (this.selectItem.note_by_id)
      this.user_modal_by = this.user_list.find(x => x.id == +this.selectItem.note_by_id);

    if (this.selectItem.note_to_id)
      this.user_modal_to = this.user_list.find(x => x.id == +this.selectItem.note_to_id);
    this.getDataById(this.selectItem.id);
    this.tabSelect = 1;
    this.addModal.show();
  }
  openDeleteModal(item?: any) {
    this.selectItem = item;
    this.status_modal = this.status_list.find(x => x.id == item.status);
    this.deleteModal.show();
  }
  clearData() {
    this.selectItem = new NoteInfo();
  }
  openAddIncident() {

  }

  openPreview(html?: any) {
    console.log(html);
    let htm = `
    <html dir="ltr">
      <head>
        <title>Preview</title>
        <link type="text/css" rel="stylesheet" href="https://cdn.ckeditor.com/4.6.1/full/contents.css?t=GB8C">
        <link type="text/css" rel="stylesheet" href="https://cdn.ckeditor.com/4.6.1/full/plugins/copyformatting/styles/copyformatting.css">
      </head>
      <body class="cke_editable cke_editable_themed cke_contents_ltr cke_show_borders">
      ${html}
      </body>
    </html>`
    let newWindow = window.open();
    newWindow.document.write(htm);
  }
  detectFiles(event) {
    let acceptedType = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'];
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        if (acceptedType.includes(file.type)) {
          let reader = new FileReader();
          reader.onload = (e: any) => {
            let f = new NoteImage();
            f.file = file;
            f.status = 1;
            f.dataUrl = e.target.result;
            this.images.push(f);
          }
          reader.readAsDataURL(file);
        } else {
          const error_text = "รองรับไฟล์นามกุล JPEG,PNG,JPG,GIF เท่านั้น";
          this.res_message = error_text;
          this.is_loading = false
          setTimeout(() => { this.swalError.fire() }, 500);
        }
      }
    }
  }
  deleteFileItem(item?: NoteImage) {
    if (item.id) {
      const index: number = this.images.indexOf(item);
      if (index !== -1) {
        this.images[index].status = 0;
      }
    } else {
      const index: number = this.images.indexOf(item);
      if (index !== -1) {
        this.images.splice(index, 1);
      }
    }
  }
  clearDataFiles() {
    if (this.imInputVariable) {
      this.imInputVariable.nativeElement.value = "";
    }
  }

  addData(file_list?: NoteImage[]) {
    this.res_message = "";
    this.is_loading = true;
    let req = new ReqAddNote();
    req.title = this.selectItem.title;
    req.desc = this.selectItem.desc;
    req.hashtag = this.selectItem.hashtag;
    req.is_noti = this.selectItem.is_noti;
    req.status = this.status_modal.id;
    req.images = file_list;

    if (this.user_modal)
      req.noti_user_id = this.user_modal.id;
    if (this.user_modal_by) {
      req.note_by_id = this.user_modal_by.id;
      req.note_by = this.user_modal_by.name;
    }
    if (this.user_modal_to) {
      req.note_to_id = this.user_modal_to.id;
      req.note_to = this.user_modal_to.name;
    }
    if (this.noti_time_modal)
      req.noti_time = this.noti_time_modal;

    if (this.note_type_modal) {
      req.note_type_id = this.note_type_modal.id;
    }
    this.noteService.addNote(req).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { request: req, response: res };
            this.commonService.activityLog(ActionEnum.Add, 'Information ➡ บันทึก ➡ สมุดบันทึก', JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `เพิ่มบันทึกสำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getData();
            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  editData(file_list?: NoteImage[]) {
    this.res_message = "";
    this.is_loading = true;
    let req = new ReqAddNote();
    req.title = this.selectItem.title;
    req.desc = this.selectItem.desc;
    req.hashtag = this.selectItem.hashtag;
    req.is_noti = this.selectItem.is_noti;
    req.status = this.status_modal.id;
    req.images = file_list;
    if (this.user_modal)
      req.noti_user_id = this.user_modal.id;
    if (this.user_modal_by) {
      req.note_by_id = this.user_modal_by.id;
      req.note_by = this.user_modal_by.name;
    }
    if (this.user_modal_to) {
      req.note_to_id = this.user_modal_to.id;
      req.note_to = this.user_modal_to.name;
    }
    if (this.noti_time_modal)
      req.noti_time = this.noti_time_modal;

    if (this.note_type_modal) {
      req.note_type_id = this.note_type_modal.id;
    }
    this.noteService.editNote(req, this.selectItem.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { id: this.selectItem.id, request: req, response: res };
            this.commonService.activityLog(ActionEnum.Edit, 'Information ➡ บันทึก ➡ สมุดบันทึก', JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `แก้ไขบันทึกสำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getData();
            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }

  deleteData() {
    this.is_loading = true;
    let req = new ReqAddNote();
    req.status = this.status_modal.id;
    this.res_message = "";
    this.noteService.deleteNoteEditStatus(req, this.selectItem.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { id: this.selectItem.id };
            this.commonService.activityLog(ActionEnum.Delete, 'Information ➡ บันทึก ➡ สมุดบันทึก', JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `ลบบันทึกสำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getData();
            }, 100);
            this.deleteModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }

  bucket_name = 'information';
  path = 'note';
  async addDataAsyn() {
    let add_files: NoteImage[] = this.images.filter(x => x.file != null);
    if (add_files.length > 0) {
      const add_file_list = this.images.map(x => x.file)
      let res_fs = await this.infoUploadService.uploadImages(add_file_list, this.bucket_name, this.path).toPromise();
      if (res_fs) {
        if (res_fs.data) {
          let fs: NoteImage[] = [];
          add_files.forEach(async (item, index) => {
            let f = new NoteImage;
            f.status = StatusEnum.Active;
            f.url = res_fs.data.img_urls[index];
            f.name = item.file.name;
            await fs.push(f);
          });
          await this.addData(fs);
        } else {
          this.addData();
        }
      } else {
        this.addData();
      }
    } else {
      this.addData();
    }
  }

  async editDataAsyn() {
    this.images.forEach((item, index) => {
      item.index = index;
    });
    let add_files: NoteImage[] = await this.images.filter(x => x.file != null);

    if (add_files.length > 0) {
      const add_file_list = this.images.map(x => x.file)
      let res_fs = await this.infoUploadService.uploadImages(add_file_list, this.bucket_name, this.path).toPromise();
      if (res_fs) {
        if (res_fs.data) {
          await add_files.forEach(async (item, index) => {
            item.url = res_fs.data.img_urls[index];
          });
          let fs: NoteImage[] = [];
          for (const item of this.images) {
            let f = new NoteImage;
            if (item.file) {
              const find_index = await add_files.find(x => x.index == item.index);
              f.status = StatusEnum.Active;
              f.url = find_index.url;
              f.name = item.file.name;
            } else {
              f.id = item.id;
              f.status = item.status;
              f.url = item.url;
              f.name = item.name;
            }
            await fs.push(f);
          }
          await this.editData(fs);
        } else {
          const files_all = await this.mapRequestDataFile();
          this.editData(files_all);
        }
      } else {
        const files_all = await this.mapRequestDataFile();
        this.editData(files_all);
      }
    } else {
      const files_all = await this.mapRequestDataFile();
      this.editData(files_all);
    }
  }

  async mapRequestDataFile() {
    let fs: NoteImage[] = [];
    if (this.images.length > 0) {
      for (const item of this.images) {
        let f = new NoteImage;
        f.id = item.id;
        f.status = item.status;
        f.url = item.url;
        f.name = item.name;
        await fs.push(f);
      }
    }
    return fs;
  }
}