import { Component, OnInit,EventEmitter } from '@angular/core';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EventService } from 'src/app/services/user/event.service';
import { FuncService } from 'src/app/services/user/func.service';
@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();
  values: any;

  criteria_l : any = []
  _confirmRain : number = 1
  _chk : any = {l1:true,l2:true,l3:true,l4:true}
  constructor(
    private func : FuncService,
    public bsModalRef: BsModalRef,
    private eS : EventService,
  ) { }

  ngOnInit(): void {
    this.getRainAlert()
  }
  clearSelect(){
    this._chk = {l1:true,l2:true,l3:true,l4:true}
    for (const i of this.criteria_l) {
      this._chk['l'+i] = false
      
    }
  }
  async save(){
    let data : any = {}
    if(this.criteria_l.length){
      let order = 1
      for (const i of this.criteria_l) {
        if(i) data[`criteria_l`+i] = order
        order++;
      }
    }
    if(this._confirmRain) data.confirm = this._confirmRain
    let res : any = await this.eS.addRainAlert(data);
    if(res){
      this.func.alertSuccess()
      this.confirm()
    }
  }
  async getRainAlert(){
    let res : any = await this.eS.getRainAlert();
    if(res){
      this._confirmRain=res.confirm
      if(res.criteria_l1) this.criteria_l[res.criteria_l1] = 1
      if(res.criteria_l2) this.criteria_l[res.criteria_l2] = 2
      if(res.criteria_l3) this.criteria_l[res.criteria_l3] = 3
      if(res.criteria_l4) this.criteria_l[res.criteria_l4] = 4
      this.clearSelect()
    }
  }
  close() {
    this.bsModalRef.hide();
  }
  confirm() {
    this.event.emit(true);
    this.bsModalRef.hide();
  }
  cancel() {
    this.event.emit(false);
    this.bsModalRef.hide();
  }
}
