<div class="modal-header">
    <h4 *ngIf="mT==7">{{device_name}}</h4>
    <div class="headstep-BG">
        <h4 *ngIf="mT==1||mT==2||mT==3||mT==4" class="modal-title" (click)="MenuType=1"
            [ngClass]="{'active': MenuType==1}">เหตุการณ์</h4>

        <h4 *ngIf="mT==8" class="modal-title" (click)="MenuType=2;reVideo();" [ngClass]="{'active': MenuType==2}">
            {{values?.device_name||'กล้องวงจรปิด'}}</h4>

        <!-- <h4 *ngIf="mT==2"
        class="modal-title" (click)="MenuType=3" [ngClass]="{'active': MenuType==3}">ข้อมูลสถิติ</h4> -->

        <h4 *ngIf="mT==3" class="modal-title" (click)="MenuType=4" [ngClass]="{'active': MenuType==4}">ข้อมูลสภาพอากาศ
        </h4>

        <h4 *ngIf="mT==5" class="modal-title" (click)="MenuType=5" [ngClass]="{'active': MenuType==5}">Air Quality Index
            (AQI)</h4>
        <h4 *ngIf="mT==6" class="modal-title" (click)="MenuType=6" [ngClass]="{'active': MenuType==6}">
            {{values.display_name||''}}</h4>

        <h4 *ngIf="mT==7" class="modal-title" (click)="MenuType=7" [ngClass]="{'active': MenuType==7}">ป้ายหน้า</h4>
        <h4 *ngIf="mT==7" class="modal-title" (click)="MenuType=8" [ngClass]="{'active': MenuType==8}">ข้อมูลอุปกรณ์
        </h4>
    </div>
    <button type="button" class="close pull-right x-BT" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="model-body">
    <h5 *ngIf="MenuType==1" class="bold">{{values?.event_type_str||''}}</h5>
    <div *ngIf="MenuType==1" class="form-BG">
        <div>
            <p>จุดเกิดเหตุ</p>
            <p>{{values?.route_str||'---------------'}}</p>
        </div>
        <div>
            <p>ประเภทเหตุการณ์</p>
            <p>{{values?.event_type_str||'---------------'}}</p>
        </div>
        <div>
            <p>วันเวลาที่เกิดเหตุการณ์</p>
            <p *ngIf="values?.occur_datetime">{{values?.occur_datetime | date:'dd/MMM/yyyy HH:mm'}}</p>
            <p *ngIf="!values?.occur_datetime">---------------</p>
        </div>
        <div [ngSwitch]="values?.status">
            <p>สถานการณ์ดำเนินงาน</p>
            <p *ngSwitchCase="1">รอการตรวจสอบ/ยืนยัน</p>
            <p *ngSwitchCase="2">ระหว่างดำเนินการ</p>
            <p *ngSwitchCase="3">สิ้นสุดเหตุการณ์</p>
            <p *ngSwitchDefault>---------------</p>
        </div>
        <div>
            <button class="w-100 btn btn-primary process-BT" (click)="openEffectModal()">
                ดำเนินการ
            </button>
        </div>
    </div>
    <div class="cctv-BG" *ngIf="MenuType==2">
        <!-- <img src="./assets/event/cctv.jpg"> -->
        <video *ngIf="values?.streaming_url" id="my-video" class="video-js vjs-big-play-centered video-panel" controls
            autoplay="true" preload="auto" width="640" height="264" data-setup='{"fluid": true}'>
            <!-- <source src="http://180.180.242.208:1935/TOC/TOC_347_KM007080.stream/playlist.m3u8" type="application/x-mpegURL" /> -->
            <source [src]="values?.streaming_url" type="application/x-mpegURL" />
            <p class="vjs-no-js">
                To view this video please enable JavaScript, and consider upgrading to a web browser that
                <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
            </p>
        </video>
        <!-- <video *ngIf="values?.streaming_url"  class="video-js vjs-big-play-centered video-panel" controls
        preload="auto" poster="{{poster}}" data-setup="{}">
        <source src="http://180.180.242.208:1935/TOC/TOC_347_KM007080.stream/playlist.m3u8" type='application/x-mpegURL'>
        <p class="vjs-no-js">
          To view this video please enable JavaScript, and consider upgrading to a web browser that
          <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
        </p>
      </video> -->
    </div>
    <div class="air-BG" *ngIf="MenuType==5">
        <div class="air" [ngStyle]="{'background': weatherD.aqi_level_color}">
            {{weatherD?.aqi||0}}
        </div>
        <div class="detail-BG">
            <div>
                <h5 class="bold">{{weatherD?.aqi_level_name||'----------------'}}</h5>
            </div>
            <div>
                <p>อุณหภูมิ</p>
                <p>{{weatherD?.air_temp||'----------------'}}</p>
            </div>
            <div>
                <p>ความเร็วลม (Km/hr)</p>
                <p>{{weatherD?.wind_speed||'----------------'}}</p>
            </div>
            <div>
                <p>Humidity</p>
                <p>{{weatherD?.relative_humidity||'----------------'}}</p>
            </div>
            <div>
                <p>ปริมาณน้ำฝน</p>
                <p>{{weatherD?.hour_precipitation||'----------------'}}</p>
            </div>
        </div>
    </div>

    <!-- 6 ------------ -->
    <div class="device-BG" *ngIf="MenuType==6">
        <div class="values-BG">
            <div class="center">
                <div>
                    <h5>{{stat_flow||'-'}}</h5>
                    <p>Flow (Vec/h)</p>
                </div>
            </div>
            <div class="center">
                <div>
                    <h5>{{stat_speed||'-'}}</h5>
                    <p>Speed (Km/h)</p>
                </div>
            </div>
            <div class="center">
                <div>
                    <h5>{{stat_occ||'-'}}</h5>
                    <p>Occupancy (%)</p>
                </div>
            </div>
        </div>
        <div class="graph-BG">
            <kendo-chart *ngIf="stat_g?.categories">
                <kendo-chart-legend [position]="'bottom'"></kendo-chart-legend>
                <kendo-chart-area background="none" [margin]="0" [height]="300">
                </kendo-chart-area>
                <!-- <kendo-chart-title text="Units sold"></kendo-chart-title> -->
                <kendo-chart-category-axis>
                    <kendo-chart-category-axis-item [categories]="stat_g?.categories" [plotBands]="stat_current">
                    </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-series>
                    <kendo-chart-series-item type="line" color="#82E2A0" [data]="stat_g.max" name="max">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                    </kendo-chart-series-item>
                    <kendo-chart-series-item type="line" color="#3190FF" [data]="stat_g.avg" name="avg">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                    </kendo-chart-series-item>
                    <kendo-chart-series-item type="line" color="#FF719D" [data]="stat_g.min" name="min">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                    </kendo-chart-series-item>
                    <kendo-chart-series-item type="line" color="#fe8e00" [data]="stat_g.current" name="current">
                        <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                    </kendo-chart-series-item>

                    <!-- <kendo-chart-series-item name="Current" type="line" color="#28b4c8" [data]="stat_g.current">
                    <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                  </kendo-chart-series-item> -->
                </kendo-chart-series>
                <kendo-chart-legend [position]="'bottom'" [visible]="true"></kendo-chart-legend>
                <kendo-chart-tooltip [visible]="true"></kendo-chart-tooltip>
            </kendo-chart>
        </div>
    </div>

    <!-- 7,8 ------------ -->
    <div class="monitoring-BG" *ngIf="MenuType==7">
        <!-- <div>
            <img src="./assets/event/sign1.jpg" alt="">
        </div>
        <div class="d-flex">
            <div>
                <img src="./assets/event/sign2.jpg" alt="">
            </div>
            <div>
                <img src="./assets/event/sign3.jpg" alt="">
            </div>
            <div>
                <img src="./assets/event/sign4.jpg" alt="">
            </div>
        </div> -->
        <div *ngIf="this.boardsignData&&this.boardsignData.length">
            <div *ngFor="let l of this.boardsignData[0].layers;index as ii;">
                <div class="d-flex justify-content-center flex-wrap">
                    <div *ngFor="let sign of l;index as iii;" class="m-2">
                        {{idx}}
                        <div class="sign-box" [style.width.px]="get_sign_width(sign?.ratio,set_sign_height)"
                            [style.height.px]="set_sign_height" *ngIf="sign?.dir == signDir.Front">
                            <!-- <img [src]="sign?.media?.media?.path" height="{{set_sign_height}}"
                        width="{{get_sign_width(sign?.ratio,set_sign_height)}}"
                        *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)" /> -->
                            <app-sign-image-item [media]="sign?.media?.media" [sign_height]="set_sign_height"
                                [is_board]="true" [config_road]="b?.config_road" [rain]="b?.rain" [ratio]="sign?.ratio"
                                [resize]="(set_sign_height/sign?.sign_type_object?.height)"
                                *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                            </app-sign-image-item>
                            <app-playlist-item [show_count]="true" [is_board]="true" [config_road]="b?.config_road"
                                [rain]="b?.rain" [resize]="(set_sign_height/sign?.sign_type_object?.height)"
                                *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                                [playlist]="sign?.media?.items" [height]="set_sign_height"
                                [width]="get_sign_width(sign?.ratio,set_sign_height)">
                            </app-playlist-item>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="monitoring-BG" *ngIf="MenuType==8">
        <div *ngFor="let i of camera_list">
            <p>{{i.name||''}}</p>
            <img [src]="i.url" alt="">
        </div>
    </div>
</div>