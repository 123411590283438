<div class="row p-3">
  <div class="col-lg-3 col-md-3 col-sm-6">
    <div>ชื่อเรื่อง :</div>
    <div>
      <input autocomplete="nope" type="text" placeholder="" maxlength="100" class="k-textbox form-control w-100"
        [(ngModel)]="search_title" [ngModelOptions]="{standalone: true}" />
    </div>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-6">
    <div>ประเภทการบันทึก :</div>
    <div>
      <kendo-combobox class="w-100" [data]="data_note_type" [allowCustom]="allowCustom" [textField]="'name'"
        [valueField]="'id'" [value]="search_note_type" (valueChange)="handleValueNoteType($event)"
        (filterChange)="handleFilterNoteType($event)" [placeholder]="'ทั้งหมด'" [filterable]="true">
      </kendo-combobox>
    </div>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-6">
    <div>สถานะ :</div>
    <div>
      <kendo-combobox class="w-100" [data]="status_list" [allowCustom]="allowCustom" [textField]="'name'"
        [filterable]="true" [valueField]="'id'" [value]="search_status" (valueChange)="handleValueStatus($event)"
        [placeholder]="'ทั้งหมด'">
      </kendo-combobox>
    </div>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-6">
    <div>Hashtag :</div>
    <div>
      <input autocomplete="nope" type="text" placeholder="" maxlength="100" class="k-textbox form-control w-100"
        [(ngModel)]="search_hashtag" [ngModelOptions]="{standalone: true}" />
    </div>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-6">
    <div>วันที่เริ่ม :</div>
    <div>
      <kendo-datepicker class="w-100" [format]="'dd/MM/yyyy'" [(value)]="search_st_date"
        [formatPlaceholder]="formatPlaceholder" >
      </kendo-datepicker>
    </div>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-6">
    <div>วันที่สิ้นสุด :</div>
    <div>
      <kendo-datepicker class="w-100" [format]="'dd/MM/yyyy'" [(value)]="search_en_date"
        [formatPlaceholder]="formatPlaceholder">
      </kendo-datepicker>
    </div>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-6">
    <div>ผู้จดบันทึก :</div>
    <div>
      <!-- <input autocomplete="nope" type="text" placeholder="" maxlength="100" class="k-textbox form-control w-100"
        [(ngModel)]="search_from" [ngModelOptions]="{standalone: true}" /> -->
        <kendo-combobox class="w-100" [data]="data_user_search_by" [allowCustom]="allowCustom" [textField]="'name'"
        [valueField]="'id'" [value]="user_search_by" (valueChange)="handleValueUserSearchBy($event)"
        (filterChange)="handleFilterUserSearchBy($event)" [placeholder]="'ทั้งหมด'" [filterable]="true">
      </kendo-combobox>
    </div>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-6">
    <div>ผู้รับงานต่อ :</div>
    <div>
      <!-- <input autocomplete="nope" type="text" placeholder="" maxlength="100" class="k-textbox form-control w-100"
        [(ngModel)]="search_to" [ngModelOptions]="{standalone: true}" /> -->
        <kendo-combobox class="w-100" [data]="data_user_search_to" [allowCustom]="allowCustom" [textField]="'name'"
        [valueField]="'id'" [value]="user_search_to" (valueChange)="handleValueUserSearchTo($event)"
        (filterChange)="handleFilterUserSearchTo($event)" [placeholder]="'ทั้งหมด'" [filterable]="true">
      </kendo-combobox>
    </div>
  </div>

  <div class="col-lg-12 col-md-12 d-flex align-items-end justify-content-end">
    <div class="mt-1">
      <button type="button" (click)="search()" class="btn btn-primary pull-right text-white ml-1"> <i
          class="fa fa-search"></i>
        ค้นหา </button>
    </div>
  </div>
  <div class="col-12 mt-3 table-header pl-3 pr-3 pt-2 pb-2 d-flex align-items-center justify-content-between">
    <div class="font-2">
      <i class="fa fa-bars mr-2"></i> สมุดบันทึก
    </div>
    <button *ngIf="isAdd" (click)="openAddModal()" type="button" class="btn btn-primary text-white"> <i
        class="fa fa-plus"></i>
      เพิ่มการบันทึก</button>
  </div>
  <div class="col-12 mt-3">
    <table class="table table-outline mb-0">
      <thead class="thead-dark" style="background-color: black;">
        <tr>
          <th class="text-center text-light table-header"><i class="fa fa-hashtag"></i></th>
          <th class="text-center text-light table-header">ชื่อเรื่อง</th>
          <th class="text-center text-light table-header">ประเภทการบันทึก</th>
          <th class="text-center text-light table-header">สถานะ</th>
          <th class="text-center text-light table-header">ผู้จดบันทึก</th>
          <th class="text-center text-light table-header">ผู้รับงานต่อ</th>
          <th class="text-center text-light table-header">Create date</th>
          <th *ngIf="isEdit" class="text-center text-light table-header">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="!this.is_loading && data_list?.length == 0">
          <td colspan="10" style="height: 50px;">
            ไม่พบข้อมูล
          </td>
        </tr>
        <tr *ngIf="this.is_loading">
          <td colspan="10" style="height: 50px;">
            กำลังโหลด ..
          </td>
        </tr>
        <tr *ngFor="let item of data_list;index as i;">
          <td class="text-center">
            <div>
              {{(i+1)+((this.currentPage - 1)*itemsPerPage)}}
            </div>
          </td>
          <td class="text-center">
            {{item?.title}}
          </td>
          <td class="text-center">
            {{item?.note_type_str}}
          </td>
          <td class="text-center">
            <!-- {{item?.status_str}} -->
            <div *ngIf="item?.status">
              <div *ngFor="let s of status_list;">
                <div *ngIf="item?.status == s.id">{{s?.name}}</div>
              </div>
            </div>
          </td>
          <td class="text-center">
            {{item?.note_by}}
          </td>
          <td class="text-center">
            {{item?.note_to}}
          </td>
          <td class="text-center">
            <div style="font-size: 12px;">{{item?.created_datetime | date: 'HH:mm dd/MM/yyyy'}}</div>
          </td>

          <td *ngIf="isEdit" class="text-center">
            <div class="d-flex align-items-center justify-content-center">
              <button (click)="openEditModal(item)" title="แก้ไข" type="button"
                class="btn btn-sm btn-warning mr-1 text-light">
                <i class="cui-note icons"></i>
              </button>
              <button (click)="openDeleteModal(item)" title="ลบ" type="button" class="btn btn-sm btn-danger ml-1">
                <i class="cui-trash icons"></i>
              </button>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12" class="table-footer">
            <div class="table-footer-inside d-flex align-items-center justify-content-between">
              <div class="ml-3 pt-3">
                <pagination [totalItems]="totalItems" [maxSize]="5" [itemsPerPage]="itemsPerPage"
                  [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)" class="pagination"
                  previousText="&lsaquo;" nextText="&rsaquo;" [boundaryLinks]="true" firstText="&laquo;"
                  lastText="&raquo;"></pagination>
              </div>
              <div></div>
              <div class="mr-3 ml-2">total: {{totalItems}}</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div bsModal #addModal="bs-modal" class="modal fade modal-md" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true" (onHide)="clearData()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i><span *ngIf="!selectItem?.id">เพิ่มบันทึก</span><span
            *ngIf="selectItem?.id">แก้ไขบันทึก</span></h4>
        <button type="button" class="close text-light" (click)="addModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div *ngIf="selectItem?.id" class="d-flex border-dark-top">
        <div class="p-2 flex-fill d-flex justify-content-center align-items-center disable-select"
          [ngClass]="(tabSelect == 1)?'menu-item-active':'menu-item'" (click)="changeTab(1)">
          <i class="cui-note icons mr-1"></i>
          <span>การจดบันทึก</span>
        </div>
        <div class="p-2 flex-fill d-flex justify-content-center align-items-center disable-select"
          [ngClass]="(tabSelect == 2)?'menu-item-active':'menu-item'" (click)="changeTab(2)">
          <i class="fa fa-bars mr-1"></i>
          <span>Activity log</span>
        </div>
      </div>
      <div *ngIf="tabSelect == 1" class="modal-body">
        <form class="form-horizontal mt-2 mb-2">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-form-label" for="text-input"> <span
                class="float-sm-left float-md-right">ชื่อเรื่อง<span class="text-danger">*</span>
                :</span></label>
            <div class="col-md-8  col-lg-8 col-xl-7">
              <input type="text" [(ngModel)]="selectItem.title" class="form-control" placeholder="" maxlength="50"
                [ngModelOptions]="{standalone: true}">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-form-label" for="select1"> <span
                class="float-sm-left float-md-right">ประเภทการบันทึก<span class="text-danger">*</span>
                :</span></label>
            <div class="col-md-8 col-lg-8 col-xl-7">
              <kendo-combobox class="w-100" [data]="data_note_type_modal" [allowCustom]="allowCustom"
                [textField]="'name'" [valueField]="'id'" [value]="note_type_modal"
                (valueChange)="handleValueNoteTypeModal($event)" (filterChange)="handleFilterNoteTypeModal($event)"
                [filterable]="true" [placeholder]="'โปรดระบุ'">
              </kendo-combobox>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-form-label" for="text-input"> <span
                class="float-sm-left float-md-right">รายละเอียด
                :</span></label>
            <div class="col-md-8  col-lg-8 col-xl-7">
              <textarea [(ngModel)]="selectItem.desc" rows="4" class="form-control" placeholder=""
                [ngModelOptions]="{standalone: true}"></textarea>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-form-label" for="select1"> <span
                class="float-sm-left float-md-right">รูปภาพประกอบ
                :</span></label>
            <div class="col-md-8 col-lg-8 col-xl-7">
              <div class="d-flex justify-content-start align-items-center flex-wrap">
                <div title="เพิ่มรูปภาพ" class="pointer btn-file-plus m-1">
                  <i class="fa fa-plus"></i>
                  <input #imInput type="file" (change)="detectFiles($event)" accept=".png,.jpg,.gif">
                </div>
                <div *ngFor="let f of images;index as i2;">
                  <div *ngIf="f?.status == statusEnum.Active" class="up-im-boder d-flex flex-wrap">
                    <div *ngIf="f?.dataUrl" class="up-img">
                      <img height="58" width="58" [src]="f?.dataUrl | safe">
                      <i title="ลบ" class="fa fa-close up-im-delete text-light" (click)="deleteFileItem(f)"></i>
                    </div>
                    <div *ngIf="!f?.dataUrl" (click)="clickFile(f?.url)" class="up-img">
                      <img *ngIf="f?.url" height="58" width="58" [src]="f?.url">
                      <i title="ลบ" class="fa fa-close up-im-delete text-light" (click)="deleteFileItem(f)"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-form-label" for="select1"> <span
                class="float-sm-left float-md-right">ผู้จดบันทึก
                :</span></label>
            <div class="col-md-8 col-lg-3">
              <kendo-combobox class="w-100" [data]="data_user_modal_by" [allowCustom]="allowCustom" [textField]="'name'"
                [valueField]="'id'" [value]="user_modal_by" (valueChange)="handleValueUserModalBy($event)"
                (filterChange)="handleFilterUserModalBy($event)" [placeholder]="''" [filterable]="true">
              </kendo-combobox>
            </div>
            <label class="col-md-4 col-lg-2 col-xl-1 col-form-label mt-md-3 mt-lg-0" for="select1"> <span
                class="float-sm-left float-md-right">ผู้รับงานต่อ
                :</span></label>
            <div class="col-md-8 col-lg-3 mt-md-3 mt-lg-0">
              <kendo-combobox class="w-100" [data]="data_user_modal_to" [allowCustom]="allowCustom" [textField]="'name'"
                [valueField]="'id'" [value]="user_modal_to" (valueChange)="handleValueUserModalTo($event)"
                (filterChange)="handleFilterUserModalTo($event)" [placeholder]="''" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-form-label" for="select1"> <span
                class="float-sm-left float-md-right">วันที่
                :</span></label>
            <div class="col-md-8 col-lg-3">
              <kendo-datetimepicker class="w-100" [format]="'dd/MM/yyyy HH:mm'" [(value)]="noti_time_modal"
                [formatPlaceholder]="formatPlaceholder">
              </kendo-datetimepicker>
            </div>
            <label class="col-md-4 col-lg-2 col-xl-1 col-form-label mt-md-3 mt-lg-0" for="select1"> <span
                class="float-sm-left float-md-right">สถานะ<span class="text-danger">*</span>
                :</span></label>
            <div class="col-md-8 col-lg-3 mt-md-3 mt-lg-0">
              <kendo-combobox class="w-100" [data]="status_list" [allowCustom]="allowCustom" [textField]="'name'"
                [valueField]="'id'" [value]="status_modal" (valueChange)="handleValueStatusModal($event)"
                [filterable]="true" [placeholder]="'โปรดระบุ'">
              </kendo-combobox>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-form-label" for="select1"> <span
                class="float-sm-left float-md-right">User Notification
                :</span></label>
            <div class="col-md-8 col-lg-3">
              <kendo-combobox class="w-100" [data]="data_user_modal" [allowCustom]="allowCustom" [textField]="'name'"
                [valueField]="'id'" [value]="user_modal" (valueChange)="handleValueUserModal($event)"
                (filterChange)="handleFilterUserModal($event)" [placeholder]="''" [filterable]="true">
              </kendo-combobox>
            </div>
            <label class="col-md-4 col-lg-2 col-xl-1 col-form-label mt-md-3 mt-lg-0"> <span
                class="float-sm-left float-md-right">Hashtag
                :</span></label>
            <div class="col-md-8 col-lg-3 mt-md-3 mt-lg-0">
              <input type="text" [(ngModel)]="selectItem.hashtag" class="form-control w-100" placeholder=""
                maxlength="50" [ngModelOptions]="{standalone: true}">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-form-label col-sm-4"> <span
                class="float-sm-left float-md-right"></span></label>
            <div class="col-md-8 col-lg-8 col-xl-7 col-sm-7">
              <div class="d-flex justify-content-start align-items-center" style="height: 20px;">
                <div>
                  <label class="checkbox-container">
                    <span>แจ้งเตือนทุกวัน</span>
                    <input type="checkbox" [(ngModel)]="selectItem.is_noti"
                      [ngModelOptions]="{standalone: true}">
                    <span class="checkmark-box"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary" (click)="addModal.hide()">ยกเลิก</button>
          <button [disabled]="!(selectItem?.title && note_type_modal != null && status_modal != null)"
            *ngIf="selectItem?.id" type="button" class="btn btn-primary text-white ml-2"
            (click)="editDataAsyn()">แก้ไข</button>
          <button [disabled]="!(selectItem?.title && note_type_modal != null && status_modal != null)"
            *ngIf="!selectItem?.id" type="button" class="btn btn-primary text-white ml-2"
            (click)="addDataAsyn()">เพิ่ม</button>
        </div>
      </div>
      <div class="modal-body" *ngIf="tabSelect == 2">
        <div class="animated fadeIn event-log-box rounded scrollbar-style" auto-scroll lock-y-offset="10"
          observe-attributes>
          <div class="pl-3 pr-3">
            <div class="d-flex" *ngFor="let elog of event_logs; let i = index"
              [ngClass]="{'mb-3': event_logs?.length == (i+1)}">
              <div class="d-flex justify-content-center align-items-center time-text-log">
                <div class="text-center margin-t-20">
                  <div>{{elog?.created_datetime| date:'dd/MM/yyyy'}}</div>
                  <div>{{elog?.created_datetime| date:'HH:mm'}}</div>
                </div>
              </div>
              <div class="d-flex justify-content-center align-items-end circle-text-log">
                <div class="h-100">
                  <div [ngClass]="{'line-1-none': i == 0,'line-2': i>0}"></div>
                  <!-- <div class="border bg-light rounded-circle circle-status border-3" [ngClass]="{'border-danger': elog?.status == 1,'border-warning': elog?.status == 2
                    ,'border-success': elog?.status == 3,'border-secondary': elog?.status == null}">
                  </div> -->
                  <div *ngIf="elog?.status == 1" title="จดบันทึก" class="border bg-light rounded-circle circle-status border-3 border-danger"></div>
                  <div *ngIf="elog?.status == 2" title="ดำเนินการ" class="border bg-light rounded-circle circle-status border-3 border-warning"></div>
                  <div *ngIf="elog?.status == 3" title="เสร็จสิ้น" class="border bg-light rounded-circle circle-status border-3 border-success"></div>
                  <div *ngIf="elog?.status == null" title="ไม่ระบุ" class="border bg-light rounded-circle circle-status border-3 border-secondary"></div>
                  <div class="line-3" *ngIf="event_logs?.length > (i+1)"></div>
                </div>
              </div>
              <div class="ml-auto w-100">
                <div class="ml-4 text-user-h d-flex align-items-end justify-content-between">
                  <div> 
                    <span *ngIf="elog?.note_by">{{elog?.note_by}}</span>
                    <span *ngIf="!elog?.note_by">-</span>
                    ส่งต่อ 
                    <span *ngIf="elog?.note_to">{{elog?.note_to}}</span>
                    <span *ngIf="!elog?.note_to">-</span></div>
                </div>
                <div class="ml-4 messages">
                  <div class="p-3">
                    <div class="text-dark">{{elog?.desc}}</div>
                    <div class="d-flex flex-wrap mt-2" *ngIf="elog?.images?.length>0">
                      <div *ngFor="let im of elog?.images; let i2 = index;trackBy:trackByIndex;">
                        <img *ngIf="im?.url" [src]="im?.url" width="60" height="60" class="border border-secondary rounded mr-1">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="is_loading" class="d-flex justify-content-center">
              <div class="lds-dual-ring"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div bsModal #deleteModal="bs-modal" class="modal fade modal-md" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="clearData()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i>ลบบันทึก</h4>
        <button type="button" class="close text-light" (click)="deleteModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mt-2 text-center">
          <h4>คุณต้องการลบใช่หรือไม่</h4>
        </div>
        <div class="mt-2 mb-4 text-center">
          <h4>{{selectItem?.title}}</h4>
        </div>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary" (click)="deleteModal.hide()">ยกเลิก</button>
          <button type="button" class="btn btn-primary text-white ml-2" (click)="deleteData()">ยืนยัน</button>
        </div>
      </div>
    </div>
  </div>
</div>
<swal #swalSuccess title="<div class='text-light'>สำเร็จ</div>" type="success" text="" icon="success"></swal>
<swal #swalError title="<div class='text-light'>ไม่สำเร็จ</div>" html="<div class='text-light'>{{res_message}}</div>"
  type="error" icon="error"></swal>