import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { type } from 'os';
import { DetailEffectComponent } from '../detail-effect/detail-effect.component';

declare let videojs: any;
import { ReportService } from 'src/app/services/user/report.service';
import * as _ from 'lodash'
import { EventService } from 'src/app/services/user/event.service';
import { MediaType, SignDirection } from 'src/app/models/sign-control/enum';
import { BoardsignInfo } from 'src/app/models/sign-control/boardsign';
import { ActionEnum, StatusEnum } from 'src/app/models/common-enum';
@Component({
  selector: 'app-event-detail-map',
  templateUrl: './event-detail-map.component.html',
  styleUrls: ['./event-detail-map.component.scss']
})
export class EventDetailMapComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();
  values: any;
  modalRef: BsModalRef;

  mT : number = 1
  MenuType : number = 1
  
  player: any;
  stat_flow : number 
  stat_speed : number 
  stat_occ : number 
  stat_g : any = {
    // categories:['3', '4', '5', '6', '7', '8', '9','10','11','12','13','14'],
    // max:[123, 276, 310, 212, 240, 156, 98,165, 210, 287, 144, 190],
    // avg:[165, 210, 287, 144, 190, 167, 212,123, 276, 310, 212, 240],
    // min:[56, 140, 195, 46, 123, 78, 95,156, 98,165, 210, 287],
    // current: [],
  }
  stat_current: any;
  weatherD : any = []
  boardsignData : any = []
  streaming_url : string = ''
  
  
  boardSelect: BoardsignInfo;
  signDir = SignDirection;
  mediaType = MediaType;
  set_sign_height: number = 111;
  camera_list:any=[]
  device_name : string
  constructor(
    private modalService: BsModalService, 
    public bsModalRef: BsModalRef,
    private rS : ReportService,
    private eS : EventService,
  ) { }

  ngOnInit(): void {
    // if(this.values) console.log(this.values)
    // this.values.typeMarker = 1
    // if(this.values.device_sub_type_id==1024) this.values.typeMarker = 8
    // if(this.values.device_sub_type_id==1035) this.values.typeMarker = 6
    console.log(this.values)
    this.mT = this.values.typeMarker
    
    this.values.type_name = (this.mT==1||this.mT==2)? 'รถยนต์ชนแบริเออร์':(this.mT==3||this.mT==4)? 'ฝนตกปานกลาง':(this.mT==8)? '':''
    if(this.mT==5) {this.MenuType=5;this.getWeather()}
    if(this.mT==6) {
      this.MenuType=6
      this.getTimeline()
    }
    if(this.mT==7) {
      this.device_name = this.values.display_name
      this.MenuType=7
      this.getBoardSign() 
    }
    if(this.mT==8) {this.MenuType=2;this.reVideo();this.streaming_url=this.values.streaming_url||''}
    // this.values.device_name = 'SMC RX - XX+XXX In'
    
  }
  async getBoardSign(){
    // let res : any = await this.eS.getBoardsign(210)
    let res : any = await this.eS.getBoardsign(this.values.boardsign_id)
    console.log(res)
    if(res){
      let arr = []
      arr.push(res)
      this.boardsignData = await this.genSignsLayerView(arr, true);
      console.log("this.boardsignData",this.boardsignData)
    }
    // let res_carmera : any = await this.eS.getBoardsignCamera({ids:210})
    let res_carmera : any = await this.eS.getBoardsignCamera({ids:this.values.boardsign_id})
    if(res_carmera){
      this.camera_list = res_carmera[0].camera
    }
  }
  async getWeather(){
    let res : any = await this.eS.getWeather({device_id:this.values.device_id})
    if(res){this.weatherD = res}
  }
  async getTimeline(){
    // let res : any = await this.rS.getSensorTimeline({station_id:this.values.station_id});
    let res_stat : any = await this.rS.getSensorStat({station_id:1273});
    let res : any = await this.rS.getSensorTimeline({station_id:1273});
    console.log(res)
    console.log(res_stat)
    if(res_stat){
      this.stat_flow = res_stat.flow
      this.stat_speed = res_stat.speed
      this.stat_occ = res_stat.occupancy
    }
    if(res){
      let data : any = {}
      this.stat_g.categories = _.map(res.volume, 'hour');
      let current = _.map(res.volume, 'current_value');
      this.stat_g.current = _.take(current, 13);
      this.stat_g.max = _.map(res.volume, 'max_old_value');
      this.stat_g.avg = _.map(res.volume, 'avg_old_value');
      this.stat_g.min = _.map(res.volume, 'min_old_value');

      console.log(this.stat_g)
      
      let current_index = res.volume.findIndex(x => x.is_current == 1);
      this.stat_current = [{
        from: current_index,
        to: current_index,
        color: '#f38a03',
        opacity: 0.8
      }];
    }
  }
  reVideo() {
    setTimeout(() => {
        let elId = document.getElementById("my-video")
        this.player = videojs(elId, {html5: {
          hls: {
            withCredentials: false,
            overrideNative: true
          },
          nativeAudioTracks: false,
          nativeVideoTracks: false
        }}, function () {
        var myPlayer = this, id = myPlayer.id();
        // Make up an aspect ratio
        var aspectRatio = 264 / 640;
  
        // internal method to handle a window resize event to adjust the video player
        function resizeVideoJS() {
          var width = document.getElementById(id).parentElement.offsetWidth;
          myPlayer.width(width);
          myPlayer.height(width * aspectRatio);
        }
  
        // Initialize resizeVideoJS()
        resizeVideoJS();
  
        // Then on resize call resizeVideoJS()
        window.onresize = resizeVideoJS;
        });
        // this.players.push(player);
    }, 1000);
  }
  close() {
    this.bsModalRef.hide();
  }

  confirm() {
    this.event.emit(true);
    this.bsModalRef.hide();
  }
  cancel() {
    this.event.emit(false);
    this.bsModalRef.hide();
  }

  openEffectModal(data?: any) {
    const initialState = {
      values: this.values,
      // list: this.list,
      // inc_type: this.allType
    };

    this.modalRef = this.modalService.show(DetailEffectComponent, { initialState, class: 'w-80', backdrop: 'static',id:2 });
    this.modalRef.content.event.subscribe(data => {
      // if (data) this.getEventMap();
    });
  }

  // ------------------
  
  get_sign_width(ratio: string, set_sign_height) {
    if (ratio) {
      const wh = ratio.split(':');
      const w = +wh[0];
      const h = +wh[1];
      const r = w / h;
      return set_sign_height * r;
    } else {
      return 0;
    }
  }
  
  async genSignsLayerView(boards?: BoardsignInfo[], is_suggest?: boolean, is_camera?: boolean) {
    boards.forEach(board => {

      if (board.signs) {
        board.signs = board.signs.filter(x => x.status == StatusEnum.Active);
        if (board.signs.length > 0 && board.station.boardsign_structure) {
          // layers
          //const layers_text = board.station.boardsign_structure.split("|");
          let layers = [];
          let layers_new = [];
          let layers_sug = [];
          const layer_no_list = _.sortedUniq(board.signs.map(a => a.layer_no));
          layer_no_list.forEach((l, index) => {
            // signs
            let signs = [];
            let signs_new = [];
            let find_sign = board.signs.filter(x => x.layer_no == l);

            if (find_sign) {
              find_sign.forEach(s => {
                if (s.media) {
                  let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, sign_type_object: s.sign_type_object, dir: s.direction, media: s.media };
                  signs.push(d);
                } else {
                  let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, sign_type_object: s.sign_type_object, dir: s.direction, media: null };
                  signs.push(d);
                }
                if (is_suggest) {
                  signs_new.push({ id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, sign_type_object: s.sign_type_object, dir: s.direction, media: null });
                }
              });
              signs = _.sortBy(signs, ['sign_no']);
              layers.push(signs);
              if (is_suggest) {
                signs_new = _.sortBy(signs_new, ['sign_no']);
                layers_new.push(signs_new);
              }
            }
            if (is_suggest) {
              if (board.signs_suggest) {
                board.signs_suggest = board.signs_suggest.filter(x => x.status == StatusEnum.Active);
                let find_sign_sug = board.signs_suggest.filter(x => x.layer_no == l);
                let signs_sug = [];
                if (find_sign_sug) {
                  find_sign_sug.forEach(s => {
                    if (s.media) {
                      let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, dir: s.direction, media: s.media };
                      signs_sug.push(d);
                    } else {
                      let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, dir: s.direction, media: null };
                      signs_sug.push(d);
                    }
                  });
                  signs_sug = _.sortBy(signs_sug, ['sign_no']);
                  layers_sug.push(signs_sug);
                }
              } else {
                board.signs_suggest = _.cloneDeep(board.signs);
                let find_sign_sug = board.signs_suggest.filter(x => x.layer_no == l);
                let signs_sug = [];
                if (find_sign_sug) {
                  find_sign_sug.forEach(s => {
                    let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, dir: s.direction, media: null };
                    signs_sug.push(d);
                  });
                  signs_sug = _.sortBy(signs_sug, ['sign_no']);
                  layers_sug.push(signs_sug);
                }
              }
            }
          });
          board.layers = layers;
          if (is_suggest) {
            board.layers_suggest = _.cloneDeep(layers_sug);
            board.layers_new = _.cloneDeep(layers_new);
          }
        } else {
          board.signs = [];
          board.layers = [];
          board.layers_suggest = [];
          board.layers_new = [];
        }
      } else {
        board.signs = [];
        board.layers = [];
        board.layers_suggest = [];
        board.layers_new = [];
      }
      if (is_camera) {
        //board.camera = [{ id:1 ,name: "กล้องส่องป้าย", url: 'http://camera.shw.transcodeglobal.com/?code=0002-0019100-2-LMS-CTV-R&1608193554031' }, { id:2,name: "กล้องส่องป้ายซ้าย", url: 'http://camera.shw.transcodeglobal.com/?code=0002-0019100-2-LMS-CTV-L&1608193554032' }];
      } else {
        board.camera = [];
      }
    });
    return boards;
  }
}
