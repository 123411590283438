<div class="panel-body">
  <div class="map" id="map-device-monitoring" [ngClass]="toggle_info ? 'map-left' : 'map'"></div>
  <div class="box-right bg-dark">
    <div class="pr-3 pl-3 pb-3 pt-2  bg-gray-900" [ngClass]="adv_search ? 'search-panel_2': 'search-panel'">
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="font-3">ตัวช่วยการค้นหา</h5>
        <img src="assets/img/doh.png" width="100" height="100" />
      </div>

      <div *ngIf="!adv_search">
        <kendo-combobox class="w-100 mt-2" [data]="data_route" [allowCustom]="allowCustom" [textField]=""
          [valueField]="" [value]="search_route" (valueChange)="handleValueRoute($event)"
          (filterChange)="handleFilterRoute($event)" [placeholder]="'กรุณาเลือกสายทาง'" [filterable]="true">
        </kendo-combobox>
        <div class="d-flex align-items-center mt-2">
          <div class="flex-fill"><input type="text" class="form-control w-100 text-center"
              placeholder="กิโลเมตรเริ่มต้น" numbersOnly maxlength="4" [(ngModel)]="search_km_st"
              [ngModelOptions]="{standalone: true}"></div>
          <div class="flex-fill ml-2 mr-2"> </div>
          <div class="flex-fill"><input type="text" class="form-control w-100 text-center" placeholder="กิโลเมตรสิ้นสุด"
              numbersOnly maxlength="4" [(ngModel)]="search_km_en" [ngModelOptions]="{standalone: true}"></div>
        </div>
        <kendo-combobox class="w-100 mt-2" [data]="status_list" [allowCustom]="allowCustom" [textField]="'name'"
          [filterable]="true" [valueField]="'id'" [value]="search_status" (valueChange)="handleValueStatus($event)"
          [placeholder]="'สถานะ'">
        </kendo-combobox>
      </div>

      <div *ngIf="adv_search">
        <kendo-combobox class="w-100 mt-2" [data]="data_province" [allowCustom]="allowCustom"
          [textField]="'province_name'" [valueField]="'province_id'" [value]="search_province"
          (valueChange)="handleValueProvince($event)" (filterChange)="handleFilterProvince($event)"
          [placeholder]="'กรุณาเลือกจังหวัด'" [filterable]="true">
        </kendo-combobox>

        <kendo-combobox class="w-100 mt-2" [data]="data_highway_district" [allowCustom]="allowCustom"
          [textField]="'highway_district'" [valueField]="'id'" [value]="search_highway_district"
          (valueChange)="handleValueHighwayDistrict($event)" (filterChange)="handleFilterHighwayDistrict($event)"
          [placeholder]="'กรุณาเลือกแขวงทางหลวง'" [filterable]="true">
        </kendo-combobox>

        <kendo-combobox class="w-100 mt-2" [data]="data_highway_department" [allowCustom]="allowCustom"
          [textField]="'highway_department'" [valueField]="'id'" [value]="search_highway_department"
          (valueChange)="handleValueHighwayDepartment($event)" (filterChange)="handleFilterHighwayDepartment($event)"
          [placeholder]="'กรุณาเลือกหมวดทางหลวง'" [filterable]="true">
        </kendo-combobox>

        <kendo-combobox class="w-100 mt-2" [data]="data_owner" [allowCustom]="allowCustom" [textField]="'company_name'"
          [valueField]="'id'" [value]="search_owner" (valueChange)="handleValueOwner($event)"
          (filterChange)="handleFilterOwner($event)" [placeholder]="'กรุณาเลือกโครงการ'" [filterable]="true">
        </kendo-combobox>

        <kendo-combobox class="w-100 mt-2" [data]="data_vendor" [allowCustom]="allowCustom" [textField]="'company_name'"
          [valueField]="'vendor_id'" [value]="search_vendor" (valueChange)="handleValueVendor($event)"
          (filterChange)="handleFilterVendor($event)" [placeholder]="'กรุณาเลือกผู้ติดตั้ง'" [filterable]="true">
        </kendo-combobox>

        <kendo-combobox class="w-100 mt-2" [data]="data_year" [allowCustom]="allowCustom" [textField]="" [valueField]=""
          [value]="search_year" (valueChange)="handleValueYear($event)" (filterChange)="handleFilterYear($event)"
          [placeholder]="'กรุณาเลือกปีที่ติดตั้ง'" [filterable]="true">
        </kendo-combobox>

        <kendo-combobox class="w-100 mt-2" [data]="status_list" [allowCustom]="allowCustom" [textField]="'name'"
          [filterable]="true" [valueField]="'id'" [value]="search_status" (valueChange)="handleValueStatus($event)"
          [placeholder]="'สถานะ'">
        </kendo-combobox>
      </div>

      <div class="d-flex justify-content-between">
        <div class="d-flex justify-content-center align-items-end" (click)="switch_search()">
          <p *ngIf="adv_search" class="d-flex justify-content-center align-items-end fw-light pointer noselect">การค้นหาปกติ</p>
          <p *ngIf="!adv_search" class="d-flex justify-content-center align-items-end fw-light pointer noselect">การค้นหาขั้นสูง
          </p>
        </div>
        <div class="d-flex align-items-end justify-content-end mt-2">
          <button type="button" (click)="getDeviceStation()" class="btn btn-primary text-white"> <i
              class="fa fa-search"></i>
            ค้นหา
          </button>
        </div>
      </div>

    </div>
    <div class="w-100 bg-primary border-top border-dark" style="height: 3px;"></div>
    <div class="border-top border-dark scrollbar-style" [ngClass]="adv_search ? 'station-panel_2': 'station-panel'">
      <div *ngIf="stationList?.length == 0 && !this.is_loading"
        class="font-3 h5 d-flex justify-content-center align-items-center mt-3 mb-3">
        ไม่พบข้อมูล
      </div>
      <div *ngIf="this.is_loading" class="d-flex justify-content-center align-items-center mt-3 mb-3">
        <div class="lds-dual-ring"></div>
      </div>
      <div *ngIf="stationList?.length > 0 && !this.is_loading">
        <div *ngFor="let group of stationList;index as i;">
          <div (click)="collapse(group?.group_id)" class="pr-3 pl-3 pb-1 pt-1 d-flex justify-content-between align-items-center bg-gray-900 group-item-panel
          pointer noselect">
            <div>
              <div class="group-name font-3">{{group?.group_name}}</div>
              <small>{{group?.group_desc}}</small>
            </div>
            <div class="d-flex">
              <div class="status-item bg-danger ml-1 d-flex justify-content-center align-items-center text-dark font-3">
                {{group?.status?.offline}}
              </div>
              <div class="status-item bg-warning ml-1 mr-1 d-flex justify-content-center align-items-center text-dark
            font-3">{{group?.status?.ma}}
              </div>
              <div
                class="status-item bg-success ml-r d-flex justify-content-center align-items-center text-dark font-3">
                {{group?.status?.online}}
              </div>
            </div>
          </div>
          <div [collapse]="group?.isCollapsed" [isAnimated]="true">
            <div *ngFor="let item of group?.station_list;index as i2;"
              class="bg-gray-650 pr-2 pl-2 pt-1 pb-1 font-4 border-bottom border-dark d-flex align-items-center justify-content-between pointer"
              [ngClass]="item?.station_id == select_station_id? 'select-station' : ''" (click)="clickStation(item)">
              <div class="d-flex align-items-center">
                <i tooltip="ออนไลน์" *ngIf="connectionStatusEnum.Online === item?.connection_status" placement="top"
                  class="fa fa-circle text-success"></i>
                <i tooltip="ออฟไลน์" *ngIf="connectionStatusEnum.Offline === item?.connection_status" placement="top"
                  class="fa fa-circle text-danger"></i>
                <i tooltip="แจ้งซ้อม" *ngIf="statusEnum.Ma === item?.connection_status" placement="top"
                  class="fa fa-circle text-warning"></i>
                <i tooltip="อุณหภูมิสูงเกินกำหนด" *ngIf="9 === device?.connection_status" placement="top"
              class="fa fa-circle text-warning"></i>
                <span style="font-size:13px;" class="ml-2 font-3">{{item?.station_name}}</span>
              </div>
              <div>
                <div><small class="nowrap" tooltip="Last Checked"><i class="fa fa-check-circle-o text-primary"></i>
                    {{item?.last_checked | date:'dd/MM/yyyy HH:mm:ss'}}</small></div>
                <div><small class="nowrap" tooltip="Last Updated"><i class="fa fa-clock-o text-primary"></i>
                    {{item?.connection_datetime | date:'dd/MM/yyyy HH:mm:ss'}}</small></div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>

  </div>
  <div #boxleft class="bg-dark scrollbar-style" [ngClass]="toggle_info ? 'box-left' : 'box-left-hide'">
    <div *ngIf="toggle_info && select_station_id" class="p-3">
      <h6 class="font-3"><span>จุดติดตั้ง : </span><span>{{select_station?.station_name}}</span></h6>
      <h6 class="font-3 mt-2"><span>ประเภทการติดตั้ง : </span>
        <span>{{select_station?.structure_type_name || '-'}}</span></h6>
      <h6 class="font-3 mt-2">รายการอุปกรณ์</h6>
      <div class="mt-2" *ngFor="let item of select_station?.groups;index as i;">
        <div
          class="d-flex justify-content-start align-items-center bg-primary p-2 font-4 text-dark border-bottom border-dark">
          {{item?.group_desc || item?.group_name }}
        </div>
        <div *ngFor="let device of item?.device_list;index as i2;">
          <div
            class="d-flex justify-content-between align-items-center bg-gray-650 p-2 font-4 border-bottom border-dark">
            <div class="d-flex align-items-center">
              <i tooltip="ออนไลน์" *ngIf="connectionStatusEnum.Online === device?.connection_status" placement="top"
                class="fa fa-circle text-success"></i>
              <i tooltip="ออฟไลน์" *ngIf="connectionStatusEnum.Offline === device?.connection_status" placement="top"
                class="fa fa-circle text-danger"></i>
              <i tooltip="แจ้งซ้อม" *ngIf="statusEnum.Ma === device?.connection_status" placement="top"
                class="fa fa-circle text-warning"></i>
              <i tooltip="อุณหภูมิสูงเกินกำหนด" *ngIf="9 === device?.connection_status" placement="top"
                class="fa fa-circle text-warning"></i>
              <span class="ml-2">{{device?.device_sub_type_name}}</span>
            </div>
            <div>
              <i *ngIf="device?.device_group_id==1012||device?.device_group_id==1027" class="fa fa-info info-BT" (click)="openInfoDevice(device)"></i>
              <i class="text-primary pointer" (click)="collapseDevice(device?.device_id)"
                [ngClass]="device?.isCollapsed ? 'fa fa-chevron-down' : 'fa fa-chevron-up'"></i>
            </div>
          </div>
          <div [collapse]="device?.isCollapsed" [isAnimated]="true">
            <div class="noselect bg-gray-700 p-2 font-4 border-bottom border-dark text-white">
              <div>Name : {{device?.device_name}}</div>
              <div>Type : {{device?.device_type_name}}</div>
              <div>Last Checked : {{device?.last_checked | date:'dd/MM/yyyy HH:mm:ss'}}</div>
              <div>Last Updated : {{(device?.connection_datetime | date:'dd/MM/yyyy HH:mm:ss') || '-'}}</div>
              <div>ปีที่ติดตั้ง :
                {{(device?.install_ts | date:'yyyy')? ((1*(device?.install_ts | date:'yyyy'))+543):'-'}}</div>
                <button *ngIf="[1012,1014,1015,1016,1027,1028].indexOf(device?.device_group_id) > -1" class="btn btn-primary w-100 mt-2 text-white" (click)="showInfo(device)">แสดงข้อมูล</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex p-2 bg-black pointer" (click)="toggleBoxLeft()"
    [ngClass]="toggle_info ? 'toggle-box-left-panel' : 'toggle-box-left-panel-hide'">
    <div class="text-primary" [ngClass]="toggle_info?'fa fa-arrow-left':'fa fa-arrow-right'">
    </div>
  </div>
</div>
