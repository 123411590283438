import { Component, OnInit } from '@angular/core';
import { AddEventComponent } from './add-event/add-event.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { EventService } from 'src/app/services/user/event.service';
import { OrderComponent } from './order/order.component';
import { FuncService } from 'src/app/services/user/func.service';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {
  modalRef: BsModalRef;

  _showAddEventBT: boolean = false
  sType_list: any = []
  _isEdit: boolean = false
  _status1: boolean = false
  configSetting: any = [0,0,0,0,0,0]
  constructor(
    private modalService: BsModalService,
    private router: Router,
    private eS: EventService,
    private func :FuncService,
  ) { }

  ngOnInit(): void {
    this.getData()
    this.Setting()
  }
  async getData() {
    let res: any = await this.eS.getEvtSubType();
    if (res.length) this.sType_list = res
  }
  async Setting(){
    let configSetting: any = await this.eS.getEventConfigSetting();
    this.configSetting=[]
    if(configSetting.length){
      for (const i of configSetting) {
        this.configSetting.push(i.status)
      }
    }
  }
  async chgSwitch(id,evt){
    console.log(evt)
    this.func.alertLoading()
    let res : any = await this.eS.updateEventConfigSetting({status:evt? 1:0,id:id})
    this.Setting()
    if(res) this.func.alertSuccess()
    if(!res) this.func.alertError('Oops!! Sorry something wrong,Please try again.')
    setTimeout(() => {
      this.func.alertClose()
    }, 1000);
  }
  async rm(id: number) {
    let res: any = await this.eS.rmEvtSubType(id);
    if (res) this.getData()
  }
  openModal(data?: any) {
    const initialState = {
      values: data,
    };

    this.modalRef = this.modalService.show(AddEventComponent, { initialState, class: 'w-80', backdrop: 'static', id: 1 });
    this.modalRef.content.event.subscribe(data => {
      if (data) this.getData();
    });
  }
  openRainOrder(data?: any) {
    const initialState = {
      values: data,
    };

    this.modalRef = this.modalService.show(OrderComponent, { initialState, class: 'w-80', backdrop: 'static', id: 1 });
    this.modalRef.content.event.subscribe(data => {
      if (data) this.getData();
    });
  }
  gotoRainConfig() {
    // this.router.navigate(['rain-config']);
    this.router.navigateByUrl('event/rain-config');
  }
  gotoAverageSpeedConfig() {
    this.router.navigateByUrl('event/speed-direction-config');
  } 
  gotoTrafficVolumeConfig() {
    this.router.navigateByUrl('event/flow-detection-config');
  }
  gotoOccupanyConfig() {
    this.router.navigateByUrl('event/occupany-detection-config');
  }
  gotoTravelTimeConfig() {
    this.router.navigateByUrl('event/traveltime-detection-config');
  } 
  gotoCarSlowedDownConfig() {
    this.router.navigateByUrl('event/queueing-detection-config');
  }
}
