<div class="modal-header">
    <h4 class="config-left text-Color">เพิ่มช่วงสายทาง</h4>
    <button type="button" class="close pull-right x-BT" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body rainConfig-BG">
    <div class="pos-BG config-left">
        <p>โปรดระบุช่วงสายทางที่ต้องการเพิ่มข้อมูล</p>
    </div>
        <div>
            <div class="input-group">
                <div class="input-group-prepend config-right config-left">
                    หมายเลขสายทาง
                </div>
                <div class="col-lg-8 col-md-4 col-sm-6">
                    <kendo-combobox class="w-100" [data]="data_route" [(ngModel)]="route" [allowCustom]="allowCustom"
                        [textField]="" [valueField]="" [value]="" (valueChange)="handleValueRoute($event)"
                        [placeholder]="'ทั้งหมด'" [filterable]="true" >
                    </kendo-combobox>
                </div>
            </div>
            <div class="input-group" style="margin-top: 10px;">
                <div class="input-group-prepend config-right-big config-left">
                    กิโลเมตรที่
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6">
                    <input autocomplete="nope" type="text" placeholder="" class="k-textbox form-control w-100"
                        [(ngModel)]="st_km" numbersOnly />
                </div>
                <div class="input-group-prepend config-left config-right">
                    ถึง
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6">
                    <input autocomplete="nope" type="text" placeholder="" class="k-textbox form-control w-100"
                        [(ngModel)]="en_km" numbersOnly />
                </div>
            </div>
        </div>
</div>
<div class="modal-footer">
    <button *ngIf="!values?.id" class="btn btn-primary save-BT" (click)="save()">บันทึก</button>
</div>