<div class="main-BG container-fluid p-0">
    <!-- <p>dashboard works!</p> -->
    <div *ngIf="_showMenu==0" class="menu-BG">
        <h5>Event Dashboard</h5>
        <p>กรุณาเลือกเมนูด้านล่าง</p>
        <div class="menu-BT">
            <button class="btn btn-outline-warning" (click)="_showMenu=1;">
                <img src="./assets/event/pins/Dashboard1-BT.png" alt="">
                อันดับสายทางที่เกิดอุบัติเหตุ
            </button>
        </div>
    </div>
    <button *ngIf="_showMenu!=0" class="btn btn-default back-BT" (click)="_showMenu=0;">
        <img src="./assets/event/pins/back-BT.png" alt="">
    </button>
    <app-rank *ngIf="_showMenu==1"></app-rank>
</div>