import { Component, OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EventService } from 'src/app/services/user/event.service';
import { BoardsignComponent } from '../../sign-control/boardsign/boardsign.component';
import { CctvDetailComponent } from '../cctv-detail/cctv-detail.component';

@Component({
  selector: 'app-manage-popup',
  templateUrl: './manage-popup.component.html',
  styleUrls: ['./manage-popup.component.scss']
})
export class ManagePopupComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();
  values: any;
  mT:number
  headName :string = ''
  modalRef: BsModalRef;
  radius : number
  group_id : any = []
  route_list : any = []
  st_road : any = ''
  st_km : any
  st_m : any
  st_direction : any = ''
  en_road : any = ''
  en_km : any
  en_m : any
  en_direction : any = ''

  effect_type_id : any = ''
  occur_datetime : any
  effect_loc_type : any = ''
  lane : number
  effType_list : any = []
  mainline : any 
  frontage : any 
  depth : number = 0 
  constructor(
    public bsModalRef: BsModalRef,
    private router: Router,
    private modalService: BsModalService,
    private eS : EventService,
  ) { }

  ngOnInit(): void {
    console.log(this.values)
    this.getEvtEffType()
    this.getRoute()
    this.mT = this.values.menuType
    if(this.mT==1) this.headName = 'ค้นหาตำแหน่งที่ต้องการให้ข้อมูล'
    if(this.mT==2) this.headName = 'CCTV'
    if(this.mT==3) this.headName = 'ผลกระทบจากเหตุการณ์'
    if(this.values.occur_datetime){
      this.occur_datetime = new Date(this.values.occur_datetime)
      this.effect_loc_type = this.values.location_type
      this.st_road = (this.values.st_road+'').padStart(4, '0')
      this.st_km = this.values.st_km
      this.st_m = this.values.st_m
      this.st_direction = this.values.st_direction
      if(this.values.location_type==2){
        this.en_road = (this.values.en_road+'').padStart(4, '0')
        this.en_km = this.values.en_km
        this.en_m = this.values.en_m
        this.en_direction = this.values.en_direction
      }
    }
  }
  async getRoute(){
    let res_route :any = await this.eS.getRoute()
    if(res_route.length) this.route_list = res_route 
  }
  async getEvtEffType(){
    let res : any = await this.eS.getEvtEffType();
    if(res.length) this.effType_list = res
  }
  gotoSign(){
    this.router.navigate([`sign-control/sign`, {test:123}]);
  }
  close() {
    this.bsModalRef.hide();
  }

  confirm() {
    this.event.emit(true);
    this.bsModalRef.hide();
  }
  cancel() {
    this.event.emit(false);
    this.bsModalRef.hide();
  }
  async sendEffectLog(){
    let data : any = {event_id:this.values.id}
    if(this.effect_loc_type) data.effect_loc_type = Number(this.effect_loc_type)
    if(this.effect_type_id) data.effect_type_id = Number(this.effect_type_id)
    if(this.occur_datetime) data.occur_datetime = this.occur_datetime
    if(this.mainline) data.mainline = this.mainline.toString()
    if(this.frontage) data.frontage = this.frontage.toString()
    if(this.effect_type_id==3) data.depth = this.depth
    if(this.effect_loc_type>0){
      data.st_route = Number(this.st_road)
      data.st_km = this.st_km||0
      data.st_m = this.st_m||0
      data.st_direction = Number(this.st_direction)
    }
    if(this.effect_loc_type==2){
      data.en_route = Number(this.en_road)
      data.en_km = this.en_km||0
      data.en_m = this.en_m||0
      data.en_direction = Number(this.en_direction)
    }
    let res : any = await this.eS.addeEvtEffType(data)
    if(res) this.confirm()
  }
  save(){
    this.openSignModal({
      event_id : this.values.id,
      route:this.values.st_road,
      st_km:this.values.st_km,
      raduis:this.radius,
      group_id:[]
    })
  }
  
  chkNumberm(evt?:any){
    let result = Number(evt)
    if(result>1000) result=999
    if(result<0) result=0
    return result
  }
  openCCTVDetailModel(data:any){
    console.log(data,this.values)
    data.route = this.values.st_road
    data.km = this.values.st_km
    const initialState = {
      values: data,
    };

    this.modalRef = this.modalService.show(CctvDetailComponent, { initialState, class: 'CCTV-BG',backdrop: 'static',id:5 });
    this.modalRef.content.event.subscribe(data => {
      sessionStorage.removeItem('event_id')
      // if (data) this.getEventMap();
    });

  }
  chgSignType(evt:any){
  }
  openSignModal(data?:any){
    if(this.group_id[0]) data.group_id.push(1013)
    if(this.group_id[1]) data.group_id.push(1014)
    if(this.group_id[2]) data.group_id.push(1012)
    if(this.group_id[3]) data.group_id.push(1015)
    const initialState = {
      values_fromEvt: data,
    };

    this.modalRef = this.modalService.show(BoardsignComponent, { initialState, class: 'markerD-BG w-80',backdrop: 'static',id:5 });
    this.modalRef.content.event.subscribe(data => {
      console.log(data)
      sessionStorage.removeItem('event_id')
      // if (data) this.getEventMap();
    });
  }
}
