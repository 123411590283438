import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class PeopleService {
  constructor(
    private api: ApiService,
  ) { }
  // async login(req?:any){
  //   return new Promise((reslove,reject)=>{
  //     this.api.login(req).subscribe((res:any)=>{

  //       if(res.code == 1){
  //         return reslove(res.data);
  //       }else{
  //         return reslove(false);
  //       }
  //     })
  //   })
  // }

  async getUsers(req?: any) {
    return new Promise((reslove, reject) => {
      this.api.getUsers(req).subscribe((res: any) => {
        if (res.code == 1) {
          return reslove(res.data);
        } else {
          return reslove(false);
        }
      })
    })
  }
  async addUser(req?: any) {
    return new Promise((reslove, reject) => {
      this.api.addUser(req).subscribe((res: any) => {
        if (res.code == 1) {
          return reslove(res.data);
        } else {
          return reslove(false);
        }
      })
    })
  }
  async rmUser(req?: any) {
    return new Promise((reslove, reject) => {
      this.api.rmUser(req).subscribe((res: any) => {
        if (res.code == 1) {
          return reslove(res.data);
        } else {
          return reslove(false);
        }
      })
    })
  }

  async updateUser(req?: any) {
    return new Promise((reslove, reject) => {
      this.api.updateUser(req).subscribe((res: any) => {
        if (res.code == 1) {
          return reslove(res.data);
        } else {
          return reslove(false);
        }
      })
    })
  }

}
