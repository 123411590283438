import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { FuncService } from 'src/app/services/user/func.service';
import { SmartmicroModalComponent } from './smartmicro-modal/smartmicro-modal.component';
import * as mapboxgl from 'mapbox-gl';
import { environment } from 'src/environments/environment';
import { DeviceInfo } from 'src/app/models/assets-management/device';

import {
  StatusEnum,
  ConnectionStatusEnum,
  ActionEnum,
} from 'src/app/models/common-enum';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import * as _ from 'lodash';
import { MonitorStation } from 'src/app/models/assets-management/monitor';
import { MonitorService } from 'src/app/services/assets-management/monitor.service';
import { CommonService } from 'src/app/services/common.service';
import { Station } from 'src/app/models/assets-management/station';
import { OwnerService } from 'src/app/services/assets-management/owner.service';
import { StationService } from 'src/app/services/assets-management/station.service';
// import { Owner } from 'src/app/models/assets-management/owner';
// import { Vendor, VendorContact } from 'src/app/models/assets-management/vendor';
// import { VendorService } from 'src/app/services/assets-management/vendor.service';
// import { DeviceGroup } from 'src/app/models/assets-management/device-group';
import { DeviceGroupService } from 'src/app/services/assets-management/device-group.service';
import { DeviceService } from 'src/app/services/user/device.service';
import { permission } from 'src/app/views/assets-management/_menu';
@Component({
  selector: 'app-smartmicro',
  templateUrl: './smartmicro.component.html',
  styleUrls: ['./smartmicro.component.scss'],
})
export class SmartmicroComponent implements OnInit {
  allowCustom: boolean = false;
  model: any;
  model_list: any = [
    { id: 20, name: 4 },
    { id: 21, name: 8 },
  ];
  modalRef: BsModalRef;
  ins_list: any;
  users_list: any = [];
  total: number = 0;
  currentPage: number = 1;
  itemsPerPage: number = 15;
  name: string = '';
  hashtag: string = '';
  ins_id: number;
  ins_name: string;
  isAdd: boolean = false;
  isEdit: boolean = false;
  search_km_st: number;
  search_m_st: number;
  search_km_en: number;
  search_m_en: number;
  map: mapboxgl.Map;
  viewType = 1; //1:list,2:map
  totalItems: any = 0;
  data_list: any = [];
  is_loading: boolean = false;

  data_station_modal: Station[] = [];
  data_station_search: Station[] = [];
  station_search_list: Station[] = [];
  search_status: any;
  search_station: any;
  search_owner: any;
  search_vendor: any;
  search_device_group: any;
  search_device_type: any;
  search_route: string;
  public data_route: Array<string>;
  route_list: string[];
  status_list = [
    { id: StatusEnum.Active, name: 'Active' },
    { id: StatusEnum.Inactive, name: 'Inactive' },
    { id: StatusEnum.Ma, name: 'MA' },
  ];
  steaming_type_list = [
    { id: 1, name: 'RTSP' },
    { id: 2, name: 'FLV' },
  ];

  // owner_modal: Owner;
  // data_owner_modal: Owner[] = [];
  // data_owner: Owner[] = [];
  // owner_list: Owner[] = [];
  // vendor_modal: Vendor;
  // vendor_contact_modal: VendorContact;
  // data_vendor_modal: Vendor[] = [];
  // data_vendor_contact_modal: VendorContact[] = [];
  // data_vendor: Vendor[] = [];
  // vendor_list: Vendor[] = [];
  // vendor_contact_list: VendorContact[] = [];

  // data_device_group: DeviceGroup[] = [];
  // device_group_list: DeviceGroup[] = [];
  // device_group_modal: DeviceGroup;
  // data_device_group_modal: DeviceGroup[] = [];
  connectionStatusEnum = ConnectionStatusEnum;

  _subMkShow: boolean = null;
  latlng: any[];
  constructor(
    private func: FuncService,
    private devS: DeviceService,
    private modalService: BsModalService,
    // private monitorService: MonitorService,
    private commonService: CommonService,
    private stationService: StationService // private ownerService: OwnerService, // private vendorService: VendorService,
  ) // private deviceGroupService: DeviceGroupService,
  {
    mapboxgl.accessToken = environment.mapbox.accessToken;
    this.commonService
      .activityLog(ActionEnum.Get, `Assets Management ➡ Setting ➡ Smart Micro`)
      .subscribe(
        (res) => {},
        (error) => {
          console.log(error);
        }
      );
  }

  async ngOnInit() {
    this.isAdd = await this.commonService
      .getPermission(permission.setting.smartmicro.add_id)
      .toPromise();
    this.isEdit = await this.commonService
      .getPermission(permission.setting.smartmicro.edit_id)
      .toPromise();
    this.getRoute();
    // await this.getDevice()
    this.getStationSearch();
    this.getData();
    // this.getOwner();
    // this.getVendor();
    // this.getDeviceGroup();
    // if(!this.map_station) this.initStationMap();
  }
  async getData() {
    this.is_loading = true;
    this.data_list = await this.devS.getDeviceSmc();
    this.totalItems = this.data_list.length;
    this.latlng = [];
    for (const i of this.data_list) {
      if (i.longitude && i.latitude)
        this.latlng.push({
          coor: [i.longitude, i.latitude],
          prop: i,
        });
    }
    this.buildMap();
    this.is_loading = false;
  }
  openModal(data?: any) {
    // data.device_id = 1691
    const initialState = {
      values: data,
      ins_list: this.ins_list,
    };
    this.modalRef = this.modalService.show(SmartmicroModalComponent, {
      initialState,
      class: 'w-80',
    });
    this.modalRef.content.event.subscribe((data) => {
      // if (data) this.getData()
    });
  }
  onSelect(evt) {
    console.log(evt);
  }
  pageChanged(event: any): void {
    // if (this.currentPage != event.page) {
    //   this.currentPage = event.page;
    //   this.getDevice();
    // }
  }
  async search() {
    this.is_loading = true;
    this.data_list = [];
    this.totalItems = 0
    let data: any = {};
    if (this.search_route) data.route = this.search_route;
    if (this.search_km_st) data.st_km = this.search_km_st;
    if (this.search_m_st) data.st_m = this.search_m_st;
    if (this.search_km_en) data.en_km = this.search_km_en;
    if (this.search_m_en) data.en_m = this.search_m_en;
    if (this.model) data.model = this.model.id;
    if (this.search_station) data.station_id = this.search_station.id;
    this.data_list = await this.devS.getDeviceSmc(data);
    if (this.data_list.length) {
      //   this.no_data = false
      this.totalItems = this.data_list.length;
      this.latlng = [];
      for (const i of this.data_list) {
        if (i.longitude && i.latitude)
          this.latlng.push({
            coor: [i.longitude, i.latitude],
            prop: i,
          });
      }

      this.updateSourceMap(this.latlng);
      this.map.setLayoutProperty('clusters', 'visibility', 'visible');
      this.map.setLayoutProperty('cluster-count', 'visibility', 'visible');
      this.map.setLayoutProperty('device', 'visibility', 'visible');
    } else {
      //   this.no_data = true

      this.map.setLayoutProperty('clusters', 'visibility', 'none');
      this.map.setLayoutProperty('cluster-count', 'visibility', 'none');
      this.map.setLayoutProperty('device', 'visibility', 'none');
    }
    this.is_loading = false;
  }
  rmlist(e) {}

  changeView(id) {
    if (id == 2) {
      if (!this.map) {
        this.buildMap();
      } else {
        setTimeout(() => {
          this.map.resize();
        }, 100);
      }
    }
  }

  getRoute() {
    this.commonService.getRoute().subscribe(
      (res) => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.route_list = _.cloneDeep(res.data);
              this.data_route = _.cloneDeep(res.data);
            }
          }
        }
        // this.is_loading = false;
      },
      (error) => {
        // this.is_loading = false;
      }
    );
  }

  handleValueStatus(value) {
    if (value) {
      this.search_status = value;
    } else {
      this.search_status = null;
    }
  }
  handleFilterRoute(value) {
    if (value.length > 0) {
      this.data_route = this.route_list.filter(
        (s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    } else if (value.length == 0) {
      this.data_route = _.cloneDeep(this.route_list);
    } else {
      this.data_route = [];
    }
  }
  handleValueRoute(value) {
    if (value) {
      this.search_route = value;
    } else {
      this.search_route = null;
    }
  }
  getStationSearch() {
    this.stationService.getStation(null, null, 1).subscribe(
      (res) => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.station_search_list = _.cloneDeep(res.data.data);
              this.data_station_search = _.cloneDeep(res.data.data);
              this.data_station_modal = _.cloneDeep(res.data.data);
            }
          }
        }
        // this.is_loading = false;
      },
      (error) => {
        // this.is_loading = false;
      }
    );
  }
  handleFilterStation(value) {
    if (value.length > 0) {
      this.data_station_search = this.station_search_list
        .filter((s) => s.name != null)
        .filter(
          (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
        );
    } else if (value.length == 0) {
      this.data_station_search = _.cloneDeep(this.station_search_list);
    } else {
      this.data_station_search = [];
    }
  }
  handleValueStation(value) {
    if (value) {
      this.search_station = value;
    } else {
      this.search_station = null;
    }
  }
  // getOwner() {
  //     this.ownerService.getOwner(null, null, 1).subscribe(
  //         res => {
  //             if (res) {
  //                 if (res.code == 1) {
  //                     if (res.data) {
  //                         this.owner_list = _.cloneDeep(res.data.data);
  //                         this.data_owner = _.cloneDeep(res.data.data);
  //                         this.data_owner_modal = _.cloneDeep(res.data.data);
  //                     }
  //                 }
  //             }
  //             // this.is_loading = false;
  //         },
  //         error => {
  //             // this.is_loading = false;
  //         });
  // }
  // handleFilterOwner(value) {
  //     if (value.length > 0) {
  //         this.data_owner = this.data_owner.filter((s) => s.company_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  //     } else if (value.length == 0) {
  //         this.data_owner = _.cloneDeep(this.owner_list);
  //     } else {
  //         this.data_owner = [];
  //     }
  // }
  // handleValueOwner(value) {
  //     if (value) {
  //         this.search_owner = value;
  //     } else {
  //         this.search_owner = null;
  //     }
  // }
  // getVendor() {
  //     this.vendorService.getVendor(null, null, 1).subscribe(
  //         res => {
  //             if (res) {
  //                 if (res.code == 1) {
  //                     if (res.data) {
  //                         this.vendor_list = _.cloneDeep(res.data);
  //                         this.data_vendor = _.cloneDeep(res.data);
  //                         this.data_vendor_modal = _.cloneDeep(res.data);

  //                     }
  //                 }
  //             }
  //             // this.is_loading = false;
  //         },
  //         error => {
  //             // this.is_loading = false;
  //         });
  // }
  // getVendorContact(vendor_id) {
  //     this.vendorService.getVendorContact(null, null, 1, vendor_id).subscribe(
  //         res => {
  //             if (res) {
  //                 if (res.code == 1) {
  //                     if (res.data) {
  //                         this.vendor_contact_list = _.cloneDeep(res.data.data);
  //                         this.data_vendor_contact_modal = _.cloneDeep(res.data.data);

  //                     }
  //                 }
  //             }
  //             // this.is_loading = false;
  //         },
  //         error => {
  //             // this.is_loading = false;
  //         });
  // }
  // handleFilterVendor(value) {
  //     if (value.length > 0) {
  //         this.data_vendor = this.data_vendor.filter((s) => s.company_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  //     } else if (value.length == 0) {
  //         this.data_vendor = _.cloneDeep(this.vendor_list);
  //     } else {
  //         this.data_vendor = [];
  //     }
  // }
  // handleValueVendor(value) {
  //     if (value) {
  //         this.search_vendor = value;
  //     } else {
  //         this.search_vendor = null;
  //     }
  // }
  // getDeviceGroup() {
  //     this.deviceGroupService.getDeviceGroup(null, null, null, null, 1).subscribe(
  //         res => {
  //             if (res) {
  //                 if (res.code == 1) {
  //                     if (res.data) {
  //                         this.device_group_list = _.cloneDeep(res.data.data);
  //                         this.data_device_group = _.cloneDeep(res.data.data);
  //                         this.data_device_group_modal = _.cloneDeep(res.data.data);
  //                     }
  //                 }
  //             }
  //             // this.is_loading = false;
  //         },
  //         error => {
  //             // this.is_loading = false;
  //         });
  // }
  // handleFilterDeviceGroup(value) {
  //     if (value.length > 0) {
  //         this.data_device_group = this.data_device_group.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  //     } else if (value.length == 0) {
  //         this.data_device_group = _.cloneDeep(this.device_group_list);
  //     } else {
  //         this.data_device_group = [];
  //     }
  // }
  // handleValueDeviceGroup(value) {
  //     if (value) {
  //         this.search_device_group = value;
  //     } else {
  //         this.search_device_group = null;
  //     }
  // }

  //Map==================

  initMap() {}
  buildMap() {
    try {
      this.map = new mapboxgl.Map({
        container: 'map_sm',
        style: environment.mapbox.style,
        zoom: 10,
        center: [100.5976347, 14.1682688],
      });

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        anchor: 'bottom',
        offset: [0, -45],
      });
      this.map.on('load', (event) => {
        setTimeout(() => {
          console.log(1);
          this.is_loading = false;
          this.map.resize();
        }, 100);

        this.addSourceMap(this.latlng);
        this.addMapLayer();
      });
      // this.map.on('click', (event) => {
      //   // this.addSourceShopMap();
      //   // this.addMapLayer();
      // })
      this.map.on(
        'click',
        'clusters',
        function (e) {
          var features = this.map.queryRenderedFeatures(e.point, {
            layers: ['clusters'],
          });
          var clusterId = features[0].properties.cluster_id;
          this.map.getSource('device-map').getClusterExpansionZoom(
            clusterId,
            function (err, zoom) {
              if (err) return;
              this.map.easeTo({
                center: features[0].geometry.coordinates,
                zoom: zoom,
              });
            }.bind(this)
          );

          this.clickItem({
            lng: features[0].geometry.coordinates[0],
            lat: features[0].geometry.coordinates[1],
          });
        }.bind(this)
      );
      this.map.on('click', 'device', (event) => {
        let prop = event.features[0].properties;

        let chk_latlng = _.filter(this.latlng, {
          coor: [prop.longitude, prop.latitude],
        });
        console.log(chk_latlng);
        if (chk_latlng.length > 1) {
          const chk: any = [];
          let num: number = 1;
          let helf: number = 0;
          let type: number = 1;
          if (chk_latlng.length % 2 == 0) {
            type = 2;
            helf = chk_latlng.length / 2;
          } else {
            type = 1;
            helf = (chk_latlng.length - 1) / 2;
          }
          for (const i of chk_latlng) {
            let mi: number = 1;
            if (num <= helf) mi = -2;
            if (type == 1 && num == chk_latlng.length) {
              mi = 0;
            }
            chk.push({
              coor: [i.coor[0] + 0.001 * num * mi, i.coor[1] + 0.001],
              prop: i.prop,
            });
            num++;
          }
          if (this._subMkShow === null) {
            this.addSubMkSourceMap(chk);
            this.addSubMkLayer();
          } else {
            this._subMkShow = !this._subMkShow;
            this.updateSubMkSourceMap(chk);
            this.map.setLayoutProperty(
              's_mks',
              'visibility',
              `${this._subMkShow ? 'visible' : 'none'}`
            );
          }
        } else {
          this.openModal(prop);
        }
      });
      this.map.on('mouseenter', 'clusters', (event) => {
        this.map.getCanvas().style.cursor = 'pointer';
      });
      this.map.on('mouseleave', 'clusters', (event) => {
        this.map.getCanvas().style.cursor = '';
      });
      this.map.on('mouseenter', 'device', (event) => {
        this.map.getCanvas().style.cursor = 'pointer';
        let prop = event.features[0].properties;
        let chk = _.filter(this.latlng, {
          coor: [prop.longitude, prop.latitude],
        });
        let htmlTxt = '';
        if (chk.length > 1) {
          for (const i of chk) {
            htmlTxt += `<div class="nowrap">
              ${prop.name} ${prop.station_str ? prop.station_str : ''}</div>`;
          }
        } else {
          htmlTxt = `<div class="nowrap">
            ${prop.name} ${prop.station_str ? prop.station_str : ''}</div>`;
        }
        popup
          .setLngLat(event.features[0].geometry.coordinates)
          .setHTML(this.getAddIncLayerHtml(htmlTxt))
          .addTo(this.map);
      });
      this.map.on('mouseleave', 'device', (event) => {
        this.map.getCanvas().style.cursor = '';
        popup.remove();
      });
      // ==============
      this.map.on('click', 's_device', (event) => {
        let prop = event.features[0].properties;
        this.openModal(prop);
      });
      this.map.on('mouseenter', 's_device', (event) => {
        this.map.getCanvas().style.cursor = 'pointer';
        let prop = event.features[0].properties;
        let htmlTxt = '';
        htmlTxt = `<div>
          ${prop.reversible_name} ${
          prop.km ? '(' + prop.km + '+' + prop.m + ')' : ''
        }</div>`;
        popup
          .setLngLat(event.features[0].geometry.coordinates)
          .setHTML(this.getAddIncLayerHtml(htmlTxt))
          .addTo(this.map);
      });
      this.map.on('mouseleave', 's_device', (event) => {
        this.map.getCanvas().style.cursor = '';
        popup.remove();
      });
    } catch (err) {
      console.log('error from mapbox' + err);
    }
  }
  addMapLayer() {
    this.map.addLayer({
      id: 'clusters',
      type: 'circle',
      source: 'device-map',
      filter: ['has', 'point_count'],
      paint: {
        // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
        // with three steps to implement three types of circles:
        //   * Blue, 20px circles when point count is less than 100
        //   * Yellow, 30px circles when point count is between 100 and 750
        //   * Pink, 40px circles when point count is greater than or equal to 750
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#51bbd6',
          100,
          '#f1f075',
          750,
          '#f28cb1',
        ],
        'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
      },
    });
    // ------------------
    this.map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'device-map',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-size': 12,
      },
    });
    // -------------------
    // this.map.addLayer({
    //   id: 'device',
    //   type: 'circle',
    //   source: 'device-map',
    //   filter: ['!', ['has', 'point_count']],
    //   paint: {
    //   'circle-color': '#11b4da',
    //   'circle-radius': 4,
    //   'circle-stroke-width': 1,
    //   'circle-stroke-color': '#fff'
    //   }
    //   });
    // -------------------
    this.map.loadImage(
      './assets/marker.png',
      function (error, image) {
        if (error) console.error(error);
        this.map.addImage('device', image);
        this.map.addLayer({
          id: 'device',
          type: 'symbol',
          source: 'device-map',
          filter: ['!', ['has', 'point_count']],
          // filter: ["has", "point_count"],
          layout: {
            'icon-image': 'device',
            'icon-size': 0.08,
            'icon-allow-overlap': false,
            //"icon-rotate": 70,
            'icon-rotate': {
              type: 'identity',
              property: 'heading',
              default: 0,
            },
          },
        });
      }.bind(this)
    );
  }

  addSourceMap(data: any) {
    if (data.length < 1) return false;
    let data_feature: any = {
      type: 'FeatureCollection',
      features: [],
    };
    let allMk: any[] = [];
    for (const i of data) {
      allMk.push(i.coor);
      data_feature.features.push({
        type: 'Feature',
        properties: i.prop,
        geometry: {
          type: 'Point',
          coordinates: i.coor,
        },
      });
    }
    this.map.addSource('device-map', {
      type: 'geojson',
      data: data_feature,
      cluster: true,
      clusterMaxZoom: 11, // Max zoom to cluster points on
      clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
    });
    this.flyAllMarker(allMk);
  }
  updateSourceMap(data) {
    if (data.length < 1) return false;
    let data_feature: any = {
      type: 'FeatureCollection',
      features: [],
    };
    let allMk: any[] = [];
    for (const i of data) {
      allMk.push(i.coor);
      data_feature.features.push({
        type: 'Feature',
        properties: i.prop,
        geometry: {
          type: 'Point',
          coordinates: i.coor,
        },
      });
    }

    this.map.getSource('device-map').setData(data_feature);
    this.flyAllMarker(allMk);
  }
  addSubMkLayer() {
    this.map.loadImage(
      './assets/submarker.png',
      function (error, image) {
        if (error) console.error(error);
        this.map.addImage('s_device', image);
        this.map.addLayer({
          id: 's_device',
          type: 'symbol',
          source: 's_device-map',
          filter: ['!', ['has', 'point_count']],
          // filter: ["has", "point_count"],
          layout: {
            'icon-image': 's_device',
            'icon-size': 0.08,
            'icon-allow-overlap': false,
            //"icon-rotate": 70,
            'icon-rotate': {
              type: 'identity',
              property: 'heading',
              default: 0,
            },
          },
        });
      }.bind(this)
    );
  }
  addSubMkSourceMap(data: any) {
    this._subMkShow = true;
    console.log(data);
    if (data.length < 1) return false;
    let data_feature: any = {
      type: 'FeatureCollection',
      features: [],
    };
    let allMk: any[] = [];
    for (const i of data) {
      allMk.push(i.coor);
      data_feature.features.push({
        type: 'Feature',
        properties: i.prop,
        geometry: {
          type: 'Point',
          coordinates: i.coor,
        },
      });
    }
    this.map.addSource('s_device-map', {
      type: 'geojson',
      data: data_feature,
      cluster: true,
      clusterMaxZoom: 11, // Max zoom to cluster points on
      clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
    });
    // this.flyAllSubMarker(allMk)
  }
  updateSubMkSourceMap(data) {
    if (data.length < 1) return false;
    let data_feature: any = {
      type: 'FeatureCollection',
      features: [],
    };
    let allMk: any[] = [];
    for (const i of data) {
      allMk.push(i.coor);
      data_feature.features.push({
        type: 'Feature',
        properties: i.prop,
        geometry: {
          type: 'Point',
          coordinates: i.coor,
        },
      });
    }

    this.map.getSource('s_device-map').setData(data_feature);
    // this.flyAllSubMarker(allMk)
  }
  getAddIncLayerHtml(txt: any): any {
    let htmltext = `
      <div style="padding: 10px 10px 10px;">
        <div style="max-width: 50vw;">${txt}</div>
      </div>
    `;
    return htmltext;
  }
  clickItem(item) {
    this.map.flyTo({ center: [item.lng, item.lat], zoom: 15 });
  }
  flyAllMarker(val: any) {
    let minLngLat: any = val[0];
    let maxLngLat: any = val[0];
    for (const i of val) {
      // lng
      if (i[0] < minLngLat[0]) minLngLat[0] = i[0];
      if (i[0] > maxLngLat[0]) maxLngLat[0] = i[0];
      // lat
      if (i[1] < minLngLat[1]) minLngLat[1] = i[1];
      if (i[1] > maxLngLat[1]) maxLngLat[1] = i[1];
    }
    this.map.fitBounds(
      [
        [minLngLat[0] - 0.5, minLngLat[1] - 0.5],
        [maxLngLat[0] + 0.5, maxLngLat[1] + 0.5],
      ],
      {
        padding: {
          top: 100,
          bottom: 25,
          left: 15,
          right: 5,
        },
      }
    );
  }
  // ==========================================
}
