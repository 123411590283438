<div class="card m-3">
    <div class="card-header table-header">
        <span *ngIf="selectedId" class="pointer mr-3" (click)="backClicked()">
            <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </span>
        รายงานข้อมูลรายคันจากอุปกรณ์ ตรวจวัด</div>
    <div class="card-body">
        <div class="row">
            <div class="col-4">
                Station
                <input [typeahead]="staList" [(ngModel)]="sta" typeaheadOptionField="name" [typeaheadScrollable]="true" [typeaheadMinLength]="0"
                [typeaheadOptionsInScrollableView]="7" (typeaheadOnSelect)="onSelect($event)" (focusout)="blur($event)" class="form-control">
            </div>
            <div class="col-4">
                วัน/เวลา เริ่มต้น
                <kendo-datetimepicker [(ngModel)]="st_dt" class="w-100" [format]="'dd-MMM-yyyy HH:mm:ss'"></kendo-datetimepicker>
            </div>
            <div class="col-4">
                วัน/เวลา สิ้นสุด
                <kendo-datetimepicker [(ngModel)]="en_dt" class="w-100" [format]="'dd-MMM-yyyy HH:mm:ss'"></kendo-datetimepicker>
            </div>
            <div class="col-4 mt-3" style="line-height: 2;">
                ช่องจราจร
                <div *ngIf="!selectedId">
                    <input type="checkbox" [(ngModel)]='laneChecked[1]' name="lane" id="l1"><label for="l1" class="ml-1 mr-2 mr-1">1</label>
                    <input type="checkbox" [(ngModel)]='laneChecked[2]' name="lane" id="l2"><label for="l2" class="ml-1 mr-2 mr-1">2</label>
                    <input type="checkbox" [(ngModel)]='laneChecked[3]' name="lane" id="l3"><label for="l3" class="ml-1 mr-2 mr-1">3</label>
                    <input type="checkbox" [(ngModel)]='laneChecked[4]' name="lane" id="l4"><label for="l4" class="ml-1 mr-2 mr-1">4</label>
                    <input type="checkbox" [(ngModel)]='laneChecked[5]' name="lane" id="l5"><label for="l5" class="ml-1 mr-2 mr-1">5</label>
                    <input type="checkbox" [(ngModel)]='laneChecked[6]' name="lane" id="l6"><label for="l6" class="ml-1 mr-2 mr-1">6</label>
                    <input type="checkbox" [(ngModel)]='laneChecked[7]' name="lane" id="l7"><label for="l7" class="ml-1 mr-2 mr-1">7</label>
                    <input type="checkbox" [(ngModel)]='laneChecked[8]' name="lane" id="l8"><label for="l8" class="ml-1 mr-2 mr-1">8</label>
                </div>
                
                <div *ngIf="selectedId" class="d-flex align-items-baseline">
                    <p class="m-0"style="width: 100px;">IN</p> 
                    <label *ngFor="let i of nums_lane" class="ml-1 mr-2 mr-1">
                    <input type="checkbox" [(ngModel)]='laneChecked_IN[(i+1)]' name="lane" [id]="l_in+(i+1)">
                    {{i+1}}
                    </label>
                </div>
                <div *ngIf="selectedId" class="d-flex align-items-baseline">
                    <p class="m-0"style="width: 100px;">OUT</p>
                    <label *ngFor="let i of nums_lane" class="ml-1 mr-2 mr-1">
                    <input type="checkbox" [(ngModel)]='laneChecked_OUT[(i+1)]' name="lane" [id]="l_out+(i+1)">
                    {{i+1}}
                    </label>
                </div>
            </div>
            
            <div class="col-4 mt-3" style="line-height: 2;">
                ประเภทรถ
                <!-- <div *ngFor="let i of classList; let idx = index;"> -->
                <label *ngFor="let i of classList; let idx = index;" [htmlFor]="i.veh_class_name+i.veh_class_id" class="ml-1 mr-1">
                    <input type="checkbox" [id]="i.veh_class_name+i.veh_class_id"
                    name="classs" [(ngModel)]="i.checked"
                    [value]="i.veh_class_name"
                    (change)="changeClass()">
                    {{i.veh_class_name}}
                </label>
                <!-- </div> -->
            </div>
            <div class="col-4 mt-3 text-right">
                <button class="btn btn-outline-info mr-2">Export CSV</button>
                <button class="btn btn-info" (click)="search()">ค้นหา</button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="chkData === 0" class="row p-4"><div class="loader">Loading...</div></div>
<div *ngIf="chkData" class="m-3 card">
    <div class="card-body">
        <div class="d-flex">
            <div class="w-50 pr-5 txt-BG">
                <p>Min <span>{{stat.min||'-'}}</span></p>
                <p>Max <span>{{stat.max||'-'}}</span></p>
                <p>Mean <span>{{stat.mean||'-'}}</span></p>
                <p>Standard Deviation <span>{{stat.std||'-'}}</span></p>
            </div>
            <div class="w-50 pl-5 txt-BG">
                <p>15th Percentile <span>{{stat.per15||'-'}}</span></p>
                <p>Quartile 1 <span>{{stat.q1||'-'}}</span></p>
                <p>Quartile 3 <span>{{stat.q3||'-'}}</span></p>
                <p>85th Percentile <span>{{stat.per85||'-'}}</span></p>
            </div>
        </div>
        <div class="d-flex">
            <div class="w-50" style="border-right: 10px solid #343437;">
                <kendo-chart>
                    <kendo-chart-title text="Frequercy/Speed(Km/h)"></kendo-chart-title>
                    <kendo-chart-value-axis>
                      <kendo-chart-value-axis-item [title]="{ text: 'Frequercy' }" [min]="feq_min" [max]="feq_max">
                      </kendo-chart-value-axis-item>
                    </kendo-chart-value-axis>
                    <kendo-chart-category-axis>
                      <kendo-chart-category-axis-item [categories]="feqLabelItem" [labels]="{format: 'd', rotation: '-45' }" [title]="{ text: 'Speed(Km/h)' }">
                      </kendo-chart-category-axis-item>
                    </kendo-chart-category-axis>
                    <kendo-chart-tooltip format="{0}"></kendo-chart-tooltip>
                    <kendo-chart-series>
                      <kendo-chart-series-item [data]="feqData">
                      </kendo-chart-series-item>
                    </kendo-chart-series>
                </kendo-chart>
            </div>
            <div class="w-50" style="border-left: 10px solid #343437;">
                <kendo-chart>
                    <kendo-chart-title text="Cumulative(%)/Speed(Km/h)"></kendo-chart-title>
                    <kendo-chart-value-axis>
                      <kendo-chart-value-axis-item [title]="{ text: 'Cumulative(%)' }" [min]="0" [max]="105">
                      </kendo-chart-value-axis-item>
                    </kendo-chart-value-axis>
                    <kendo-chart-category-axis>
                      <kendo-chart-category-axis-item [categories]="cumulLabelItem" [labels]="{format: 'd', rotation: '-45' }" [title]="{ text: 'Speed(Km/h)' }">
                      </kendo-chart-category-axis-item>
                    </kendo-chart-category-axis>
                    <kendo-chart-legend position="bottom" orientation="horizontal"></kendo-chart-legend>
  
                    <kendo-chart-tooltip format="{0}%">
                    </kendo-chart-tooltip>
                    <kendo-chart-series>
                      <kendo-chart-series-item type="line" [data]="cumulData">
                      </kendo-chart-series-item>
                      <!-- <kendo-chart-series-item *ngFor="let item of series"
                          type="line"  [data]="item.data" [name]="item.name">
                      </kendo-chart-series-item> -->
                    </kendo-chart-series>
  
                    <kendo-chart-y-axis>
                      <kendo-chart-y-axis-item [title]="{ text: 'Charge' }" [labels]="{ format: '{0}%' }" [max]="100">
                      </kendo-chart-y-axis-item>
                    </kendo-chart-y-axis>
                </kendo-chart>
            </div>
        </div>
    </div>
</div>