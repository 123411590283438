<div class="animated fadeIn m-4">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header bg-gray-950">
          <!-- /report/sensor/agg/-->
          <div>รายงานรถรวมจากอุปกรณ์ตรวจวัด (Aggregate)</div>
        </div>
        <div class="card-body p-3">
          <div class="row">
            <div class="col-xl-1 col-lg-2 col-md-2 col-sm-3">
              <div class="d-flex flex-wrap align-items-center justify-content-sm-end h-100">
                จุดติดตั้ง</div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-9">
              <kendo-combobox class="form-control" [data]="data_sta" [allowCustom]="allowCustom" [suggest]="true"
                [textField]="'name'" [valueField]="'id'" [(value)]="sta" (filterChange)="handleFilter($event)"
                [filterable]="true" (valueChange)="handleValue($event)" [placeholder]="'ทั้งหมด'">
              </kendo-combobox>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-xl-1 col-lg-2 col-md-2 col-sm-3">
              <div class="d-flex flex-wrap align-items-center justify-content-sm-end h-100">
                ช่วงเวลา</div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-9">
              <kendo-combobox class="form-control" [data]="data_time_range" [allowCustom]="allowCustom" [suggest]="true"
                [textField]="'name'" [valueField]="'value'" [(value)]="time_range" [filterable]="true"
                (valueChange)="handleValueTime($event)" [clearButton]="false">
              </kendo-combobox>
            </div>

            <div class="d-none d-md-block d-lg-none col-md-6">
              <!-- Visible only on md -->
            </div>
            <div class="d-none d-lg-block d-xl-none col-lg-6">
              <!-- Visible only on lg -->
            </div>
            <div class="col-xl-1 col-lg-2 col-md-2 col-sm-3">
              <div class="d-flex flex-wrap align-items-center justify-content-sm-end h-100">
                <div class="nowrap">วัน/เวลา</div>
                <div class="nowrap ml-1">เริ่มต้น</div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-9">
              <kendo-datetimepicker [(ngModel)]="st_dt" class="w-100" [(format)]="date_format" (valueChange)="chgDatetime($event)"
                [formatPlaceholder]="{year: 'ปี', month: 'เดือน', day: 'วัน', hour: 'ชั่วโมง', minute: 'นาที'}"
                [steps]="steps">
                <kendo-datepicker-messages today="วันนี้"></kendo-datepicker-messages>
                <kendo-timepicker-messages now="ตอนนี้"></kendo-timepicker-messages>
              </kendo-datetimepicker>
            </div>
            <div class="col-xl-1 col-lg-2 col-md-2 col-sm-3">
              <div class="d-flex flex-wrap align-items-center justify-content-sm-end h-100">
                <div class="nowrap">วัน/เวลา</div>
                <div class="nowrap ml-1">สิ้นสุด</div>
              </div>

            </div>
            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-9">
              <kendo-datetimepicker [(ngModel)]="en_dt" class="w-100" [(format)]="date_format" [disabled]="!st_dt" [min]="min" [max]="max"
                [formatPlaceholder]="{year: 'ปี', month: 'เดือน', day: 'วัน', hour: 'ชั่วโมง', minute: 'นาที'}"
                [steps]="steps">
                <kendo-datepicker-messages today="วันนี้"></kendo-datepicker-messages>
                <!-- <kendo-timepicker-messages now="ตอนนี้"></kendo-timepicker-messages> -->
              </kendo-datetimepicker>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-xl-1 col-lg-2 col-md-2 col-sm-3">
              <div class="d-flex flex-wrap align-items-center justify-content-sm-end">
                ช่องจราจร</div>
            </div>
            <div class="col-xl-11 col-lg-10 col-md-10 col-sm-9">
              <div>
                <input type="checkbox" [(ngModel)]='laneChecked[1]' name="lane" id="l1"><label for="l1"
                  class="ml-1 mr-2 mr-1">1(ฝั่งชิดไหล่ทาง)</label>
                <input type="checkbox" [(ngModel)]='laneChecked[2]' name="lane" id="l2"><label for="l2"
                  class="ml-1 mr-2 mr-1">2</label>
                <input type="checkbox" [(ngModel)]='laneChecked[3]' name="lane" id="l3"><label for="l3"
                  class="ml-1 mr-2 mr-1">3</label>
                <input type="checkbox" [(ngModel)]='laneChecked[4]' name="lane" id="l4"><label for="l4"
                  class="ml-1 mr-2 mr-1">4</label>
                <input type="checkbox" [(ngModel)]='laneChecked[5]' name="lane" id="l5"><label for="l5"
                  class="ml-1 mr-2 mr-1">5</label>
                <input type="checkbox" [(ngModel)]='laneChecked[6]' name="lane" id="l6"><label for="l6"
                  class="ml-1 mr-2 mr-1">6</label>
                <input type="checkbox" [(ngModel)]='laneChecked[7]' name="lane" id="l7"><label for="l7"
                  class="ml-1 mr-2 mr-1">7</label>
                <input type="checkbox" [(ngModel)]='laneChecked[8]' name="lane" id="l8"><label for="l8"
                  class="ml-1 mr-2 mr-1">8</label>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-xl-1 col-lg-2 col-md-2 col-sm-3">
              <div class="d-flex flex-wrap align-items-center justify-content-sm-end">
                ประเภทรถ</div>
            </div>
            <div class="col-xl-11 col-lg-10 col-md-10 col-sm-9">
              <label *ngFor="let i of classList; let idx = index;" [htmlFor]="i.veh_class_name+i.veh_class_id"
                class="mr-1" [ngClass]="(idx != 0)?'ml-1':''">
                <input type="checkbox" [id]="i.veh_class_name+i.veh_class_id" name="classs" [(ngModel)]="i.checked"
                  [value]="i.veh_class_name" (change)="changeClass()">
                {{i.veh_class_name}}
              </label>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12">
              <div class="d-flex align-items-end justify-content-end">

                <button type="button" class="btn btn-primary font-3" style="min-width: 80px;" (click)="search()"
                  [disabled]="!sta || !time_range|| !st_dt || !en_dt">ค้นหา</button>
                <button type="button" class="btn btn-warning ml-2" (click)="generateExcel()"
                  [disabled]="!this.totalItems" style="border-radius: 50px;">
                  <i *ngIf="is_loading_excel" class="fa fa-spinner fa-spin fa-fw"></i>
                  <i *ngIf="!is_loading_excel" class="fa fa-file-excel-o"></i>
                </button>
                <!-- <button type="button" class="btn btn-warning ml-2" (click)="exportCleanLog()"
                  [disabled]="disCSV || !values || (values && noData)" style="border-radius: 50px;">
                  <i *ngIf="disCSV && values && !noData" class="fa fa-spinner fa-spin fa-fw"></i>
                  <i *ngIf="!disCSV || !values || noData" class="fa fa-file-excel-o"></i>
                </button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12" *ngIf="is_loading">
      <div class="d-flex justify-content-center align-items-center w-100 h-100 ">
        <div class="lds-dual-ring"></div>
      </div>
    </div>
    <div class="col-12" *ngIf="totalItems<=0 && totalItems != null && !is_loading">
      <div class="not-found-box d-flex justify-content-center align-items-center">
        <h5> ไม่พบข้อมูล </h5>
      </div>
    </div>
    <div *ngIf="totalItems && !is_loading" class="col-12 mt-3 scrollbar-style"
      style="overflow-x:auto;overflow-y:hidden;">
      <table class="table table-outline mb-0">
        <tbody>
          <tr>
            <td colspan="20" style="padding: 0 0 0 0 !important;">
              <div class="scrollbar-style" style="overflow-y:auto;overflow-x:hidden;;height: 70vh;">
                <table class="table table-outline mb-0">
                  <thead class="thead-dark" style="background-color: black;">
                    <tr>
                      <th class="text-center text-light">ลำดับ</th>
                      <th class="text-center text-light">เวลา</th>
                      <th class="text-center text-light nowrap">ช่องจราจร</th>
                      <!-- <th class="text-center text-light nowrap">veh_class</th>
                      <th class="text-center text-light nowrap">speed</th>
                      <th class="text-center text-light nowrap">length</th> -->
                      
                      <th class="text-center text-light">avg_speed</th>
                      <th class="text-center text-light">avg_speed85</th>
                      <th class="text-center text-light">gap</th>
                      <th class="text-center text-light">headway</th>
                      <th class="text-center text-light">occupancy</th>
                      <ng-container *ngFor="let c of classList_search">
                        <th class="text-center text-light" style="min-width:100px ;">{{c.veh_class_name}}</th>
                      </ng-container>
                     
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let item of data_list | slice: (currentPage-1) * itemsPerPage : (currentPage-1) * itemsPerPage + itemsPerPage;index as i">
                      <td class="text-center">
                        <div>{{(i+1)+(itemsPerPage*(currentPage-1)) | number}}</div>
                      </td>
                      <td class="text-center">
                        <div>{{item?.sensor_datetime | date:'dd/MM/yyyy HH:mm'}}</div>
                      </td>
                      <td class="text-center">
                        <div>{{item?.lane}}</div>
                      </td>
                      <!-- <td class="text-center">
                        <div>{{item?.veh_class}}</div>
                      </td>
                      <td class="text-center">
                        <div>{{item?.speed| number:'0.0-2'}}</div>
                      </td>
                      <td class="text-center">
                        <div>{{item?.length}}</div>
                      </td> -->
                      
                      <td class="text-center">
                        <div>{{item?.avg_speed| number:'0.0-2'}}</div>
                      </td>
                      <td class="text-center">
                        <div>{{item?.avg_speed85| number:'0.0-2'}}</div>
                      </td>
                      <td class="text-center">
                        <div>{{item?.gap| number:'0.0-2'}}</div>
                      </td>
                      <td class="text-center">
                        <div>{{item?.headway| number:'0.0-2'}}</div>
                      </td>
                      <td class="text-center">
                        <div>{{item?.occupancy| number:'0.0-2'}}</div>
                      </td>
                      <ng-container *ngFor="let c of classList_search">
                        <td class="text-center">
                          <div>{{item["count_class"+c.veh_class_id]| number:'0.0'}}</div>
                        </td>
                      </ng-container>
                     
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="50" class="table-footer">
              <div class="table-footer-inside d-flex align-items-center justify-content-between">
                <div class="ml-3 pt-3">
                  <pagination [totalItems]="totalItems" [maxSize]="10" [itemsPerPage]="itemsPerPage"
                    [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)" class="pagination"
                    previousText="&lsaquo;" nextText="&rsaquo;" [boundaryLinks]="true" firstText="&laquo;"
                    lastText="&raquo;"></pagination>
                </div>
                <div></div>
                <div *ngIf="totalItems != null" class="mr-3 ml-2">total: {{totalItems| number}}</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
