<div class="card">
    <div class="card-header">
        <h5>โปรดเลือกเหตุการณ์ที่ต้องการตั้งค่าแจ้งเตือน</h5>
        <button class="btn btn-primary" *ngIf="!_showAddEventBT" (click)="_showAddEventBT=true;_isEdit=true">
            แก้ไข/เพิ่ม เหตุการณ์
        </button>
        <button class="btn btn-outline-danger" *ngIf="_showAddEventBT" (click)="_showAddEventBT=false;_isEdit=false">
            ยกเลิกการแก้ไข
        </button>
    </div>
    <div class="card-body mt-3 ml-3 mr-3">
        <div class="inner">
            <div class="center">
                แจ้งเตือนตรวจจับน้ำฝน
            </div>
            <div>
                <div style="line-height: 1;color: #ff8f00;" class="d-flex justify-content-end align-items-center ml-3 mr-3 mb-2">
                    off <ui-switch class="ml-1 mr-1" (change)="chgSwitch(1,$event)"
                    [(ngModel)]="configSetting[0]" size="small"></ui-switch> on
                </div>
                <button (click)="gotoRainConfig()">เงื่อนไขการแจ้งเตือน</button>
                <button (click)="openRainOrder()">ลำดับการแจ้งเตือน</button>
            </div>
        </div>
        <div class="inner">
            <div class="center">
                ความเร็วเฉลี่ยลดลงจากช่วงเวลาปกติ
            </div>
            <div>
                <div style="line-height: 1;color: #ff8f00;" class="d-flex justify-content-end align-items-center ml-3 mr-3 mb-2">
                    off <ui-switch class="ml-1 mr-1"  (change)="chgSwitch(2,$event)"
                    [(ngModel)]="configSetting[1]" size="small"></ui-switch> on
                </div>
                <button (click)="gotoAverageSpeedConfig()">เงื่อนไขการแจ้งเตือน</button>
            </div>
        </div>
        <div class="inner">
            <div class="center">
                ปริมาณจราจรมีแนวโน้มเพิ่มขึ้นมากกว่าช่วงเวลาปกติ
            </div>
            <div>
                <div style="line-height: 1;color: #ff8f00;" class="d-flex justify-content-end align-items-center ml-3 mr-3 mb-2">
                    off <ui-switch class="ml-1 mr-1"  (change)="chgSwitch(3,$event)"
                    [(ngModel)]="configSetting[2]" size="small"></ui-switch> on
                </div>
                <button (click)="gotoTrafficVolumeConfig()">เงื่อนไขการแจ้งเตือน</button>
            </div>
        </div>
        <div class="inner">
            <div class="center">
                ค่า Occupancy เพิ่มขึ้นมากกว่าช่วงเวลาปกติ
            </div>
            <div>
                <div style="line-height: 1;color: #ff8f00;" class="d-flex justify-content-end align-items-center ml-3 mr-3 mb-2">
                    off <ui-switch class="ml-1 mr-1"  (change)="chgSwitch(4,$event)"
                    [(ngModel)]="configSetting[3]" size="small"></ui-switch> on
                </div>
                <button (click)="gotoOccupanyConfig()">เงื่อนไขการแจ้งเตือน</button>
            </div>
        </div>
        <div class="inner">
            <div class="center">
                ระยะเวลาเดินทางหรือความเร็วเฉลี่ยในแต่ละช่วงถนนเพิ่มขึ้นมากกว่าช่วงเวลาปกติ
            </div>
            <div>
                <div style="line-height: 1;color: #ff8f00;" class="d-flex justify-content-end align-items-center ml-3 mr-3 mb-2">
                    off <ui-switch class="ml-1 mr-1"  (change)="chgSwitch(5,$event)"
                    [(ngModel)]="configSetting[4]" size="small"></ui-switch> on
                </div>
                <button (click)="gotoTravelTimeConfig()">เงื่อนไขการแจ้งเตือน</button>
            </div>
        </div>
        <div class="inner">
            <div class="center">
                ระยะทางที่รถชะลอตัวหรือความยาวแถวคอยเพิ่มขึ้นเกินกว่าค่าที่กำหนด
            </div>
            <div>
                <div style="line-height: 1;color: #ff8f00;" class="d-flex justify-content-end align-items-center ml-3 mr-3 mb-2">
                    off <ui-switch class="ml-1 mr-1" (change)="chgSwitch(6,$event)"
                    [(ngModel)]="configSetting[5]" size="small"></ui-switch> on
                </div>
                <button (click)="gotoCarSlowedDownConfig()">เงื่อนไขการแจ้งเตือน</button>
            </div>
        </div>
    </div>
    <div class="card-footer" *ngIf="_showAddEventBT">
        <button class="add-BT" (click)="openModal()">
            <i class="fa fa-plus" aria-hidden="true"></i>
            เพิ่มเหตุการณ์
        </button>
    </div>
    <div class="card-body mb-3 ml-3 mr-3 subtype-BG">
        <div *ngFor="let i of sType_list" class="inner pointer">
            <button *ngIf="_isEdit" class="closePopup-BT" (click)="rm(i.id)">&times;</button>
            <div class="s-inner" (click)="openModal(i)">
                <div class="center">
                    {{i.name||''}}
                </div>
            </div>
        </div>
    </div>
</div>