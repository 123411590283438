export const mainRouteUrl = "report";
export const id = 1300;
export const menuItems_ = [
    {
        id: 1301,
        name: 'รายงาน1',
        url: `/${mainRouteUrl}/report1`,
        icon: 'fa fa-clone',
        parent_id: id
    },
    {
        id: 1302,
        name: 'รายงาน2',
        url: `/${mainRouteUrl}/report1`,
        icon: 'fa fa-clone',
        parent_id: id
    }
];

export const menuItems = [
    {
        id: 1300,
        name: 'Report',
        url: '/report',
        icon: 'fa fa-clone',
        parent_id: id
    },
    {
        id: 1301,
        name: 'รายงานข้อมูลความหนาแน่นเฉลี่ยจาก Sensor รายชั่วโมง',
        url: '/report/table/avg-density',
        icon: 'cui-speedometer icons',
        parent_id: id
    },
    /*{
        id: 1302,
        name: 'รายงานข้อมูลความเร็วเฉลี่ยจาก Sensor',
        url: '/report/table/avg-speed',
        icon: 'cui-speedometer icons',
        parent_id: id
    },*/
    // {
    //     id: 1303,
    //     name: 'รายงานชั่วโมงการทำงานของอุปกรณ์',
    //     url: '/report/table/device-work-hour',
    //     icon: 'fa fa-clone',
    //     parent_id: id
    // },
    {
        id: 1304,
        name: 'รายงานการเกิดอุบัติการณ์',
        url: '/report/table/inc',
        icon: 'fa fa-exclamation-triangle',
        parent_id: id
    },
    /*{
        id: 1305,
        name: 'รายงานการจัดการอุบัติเหตุ',
        url: '/report/table/inc-detail',
        icon: 'fa fa-exclamation-triangle',
        parent_id: id
    },*/
    {
        id: 1306,
        name: 'สถิติการเกิดอุบัติเหตุ',
        url: '/report/table/inc-stat',
        icon: 'fa fa-exclamation-triangle',
        parent_id: id
    },
    {
        id: 1307,
        name: 'สถิติประเภทการเกิดอุบัติเหตุ',
        url: '/report/table/inc-type-stat',
        icon: 'fa fa-exclamation-triangle',
        parent_id: id
    },
    {
        id: 1308,
        name: 'รายงานการจัดการแจ้งซ่อม',
        url: '/report/table/ma',
        icon: 'cui-wrench icons',
        parent_id: id
    },
    {
        id: 1309,
        name: 'รายงานสถิติการเสียของอุปกรณ์',
        url: '/report/table/ma-stat',
        icon: 'cui-wrench icons',
        parent_id: id
    },
    {
        id: 1310,
        name: 'รายงานรายละเอียดการจัดการ การแจ้งซ่อม',
        url: '/report/table/ma-detail',
        icon: 'cui-wrench icons',
        parent_id: id
    },
    {
        id: 1311,
        name: 'รายงานข้อมูลดิบจาก Sensor',
        url: '/report/table/row-sensor-data',
        icon: 'icon-graph',
        parent_id: id
    },
    {
        id: 1312,
        name: 'รายงาน การตรวจจับการใช้ช่องทางผิดเงื่อนไขจาก sensor',
        url: '/report/table/speed-detection',
        icon: 'icon-graph',
        parent_id: id
    },
    // {
    //     id: 1313,
    //     name: 'รายงานการคำนวณระยะเวลาการเดินทาง',
    //     url: '/report/table/traveltime',
    //     icon: 'fa fa-road',
    //     parent_id: id
    // },
    // {
    //     id: 1314,
    //     name: 'รายงานการคำนวณระยะเวลาการเดินทางจาก Sensor',
    //     url: '/report/table/traveltime-sensor',
    //     icon: 'fa fa-road',
    //     parent_id: id
    // },
    {
        id: 1315,
        name: 'รายงานการใช้งานระบบของเจ้าหน้าที่',
        url: '/report/table/user-log',
        icon: 'cui-user icons',
        parent_id: id
    },
    // {
    //     id: 1316,
    //     name: 'รายงานข้อมูลการนับรถจาก Sensor',
    //     url: '/report/table/volume',
    //     icon: 'fa fa-car',
    //     parent_id: id
    // },
    // {
    //     id: 1317,
    //     name: 'รายงานข้อมูลปริมาณจราจร',
    //     url: '/report/table/traffic-volume',
    //     icon: 'fa fa-car',
    //     parent_id: id
    // },
    // {
    //     id: 1318,
    //     name: 'รายงานข้อมูลปริมาณจราจรแบบรายเลน',
    //     url: '/report/table/traffic-volume-by-lane',
    //     icon: 'fa fa-car',
    //     parent_id: id
    // },
    // {
    //     id: 1319,
    //     name: 'รายงานข้อมูลความเร็วเฉลี่ยจากเครื่องตรวจวัดสภาพจราจร',
    //     url: '/report/table/traffic-speed',
    //     icon: 'cui-speedometer icons',
    //     parent_id: id
    // },
    // {
    //     id: 1320,
    //     name: 'รายงานข้อมูลความเร็วเฉลี่ยจากเครื่องตรวจวัดสภาพจราจรแบบรายเลน',
    //     url: '/report/table/traffic-speed-by-lane',
    //     icon: 'cui-speedometer icons',
    //     parent_id: id
    // },
    {
        id: 1321,
        name: 'รายงานการเปลี่ยนป้าย ATIS และ LMS',
        url: '/report/table/sign-log',
        icon: 'fa fa-clone',
        parent_id: id
    },
    {
        id: 1322,
        name: 'รายงานการสร้างภาพ ATIS',
        url: '/report/table/atis-log',
        icon: 'fa fa-clone',
        parent_id: id
    },
    {
        id: 1398,
        name: 'รายงานข้อมูลการจราจรจากเครื่องตรวจวัด ที่ติดบนป้าย LMS / ATIS',
        icon: 'icon-organization',
        url: '/report/menu/100',
        parent_id: id,
    },
    // {
    //     id: 1397,
    //     name: 'รายงานข้อมูลการจราจรจากเครื่องตรวจวัด ที่ติดบนป้าย LMS / ATIS',
    //     icon: 'icon-organization',
    //     url: '/report/menu/100',
    //     parent_id: id,
    // },
    {
        id: 1324,
        name: 'รายงานปริมาณจราจรต่อวันในแต่ละวัน',
        url: '/report/table/traffic-by-daily',
        icon: 'icon-event'
    },
    {
        id: 1326,
        name: 'รายงานปริมาณจราจรเฉลี่ยต่อวันในแต่ละสัปดาห์',
        url: '/report/table/traffic-by-weeklyavg',
        icon: 'icon-organization'
    },
    {
        id: 1327,
        name: 'รายงานปริมาณจราจรต่อวันในแต่ละเดือน',
        url: '/report/table/traffic-by-monthavg',
        icon: 'icon-organization'
    },
    {
        id: 1328,
        name: 'รายงานปริมาณจราจรต่อวันในรอบปี',
        url: '/report/table/traffic-by-annualavg',
        icon: 'icon-organization'
    },
    {
        id: 1329,
        name: 'รายงานปริมาณจราจรต่อวันเฉพาะวันธรรมดาในรอบปี',
        url: '/report/table/traffic-by-annualavg-weekday',
        icon: 'icon-organization'
    },
    {
        id: 1330,
        name: 'รายงานปริมาณจราจรต่อวัน',
        url: '/report/table/traffic-by-avg',
        icon: 'icon-organization'
    },
    {
        id: 1331,
        name: 'รายงานปริมาณจราจรที่ชั่วโมงสูงสุด',
        url: '/report/table/traffic-by-peakhour',
        icon: 'icon-organization'
    },
    {
        id: 1332,
        name: 'รายงานค่าตัวปรับแก้ปริมาณจราจรตามฤดูกาล',
        url: '/report/table/traffic-by-sensor',
        icon: 'icon-organization'
    },
    // {
    //     id: 1333,
    //     name: 'Lane Distribution Factor',
    //     url: '/report/table/lane-distribution-factor',
    //     icon: 'icon-organization'
    // },
    {
        id: 1323,
        name: 'รายงานความเร็วเฉลี่ย ราย 15 นาที',
        url: '/report/table/traffic-speed-by-15min',
        icon: 'icon-speedometer'
    },
    {
        id: 1325,
        name: 'รายงานการกระจายตัวของ Occupancy ราย 15 นาที',
        url: '/report/table/traffic-occupancy-by-15mins',
        icon: 'icon-organization'
    },
    {
        id: 1334,
        name: 'รายงานการกระจายตัวของความเร็วเฉลี่ย ราย 15 นาที',
        url: '/report/table/traffic-avgspeed-by-15mins',
        icon: 'icon-organization'
    },
    {
        id: 1399,
        name: 'รายงานข้อมูลการจราจรจากจุด Reversible Lane',
        icon: 'fa fa-road',
        url: '/report/menu/200',
        parent_id: id,

    },
    {
        id: 1335,
        name: 'รายงานปริมาณจราจรต่อวันในแต่ละวัน',
        url: '/report/table/reversible-traffic-by-daily',
        icon: 'fa fa-road',
        parent_id: id
    },
    {
        id: 1336,
        name: 'รายงานปริมาณจราจรต่อวัน',
        url: '/report/table/reversible-traffic-by-avg',
        icon: 'fa fa-road',
        parent_id: id
    },
    {
        id: 1337,
        name: 'รายงานปริมาณจราจรที่ชั่วโมงสูงสุด',
        url: '/report/table/traffic-by-peakhour',
        icon: 'fa fa-road',
        parent_id: id
    },
    {
        id: 1338,
        name: 'Report Demand จราจรช่วงเปิด reversible lane',
        url: '/report/table/reversible-demand',
        icon: 'fa fa-road',
        parent_id: id
    },
    {
        id: 1339,
        name: 'รายงานสรุปการตรวจจับการใช้ช่องทางพิเศษ',
        url: '/report/table/reversible-result',
        icon: 'fa fa-road',
        parent_id: id
    },
    {
        id: 1340,
        name: 'Export ข้อมูล sensor(ดิบ)',
        url: '/report/table/reversible-raw-data',
        icon: 'fa fa-road',
        parent_id: id
    }
];
export const permission = {
};


//------------------------------------------------------------------------------------------------------//
export const key_menu = "MainMenuKey1";
export const menu = [
    {
        id: 1,
        name: 'รายงานข้อมูลความหนาแน่นเฉลี่ยจาก Sensor รายชั่วโมง',
        url: '/report/table/avg-density',
        icon: 'cui-speedometer icons'
    },
    /*{
        id: 2,
        name: 'รายงานข้อมูลความเร็วเฉลี่ยจาก Sensor',
        url: '/report/table/avg-speed',
        icon: 'cui-speedometer icons'
    },*/
    // {
    //     id: 3,
    //     name: 'รายงานชั่วโมงการทำงานของอุปกรณ์',
    //     url: '/report/table/device-work-hour',
    //     icon: 'fa fa-clone'
    // },
    {
        id: 4,
        name: 'รายงานการเกิดอุบัติการณ์',
        url: '/report/table/inc',
        icon: 'fa fa-exclamation-triangle'
    },
    {
        id: 5,
        name: 'รายงานการจัดการอุบัติเหตุ',
        url: '/report/table/inc-detail',
        icon: 'fa fa-exclamation-triangle'
    },
    {
        id: 6,
        name: 'สถิติการเกิดอุบัติเหตุ',
        url: '/report/table/inc-stat',
        icon: 'fa fa-exclamation-triangle'
    },
    {
        id: 7,
        name: 'สถิติประเภทการเกิดอุบัติเหตุ',
        url: '/report/table/inc-type-stat',
        icon: 'fa fa-exclamation-triangle'
    },
    {
        id: 8,
        name: 'รายงานการจัดการแจ้งซ่อม',
        url: '/report/table/ma',
        icon: 'cui-wrench icons'
    },
    {
        id: 9,
        name: 'รายงานสถิติการเสียของอุปกรณ์',
        url: '/report/table/ma-stat',
        icon: 'cui-wrench icons'
    },
    {
        id: 10,
        name: 'รายงานรายละเอียดการจัดการ การแจ้งซ่อม',
        url: '/report/table/ma-detail',
        icon: 'cui-wrench icons'
    },
    {
        id: 11,
        name: 'รายงานข้อมูลดิบจาก Sensor',
        url: '/report/table/row-sensor-data',
        icon: 'icon-graph'
    },
    {
        id: 12,
        name: 'รายงาน การตรวจจับการใช้ช่องทางผิดเงื่อนไขจาก sensor',
        url: '/report/table/speed-detection',
        icon: 'icon-graph'
    },
    // {
    //     id: 13,
    //     name: 'รายงานการคำนวณระยะเวลาการเดินทาง',
    //     url: '/report/table/traveltime',
    //     icon: 'fa fa-road'
    // },
    // {
    //     id: 14,
    //     name: 'รายงานการคำนวณระยะเวลาการเดินทางจาก Sensor',
    //     url: '/report/table/traveltime-sensor',
    //     icon: 'fa fa-road'
    // },
    {
        id: 15,
        name: 'รายงานการใช้งานระบบของเจ้าหน้าที่',
        url: '/report/table/user-log',
        icon: 'cui-user icons'
    },
    // {
    //     id: 16,
    //     name: 'รายงานข้อมูลการนับรถจาก Sensor',
    //     url: '/report/table/volume',
    //     icon: 'fa fa-car'
    // },
    // {
    //     id: 17,
    //     name: 'รายงานข้อมูลปริมาณจราจร',
    //     url: '/report/table/traffic-volume',
    //     icon: 'fa fa-car'
    // },
    // {
    //     id: 18,
    //     name: 'รายงานข้อมูลปริมาณจราจรแบบรายเลน',
    //     url: '/report/table/traffic-volume-by-lane',
    //     icon: 'fa fa-car'
    // },
    // {
    //     id: 19,
    //     name: 'รายงานข้อมูลความเร็วเฉลี่ยจากเครื่องตรวจวัดสภาพจราจร',
    //     url: '/report/table/traffic-speed',
    //     icon: 'cui-speedometer icons'
    // },
    // {
    //     id: 20,
    //     name: 'รายงานข้อมูลความเร็วเฉลี่ยจากเครื่องตรวจวัดสภาพจราจรแบบรายเลน',
    //     url: '/report/table/traffic-speed-by-lane',
    //     icon: 'cui-speedometer icons'
    // },
    {
        id: 21,
        name: 'รายงานการเปลี่ยนป้าย ATIS และ LMS',
        url: '/report/table/sign-log',
        icon: 'fa fa-clone'
    },
    {
        id: 22,
        name: 'รายงานการสร้างภาพ ATIS',
        url: '/report/table/atis-log',
        icon: 'fa fa-clone'
    },
    {
        id: 100,
        name: 'รายงานข้อมูลการจราจรจากเครื่องตรวจวัด ที่ติดบนป้าย LMS / ATIS',
        icon: 'icon-organization',
        children: [
            {
                id: 24,
                name: 'รายงานปริมาณจราจรต่อวันในแต่ละวัน',
                url: '/report/table/traffic-by-daily',
                icon: 'icon-event'
            },
            {
                id: 26,
                name: 'รายงานปริมาณจราจรเฉลี่ยต่อวันในแต่ละสัปดาห์',
                url: '/report/table/traffic-by-weeklyavg',
                icon: 'icon-organization'
            },
            {
                id: 27,
                name: 'รายงานปริมาณจราจรต่อวันในแต่ละเดือน',
                url: '/report/table/traffic-by-monthavg',
                icon: 'icon-organization'
            },
            {
                id: 28,
                name: 'รายงานปริมาณจราจรต่อวันในรอบปี',
                url: '/report/table/traffic-by-annualavg',
                icon: 'icon-organization'
            },
            {
                id: 29,
                name: 'รายงานปริมาณจราจรต่อวันเฉพาะวันธรรมดาในรอบปี',
                url: '/report/table/traffic-by-annualavg-weekday',
                icon: 'icon-organization'
            },
            {
                id: 30,
                name: 'รายงานปริมาณจราจรต่อวัน',
                url: '/report/table/traffic-by-avg',
                icon: 'icon-organization'
            },
            {
                id: 31,
                name: 'รายงานปริมาณจราจรที่ชั่วโมงสูงสุด',
                url: '/report/table/traffic-by-peakhour',
                icon: 'icon-organization'
            },
            {
                id: 32,
                name: 'รายงานค่าตัวปรับแก้ปริมาณจราจรตามฤดูกาล',
                url: '/report/table/traffic-by-sensor',
                icon: 'icon-organization'
            },
            {
                id: 33,
                name: 'Lane Distribution Factor',
                url: '/report/table/lane-distribution-factor',
                icon: 'icon-organization'
            },
            {
                id: 23,
                name: 'รายงานความเร็วเฉลี่ย ราย 15 นาที',
                url: '/report/table/traffic-speed-by-15min',
                icon: 'icon-speedometer'
            },
            {
                id: 25,
                name: 'รายงานการกระจายตัวของ Occupancy ราย 15 นาที',
                url: '/report/table/traffic-occupancy-by-15mins',
                icon: 'icon-organization'
            },
            {
                id: 34,
                name: 'รายงานการกระจายตัวของความเร็วเฉลี่ย ราย 15 นาที',
                url: '/report/table/traffic-avgspeed-by-15mins',
                icon: 'icon-organization'
            },
        ]
    },
    {
        id: 101,
        name: 'รายงานประสิทธิของระบบตรวจจับและแจ้งเตือนอุบัติการณ์บนทางหลวงอัตโนมัติ',
        url: '/report/table/automatic-highway-incident-notification',
        icon: 'icon-organization',
    },
    {
        id: 102,
        name: 'รายงานการปรับแก้ข้อมูลระยะเวลาในการเดินทาง',
        url: '/report/table/remaining-time-data',
        icon: 'icon-organization',
    },
    // {
    //     id: 200,
    //     name: 'รายงานข้อมูลการจราจรจากจุด Reversible Lane',
    //     icon: 'fa fa-road',
    //     children: [
    //         {
    //             id: 35,
    //             name: 'รายงานปริมาณจราจรต่อวันในแต่ละวัน',
    //             url: '/report/table/reversible-traffic-by-daily',
    //             icon: 'fa fa-road'
    //         },
    //         {
    //             id: 36,
    //             name: 'รายงานปริมาณจราจรต่อวัน',
    //             url: '/report/table/reversible-traffic-by-avg',
    //             icon: 'fa fa-road'
    //         },
    //         {
    //             id: 37,
    //             name: 'รายงานปริมาณจราจรที่ชั่วโมงสูงสุด',
    //             url: '/report/table/traffic-by-peakhour',
    //             icon: 'fa fa-road'
    //         },
    //         {
    //             id: 38,
    //             name: 'Report Demand จราจรช่วงเปิด reversible lane',
    //             url: '/report/table/reversible-demand',
    //             icon: 'fa fa-road'
    //         },
    //         {
    //             id: 39,
    //             name: 'รายงานสรุปการตรวจจับการใช้ช่องทางพิเศษ',
    //             url: '/report/table/reversible-result',
    //             icon: 'fa fa-road'
    //         },
    //         {
    //             id: 40,
    //             name: 'Export ข้อมูล sensor(ดิบ)',
    //             url: '/report/table/reversible-raw-data',
    //             icon: 'fa fa-road'
    //         }
    //     ]
    // },
];

export const menuHead = [
    {
        id: 1300,
        name: 'Report',
        url: `/${mainRouteUrl}`,
        icon: 'fa fa-file-text',
        parent_id: id
    },
    {
        id: 1500,
        name: 'Export log',
        url: `/${mainRouteUrl}/log`,
        icon: 'fa fa-clone',
        parent_id: id
    },
];
export const menuLog = [
    {
        id: 1501,
        name: 'User activity log',
        url: `/${mainRouteUrl}/log/user-activity`,
        icon: 'icon-user-following'
    },
    {
        id: 1502,
        name: 'Boardsign control log',
        url: `/${mainRouteUrl}/log/board-sign-control`,
        icon: 'icon-screen-desktop'
    },
    {
        id: 1503,
        name: 'ตัวแจ้งซ่อม',
        url: `/${mainRouteUrl}/log/ma`,
        icon: 'icon-magnet'
    },
    {
        id: 1504,
        name: 'Event log',
        url: `/${mainRouteUrl}/log/event`,
        icon: 'icon-organization'
    },
    {
        id: 1505,
        name: 'VSW/RLW status',
        url: `/${mainRouteUrl}/log/vsw`,
        icon: 'icon-screen-desktop'
    },
    {
        id: 1506,
        name: 'TT_Security status',
        url: `/${mainRouteUrl}/log/tt-security`,
        icon: 'fa fa-shield'
    },
];