<div class="row pt-3 pr-3 pl-3">
  <div class="col-lg-3 col-md-4 col-sm-6">
    <div>ชื่อ :</div>
    <div>
      <input autocomplete="nope" type="text" placeholder="" maxlength="100" class="k-textbox form-control w-100"
        [(ngModel)]="search_name" [ngModelOptions]="{standalone: true}" />
    </div>
  </div>
  <div class="col-lg-3 col-md-4 col-sm-6">
    <div>ชนิดป้าย :</div>
    <div>
      <kendo-combobox class="w-100" [data]="data_sign_type" [allowCustom]="allowCustom" [textField]="'name'"
        [valueField]="'id'" [value]="search_sign_type" (valueChange)="handleValueSignType($event)"
        (filterChange)="handleFilterSignType($event)" [placeholder]="'ทั้งหมด'" [filterable]="true">
      </kendo-combobox>
    </div>
  </div>
  <div class="col-lg-3 col-md-4 col-sm-6">
    <div>ขนาด :</div>
    <div>
      <kendo-combobox class="w-100" [data]="data_size" [allowCustom]="allowCustom" 
      textField="size" 
      valueField="size"
      [(ngModel)]="search_size" 
      (valueChange)="handleValueSize($event)" (filterChange)="handleFilterSize($event)" [placeholder]="'ทั้งหมด'"
        [filterable]="true">
      </kendo-combobox>
    </div>
  </div>
  <!-- <div class="col-lg-3 col-md-4 col-sm-6">
    <div>อัตราส่วน :</div>
    <div>
      <kendo-combobox class="w-100" [data]="data_ratio" [allowCustom]="allowCustom" [textField]="''" [valueField]="''"
        [value]="search_ratio" (valueChange)="handleValueRatio($event)" (filterChange)="handleFilterRatio($event)"
        [placeholder]="'ทั้งหมด'" [filterable]="true">
      </kendo-combobox>
    </div>
  </div> -->
  <div class="col-lg-3 col-md-4 col-sm-6">
    <div>ประเภทการใช้งาน :</div>
    <div>
      <kendo-combobox class="w-100" [data]="use_type_list" [allowCustom]="allowCustom" [textField]="'name'"
        [valueField]="'id'" [value]="search_use_type" (valueChange)="handleValueUseType($event)"
        [placeholder]="'ทั้งหมด'" [filterable]="true">
      </kendo-combobox>
    </div>
  </div>

  <div class="col-lg-12 col-md-8 col-sm-12 d-flex align-items-end justify-content-end">
    <div class="mt-1">
      <button type="button" (click)="search()" class="btn btn-primary pull-right text-white ml-1"> <i
          class="fa fa-search"></i>
        ค้นหา </button>
    </div>
  </div>

  <div class="col-12 mt-3 table-header pl-3 pr-3 pt-2 pb-2 d-flex align-items-center justify-content-between">
    <div class="font-2">
      <i class="fa fa-clone mr-2"></i> Playlist
    </div>
    <div>
      <button *ngIf="isCreate" type="button" class="btn btn-primary text-white ml-1" (click)="openAddPlaylistModal()">
        <i class="fa fa-plus"></i>
        สร้าง Playlist</button>
    </div>
  </div>
  <div class="col-12 table-panel-body-height scrollbar-style">
    <div *ngIf="is_loading" class="d-flex justify-content-center align-items-center w-100 h-100 ">
      <div class="lds-dual-ring"></div>
    </div>
    <div *ngIf="!is_loading" class="d-flex align-content-start flex-wrap">
      <div *ngFor="let media of data_list">
        <div>
          <div class="p-2">
            <app-playlist-item class="pointer" [show_count]="true" *ngIf="media?.items" [playlist]="media?.items"
              [height]="medie_sign_height"
              [width]="medie_sign_width(media?.sign_type_obj?.aspect_ratio,medie_sign_height)"
              (click)="openPlaylistInfoModal(media)">
            </app-playlist-item>
            <div *ngIf="!media?.items" class="pointer" [style.height.px]="medie_sign_height"
              [style.width.px]="medie_sign_width(media?.sign_type_obj?.aspect_ratio,medie_sign_height)"
              (click)="openPlaylistInfoModal(media)"></div>
            <div class="clearfix m0 text-center text-white"
              [ngStyle]="getSignNameTextStyles(medie_sign_width(media?.sign_type_obj?.aspect_ratio,medie_sign_height))">
              <small title="{{media?.name}}">{{media?.name}}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 p-0 table-header d-flex align-items-center justify-content-between">
    <div class="mr-2 ml-3">
      <pagination [totalItems]="totalItems" [maxSize]="5" [itemsPerPage]="itemsPerPage" [(ngModel)]="currentPage"
        (pageChanged)="pageChanged($event)" class="pagination p-0 m-0" previousText="&lsaquo;" nextText="&rsaquo;"
        [boundaryLinks]="true" firstText="&laquo;" lastText="&raquo;"></pagination>
    </div>
    <div></div>
    <div class="mr-3 ml-2">total: {{totalItems}}</div>
  </div>
</div>

<div bsModal #addPlaylistModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true" (onHide)="closeAddPlaylist()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i><span *ngIf="action == 1">สร้าง Playlist</span> <span
            *ngIf="action == 2">แก้ไข Playlist</span></h4>
        <button type="button" class="close text-light" (click)="addPlaylistModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div *ngIf="is_loading_add" class="modal-body d-flex align-items-center justify-content-center">
        <div class="lds-dual-ring"></div>
      </div>
      <div *ngIf="!is_loading_add" class="modal-body">
        <form class="form-horizontal mt-2 mb-2">
          <div class="form-group row">
            <div class="col-md-12">
              <div class="d-flex align-items-center justify-content-center mt-1" style="min-height: 35px;">
                <label class="radio-container m-1">ใช้ได้กับทุกป้าย
                  <input id="isPubilcImRadio2" type="radio" name="isPubilcImRadio2" [value]="true"
                    [(ngModel)]="is_public" [ngModelOptions]="{standalone: true}"
                    (ngModelChange)="isPublicChange($event)">
                  <span class="radio-checkmark"></span>
                </label>
                <label class="radio-container m-1">ระบุป้าย
                  <input id="isPubilcImRadio2" type="radio" name="isPubilcImRadio2" [value]="false"
                    [(ngModel)]="is_public" [ngModelOptions]="{standalone: true}"
                    (ngModelChange)="isPublicChange($event)">
                  <span class="radio-checkmark"></span>
                </label>
              </div>
            </div>
          </div>

          <div *ngIf="!is_public" class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">ป้าย
                :</span></label>
            <div class="col-md-6">
              <kendo-combobox class="w-100" [data]="data_boardsign_modal" [allowCustom]="allowCustom"
                [textField]="'name'" [valueField]="'id'" [value]="boardsign_modal"
                (valueChange)="handleValueBoardsignModal(($event))" (filterChange)="handleFilterBoardsignModal($event)"
                [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
              <small *ngIf="clickSelectMediaBoard && !boardsign_modal" class="text-danger">* โปรดระบุป้ายที่จะใช้
                Playlist นี้ได้ ก่อนทำการเลือกรูป</small>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">ชื่อภาพ
                :</span></label>
            <div class="col-md-6">
              <input type="text" [(ngModel)]="selectItem.name" name="text-input-img-name" class="form-control"
                placeholder="" maxlength="50">
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">ชนิดป้าย
                :</span></label>
            <div class="col-md-6">
              <kendo-combobox class="w-100" [data]="data_sign_type_modal" [allowCustom]="allowCustom"
                [textField]="'name'" [valueField]="'id'" [value]="sign_type_modal"
                (valueChange)="handleValueSignTypeModal($event)" (filterChange)="handleFilterSignTypeModal($event)"
                [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
              <small *ngIf="clickSelectMedia && !sign_type_modal" class="text-danger">*
                โปรดเลือกชนิดป้ายก่อนทำการเลือกรูป</small>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">รูปภาพ
                :</span></label>
            <div class="col-md-6">
              <div class="text-center">
                <div class="d-flex align-items-center justify-content-between flex-wrap m-1 border border-dark"
                  *ngFor="let item of playlist; let i = index">
                  <div title="เลือกรูป" [style.width.px]="get_sign_width" [style.height.px]="set_sign_height"
                    (click)="openMediaModal(i)" class="bg-gray-800 m-2 position-relative pointer">
                    <div style="width: 100%;height:100%"
                      class="d-flex align-items-center justify-content-center position-absolute"><i
                        class="fa fa-file-image-o"></i></div>
                    <img *ngIf="item?.media?.path" width="100%" height="100%" class="position-absolute"
                      style="top: 0;left: 0;" [src]="item?.media?.path" />
                  </div>
                  <input type="text" [(ngModel)]="item.duration" name="text-input-dur{{i}}"
                    class="form-control text-center ml-2 mr-1" placeholder="เวลา" maxlength="4" style="width: 60px">
                  <span class="ml-1 mr-2">วินาที</span>
                  <button title="ลบรูป" type="button" class="btn btn-sm btn-pill btn-danger m-2"
                    (click)="deleteList(item)"><i class="fa fa-times pointer"></i></button>
                </div>
                <button title="เพิ่มรูป" type="button" class="btn btn-sm btn-pill btn-success m-1"
                  (click)="addList()"><i class="fa fa-plus pointer"></i></button>
              </div>

            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="select1"> <span
                class="float-sm-left float-md-right">ประเภทการใช้งาน
                :</span></label>
            <div class="col-md-6">
              <kendo-combobox class="w-100" [data]="use_type_list" [allowCustom]="allowCustom" [textField]="'name'"
                [valueField]="'id'" [value]="use_type_modal" (valueChange)="handleValueUseTypeModal($event)"
                [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="select2"> <span class="float-sm-left float-md-right">สถานะ
                :</span></label>
            <div class="col-md-6">
              <kendo-combobox class="w-100" [data]="status_list" [allowCustom]="allowCustom" [textField]="'name'"
                [valueField]="'id'" [value]="status_modal" (valueChange)="handleValueStatusModal($event)"
                [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>
        </form>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary" (click)="addPlaylistModal.hide()">ยกเลิก</button>
          <button *ngIf="action == 1" type="button" class="btn btn-primary text-light ml-2" (click)="addPlaylist()"
            [disabled]="btAddPlaylistDisable() || (!is_public && !boardsign_modal)">สร้าง</button>
          <button *ngIf="action == 2" type="button" class="btn btn-primary text-light ml-2" (click)="editPlaylist()"
            [disabled]="btAddPlaylistDisable() || (!is_public && !boardsign_modal)">แก้ไข</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div bsModal #mediaListModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i><span>Media</span></h4>
        <button type="button" class="close text-light" (click)="mediaListModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="select_media_inx != null && addPlaylistModal.isShown">
          <app-media-selector [sign_height]="medie_sign_height" [boardsign_id]="select_media_boardsign_id"
            [sign_type_id]="sign_type_modal?.id" [toggle]="toggle" [select_type]="2"
            (onComponentClosed)="closeMediaModal($event)"></app-media-selector>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal modal1 fade1" bsModal #playlistInfoModal="bs-modal" tabindex="-1" role="dialog"
  aria-labelledby="mySmallModalLabel" aria-hidden="true" (onHide)="closePlaylistInfoModal()">
  <div class="modal-dialog modal2-dialog" [style.min-width.px]="250">
    <div class="modal-content">
      <div class="modal-body">
        <div class="mt-1">
          <div class="panel-body">
            <div class="d-flex justify-content-center align-items-center" *ngIf="select_plt_item">
              <app-playlist-item [show_count]="true" *ngIf="select_plt_item && select_plt_item?.items"
                [playlist]="select_plt_item?.items" [height]="medie_sign_height"
                [width]="medie_sign_width(select_plt_item?.sign_type_obj?.aspect_ratio,medie_sign_height)"
                [modal]="true" [unsub]="unsub_playlist"></app-playlist-item>
            </div>
            <div class="clearfix mt-1">
              <p>{{select_plt_item?.name}}</p>
            </div>
            <div class="row">
              <div class="col-6">
                <button [disabled]="!isCreate" class="btn btn-warning btn-block mt-1 text-light"
                  (click)="openEditPlaylistModal(select_plt_item)">
                  <i class="cui-note icons mr-1"></i>แก้ไข</button>
              </div>
              <div class="col-6">
                <button [disabled]="!isCreate" class="btn btn-danger btn-block mt-1"
                  (click)="OpenConfirmDeleteMediaModal()">
                  <i class="cui-trash icons mr-1"></i>ลบ</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal modal1 fade1" bsModal #confirmDeletePlaylistModal="bs-modal" tabindex="-1" role="dialog"
  aria-labelledby="mySmallModalLabel" aria-hidden="true" (onHide)="closePlaylistInfoModal()">
  <div class="modal-dialog modal1-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="mt-3">
          <div class="panel-body text-center">
            <div class="d-flex justify-content-center align-items-center" style="min-height: 100px">
              <h5>คุณต้องการลบ <span class="text-primary"> {{select_plt_item?.name}} </span> ใช่หรือไม่</h5>
            </div>
            <div class="d-flex justify-content-end mt-1">
              <button class="btn btn-secondary mr-1" (click)="confirmDeletePlaylistModal.hide()"> ยกเลิก </button>
              <button class="btn btn-primary text-light ml-1" (click)="deletePlaylist(select_plt_item)"> ตกลง </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<swal #swalSuccess title="<div class='text-light'>สำเร็จ</div>" type="success" text=""></swal>
<swal #swalError title="<div class='text-light'>ไม่สำเร็จ</div>" html="<div class='text-light'>{{res_message}}</div>"
  type="error"></swal>