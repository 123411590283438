import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/services/common.service';
import { ActionEnum } from 'src/app/models/common-enum';
@Component({
  selector: 'app-traffic-signal',
  templateUrl: './traffic-signal.component.html',
  styleUrls: ['./traffic-signal.component.scss']
})
export class TrafficSignalComponent implements OnInit {
  url: any;
  constructor(private authService: AuthService, private commonService: CommonService) {
    this.commonService.activityLog(ActionEnum.Get, 'Traffic Information ➡ จัดการสี่แยกจราจร').subscribe(res => { }, error => { console.log(error); }); 
  }

  ngOnInit(): void {
    const token = this.authService.user_token;
    const username = this.authService.user_name;
    if (username && token) {
      //this.url =  `http://www.ntdcloud.com/?next=/trafficlight/&client_id=${username}&token=${token}`;
      // const url1 = `http://www.ntdcloud.com/?next=/trafficlight/&client_id=${username}&token=${token}`;
      // this.url = url1 + "&output=embed";
      // window.parent.location.replace(this.url);
      // console.log(this.url);
      //this.url = `http://www.ntdcloud.com/trafficlight/?client_id=${username}&token=${token}`;
      this.url = `http://ntr.shw.transcode.co.th/trafficlight/?client_id=${username}&token=${token}&from_web=${environment.from_web}`;
    }
    // console.log(token);
    // console.log(username);
    // console.log(this.url);
  }

}
