import { NgModule } from '@angular/core';
import { ExtraOptions, Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { AuthGuard } from './services/auth/auth.guard';
import { LoginComponent } from './views/login/login.component';
import { ApiDocumentComponent } from './views/document/api-document/api-document.component'
export let routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  // {
  //   path: 'api-document',
  //   component: ApiDocumentComponent,

  // },
  {
    path: 'api-document',
    loadChildren: () => import('./views/document/document.module').then(m => m.DocumentModule),
  },
  {
    path: 'schematic',
    loadChildren: () => import('./views/schematic/schematic.module').then(m => m.SchematicModule),
  },
  {
    path: 'schematic-pins',
    loadChildren: () => import('./views/schematic-pins/schematic-pins.module').then(m => m.SchematicPinsModule),
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'sign-control',
        loadChildren: () => import('./views/sign-control/sign-control.module').then(m => m.SignControlModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'assets-management',
        loadChildren: () => import('./views/assets-management/assets-management.module').then(m => m.AssetsManagementModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'user-management',
        loadChildren: () => import('./views/user-management/user-management.module').then(m => m.UserManagementModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'traffictool',
        // component : SmartmicroComponent
        loadChildren: () => import('./views/traffic-tool/traffic-tool.module').then(m => m.TrafficToolModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'event',
        loadChildren: () => import('./views/event/event.module').then(m => m.EventModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'information',
        loadChildren: () => import('./views/information/information.module').then(m => m.InformationModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'report',
        loadChildren: () => import('./views/report/report.module').then(m => m.ReportModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'previous-version',
        loadChildren: () => import('./views/previous-version/previous-version.module').then(m => m.PreviousVersionModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'device-monitoring',
        loadChildren: () => import('./views/device-monitoring/device-monitoring.module').then(m => m.DeviceMonitoringModule),
        canActivate: [AuthGuard]
      },
    ]
  }
];

const config: ExtraOptions = {
  useHash: false,
};
@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
