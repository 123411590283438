import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { EventService } from 'src/app/services/user/event.service';
import { ModalTraveltimeDetectionComponent } from './model-traveltime-detection/model-traveltime-detection.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-config-traveltime-detection',
  templateUrl: './config-traveltime-detection.component.html',
  styleUrls: ['./config-traveltime-detection.component.scss']
})
export class ConfigTraveltimeDetectionComponent implements OnInit {
  modalRef: BsModalRef;
  routeItems: any = []
  route: any
  st_km: number
  en_km: number
  config: number
  deviceLists: any = []
  ids: any = []
  idx_arr: any = []
  itemsPerPage: number = 10
  currentPage: number = 1
  configList : any = []
  constructor(
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private router: Router,
    public eS: EventService,
  ) { }

  ngOnInit(): void {
    this.getRoute();
    this.getTravalTimeDetactionDevices();
    this.getConfigList();
  }
  async getConfigList() {
    let res : any =await this.eS.getconfigSettingList()
    if(res.length) this.configList = res
  }
  async getTravalTimeDetactionDevices() {
    this.deviceLists = []
    let data: any = {}
    if (Number(this.route) >= 0) data.route = this.route
    if (this.st_km >= 0) data.st_km = this.st_km
    if (this.en_km >= 0) data.en_km = this.en_km
    if (this.config >= 0) data.config = this.config
    let res: any = await this.eS.getTravalTimeDetactionDevices(data)
    let count = 1
    res.forEach((val:any) => {val.device_id = count;count++;})
    if (res.length) this.deviceLists = res
  }
  async getRoute() {
    let res: any = await this.eS.getRoute();
    if (res) this.routeItems = res
  }
  openModel(data?: any) {
    let setdata:any = {}
    if (!data) {
      setdata = { device_id: [], device_loc: [] }
      for (const key in this.ids) {
        if (this.ids[key] === true) setdata.device_id.push(key)
      }
      setdata.device_loc = _.filter(this.deviceLists, (v) => {
        return setdata.device_id.find(x => x == v.device_id)
      })
    }
    console.log(data)
    if(data) {
      setdata = data
      setdata.id = data.device_id
    }
    const initialState = {
      values: setdata,
    };

    this.modalRef = this.modalService.show(ModalTraveltimeDetectionComponent, { initialState, class: 'w-80', backdrop: 'static', id: 5 });
    this.modalRef.content.event.subscribe(data => {
      // if (data) this.getEventMap();
      this.getTravalTimeDetactionDevices();
    });
  }
  back(){
    this.router.navigateByUrl('event/setting');
  }
}
