export const traffic_url = "traffictool";
export const id = 1100;
// Mockup id,Nodata in Doc
export const menuItems = [
{
        id: 1101,
        name: 'Report Traveltime',
        url: `/${traffic_url}/traveltime`,
        icon: 'fa fa-road',
        parent_id: id
    },
    // { 
    //     id: 1102,
    //     name: 'ตรวจจับความเร็ว/ สำรวจข้อมูลสภาพอากาศ',
    //     url: `/${traffic_url}/weather-station`,
    //     icon: 'fa fa-cloud',
    //     parent_id: id
    // },
    // {
    //     id: 1103,
    //     name: 'จัดการสี่แยกจราจร',
    //     url: `/${traffic_url}/traffic-signal`,
    //     icon: 'fa fa-plus',
    //     parent_id: id
    // },
    {
        id: 1104,
        name: 'Sensor',
        url: `/${traffic_url}/sensor`,
        icon: 'icon-directions',
        parent_id: id
    },
    {
        id: 1105,
        name: 'รายงานจากอุปกรณ์ตรวจวัด SMC',
        url: `/${traffic_url}/smc`,
        icon: 'icon-bell',
        parent_id: id
    },
    // {
    //     id: 1105,
    //     name: 'รายงานจากอุปกรณ์ตรวจวัด SMC',
    //     url: `/${traffic_url}/sensor-measurement`,
    //     icon: 'icon-bell',
    //     parent_id: id
    // },
    {
        id: 1106,
        name: 'รายงานรายคันจากจุดสำรวจ',
        url: `/${traffic_url}/per-measurement`,
        icon: 'icon-pin',
        parent_id: id
    },
];
export const permission = {
    
};