import { Component, OnInit } from '@angular/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';

import { ReportService } from 'src/app/services/user/report.service';
import * as moment from 'moment'
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { CommonService } from 'src/app/services/common.service';
import { ActionEnum } from 'src/app/models/common-enum';
@Component({
  selector: 'app-sensor-measurement',
  templateUrl: './sensor-measurement.component.html',
  styleUrls: ['./sensor-measurement.component.scss']
})
export class SensorMeasurementComponent implements OnInit {
  staList: any[] = []
  sta: number = null
  station_id: number = null

  stat: any;
  st_dt: any;
  en_dt: any;
  laneChecked: any = [null, true, true, true, true]
  laneChecked_IN: any = [null, true, true, true, true]
  laneChecked_OUT: any = [null, true, true, true, true]
  classList: any = []

  feqLabelItem: string[] = ['0-1', '1-2', '2-3', '4,5']
  feqData: number[] = [1, 2, 3, 4]
  feq_max: number = 4
  feq_min: number = 0

  cumulLabelItem: any[] = ['0-1', '1-2', '2-3', '4,5']
  cumulData: any[] = [15, 28, 30, 80]

  chkData: any = null
  selectedId: number

  nums_lane: any
  constructor(
    private rp_S: ReportService,
    private route: ActivatedRoute,
    private location: Location,
    private commonService: CommonService) {
    this.commonService.activityLog(ActionEnum.Get, 'Traffic Information ➡ รายงานรายคันจาก SMC').subscribe(res => { }, error => { console.log(error); });
  }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      this.selectedId = Number(params.get("id"));
      if (params.get("st_dt")) this.st_dt = new Date(params.get("st_dt"));
      if (params.get("en_dt")) this.en_dt = new Date(params.get("en_dt"));
    });

    if (!this.selectedId) { this.getStation() }
    else { this.getStationPer() }
  }

  async getStationPer() {
    // this.nums_lane = Array(Number(this.sta.num_lane)).fill(1).map((x,i)=>i);
    this.nums_lane = Array(Number(4)).fill(1).map((x, i) => i);
  }
  async getStation() {
    this.blur()
    let res: any = await this.rp_S.getStation();
    if (res.length) this.staList = res
  }
  async getVehClass(sta: number) {
    this.classList = await this.rp_S.getVehClass({ station_id: sta })
  }

  async search() {
    this.chkData = 0
    let lane_: any = [];
    let classval: any = [];
    this.feq_max = 0
    this.feq_min = 0
    this.feqLabelItem = []
    this.feqData = []
    this.cumulLabelItem = []
    this.cumulData = []
    for (const i of this.classList) {
      if (i.checked) classval.push(i.veh_class_id)
    }
    let data: any = {
      station_id: this.station_id,
      veh_class: classval
    }

    if (!this.selectedId) {
      for (const key in this.laneChecked) {
        if (Object.prototype.hasOwnProperty.call(this.laneChecked, key)) {
          const element = this.laneChecked[key];
          if (element) lane_.push(key)
        }
      }
      data.lane = lane_.map(x => Number(x))
    } else {
      lane_ = []
      for (const key in this.laneChecked_IN) {
        if (Object.prototype.hasOwnProperty.call(this.laneChecked_IN, key)) {
          const element = this.laneChecked_IN[key];
          if (element) lane_.push(key)
        }
      }
      data.lane_in = lane_.map(x => Number(x))
      lane_ = []
      for (const key in this.laneChecked_OUT) {
        if (Object.prototype.hasOwnProperty.call(this.laneChecked_OUT, key)) {
          const element = this.laneChecked_OUT[key];
          if (element) lane_.push(key)
        }
      }
      data.lane_out = lane_.map(x => Number(x))
    }
    if (this.st_dt) data.start_datetime = (this.st_dt) ? moment(this.st_dt, "DD/MM/YYYY").add(7, "hours").toISOString() : null
    if (this.en_dt) data.end_datetime = (this.en_dt) ? moment(this.en_dt, "DD/MM/YYYY").add(7, "hours").toISOString() : null
    let res: any = await this.rp_S.getSensorMeasurement(data)
    this.chkData = 1
    if (res) {
      this.stat = res.stat
      if (res.graph.freq.length) {
        for (const i of res.graph.freq) {
          this.feqLabelItem.push(i.label)
          this.feqData.push(i.value)
          if (i.value > this.feq_max) this.feq_max = i.value
          if (i.value < this.feq_min) this.feq_min = i.value
        }
      }
      if (res.graph.qumu.length) {
        for (const i of res.graph.qumu) {
          this.cumulLabelItem.push(i.label)
          this.cumulData.push(i.value)
        }
      }
    }
  }
  onSelect(event: TypeaheadMatch): void {
    this.station_id = event.item.id
    this.getVehClass(this.station_id)
  }

  blur(evt?: any) {
    this.sta = null
    this.station_id = null
  }
  backClicked() { this.location.back(); }
}
