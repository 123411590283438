import { Component, OnInit } from '@angular/core';
import { FuncService } from 'src/app/services/user/func.service';

@Component({
  selector: 'app-submenu-smc',
  templateUrl: './submenu-smc.component.html',
  styleUrls: ['./submenu-smc.component.scss']
})
export class SubmenuSmcComponent implements OnInit {

  constructor(
    private func : FuncService,
  ) { }

  ngOnInit(): void {
  }
  goto(txt:string){
    this.func.goto('traffictool/'+txt)
  }
}
