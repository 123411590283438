import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EventService } from 'src/app/services/user/event.service';
@Component({
  selector: 'app-model-queueing-detection',
  templateUrl: './model-queueing-detection.component.html',
  styleUrls: ['./model-queueing-detection.component.scss']
})
export class ModelQueueingDetectionComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();
  values: any;
  icon : any;
  alert:any;
  queueLength : any;
  normalSpeedDiff : any;
  length : boolean;
  constructor(
    public bsModalRef: BsModalRef,
    public eS : EventService,
  ) { }

  ngOnInit(): void {
    if(this.values.id) this.getQueueingDetactionDevices({id:this.values.id})
  }
  async getQueueingDetactionDevices(data:any){
    let res : any = await this.eS.getQueueingDetactionDevices(data)
    if(res){
      this.queueLength = res[0].queue_length
      this.normalSpeedDiff = res[0].normal_speed_diff
      this.icon = res[0].icon_url
    }
  }
  ImgBase64(evt?: any,imgPath? : any){
    var reader = new FileReader();
    reader.readAsDataURL(evt.target.files[0]);
    reader.onload = async (_event) => {
      let res : any = await this.eS.uploadIconImg({image:reader.result})
      if(res) this.icon = res.img_url 
    }
  }
  async save(){
    let data :any = {
      id: this.values.device_id,
      config: {
        queue_length: this.queueLength,
        normal_speed_diff: this.normalSpeedDiff
      },
      icon: this.icon
    }
    let res : any = await this.eS.addUpdateQueueingDetactionDevices(data)
    if(res) this.confirm();
  }
  
  confirm() {
    this.event.emit(true);
    this.bsModalRef.hide();
  }
  close() {
    this.event.emit(false);
    this.bsModalRef.hide();
  }
}
