import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FuncService } from 'src/app/services/user/func.service'
import { PeopleModalComponent } from './people-modal/people-modal.component';
import { PeopleService } from 'src/app/services/user/people.service'

import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { InstitutionService } from 'src/app/services/user/institution.service';
import { CommonService } from 'src/app/services/common.service';
import { permission } from 'src/app/views/user-management/menu';
import { ActionEnum } from 'src/app/models/common-enum';
import { ApiService } from 'src/app/services/user/api.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss']
})
export class PeopleComponent implements OnInit {

  @ViewChild('swalSuccessRm', { static: false }) private swalSuccessRm: SwalComponent;
  modalRef: BsModalRef;
  ins_list: any;
  constructor(
    private func: FuncService,
    private modalService: BsModalService,
    private peoS: PeopleService,
    private insS: InstitutionService,
    private commonService: CommonService,
    private apiService: ApiService,
  ) {
    this.commonService.activityLog(ActionEnum.Get, 'User Management ➡ ผู้ใช้งาน').subscribe(res => { }, error => { console.log(error); });
  }
  users_list: any = [];
  total: number = 0;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  name: string = '';
  hashtag: string = '';
  ins_id: number;
  ins_name: string;
  isAdd: boolean = false;
  isEdit: boolean = false;
  async ngOnInit() {
    this.getData()
    this.isAdd = await this.commonService.getPermission(permission.user.add_id).toPromise();
    this.isEdit = await this.commonService.getPermission(permission.user.edit_id).toPromise();
  }

  openModal(data?: any) {
    const initialState = {
      values: data,
      ins_list: this.ins_list
    };

    this.modalRef = this.modalService.show(PeopleModalComponent, { initialState });
    this.modalRef.content.event.subscribe(data => {
      if (data) this.getData()
      // return resolve(data);
    });
  }
  async getData(data?: any) {
    if (!this.ins_list) this.ins_list = await this.insS.getIns(data);
    this.users_list = await this.peoS.getUsers(data);
    for (const i of this.users_list) {
      i.name = `${i.firstname || ''} ${i.lastname || ''}`
    }
    this.total = this.users_list.length
  }
  search() {
    let params: any = {}
    if (this.name) params.name = this.name
    if (this.ins_id) params.ins_id = this.ins_id
    if (this.hashtag) params.hashtag = this.hashtag
    this.getData(params)
  }
  pageChanged(evt?) {
    this.currentPage = evt.page
  }

  setPage(pageNo: number): void {
    this.currentPage = pageNo;
  }
  async rmlist(id) {
    let result = await this.func.alertpopup('<h4>คุณต้องการลบใช่หรือไม่</h4>', `<i class="icon-info mr-2"></i>ลบผู้ใช้งาน`, 1);
    if (result) {
      let res: any = await this.peoS.rmUser({ id: id })
      if (res.id) {
        this.swalSuccessRm.fire()
        let request_data = { url: `${this.apiService.api.users}/${id}}` };
        this.commonService.activityLog(ActionEnum.Delete, 'User Management ➡ ผู้ใช้งาน', JSON.stringify(request_data)).subscribe(res => { }, error => { console.log(error); });
        this.getData()
      }
    }
  }
  onSelect(event: TypeaheadMatch): void {
    console.log(this.name)
    console.log(event.item)
    if (event.item.firstname) {
      this.name = event.item.firstname
    } else {
      this.ins_id = event.item.id
    }
  }
  blur(evt?: any) {
    if (!this.ins_id || !this.ins_name) {
      this.ins_name = null
      this.ins_id = null
    }
  }
}
