import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { RouteDirectionEnum } from '../../../models/common-enum';
import * as _ from 'lodash';
import * as moment from 'moment';
import * as ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import { CommonService } from 'src/app/services/common.service';
import { ActionEnum } from 'src/app/models/common-enum';
import { DatePipe } from '@angular/common';
import { days } from '../../sign-control/schedule/days';
import { ReportService } from 'src/app/services/user/report.service';
type AOA = any[][];

@Component({
  selector: 'app-sensor-measurement-new',
  templateUrl: './sensor-measurement-new.component.html',
  styleUrls: ['./sensor-measurement-new.component.scss']
})
export class SensorMeasurementNewComponent implements OnInit {
  public allowCustom: boolean = false;
  stationList: any
  laneChecked: any = [null, true, true, true, true]
  classList: any = [];
  classList_search: any[] = [];

  public staList: Array<{ name: string, id: number }>;
  sta: any;
  directionEnum = RouteDirectionEnum;
  dir: number = this.directionEnum.IN;
  public data_sta: Array<{ name: string, id: number }>;
  st_dt: any;
  en_dt: any;
  classval: any = [];
  nums_lane: any

  public data_time_range: Array<{ name: string, value: string, date_format: string, steps: any }> = [
    { name: '5 นาที', value: '5m', date_format: 'dd/MM/yyyy HH:mm', steps: { hour: 1, minute: 5 } },
    { name: '15 นาที', value: '15m', date_format: 'dd/MM/yyyy HH:mm', steps: { hour: 1, minute: 15 } },
    { name: '1 ชั่วโมง', value: '1h', date_format: 'dd/MM/yyyy HH:mm', steps: { hour: 1, minute: 60 } }
  ]
  time_range = { name: '5 นาที', value: '5m', date_format: 'dd/MM/yyyy HH:mm' };
  date_format = 'dd/MM/yyyy HH:mm';
  steps: any = { hour: 1, minute: 5 };
  totalItems: any;
  is_loading_excel: any;
  is_loading: any;
  data_list: any = [];
  currentPage: number = 1;
  itemsPerPage: number = 50;
  constructor(
    private rS : ReportService,
    private datePipe: DatePipe,
    private dashboardService: DashboardService,
    private commonService: CommonService) {
    this.commonService.activityLog(ActionEnum.Get, 'Traffic Information ➡ รายงานรายคันจาก SMC').subscribe(res => { }, error => { console.log(error); });
  }

  getId: any
  ngOnInit() {
    this.st_dt = null;
    this.en_dt = null;
    this.getStationList();
    setTimeout(() => {
      this.data_sta = _.cloneDeep(this.staList);
    }, 1000);
  }
  pageChanged(e){

  }
  handleFilter(value) {
    if (value.length > 0) {
      this.data_sta = this.data_sta.filter(s => s.name != null).filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_sta = _.cloneDeep(this.staList);
    } else {
      this.data_sta = [];
    }
  }
  handleValue(value) {
    if (value) {
      this.sta = value;
      this.dashboardService.searchVehClass(this.sta.id).subscribe(
        res => {
          if (res.code == 1) {

            if (!_.isEmpty(res.data)) {
              this.classList = res.data;
            } else {
              this.classList = [];
            }
          }
        },
        err => {
          this.classList = [];
          console.log(JSON.stringify(err.statusText));
        });
    } else {
      this.sta = null;
    }
  }

  handleValueTime(value) {
    this.date_format = value.date_format;
    this.steps = value.steps;
  }
  async getStationList() {
    let point = [];
    this.dashboardService.getStationlistPER().subscribe(
      res => {
        if (res.code == 1) {
          this.staList = _.cloneDeep(res.data);
          this.data_sta = _.cloneDeep(res.data);

          this.stationList = _.cloneDeep(point);
        }
      },
      err => {
        console.log(JSON.stringify(err.statusText));
      });
  }
  search_data: any
  async search() {
    this.currentPage = 1;
    this.is_loading = true;
    this.classval = [];
    this.classList_search = [];

    for (const i of this.classList) {
      if (i.checked) {
        this.classval.push(i.veh_class_id);
        this.classList_search.push(i);
      }
    }
    let _lane: any = [];
    let data: any = {
      station_id: (this.sta) ? this.sta.id : null,
      veh_class: this.classval,
    }
    for (const key in this.laneChecked) {
      if (Object.prototype.hasOwnProperty.call(this.laneChecked, key)) {
        const element = this.laneChecked[key];
        if (element) _lane.push(+key)
      }
    }
    data.lane = _lane;

    if (this.st_dt) data.st_date = (this.st_dt) ? moment(this.st_dt, "DD/MM/YYYY").toISOString() : null;
    if (this.en_dt) data.en_date = (this.en_dt) ? moment(this.en_dt, "DD/MM/YYYY").toISOString() : null;
    if (this.time_range) {
      data.interval = this.time_range.value;
    }
    this.search_data = data;
    // console.log(data)
    let res : any = await this.rS.getMeasureAgg(data)
    this.totalItems = 0;
    if(res.length){
      this.data_list = res;
      this.totalItems = this.data_list.length;
    }
    this.is_loading = false;
  }
  changeClass() {
    // this.classval = this.classval.filter((item) => item.selected)
    console.log(this.classList)
  }
  
  //-------------------------------Set filter datetimepicker---------------------------------//
  min : any
  max : any
  chgDatetime(evt:any){
    var today = new Date(evt);
    // this.min = new Date(today.setDate(today.getDate()-7))
    this.min = evt
    this.max = new Date(today.setDate(today.getDate()+7))
  }
  //-------------------------------EXCEL---------------------------------//
  excel_data: AOA = [];
  workbook = new ExcelJS.Workbook();
  generateExcel() {
    if (this.data_list.length > 0) {
      this.is_loading_excel = true;
      this.workbook = new ExcelJS.Workbook();
      let worksheet = this.workbook.addWorksheet('Sheet1');
      let titleRow = worksheet.addRow([`รายงานข้อมูลรายคันจากอุปกรณ์ตรวจวัด จุดติดตั้ง${this.sta.name} จากวันที่ ${this.datePipe.transform(this.st_dt, 'dd/MM/yyyy HH:mm')} ถึงวันที่ ${this.datePipe.transform(this.en_dt, 'dd/MM/yyyy HH:mm')} ${this.time_range.value? ' ราย '+ this.time_range.value:''}`]);
      let speed_null = [];
      let count_null = [];
      let speed_class = [];
      let count_class = [];
      this.classList_search.forEach((item, index) => {
        if (index != 0) {
          speed_null.push("");
          count_null.push("");
        }
        speed_class.push(item.veh_class_name);
        count_class.push(item.veh_class_name);
      });
      // let headerRow = ["ลำดับ", "เวลา", "ช่องจราจร", "veh_class", "speed",'Length'];
      let headerRow = ["ลำดับ", "เวลา", "ช่องจราจร", "avg_speed", "avg_speed85", "gap", "headway", "occupancy"];
      // let headerRow1 = worksheet.addRow([...headerRow]);
      let headerRow1 = worksheet.addRow([...headerRow, ...count_class]);

      headerRow1.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFC6EFCE' },
          bgColor: { argb: 'FF006100' }
        }
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      })

      // Add Data and Conditional Formatting
      console.log(this.data_list)
      this.data_list.forEach((item, index) => {
        let count_class_val = [];
        this.classList_search.forEach((item2) => {
          count_class_val.push(item["count_class" + item2.veh_class_id]);
        });
        const d = [index + 1,
        item.sensor_datetime ? this.datePipe.transform(item.sensor_datetime, 'dd/MM/yyyy HH:mm') : '',
        item.lane,
        item.avg_speed,
        // item.veh_class,
        // item.speed != null ? item.speed.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : '',
        // item.length]
        item.avg_speed85 != null ? item.avg_speed85.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : '',
        item.gap != null ? item.gap.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : '',
        item.headway != null ? item.headway.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : '',
        item.occupancy != null ? item.occupancy.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : '',
        // ...count_class_val];
        ...count_class_val];
        let row = worksheet.addRow(d);
      });
      worksheet.mergeCells(1, 1, 1, headerRow.length + this.classList_search.length);
      // worksheet.mergeCells(1, 1, 1, headerRow.length);

      for (var i = 1; i <= headerRow.length + (this.classList_search.length); i++) {
        if (i == 1 || i == 3) {
          worksheet.getColumn(i).width = 10;
        } else if (i == 2) {
          worksheet.getColumn(i).width = 20;
        } else {
          worksheet.getColumn(i).width = 16;
        }

      }
      setTimeout(() => {
        worksheet.eachRow(function (Row, rowNum) {
          if (rowNum <= 2) {
            worksheet.getRow(rowNum).height = 20;
            Row.font = { bold: true }
          }
          Row.eachCell(function (Cell, cellNum) {
            if (rowNum == 1) {
              Cell.alignment = {
                vertical: 'middle',
                horizontal: 'left'
              }
            }
            if (rowNum >= 2) {
              Cell.alignment = {
                vertical: 'middle',
                horizontal: 'center'
              }
            }
          })
        })
      }, 500);

      this.createExcel();
    }
  }
  createExcel() {
    setTimeout(() => {
      this.is_loading_excel = false;
      this.workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(blob, `รายงานข้อมูลรายคันจากอุปกรณ์ตรวจวัด${this.time_range.value? ' ราย '+ this.time_range.value:''}.xlsx`);
      })
    }, 1000);
  }
}
