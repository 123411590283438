import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EventService } from 'src/app/services/user/event.service';
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();
  values: any;

  rainWeight : any[] = []
  rainSpeed : any[] = []
  rainIcon : any[] = []
  rainalert : any[] = []
  intervalTime : number
  constructor(
    public bsModalRef: BsModalRef,
    public eS : EventService,
  ) { }

  ngOnInit(): void {
    if(this.values.id) this.getRain({id:this.values.rain_detection_criteria_id})
    console.log(this.values)
  }
  async getRain(data:any){
    let res : any = await this.eS.getRain(data)
    if(res){
      this.intervalTime = res.interval_time
      this.rainWeight = [res.rain_l1,res.rain_l2,res.rain_l3,res.rain_l4]
      this.rainSpeed = [res.rain_l1_spd,res.rain_l2_spd,res.rain_l3_spd,res.rain_l4_spd]
      this.rainIcon = [res.icon_l1,res.icon_l2,res.icon_l3,res.icon_l4]
      this.rainalert = [res.alert_l1,res.alert_l2,res.alert_l3,res.alert_l4]
    }
  }
  ImgBase64(evt?: any,imgPath? : any){
    var reader = new FileReader();
    reader.readAsDataURL(evt.target.files[0]);
    reader.onload = async (_event) => {
      let res : any = await this.eS.uploadIconImg({image:reader.result})
      if(res) this.rainIcon[imgPath] = res.img_url 
    }
  }
  chgChk(){
    console.log(this.rainalert)
  }
  async save(){
    let data :any = {
      device_id : this.values.device_id,
      interval_time:this.intervalTime,
    }
    if(this.rainWeight[0]) data.rain_l1=this.rainWeight[0]
    if(this.rainWeight[1]) data.rain_l2=this.rainWeight[1]
    if(this.rainWeight[2]) data.rain_l3=this.rainWeight[2]
    if(this.rainWeight[3]) data.rain_l4=this.rainWeight[3]
    if(this.rainSpeed[0]) data.rain_l1_spd=this.rainSpeed[0]
    if(this.rainSpeed[1]) data.rain_l2_spd=this.rainSpeed[1]
    if(this.rainSpeed[2]) data.rain_l3_spd=this.rainSpeed[2]
    if(this.rainSpeed[3]) data.rain_l4_spd=this.rainSpeed[3]
    if(this.rainIcon[0]) data.icon_l1=this.rainIcon[0]
    if(this.rainIcon[1]) data.icon_l2=this.rainIcon[1]
    if(this.rainIcon[2]) data.icon_l3=this.rainIcon[2]
    if(this.rainIcon[3]) data.icon_l4=this.rainIcon[3]
    if(this.rainalert[0]) data.alert_l1=this.rainalert[0]
    if(this.rainalert[1]) data.alert_l2=this.rainalert[1]
    if(this.rainalert[2]) data.alert_l3=this.rainalert[2]
    if(this.rainalert[3]) data.alert_l4=this.rainalert[3]
    
    let res : any = await this.eS.addRain(data)
    if(res) this.confirm();
  }
  
  confirm() {
    this.event.emit(true);
    this.bsModalRef.hide();
  }
  close() {
    this.event.emit(false);
    this.bsModalRef.hide();
  }
}
