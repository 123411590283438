import { Component, OnInit } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { EventService } from 'src/app/services/user/event.service';
import { environment as env } from 'src/environments/environment';
import {dataHeatMap} from '../trees'
import * as mm from 'moment';

import * as turf_bbox from '@turf/bbox';
@Component({
  selector: 'app-rank',
  templateUrl: './rank.component.html',
  styleUrls: ['./rank.component.scss']
})
export class RankComponent implements OnInit {

  map: any;
  dataHeat : any = dataHeatMap
  st_dt : any
  en_dt : any
  eventListFeatures : any[] = []
  graphData : any = []
  gLabel : any = []
  xGroupName : any = []
  color : any = ['#ff4b4b','#ffa750','#f7ff2d','#73ff2d','#2dff74','#2dffde','#2debff','#2dadff','#2d5eff','#ac2dff','#ff2de8','#ff2d76','#e67584']
  constructor(
    private eS : EventService,
  ) { }

  ngOnInit(): void {
    this.initMap()
    this.search()
  }
  async search(){
    console.log(this.st_dt,this.en_dt)
    this.eventListFeatures = []
    this.xGroupName = []
    this.graphData = []
    this.gLabel = []
    let data = {
      st_date:this.st_dt? mm(this.st_dt).toISOString():mm().subtract(5, 'months').toISOString(),
      en_date:this.en_dt? mm(this.en_dt).toISOString():mm().toISOString(),
    }
    console.log(data)
    let res : any = await this.eS.getEvtDashboardRank(data)
    if(res.chart&&res.chart.length) this.setGraph(res.chart)
    if(res.heat_map&&res.heat_map.length) this.setMap(res.heat_map)
    if(!res.heat_map) console.log("No loc map")
  }
  setGraph(vals?:any){
/**
 * {
		“group_name”: “route2”,
		“rank_data”: [
			{
				“sub_type”: “subtype_a”,
				“count”: 10
      }
    ]
  }
 */
    let round = 0
    for (const i of vals) {
      let ll = 0
      this.xGroupName.push(i.group_name)
      // if(!this.graphData.length) this.graphData = []
      for (const j of i.rank_data) {
        if(!this.graphData[ll]) this.graphData[ll] = []
        this.graphData[ll].push(j.count)
        if(round==0){
          this.gLabel.push(j.sub_type)
        }
        ll++;
      }
      round++;
    }
  }
  setMap(vals?:any){
    if(!vals.length) return
    for (const i of vals) {
      this.eventListFeatures.push(this.geo_feature(i))
    }
    this.updateMap()
    // setTimeout(() => {
    //   this.initMap()
    // }, 1000);
  }
  async initMap() {
    this.map = new mapboxgl.Map({
      accessToken: env.mapbox.accessToken,
      container: 'map-dashboard',
      // style: env.mapbox.style,
      style: 'mapbox://styles/mapbox/dark-v10',
      zoom: 8,
      center: [100.523186, 13.736717],
    });
    // this.spiderifier = new MapboxglSpiderifier(this.map, {
    //   onClick: function(e, spiderLeg){
    //     console.log('Clicked on ', spiderLeg);
    //   },
    //   markerWidth: 40,
    //   markerHeight: 40,
    // });
    this.map.dragRotate.disable();

    this.map.on('load', (event: any) => {

      this.map.setPaintProperty('water', 'fill-color', '#000000');
      this.map.addSource('trees', {
        'type': 'geojson',
        'data': this.dataHeat
      });

      this.map.addLayer(
        {
          'id': 'trees-heat',
          'type': 'heatmap',
          'source': 'trees',
          'maxzoom': 15,
          'paint': {
            // increase weight as diameter breast height increases
            'heatmap-weight': {
              'property': 'dbh',
              'type': 'exponential',
              'stops': [
                [1, 0],
                [62, 1]
              ]
            },
            // increase intensity as zoom level increases
            'heatmap-intensity': {
              'stops': [
                [11, 1],
                [15, 3]
              ]
            },
            // use sequential color palette to use exponentially as the weight increases
            'heatmap-color': [
              'interpolate',
              ['linear'],
              ['heatmap-density'],
              0,
              'rgba(236,222,239,0)',
              0.2,
              'rgb(208,209,230)',
              0.4,
              'rgb(166,189,219)',
              0.6,
              'rgb(103,169,207)',
              0.8,
              'rgb(28,144,153)'
            ],
            // increase radius as zoom increases
            'heatmap-radius': {
              'stops': [
                [11, 15],
                [15, 20]
              ]
            },
            // decrease opacity to transition into the circle layer
            'heatmap-opacity': {
              'default': 1,
              'stops': [
                [14, 1],
                [15, 0]
              ]
            }
          }
        },
        'waterway-label'
      );

      this.map.addLayer(
        {
          'id': 'trees-point',
          'type': 'circle',
          'source': 'trees',
          'minzoom': 14,
          'paint': {
            // increase the radius of the circle as the zoom level and dbh value increases
            'circle-radius': {
              'property': 'dbh',
              'type': 'exponential',
              'stops': [
                [{ zoom: 15, value: 1 }, 5],
                [{ zoom: 15, value: 62 }, 10],
                [{ zoom: 22, value: 1 }, 20],
                [{ zoom: 22, value: 62 }, 50]
              ]
            },
            'circle-color': {
              'property': 'dbh',
              'type': 'exponential',
              'stops': [
                [0, 'rgba(236,222,239,0)'],
                [10, 'rgb(236,222,239)'],
                [20, 'rgb(208,209,230)'],
                [30, 'rgb(166,189,219)'],
                [40, 'rgb(103,169,207)'],
                [50, 'rgb(28,144,153)'],
                [60, 'rgb(1,108,89)']
              ]
            },
            'circle-stroke-color': 'white',
            'circle-stroke-width': 1,
            'circle-opacity': {
              'stops': [
                [14, 0],
                [15, 1]
              ]
            }
          }
        },
        'waterway-label'
      );

      this.search()
    })
    
    this.map.on('click', 'trees-point', (event) => {
      new mapboxgl.Popup()
      .setLngLat(event.features[0].geometry.coordinates)
      .setHTML(`<strong>DBH:</strong> ${event.features[0].properties.dbh}`)
      .addTo(this.map);
    });
  }
  
  geo_feature(vals:any){
    return {
      "type": "Feature",
      "properties": vals,
      "geometry": {
        "type": "Point",
        "coordinates": [vals.lng,vals.lat]
      }
    }
    
  }
  updateMap() {
    if (this.map.getSource('trees') != null) {
      this.map.getSource('trees').setData({
        'type': 'FeatureCollection',
        'features': this.eventListFeatures
      });

      let bbox = turf_bbox.default({
        type: 'FeatureCollection',
        features: this.eventListFeatures
      });
      this.map.fitBounds(bbox, {
        padding: { top: 60, bottom: 60, left: 60, right: 60 }
      });

    }
    // this.loading = false
  }
}
