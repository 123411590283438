export class Station {
    id: number;
    name: string;
    station_type: number;
    route: string;
    km: number;
    m: number;
    dir: number;
    status: number;
    lat: number;
    lng: number;
    owner_id: number;
    owner_str: string;
    prov_id: number;
    prov_str: string;
    dist_id: number;
    dist_str: string;
    created_datetime?: Date;
    lastupdated_datetime?: Date;
    license_plate_number: string;
    licenes_plate_province: number;
    chassis_no: string;
    mdvr_no: string;
    vendor_id?: number;
    structure_type_id?: number;
}

export class ReqAddStation {
    name: string;
    station_type: number;
    route: string;
    km?: number;
    m?: number;
    dir?: number;
    status?: number;
    lat?: number;
    lng?: number;
    owner_id?: number;
    vendor_id?: number;
    prov_id?: number;
    dist_id?: number;
    license_plate_number: string;
    licenes_plate_province: number;
    chassis_no: string;
    mdvr_no: string;
    structure_type_id?: number;
    operation_zone_id?: number;
}
