import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FuncService } from 'src/app/services/user/func.service';
import { EventService } from 'src/app/services/user/event.service';
import { EventModalComponent } from './event-modal/event-modal.component';
import { DeviceService } from 'src/app/services/user/device.service';
import { CommonService } from 'src/app/services/common.service';
import { permission } from 'src/app/views/event/menu';
import * as mm from 'moment';
import { EventDetailComponent } from '../event-detail/event-detail.component';
import { evt_status } from '../values';
import { ActionEnum } from 'src/app/models/common-enum';
import { Router, ActivatedRoute } from '@angular/router';

import { DateInputCustomFormatPlaceholder } from '@progress/kendo-angular-dateinputs';
import * as FileSaver from 'file-saver';
import * as ExcelJS from 'exceljs';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { DetailEffectComponent } from '../detail-effect/detail-effect.component';
import 'moment/locale/th'  // without this line it didn't work
moment.locale('th')
@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
})
export class EventComponent implements OnInit {
  public formatPlaceholder: DateInputCustomFormatPlaceholder = {
    day: 'วัน',
    month: 'เดือน',
    year: 'ปี',
    hour: 'ชั่วโมง',
    minute: 'นาที',
    second: 'วินาที',
  };

  modalRef: BsModalRef;
  modalDetailEvt: BsModalRef;
  allowCustom: boolean = false;
  allData: any = [];
  route_list: any;
  search_txt: any;
  search_route: any;
  direction: any = [true, true];
  st_date: any;
  en_date: any;
  data_list: any = [];
  groupList: any = [];
  allType: any;
  list: any;
  total: number = 0;
  currentPage: number = 1;
  itemsPerPage: number = 15;
  data_route: any;
  route: any;
  st_km: any;
  en_km: any;
  src_group_id: any = null;
  group_id: any = null;
  type_id: any = null;
  subtype_id: any = null;
  status: any = null;

  all: any = {};
  total_items: any
  total_pages: any

  isAdd: boolean = false;
  isDetail: boolean = false;
  isDelete: boolean = false;
  statusList: any = evt_status;
  typeList: any;
  subtypeList: any;
  paramsObject: any;

  province_list_all: any;
  province_list: any;
  district_list_all: any;
  district_list: any;
  department_list_all: any;
  department_list: any;
  search_province: any;
  search_district: any;
  search_department: any;
  
  srcItems : any = []
  src_selected : any
  constructor(
    private func: FuncService,
    private modalService: BsModalService,
    private evt_S: EventService,
    private devS: DeviceService,
    private commonService: CommonService,
    private router: Router,
    private route_p: ActivatedRoute
  ) {
    this.commonService
      .activityLog(ActionEnum.Get, 'Event Management ➡ Event list')
      .subscribe(
        (res) => {},
        (error) => {
          console.log(error);
        }
      );
  }

  async ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.isAdd = await this.commonService
      .getPermission(permission.incident.add_id)
      .toPromise();
    this.isDetail = await this.commonService
      .getPermission(permission.incident.detail_id)
      .toPromise();
    this.isDelete = await this.commonService
      .getPermission(permission.incident.delete_id)
      .toPromise();
    this.getData();
    
    this.getSrc();
  }

  async getSrc() {
    let res : any = await this.evt_S.getSrc();
    if(res){
      res.push({id:5,descript:'แจ้งเตือนผ่านเจ้าหน้าทั้งหมด'})
      this.srcItems = res
    }
  }
  handleValueStatus(e:any){
    if (e === undefined) {
      this.status = null;
    } else {
      this.status = e.id
    }
  }
  handleValueSrc(e:any){
    console.log(this.src_selected,e)
    if (e === undefined) {
      this.src_selected = null;
    } else {
      this.src_selected = e
    }
  }
  handleValueType(e:any){
    this.subtypeList = []
    if (e === undefined) {
      this.type_id = null
    }else{
      this.type_id = e.event_type_id
      this.subtypeList = e.subtype
    }
  }

  params_list: any;
  clearPosParam() {
    this.router.navigateByUrl('event/list');
  }
  async getParams() {
    let result: any = this.route_p.params.subscribe(async (params) => {
      this.params_list = params;
      await this.getEvt({ id: params.evt_id });
      this.openModal(this.data_list[0]);
      this.clearPosParam();
    });
    result.unsubscribe();
    // this.search()
  }
  FilterRoute(evt: any) {
    if (evt.length > 0) {
      this.route_list = this.route_list.filter((res) => res.includes(evt));
    } else if (evt.length == 0) {
      this.route_list = this.route;
    } else {
    }
  }
  async ChageRoute(evt) {
    if (evt === undefined) delete this.all.route
  }
  FilterProvince(evt: any) {
    if (evt.length > 0) {
      this.province_list = this.province_list.filter((txt) => txt.province_name.toLowerCase().indexOf(evt.toLowerCase()) !== -1);
    } else if (evt.length == 0) {
      this.province_list = this.province_list_all;
    } else {
    }
  }
  async ChageProvince(evt) {
    if (evt) {
      this.search_province = evt;
      this.district_list = this.district_list_all.filter(v => v.id_province == evt.province_id)

    } else {
      this.search_province = null;
      this.search_district = null;
      this.search_department = null;
      this.district_list = []
      this.department_list = []
    }
  }
  async ChageDistrict(evt) {
    if (evt) {
      this.search_district = evt;
      this.department_list = this.department_list_all.filter(h => h.id_highway_district == evt.id)
    } else {
      this.search_district = null;
      this.search_department = null;
      this.department_list = []
    }
  }
  FilterDistrict(evt: any) {
    if (evt.length > 0) {
      this.district_list = this.district_list.filter((txt) => txt.highway_district.toLowerCase().indexOf(evt.toLowerCase()) !== -1);
    } else if (evt.length == 0) {
      this.district_list = this.district_list_all.filter(v => v.id_province == this.search_province.province_id);
    } else {
    }
  }
  async ChageDepartment(evt) {
    if (evt) {
      this.search_department = evt;
    } else {
      this.search_department = null;
    }
  }
  FilterDepartment(evt: any) {
    if (evt.length > 0) {
      this.department_list = this.department_list.filter((txt) => txt.highway_department.toLowerCase().indexOf(evt.toLowerCase()) !== -1);
    } else if (evt.length == 0) {
      this.department_list = this.department_list_all.filter(v => v.id_province == this.search_province.province_id);
    } else {
    }
  }

  async search(_ALL?:boolean) {
    this.all = {}
    if(!_ALL) this.data_list = null;
    if (this.search_route && this.search_route !== 'null')
      this.all.route = Number(this.search_route);
    if (this.src_group_id && this.src_group_id !== 'null')
      this.all.src_group_id = this.src_group_id;
    if (this.group_id && this.group_id !== 'null')
      this.all.group_id = this.group_id;
    if (this.type_id && this.type_id !== 'null')
      this.all.event_type_id = this.type_id;
    if(this.subtype_id) this.all.event_subtype_id = this.subtype_id
    if (this.status && this.status !== 'null') this.all.status = this.status;
    if (this.st_km) {
      this.all.st_km = this.st_km;
      this.all.st_m = 0;
    }
    if (this.en_km) {
      this.all.en_km = this.en_km;
      this.all.en_m = 1000;
    }
    if (this.st_date)
      this.all.st_date = mm(this.st_date).toISOString();
    if (this.en_date)
      this.all.en_date = mm(this.en_date)
        .endOf('day')
        .toISOString();

    if (this.type_id === null) delete this.all.event_type_id
    if (this.status === null) delete this.all.status

    if (!this.st_date||this.st_date === undefined || this.st_date === null) this.all.st_date = mm("1999-01-01").toISOString()
    if (!this.en_date||this.en_date === undefined || this.en_date === null) this.all.en_date = mm("2400-01-01").toISOString()

    if (this.st_km === undefined || this.st_km == '') delete this.all.st_km && delete this.all.st_m 
    if (this.en_km === undefined || this.en_km == '') delete this.all.en_km && delete this.all.en_m
    
    if(this.search_province) this.all.province_id = this.search_province.province_id
    if(this.search_district) this.all.district_id = this.search_district.id
    if(this.search_department) this.all.department_id = this.search_department.id
    
    
    if(this.src_selected) this.all.event_src_id = this.src_selected.id
    if(this.src_selected&&this.src_selected.id==5) this.all.event_src_id = '1,2,3'
    this.all.page = this.currentPage ;
    this.all.limit = this.itemsPerPage;
    if(_ALL) return this.all;
    if(!_ALL) {
      this.currentPage = 1
      this.getEvt(this.all);
    }
    // this.getEvt(this.all);
  }
  async getEvt(params?: any) {
    let res: any = await this.evt_S.getEvt(params);
    if (res.data.length) {
      for (const i of res.data) {
        i.status_name = this.statusList.find((v) => i.status == v.id).name;
      }
    }
    // res.data =res.data.map((v)=>{
    //   v.en_road = null
    //   return v
    // })
    console.log(res.data)
    if (res) this.data_list = res.data;
    if (res) this.total_items = res.total_items
    if (res) this.total_pages = res.total_pages
  }
  async rmlist(id) {
    let result = await this.func.alertpopup(
      '<h4>คุณต้องการลบใช่หรือไม่</h4>',
      `<i class="icon-info mr-2"></i>ลบผู้ใช้งาน`,
      1
    );
    if (result) {
      let res: any = await this.evt_S.rmEvt(id);
      if (res) this.search();
    }
  }
  async getData() {
    // this.allData = await this.evt_S.getIncidentType()
    // this.allType = this.allData
    // this.getGroup();
    this.getType();
    this.getRoute();
    this.getProvince();
    this.getDistrict();
    this.getDepartment();
    if (this.router.url.match('fromIncident=true;')) {
      this.getParams();
    } else if (this.route_p.snapshot.queryParamMap.get('event_type_id')) {
      this.route_p.queryParamMap.subscribe((params) => {
        this.paramsObject = { ...params.keys, ...params };
        this.type_id = this.paramsObject.params.event_type_id;
        this.status = this.paramsObject.params.status;
      });
      this.search();
    } else {
      this.all.page = this.currentPage;
      this.all.limit = this.itemsPerPage;
      this.all.st_date = mm("1999-01-01").toISOString()
      this.all.en_date = mm("2400-01-01").toISOString()
      this.getEvt(this.all);
    }
  }
  async getRoute() {
    this.route_list = await this.devS.getRoute();
    this.route = this.route_list;
  }
  async getProvince() {
    this.province_list = await this.evt_S.getProvince();
    this.province_list_all = _.cloneDeep(this.province_list);
  }
  async getDistrict() {
    this.district_list = await this.evt_S.getDistrict();
    this.district_list_all = _.cloneDeep(this.district_list);
  }
  async getDepartment() {
    this.department_list = await this.evt_S.getDepartment();
    this.department_list_all = _.cloneDeep(this.department_list);
  }
  // async getGroup() {
  //   this.groupList = await this.evt_S.getEvtGroup();
  // }
  async getType() {
    // this.typeList = await this.evt_S.getEvtType();
    this.typeList = await this.evt_S.getmapEvtSubType();
  }
  openModal(data?: any) {
    const initialState = {
      values: data,
      list: this.list,
      inc_type: this.allType,
    };
    // this.modalRef = this.modalService.show(EventModalComponent, {
    this.modalRef = this.modalService.show(DetailEffectComponent, {
      initialState,
      class: 'w-80',
      backdrop: 'static',
    });
    this.modalRef.content.event.subscribe((data) => {
      // this.getData()
      if (data) this.search();
    });
  }
  pageChanged(event: any): void {
    if (this.currentPage != event.page) {
      this.currentPage = event.page;
      this.all.page = this.currentPage;
      this.getEvt(this.all);
      //   // this.getDevice();
    }
  }
  handleFilter(value) {
    if (value.length > 0) {
      this.allData = this.allData.filter(
        (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    } else if (value.length == 0) {
      this.allData = _.cloneDeep(this.allType);
    } else {
      this.allData = [];
    }
  }
  handleValue(value) {
    if (value) {
      this.search_txt = value;
    } else {
      this.search_txt = null;
    }
  }

  addEvent() {
    const initialState = {
      values: null,
    };
    this.modalDetailEvt = this.modalService.show(EventDetailComponent, {
      initialState,
      class: 'w-80',
      backdrop: 'static',
    });
    this.modalDetailEvt.content.event.subscribe((data) => {
      if (data) this.getData();
      // return resolve(data);
    });
  }

  // Export Excel-----------------
  // excel_data: AOA = [];
  workbook = new ExcelJS.Workbook();
  async genExcel() {
//     console.log(moment().format('LLLL'))
//     console.log(moment().fromNow())
    Swal.fire({
      title: 'Please wait export excel',
      text: '',
      // icon: 'error',
      // confirmButtonText: 'OK'
      // timer: 1500,
      showConfirmButton: false,
      imageUrl: './assets/event/loading2.gif',
      // imageWidth: 400,
      imageHeight: 200,
    })
    const title = 'Event list';
    // let header = _.union(["วันที่", "วัน", "รวมทุกประเภท (คัน)"], ["1","2","3"]);
    let header = ["#", "ประเภทเหตุการณ์", "สถานะการดำเนินการ", "ตำแหน่งของเหตุการณ์", "Create datetime"];
    
    this.workbook = new ExcelJS.Workbook();
    let worksheet = this.workbook.addWorksheet('รายเดือน');
    worksheet.addRow([]);
    let headerRow1 = worksheet.addRow([title]);
    headerRow1.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC6EFCE' },
        bgColor: { argb: 'FF006100' }
      }
    })
    worksheet.addRow([]);
    worksheet.addRow(header);
    worksheet.mergeCells(`A2:E2`)
    for (var i = 3; i <= 5; i++) {
      worksheet.getColumn(i).width = 30;
    }
    let ll = 1
    let word_lenth = 1;
    let pos_lenth = 1;
    let r = await this.search(true)
    delete r.page
    delete r.limit
    let res: any = await this.evt_S.getEvt(r);
    if(!res||!res.data.length) console.log("error res")
    for (const i of res.data) {
    // await this.search(true);
    // for (const i of this.data_list) {
      i.status_name = this.statusList.find((v) => i.status == v.id).name;
      let t_evt = `${i.event_group_name||''} ${i.event_type_name}`
      word_lenth = t_evt.length+10>word_lenth? t_evt.length+10:word_lenth
      let pos = (!i.st_dir&&!i.en_dir)? '':`${i.st_dir||''}-${i.en_dir||''}`
      switch(i.st_dir) {
        case 1:
          pos='ขาเข้า';break;
          case 2:
          pos='ขาออก';break;
          case 3:
          pos='ขาเข้า-ขาออก';break;
        default:
      }
      if(i.st_road) pos+="สายทาง "+i.st_road + " (" + i.st_km + " + " + i.st_m + ")"
      if(i.st_dir&&i.en_dir) pos+='/'
      switch(i.en_dir) {
        case 1:
          pos+='ขาเข้า';break;
          case 2:
          pos+='ขาออก';break;
          case 3:
          pos+='ขาเข้า-ขาออก';break;
        default:
      }
      moment.localeData('th')
      pos_lenth = pos.length>pos_lenth? pos.length:pos_lenth
      if(i.en_road) pos+="สายทาง "+i.en_road + " (" + i.en_km + " + " + i.en_m + ")"
      worksheet.addRow([
        ll,
        t_evt,
        i.status_name,
        pos,
        i.created_datetime? `${moment(i.created_datetime).format("DD MMM YYYY")}`:'',
      ]);
      ll++;
    }
    
    worksheet.getColumn(2).width = word_lenth;
    worksheet.getColumn(4).width = pos_lenth+20;
    setTimeout(() => {
      worksheet.eachRow(function (Row, rowNum) {
        worksheet.getRow(rowNum).height = 25;
        if (rowNum <= 4) {
          Row.font = { bold: true }
        }
        
        Row.eachCell(function (Cell, cellNum) {
          Cell.alignment = {
            vertical: 'middle',
            horizontal: 'center'
          }
          if(rowNum>4&&cellNum==2){
            Cell.alignment = {
              vertical: 'middle',
              horizontal: 'left'
            }
          }
        })
      });
    }, 500);
    this.createExcel();
  }
  
  createExcel() {
    setTimeout(() => {
      // this.is_loading_excel = false;
      this.workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        // FileSaver.saveAs(blob, `EventList${moment().startOf('year').add(7, "hours").format("DDMMMYYYY")}.xlsx`);
        FileSaver.saveAs(blob, `EventList${moment().add(7, "hours").format("DDMMMYYYY")}.xlsx`);
      })
      Swal.fire('Saved!', '', 'success')
    }, 1000);
  }
}
