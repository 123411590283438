import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';
import { isEmpty } from 'lodash';
import { FuncService } from './func.service';
import { CommonService } from '../common.service';
import { AuthService } from '../auth/auth.service';
import { ReportService } from '../report/report.service';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  api = {
    route: `${env.api.domain_assets}/route`,
    login: `${env.api.domain_auth}/login`,
    // user management path
    user: `${env.api.domain_auth}`,
    users: `${env.api.domain_auth}/users`,

    // Institution
    ins: `${env.api.domain_auth}/institution`,

    // Group
    devicepermissionG: `${env.api.domain_assets}/users/devicepermission`,
    userpermissionG: `${env.api.domain_auth}/users/permission`,
    permissionG: `${env.api.domain_auth}/permission`,
    group: `${env.api.domain_auth}/group`,
    ugroup: `${env.api.domain_auth}/usergroup`,
    event: `${env.api.domain_event}/event`,
    rain: `${env.api.domain_event}/v2/rain/detection`,
    devices: `${env.api.domain_event}/device`,
    log: `/log`,
    info: `/info`,
    type: `/type`,
    image: `/image`,
    iconImage: `/icon/image`,
    doc: `/document`,
    imageLog: `/log/image`,
    signLog: `/log/sign`,
    subtype: `/subtype`,
    mapsubtype: `/type/subtype`,
    effect: `/effect`,
    approve: `/approve`,
    grp: `/group`,
    signature: `/signature`,
    confirm: '/confirm',
    configSetting: `${env.api.domain_event}/event-config-setting`,
    configSettingList: `${env.api.domain_event}/criteria/traveltime/configlist`,
    verifyMethod: `${env.api.domain_event}/event-verify-method`,
    event_verifyMethod: `${env.api.domain_event}/event-info/verify-method`,
    loc: `${env.api.domain_event}/map/location`,
    locPlanet: `${env.api.domain_event}/planet-route`,
    evtNoti: `${env.api.domain_event}/map/event/noti`,
    evtcount: `${env.api.domain_event}/map/event/count`,
    cctv: `${env.api.domain_event}/cctv`,
    iconMarker: `${env.api.domain_event}/event/icon`,
    weather: `${env.api.domain_event}/map/weather`,
    evtReport: `${env.api.domain_event}/event-pdf`,
    evtRank: `${env.api.domain_event}/dashboard/event/route/rank`,
    msg: '/log/chat',
    mapEvt: `${env.api.domain_event}/map/event`,
    r: `/route`,
    src: `/source`,
    status: `/status`,
    effType: `${env.api.domain_event}/effect/type`,
    effInfo: `${env.api.domain_event}/effect/info`,
    rainConfig: `${env.api.domain_event}/rain/config`,
    rainAlert: `${env.api.domain_event}/rain/alert`,
    incident: `${env.api.domain_event}/incident`,
    incident_type: `${env.api.domain_event}/incident/type`,
    uploadImg: `${env.api.domain_event}/upload/image`,
    traveltimeConfig: `${env.api.domain_report}/dashboard/traveltime/config`,
    traveltime: `${env.api.domain_report}/dashboard/traveltime/timeseries`,
    schematic: `${env.api.domain_assets}/schematic`,
    travelZone: `${env.api.domain_report}/dashboard/traveltime/zone`,

    // SMC
    configRoad: `${env.api.domain_assets}/device`,
    devSmc: `${env.api.domain_assets}/device/smc`,
    reversible: `${env.api.domain_assets}/reversible`,
    ticket: `${env.api.domain_assets}/ma-ticket`,
    // configRoad: `${env.api.domain_traffic_tool}/device`,
    report: `${env.api.domain_report}`,
    pvr: `${env.api.domain_report}` + '/smc/speed/stat/pvr',
    vehClass: '/smc/class',
    reportMeasureSeed: `${env.api.domain_report}/report/sensor/pvr`,
    reportMeasureAgg: `${env.api.domain_report}/report/sensor/agg`,
    reportStation: `${env.api.domain_report}/smc/station`,
    reportSensorVolume: `${env.api.domain_report}/dashboard/sensor/volume`,
    reportSensorStat: `${env.api.domain_report}/dashboard/sensor/statistic`,
    reportSensorTimeline: `${env.api.domain_report}/dashboard/sensor/timeline`,
    perStaion: `${env.api.domain_report}/station/per`,
    ma: `${env.api.domain_report}/maintenance`,
    calendar: `/calendar`,
    del: `/delete`,
    upload: `/upload`,
    smc: `/smc-config-road`,
    export: `/export`,
    vendor: `${env.api.domain_assets}/vendor`,
    structureType: `${env.api.domain_assets}/station/structure/type`,
    zone: `${env.api.domain_assets}/operation/zone`,
    province: `${env.api.domain_assets}/province`,
    district: `${env.api.domain_assets}/highway-district`,
    department: `${env.api.domain_assets}/highway-department`,
    boardsign: `${env.api.domain_sign_control}/boardsign`,
    boardsignCamera: `${env.api.domain_sign_control}/boardsign/camera`,

    //config
    speedDirection: `${env.api.domain_event}/detection/criteria/speed`,
    flowDetection: `${env.api.domain_event}/detection/criteria/flow`,
    occupanyDetaction: `${env.api.domain_event}/detection/criteria/occupancy`,
    travalTimeDetaction: `${env.api.domain_event}/v2/detection/criteria/traveltime`,
    queueingDetaction: `${env.api.domain_event}/detection/criteria/queueing`,

    // event
    event_: `${env.api.domain_event}`
  };
  // `device/${device_id}/smc-config-road`;
  constructor(
    private http: HttpClient,
    private func: FuncService,
    private authService: AuthService,
    private commonService: CommonService,
    private ReportService: ReportService
  ) { }
  public static token: string;
  public static setToken(access_token) {
    this.token = "Bearer " + access_token;
  }
  public static getToken() {
    if (!this.token) this.setToken(localStorage.currentUserTokenWebAppShw);
    return (this.token) ? this.token : null;
  }
  setheader() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };;
  }
  getInfo() {
    return JSON.parse(sessionStorage.currentUserWebAppShw);
  }
  getProvince() {
    const httpOptions = this.setheader();
    return this.http.get<any[]>(this.api.province, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getProvince', []))
      );
  }
  getDistrict() {
    const httpOptions = this.setheader();
    return this.http.get<any[]>(this.api.district, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getDistrict', []))
      );
  }
  getDepartment() {
    const httpOptions = this.setheader();
    return this.http.get<any[]>(this.api.department, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getDepartment', []))
      );
  }
  getRoute() {
    const httpOptions = this.setheader();
    return this.http.get<any[]>(this.api.route, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getRoute', []))
      );
  }
  getDevicePermission(params?: any) {
    let txt: string = this.setParams(params);
    const httpOptions = this.setheader();
    return this.http.get<any[]>(this.api.devicepermissionG + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getDevicePermission', []))
      );
  }
  getDeviceSmc(params?: any) {
    let txt: string = this.setParams(params);
    const httpOptions = this.setheader();
    return this.http.get<any[]>(this.api.devSmc + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getDeviceSmc', []))
      );
  }
  getCctv(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.cctv + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getEvtCount', []))
      );
  }
  geticonMarker(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.iconMarker + txt)
      .pipe(
        catchError(catchError(err => of('geticonMarker', err)))
      );
  }
  getWeather(params?: any): Observable<any[]> {
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.weather + txt)
      .pipe(
        catchError(this.commonService.errorHandler('getWeather', []))
      );
  }
  /**
   * this._http.get(url, {responseType: ResponseContentType.ArrayBuffer}).subscribe( data => {
    var file = new Blob([data.blob()], {type: 'application/pdf'});
    var fileURL = window.URL.createObjectURL(file);
    window.open(fileURL);
});  
   */
  getEvtReportpdf(params?: any) {
    let txt: string = this.setParams(params);
    window.open(this.api.evtReport + txt);
  }
  getEvtDashboardRank(params?: any): Observable<any[]> {
    // const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.evtRank + txt)
      .pipe(
        catchError(this.commonService.errorHandler('getEvtCount', []))
      );
  }
  getTicket(params?: any) {
    let txt: string = this.setParams(params);
    const httpOptions = this.setheader();
    return this.http.get<any[]>(this.api.ticket + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getTicket', []))
      );
  }

  getzone(params?: any) {
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.travelZone + txt)
      .pipe(
        catchError(this.commonService.errorHandler('getzone', []))
      );
  }
  getschematic(id?: any) {
    // let txt: string = this.setParams(params)
    return this.http.get<any[]>(this.api.schematic + '/' + id)
      .pipe(
        catchError(this.commonService.errorHandler('getschematic', []))
      );
  }
  getTraveltime(params?: any) {
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.traveltime + txt)
      .pipe(
        catchError(this.commonService.errorHandler('getTraveltime', []))
      );
  }

  addTraveltimeConfig(data?: any): Observable<any[]> {
    // const httpOptions = this.setheader();
    if (data.id) {
      return this.http.put<any[]>(this.api.traveltimeConfig, data)
        .pipe(
          catchError(catchError(err => of('addTraveltimeConfig', err)))
          // catchError(this.commonService.errorHandler('addTraveltimeConfig', []))
        );

    } else {
      return this.http.post<any[]>(this.api.traveltimeConfig, data)
        .pipe(
          catchError(this.commonService.errorHandler('addTraveltimeConfig', []))
        );
    }
  }
  addPermissionGroup(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.post<any[]>(this.api.userpermissionG, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('updateUser', []))
      );
  }
  getPermissionGroup(g_id?: number): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.get<any[]>(this.api.permissionG + `/${g_id}`, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getPermissionGroup', []))
      );
  }
  getUsers(params?: any): Observable<any[]> {
    let txt: string = this.setParams(params);
    const httpOptions = this.setheader();
    return this.http.get<any[]>(this.api.users + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getUsers', []))
      );
  }
  addUser(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = '';
    if (data.id) {
      txt = '/' + data.id;
      delete data.id;
      delete data.username;
      return this.http.put<any[]>(this.api.users + txt, data, httpOptions)
        .pipe(
          catchError(this.commonService.errorHandler('updateUser', []))
        );
    }
    return this.http.post<any[]>(this.api.users + txt, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addUser', []))
      );
  }
  updateUser(data: any): Observable<any[]> {
    let txt = '/' + data.id;
    const httpOptions = this.setheader();
    return this.http.put<any[]>(this.api.users + txt, data, httpOptions);
  }

  rmUser(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = '';
    if (data.id) {
      txt = '/' + data.id;
      delete data.id;
    }
    return this.http.delete<any[]>(this.api.users + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getUsers', []))
      );
  }

  // Institution =====================
  getIns(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.ins + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getIns', []))
      );
  }
  addIns(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = '';
    let method: any = this.http.post;
    if (data.id) {
      method = this.http.put;
      txt = '/' + data.id;
      delete data.id;
      delete data.username;
      return this.http.put<any[]>(this.api.ins + txt, data, httpOptions)
        .pipe(
          catchError(this.commonService.errorHandler('updateIns', []))
        );
    }
    return this.http.post<any[]>(this.api.ins + txt, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addIns', []))
      );
  }
  rmIns(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = '';
    if (data.id) {
      txt = '/' + data.id;
      delete data.id;
    }
    return this.http.delete<any[]>(this.api.ins + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('rmIns', []))
      );
  }

  // Group =====================
  getGroup(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.group + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getGroup', []))
      );
  }
  addGroup(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = '';
    let method: any = this.http.post;
    if (data.id) {
      method = this.http.put;
      txt = '/' + data.id;
      delete data.id;
      delete data.username;
      return this.http.put<any[]>(this.api.group + txt, data, httpOptions)
        .pipe(
          catchError(this.commonService.errorHandler('updateGroup', []))
        );
    }
    return this.http.post<any[]>(this.api.group + txt, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addGroup', []))
      );
  }
  rmGroup(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = '';
    if (data.id) {
      txt = '/' + data.id;
      delete data.id;
    }
    return this.http.delete<any[]>(this.api.group + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('rmGroup', []))
      );
  }

  addUGroup(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.post<any[]>(this.api.ugroup, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addUGroup', []))
      );
  }
  rmUGroup(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = '';
    if (data.user_id) {
      txt = '/' + data.user_id;
      delete data.user_id;
    }
    if (data.group_id) {
      txt += '/' + data.group_id;
      delete data.group_id;
    }
    return this.http.delete<any[]>(this.api.ugroup + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addUGroup', []))
      );
  }

  // Event ==========================
  getEvtCount(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.evtcount + txt)
      .pipe(
        catchError(catchError(err => of('getEvtCount', err)))
      );
  }
  getEvtEffType(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.effType + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getEvtEffType', []))
      );
  }
  addEvtEffType(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.post<any[]>(this.api.effInfo, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('updateEvt', []))
      );
  }
  getMapEvtSrc(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.event + this.api.src + txt)
      .pipe(
        catchError(this.commonService.errorHandler('getMapEvtSrc', []))
      );
  }
  getMapEvtRoute(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.mapEvt + this.api.r + txt)
      .pipe(
        catchError(this.commonService.errorHandler('getMapEvtRoute', []))
      );
  }
  getmapEvt(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.mapEvt + txt)
      .pipe(
        catchError(this.commonService.errorHandler('getmapEvt', []))
      );
  }
  getmapEvtSubType(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.event + this.api.mapsubtype + txt)
      .pipe(
        catchError(err => of('getmapEvtSubType', err))
      );
  }
  getmapEvtStatus(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.mapEvt + this.api.status + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getmapEvtStatus', []))
      );
  }
  getEvtById(id?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.get<any[]>(this.api.event + this.api.info + '/' + id, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getEvtById', []))
      );
  }
  getEvtNoti(id?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.get<any[]>(this.api.evtNoti, httpOptions)
      .pipe(
        catchError(catchError(err => of('getEvtNoti', err)))
      );
  }
  getEvtLog(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.event + this.api.log + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getEvt', []))
      );
  }
  getEvt(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.event + this.api.info + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getEvt', []))
      );
  }
  addEvt(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    if (data.id) {
      return this.http.put<any[]>(this.api.event + this.api.info, data, httpOptions)
        .pipe(
          catchError(this.commonService.errorHandler('updateEvt', []))
        );
    }
    return this.http.post<any[]>(this.api.event + this.api.info, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addEvt', []))
      );
  }
  rmEvt(id: number): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.delete<any[]>(this.api.event + this.api.info + `/${id}`, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('updateEvt', []))
      );
  }
  addImgEvt(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.post<any[]>(this.api.event + this.api.image, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addImgEvt', []))
      );
  }
  addImgEvtLog(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.post<any[]>(this.api.event + this.api.imageLog, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addImgEvtLog', []))
      );
  }
  addSignLog(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.post<any[]>(this.api.event + this.api.signLog, data, httpOptions)
      .pipe();
  }
  getLocation(params?: any): Observable<any[]> {
  const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(`${this.api.loc}/${txt}`, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('updateEvt', []))
      );
  }
  getLocationPlanet(params?: any): Observable<any[]> {
  const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(`${this.api.locPlanet}${txt}`, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('updateEvt', []))
      );
  }
  getEvtConfirm(id: number): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.get<any[]>(this.api.event + this.api.confirm + `/${id}`, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('updateEvt', []))
      );
  }
  getEvtMsg(id: number): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.get<any[]>(this.api.event + this.api.msg + `?evt_id=${id}`, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('updateEvt', []))
      );
  }
  addEvtMsg(data: any): Observable<any[]> {
    // data.u_id = this.getInfo().id
    const httpOptions = this.setheader();
    // let event_id = (data.evt_id)? data.evt_id : null
    // if(data.evt_id) delete data.evt_id
    // return this.http.post<any[]>(this.api.event+this.api.msg+`/${event_id}`,data, httpOptions)
    return this.http.post<any[]>(this.api.event + this.api.msg, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('updateEvt', []))
      );
  }
  getRain(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.rain + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getRain', []))
      );
  }
  addRain(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    if (data.id) {
      return this.http.put<any[]>(this.api.rain, data, httpOptions)
        .pipe(
          catchError(this.commonService.errorHandler('updateRain', []))
        );
    }
    return this.http.post<any[]>(this.api.rain, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addRain', []))
      );
  }
  rmRain(id: number): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.delete<any[]>(this.api.rain + `/${id}`, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('rmRain', []))
      );
  }
  getEvtType(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.event + this.api.type + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getEvt', []))
      );
  }
  getEvtSubType(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.event + this.api.subtype + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getEvtSubType', []))
      );
  }

  addEvtSubType(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    if (data.id) {
      return this.http.put<any[]>(this.api.event + this.api.subtype, data, httpOptions)
        .pipe(
          catchError(this.commonService.errorHandler('updateEvtSubType', []))
        );
    }
    return this.http.post<any[]>(this.api.event + this.api.subtype, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addEvtSubType', []))
      );
  }
  rmEvtSubType(id: number): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.delete<any[]>(this.api.event + this.api.subtype + `/${id}`, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('rmEvtSubType', []))
      );
  }
  getEvtGroup(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.event + this.api.grp + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getEvt', []))
      );
  }
  getEvtImg(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.event + this.api.image + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getEvt', []))
      );
  }

  getEvtEff(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.event + this.api.effect + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getEvt', []))
      );
  }
  addEvtEff(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = '';
    if (data.event_id) {
      txt = '/' + data.event_id;
      delete data.event_id;
    }
    return this.http.put<any[]>(this.api.event + this.api.effect + txt, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('updateEvt', []))
      );
  }
  rmevtEff(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = '';
    if (data.id) {
      txt = '/' + data.id;
      delete data.id;
    }
    return this.http.delete<any[]>(this.api.event + this.api.effect + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('updateEvt', []))
      );
  }
  updateEvtApprove(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.put<any[]>(this.api.event + this.api.approve, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('updateEvt', []))
      );
  }
  updateEvtSignature(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.put<any[]>(this.api.event + this.api.signature, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('updateEvt', []))
      );
  }

  getEventConfigSetting(): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.get<any[]>(this.api.configSetting, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getEventConfigSetting', []))
      );
  }
  updateEventConfigSetting(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let id = data.id
    delete data.id
    return this.http.put<any[]>(this.api.configSetting+`/${id}`, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('updateEventConfigSetting', []))
      );
  }

  getconfigSettingList(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.configSettingList)
      .pipe(
        catchError(err => of('getconfigSettingList', err))
      );
  }

  getVerifyMethod(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.verifyMethod)
      .pipe(
        catchError(err => of('getVerifyMethod', err))
      );
  }

  updateEventVerifyMethod(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.put<any[]>(this.api.event_verifyMethod, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('updateEventVerifyMethod', []))
      );
  }
  // Report api==========================

  getMeasureSeed(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    // let txt: string = this.setParams(params);
    return this.http.post<any[]>(this.api.reportMeasureSeed, data,httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getMeasureSeed', []))
      );
  }
  getMeasureAgg(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    // let txt: string = this.setParams(params);
    return this.http.post<any[]>(this.api.reportMeasureAgg, data,httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getMeasureSeed', []))
      );
  }
  getReportStation(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.reportStation + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getReportStation', []))
      );
  }
  getReportSensorVolume(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.reportSensorVolume + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getreportSensorVolume', []))
      );
  }
  getreportSensorStat(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.reportSensorStat + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('reportSensorStat', []))
      );
  }
  getreportSensorTimeline(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.reportSensorTimeline + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('reportSensorTimeline', []))
      );
  }

  getVehClass(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.report + this.api.vehClass + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getEvt', []))
      );
  }
  getSensorMeasurement(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.post<any[]>(this.api.pvr, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getSensorMeasurement', []))
      );
  }
  getPerStation(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.perStaion + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getEvt', []))
      );
  }

  addPerStation(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    if (data.id) {
      let txt = '/' + data.id;
      delete data.id;
      return this.http.put<any[]>(this.api.perStaion + txt, data, httpOptions)
        .pipe(
          catchError(this.commonService.errorHandler('updatePerStation', []))
        );
    }
    return this.http.post<any[]>(this.api.perStaion, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addPerStation', []))
      );
  }
  rmPerStation(id: number): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.delete<any[]>(this.api.perStaion + `/${id}`, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('rmPerStation', []))
      );
  }

  getPerCarlendar(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.perStaion + this.api.calendar + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getEvt', []))
      );
  }
  addPerCarlendar(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();

    let formData = new FormData();
    formData.append('actual_in', data.actual_in);
    formData.append('file_in', data.file_in);
    formData.append('actual_out', data.actual_out);
    formData.append('file_out', data.file_out);

    formData.append('station', data.station_id.toString());

    data.files.forEach(item => {
      formData.append('files', item);
    });
    // return this.http.post<ApiResponse<any>>(url, formData);
    return this.http.post<any[]>(this.api.perStaion + this.api.upload, formData)
      .pipe(
        catchError(this.commonService.errorHandler('addPerCarlendar', []))
      );
  }
  rmPerCarlendar(data: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt = `${data.station_id}?date=${data.dt}`;
    return this.http.delete<any[]>(this.api.perStaion + this.api.del + `/${txt}`, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('rmPerCarlendar', []))
      );
  }
  getMaTicketlog(params?: any, menu_id?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    // console.log(this.api.ma + txt,menu_id);
    let api_url_export = this.api.ma + '/export' + txt;
    this.ReportService.setUrl(menu_id, api_url_export);
    return this.http.get<any[]>(this.api.ma + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getMaTicketlog', []))
      );
  }
  getMaTicketlog_export(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.ma + this.api.export + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getMaTicketlog', []))
      );
  }
  // Upload image Event==========================

  uploadIconImg(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.post<any[]>(this.api.event + this.api.iconImage, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('uploadIconImg', []))
      );
  }
  uploadDoc(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.post<any[]>(this.api.event + this.api.doc, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('uploadIconImg', []))
      );
  }
  uploadImages(image: File, event_id: number): Observable<any[]> {
    let formData = new FormData();
    if (event_id) {
      formData.append('event_id', event_id.toString());
    }

    // image.forEach(item => {
    formData.append('image', image);
    // });
    return this.http.post<any[]>(this.api.event + this.api.image, formData)
      .pipe(
        catchError(this.commonService.errorHandler('uploadImages', []))
      );
  }
  rmImages(path: string): Observable<any[]> {
    let txt = `?link=${path}`;
    return this.http.delete<any[]>(this.api.uploadImg + txt)
      .pipe(
        catchError(this.commonService.errorHandler('rmImages', []))
      );
  }

  getRainConfig(): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.get<any[]>(this.api.rainConfig, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getRainConfig', []))
      );
  }
  updateRainConfig(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.put<any[]>(this.api.rainConfig, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('updateRainConfig', []))
      );
  }
  getRainAlert(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.get<any[]>(this.api.rainAlert, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getRainAlert', []))
      );
  }
  addRainAlert(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.post<any[]>(this.api.rainAlert, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('updateRainAlert', []))
      );
  }
  // Incident ==========================
  getIncident(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.incident + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getIncidentType', []))
      );
  }
  getIncidentById(id: number): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.get<any[]>(this.api.incident + '/' + id, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getIncidentType', []))
      );
  }
  getIncidentName(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = "/lane/name";
    return this.http.get<any[]>(this.api.incident + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getIncidentType', []))
      );
  }
  addIncident(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = '';
    if (data.id) {
      txt = '/' + data.id;
      delete data.id;
      return this.http.put<any[]>(this.api.incident + txt, data, httpOptions)
        .pipe(
          catchError(this.commonService.errorHandler('addIncident', []))
        );
    }
    return this.http.post<any[]>(this.api.incident + txt, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addIncident', []))
      );
  }
  getIncidentType(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.incident_type + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getIncidentType', []))
      );
  }
  addIncidentType(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = '';
    if (data.id) {
      txt = '/' + data.id;
      delete data.id;
      return this.http.put<any[]>(this.api.incident_type + txt, data, httpOptions)
        .pipe(
          catchError(this.commonService.errorHandler('addIncidentType', []))
        );
    }
    return this.http.post<any[]>(this.api.incident_type + txt, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addIncidentType', []))
      );
  }
  rmIncident(id: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.put<any[]>(this.api.incident + '/' + id, { status: 0 }, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('rmIncidentType', []))
      );
  }

  rmIncidentType(id: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.delete<any[]>(this.api.incident_type + '/' + id, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('rmIncidentType', []))
      );
  }
  // rmIncidentType(id: any): Observable<any[]> {
  //   return this.http.put<any[]>(this.api.incident_type+'/'+id,{status: 0},  httpOptions)
  //     .pipe(
  //       catchError(this.commonService.errorHandler('rmIncidentType', []))
  //     );
  // }
  addIncidentAct(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt = '/' + data.incident_id + '/action';
    delete data.incident_id;
    return this.http.put<any[]>(this.api.incident + txt, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addIncidentAct', []))
      );
  }

  // ConfigRoad =====================
  getSmc(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    // let txt : any = `${(data.route)? '?page=1&route='+data.route:''}`
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.configRoad + `/smc` + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getSmc', []))
      );
  }
  getSmcConfigRoad(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.get<any[]>(this.api.configRoad + `/${data.device_id + this.api.smc}`, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getSmcConfigRoad', []))
      );
  }
  addSmcConfigRoad(device_id, data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.post<any[]>(this.api.configRoad + `/${device_id + this.api.smc}`, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addSmcConfigRoad', []))
      );
  }

  // Reversible =====================

  getReversible(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.reversible + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getReversible', []))
      );
  }
  addReversible(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.post<any[]>(this.api.reversible, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addReversible', []))
      );
  }
  updateReversible(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let id = data.rv_id;
    delete data.rv_id;
    return this.http.put<any[]>(this.api.reversible + '/' + id, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('updateReversible', []))
      );
  }
  rmRv(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.delete<any[]>(this.api.reversible + `/${data}`, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('rmRv', []))
      );
  }

  addReversibleSensor(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.post<any[]>(this.api.reversible + '/sensor', data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addReversibleSensor', []))
      );
  }
  rmRvSs(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.delete<any[]>(this.api.reversible + `/sensor/${data}`, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('rmRvSs', []))
      );
  }

  getVendor(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.vendor + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getVendor', []))
      );
  }
  getStructureType(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.structureType + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getStructureType', []))
      );
  }
  getZone(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.zone + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getZone', []))
      );
  }

  getRainDevices(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.devices + txt, httpOptions)
      .pipe(
        catchError(err => of('getRainDevice', err))
      );
  }
  getBoardsign(bs_id?: any): Observable<any[]> {
    // let txt: string = this.setParams(params)
    return this.http.get<any[]>(this.api.boardsign + `/${bs_id}`)
      .pipe(
        catchError(err => of('getBoardsign', err))
      );
  }
  getBoardsignCamera(params?: any): Observable<any[]> {
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.boardsignCamera + txt)
      .pipe(
        catchError(err => of('getBoardsignCamera', err))
      );
  }
  setParams(vals: any) {
    let txt: string = "";
    if (!isEmpty(vals)) {
      txt = '?';
      for (const key in vals) {
        if (Object.prototype.hasOwnProperty.call(vals, key)) {
          if (txt.length > 1) txt += '&';
          const element = vals[key];
          txt += `${key}=${element}`;
        }
      }
    }
    return txt;
  }
  getSpeedDirectionDevices(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.speedDirection + txt, httpOptions)
      .pipe(
        catchError(err => of('getSpeedDirectionDevice', err))
      );
  }
  addUpdateSpeedDirectionDevices(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.post<any[]>(this.api.speedDirection, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addEditSpeedDirectionDevices', []))
      );
  }
  getFlowDetectionDevices(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.flowDetection + txt, httpOptions)
      .pipe(
        catchError(err => of('getFlowDetectionDevice', err))
      );
  }
  addUpdateFlowDetectionDevices(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.post<any[]>(this.api.flowDetection, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addEditFlowDirectionDevices', []))
      );
  }
  getOccupanyDetactionDevices(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.occupanyDetaction + txt, httpOptions)
      .pipe(
        catchError(err => of('getOccupanyDetactionDevice', err))
      );
  }
  addUpdateOccupanyDetactionDevices(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.post<any[]>(this.api.occupanyDetaction, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addEditOccupanyDetactionDevices', []))
      );
  }
  getTravalTimeDetactionDevices(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.travalTimeDetaction + txt, httpOptions)
      .pipe(
        catchError(err => of('getTravalTimeDetactionDevice', err))
      );
  }
  addUpdateTravalTimeDetactionDevices(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.post<any[]>(this.api.travalTimeDetaction, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addEditTravalTimeDetactionDevices', []))
      );
  }
  getQueueingDetactionDevices(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params);
    return this.http.get<any[]>(this.api.queueingDetaction + txt, httpOptions)
      .pipe(
        catchError(err => of('getQueueingDetactionDevice', err))
      );
  }
  addUpdateQueueingDetactionDevices(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.post<any[]>(this.api.queueingDetaction, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addEditQueueingDetactionDevices', []))
      );
  }

  getEventAutomaticIncidentAndNotification(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(data);
    let url = this.api.event_ + `/event-reportpdf${txt}`;
    return this.http.get<any[]>(url, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addEditQueueingDetactionDevices', []))
      );
  }
  // remaining-time-data
  getEventRemainingTimeData(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    // let url = this.api.event_ + `/fusion-pdf?st_road=1&st_date=2023-01-27T00:00:00.000Z&en_date=2023-01-27T00:10:00.000Z`;
    let url = this.api.event_ + `/fusion-pdf?st_road=${data.st_rout}&en_date=${data.en_date}&st_date=${data.st_date}&st_km=${data.st_km}&en_km=${data.en_km}`;
    return this.http.get<any[]>(url, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addEditQueueingDetactionDevices', []))
      );
  }
}
