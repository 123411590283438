import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ApiResponse } from '../../models/common';
import { Router, ActivatedRoute } from '@angular/router';
import { PeopleService } from 'src/app/services/user/people.service';
import * as md5 from 'md5';
import * as _ from 'lodash';
import { menuItems as menuItems1 } from 'src/app/views/assets-management/_menu';
import { menuItems as menuItems2 } from 'src/app/views/user-management/menu';
import { menuItems as menuItems3 } from 'src/app/views/sign-control/_menu';
import { menuItems as menuItems4 } from 'src/app/views/traffic-tool/menu';
import { menuItems as menuItems5 } from 'src/app/views/event/menu';
import { menuItems as menuItems6 } from 'src/app/views/information/_menu';
import { menuItems as menuItems7 } from 'src/app/views/dashboard/_menu';
import { menuItems as menuItems8 } from 'src/app/views/report/_menu';
import { menuItems as menuItems9 } from 'src/app/views/previous-version/_menu';
import { menuItems as menuItems10 } from 'src/app/views/device-monitoring/_menu';
import { navItems } from 'src/app/_nav';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('swalInfo', { static: false }) swalInfo: SwalComponent;
  isLoading: boolean;
  model: any = { username: '', password: '' };
  returnUrl: string;
  alerts: any = [];
  dismissible = true;
  menuItems = [];
  constructor(
    private authS: PeopleService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService) {
    this.menuItems = _.concat(menuItems7, menuItems4, menuItems5, menuItems3, menuItems1, menuItems2, menuItems6, menuItems8, menuItems9, menuItems10);
  }

  ngOnInit() {
    // if(this.route.snapshot.queryParams.iwz==='true'){
    //   let pr = this.route.snapshot.queryParams
    //   this.authService.loginMD5(pr.u, pr.p)
    //   .subscribe(
    //     res => {
    //       // sessionStorage.setItem('iwz','true')
    //       this.router.navigate(['/sign-control/sign',{iwz:true}]);
    //   })
    // }
  }
  onButtonClick() {
    this.login();
  }
  resMessage: any = '';

  async login() {
    if (this.model.username && this.model.password) {
      this.isLoading = true;
      // let body = {
      //   username : this.model.username,
      //   password : md5(this.model.password)
      // }
      // let res : any = await this.authS.login(body)
      // console.log(res)
      // if(res){
      //   this.router.navigate(['']);
      //   setTimeout(() => { this.isLoading = false; }, 1000);
      // }else{
      //   this.resMessage = "No user,Please check username or password";

      //   setTimeout(() => { this.swalInfo.fire() }, 500);

      //   this.isLoading = false;
      // }
      this.authService.login(this.model.username, this.model.password)
        .subscribe(
          res => {
            if (res.code == 1) {

              setTimeout(() => {
                this.isLoading = false;
                if (res.data.permission.length > 0) {
                  const n_id = navItems.map(x => x.id);
                  const n_ids = _.intersection(n_id, res.data.permission);
                  const n = _.chain(navItems).keyBy('id').at(n_ids).value();
                  //console.log(n);

                  const menus = this.menuItems.filter(x => x.parent_id == n[0].id);
                  //console.log(menus);
                  const m_id = menus.map(x => x.id);
                  const ids = _.intersection(m_id, res.data.permission);
                  const m = _.chain(menus).keyBy('id').at(ids).value();
                  //console.log(m);
                  let url = ''
                  for (const i of m) {
                    if (i) {
                      url = i.url
                      break;
                    }
                  }
                  this.router.navigate([url]);
                }
                this.commonService.activityLog("เข้าใช้งานระบบ", "Login").subscribe(res => { }, error => { console.log(error); });
              }, 1000);
              this.commonService.setToggle(true);

            } else {
              this.resMessage = res.message;
              setTimeout(() => { this.swalInfo.fire() }, 100);
              this.isLoading = false;
            }
          },
          error => {
            if (error.status == 401) {
              this.isLoading = false;
              this.resMessage = 'ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง';
            } else {
              this.resMessage = 'ไม่สามารถเชื่อมต่อเซิร์ฟเวอร์ได้';
              this.isLoading = false;
            }

            setTimeout(() => { this.swalInfo.fire(); }, 100);
          });
    } else {
      this.isLoading = true;
      this.resMessage = 'กรุณากรอกข้อมูลให้ครบถ้วน';
      setTimeout(() => {
        this.swalInfo.fire();
        this.isLoading = false;
      }, 100);

    }
  }
}
