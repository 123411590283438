import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EventService } from 'src/app/services/user/event.service';
@Component({
  selector: 'app-model-speed-direction',
  templateUrl: './model-speed-direction.component.html',
  styleUrls: ['./model-speed-direction.component.scss']
})
export class ModelSpeedDirectionComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();
  values: any;
  alert:any;
  icon: any;
  normalTrafficSd: any;
  minimunSpeed: any;
  normalTrafficSpeedDiff: any;
  freeFlowTrafficSpeedDiff: any;
  constructor(
    public bsModalRef: BsModalRef,
    public eS: EventService,
  ) { }

  ngOnInit(): void {
    if(this.values.id) this.getSpeedDirectionDevices({id:this.values.id})
  }
  async getSpeedDirectionDevices(data:any){
    let res : any = await this.eS.getSpeedDirectionDevices(data)
    if(res){
      this.normalTrafficSd = res.normal_traffic_sd
      this.minimunSpeed = res.minimun_speed
      this.normalTrafficSpeedDiff = res.normal_traffic_speed_diff
      this.freeFlowTrafficSpeedDiff = res.free_flow_traffic_speed_diff
      this.icon = res.icon_url
    }
  }
  ImgBase64(evt?: any) {
    var reader = new FileReader();
    reader.readAsDataURL(evt.target.files[0]);
    reader.onload = async (_event) => {
      let res: any = await this.eS.uploadIconImg({ image: reader.result })
      if (res) this.icon = res.img_url
    }
  }
  chgChk() {
    // console.log(this.rainalert)
  }
  async save() {
    if(this.normalTrafficSd != null && this.minimunSpeed != null && this.normalTrafficSpeedDiff != null && this.freeFlowTrafficSpeedDiff != null){
      let data: any = {
        device_ids: this.values.device_id,
        config: {
          normal_traffic_sd: this.normalTrafficSd,
          minimun_speed: this.minimunSpeed,
          normal_traffic_speed_diff: this.normalTrafficSpeedDiff,
          free_flow_traffic_speed_diff: this.freeFlowTrafficSpeedDiff
        },
        icon: this.icon
      }
      let res : any = await this.eS.addUpdateSpeedDirectionDevices(data)
      if(res) this.confirm();
    }
  }

  confirm() {
    this.event.emit(true);
    this.bsModalRef.hide();
  }
  close() {
    this.event.emit(false);
    this.bsModalRef.hide();
  }
}
