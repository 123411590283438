<div class="main-BG container-fluid p-0">
  <div class="" id="map-BG">
    <div class="p-0 d-flex" style="gap:5px;">
      <div class="map" id="map-event"></div>
      <div class="map" id="map-event2" style="max-width: 0px;">MapMap</div>
    </div>
    <div class="p-0 my-sidebar" [ngClass]="{'Action1': _open,'Action0': !_open}">
      <button class="action-BT" [ngClass]="{'rotate0': _open,'rotate1': !_open}" (click)="_open=!_open;mapResize1()">
        <img src="./assets/event/slide-in.png" alt="">
      </button>
      <div class="blog-BG">
        <div class="head">
          <h5>ศูนย์บริหารจัดการจราจร<br>และอุบัติเหตุ</h5>
          <img src="./assets/event/logo.png" alt="">
        </div>
        <div class="eventlist-BG">
          <div class="title-BG">
            <div>การจัดเหตุการณ์บนสายทาง</div>
            <div>
              <button class="addEvt-BT" (click)="openEffectModal()">เพิ่มเหตุการณ์</button>
              <button class="filter-BT" (click)="_isCollapsedFilter=!_isCollapsedFilter" [ngClass]="{'active': !_isCollapsedFilter}">
                <img *ngIf="_isCollapsedFilter" src="./assets/event/Icon-sort-active.png" alt="">
                <img *ngIf="!_isCollapsedFilter" src="./assets/event/Icon-sort.png" alt="">
              </button>
            </div>
          </div>
        </div>
        <div id="noti-BG" class="noti-BG" [collapse]="!_isCollapsedFilter" [isAnimated]="true">
          <div class="noti-show-BG">
            <div [ngClass]="{'active': _chkType==1}" (click)="_chkType = 1;searchStatus({status:'1'});">
              รอตรวจสอบ 
              <span *ngIf="evtNum?.wait_for_check" class="badge badge-warning">{{evtNum?.wait_for_check}}</span>
            </div>
            <div [ngClass]="{'active': _chkType==2}" (click)="_chkType = 2;searchStatus({status:'2'});">
              ระหว่างดำเนินการ 
              <span *ngIf="evtNum?.in_progress" class="badge badge-warning">{{evtNum?.in_progress}}</span>
            </div>
            <div [ngClass]="{'active': _chkType==3}" (click)="_chkType = 3;searchStatus({status:'1,2'});">
              ทั้งหมด 
              <span *ngIf="evtNum?.total" class="badge badge-warning">{{evtNum?.total}}</span>
            </div>
            <div [ngClass]="{'active': _chkType==4}" (click)="_chkType = 4;conclude();">
              สรุป
            </div>
          </div>
          <button type="button" class="btn notiHead-BT" 
          (click)="_isCollapsedAcc = !_isCollapsedAcc;_isCollapsedInc=true;_isCollapsedWz=true;"
          [attr.aria-expanded]="!_isCollapsedAcc" aria-controls="accidentBlog">Accident 
          <img [ngClass]="{'rotate0': _isCollapsedAcc,'rotate1': !_isCollapsedAcc}" src="./assets/event/dropdown.png" alt="">
          </button>
          <div id="accidentBlog" class="notiblog-BG" [collapse]="_isCollapsedAcc" [isAnimated]="true">
            <div class="detail-BG pointer" *ngFor="let i of acc_list" (click)="flytoI(i)">
              <div>
                <p>{{i.event_subtype_str||''}}</p>
                
                <span *ngIf="i.status==1" class="badge badge-danger"><img src="./assets/event/time.png" alt=""> รอตรวจสอบ</span>
                <span *ngIf="i.status==2" class="badge badge-warning"><img src="./assets/event/time.png" alt=""> ระหว่างดำเนินการ</span>
                <span *ngIf="i.status==3" class="badge badge-success"><img src="./assets/event/complete.png" alt=""> สิ้นสุด</span>
              </div>
              <div>
                <p>จุดเกิดเหตุ</p>
                <span>{{i.route_str||'------------------'}}</span>
              </div>
              <div>
                <p>ประเภทเหตุการณ์</p>
                <span>{{i.event_type_str||'------------------'}}</span>
              </div>
              <div>
                <p>วันเวลาที่เกิดเหตุการณ์</p>
                <span *ngIf="!i.occur_datetime">------------------</span>
                <span *ngIf="i.occur_datetime">{{i.occur_datetime | date:'dd/MMM/yyyy HH:mm'}}</span>
              </div>
              <div *ngIf="i.status!=3">
                <button class="btn btn-primary mt-2 w-100" (click)="openEffectModal(i)">ดำเนินการ</button>
              </div>
            </div>
          </div>
          
          <hr>
          <button type="button" class="btn notiHead-BT" 
          (click)="_isCollapsedInc = !_isCollapsedInc;_isCollapsedAcc=true;_isCollapsedWz=true"
          [attr.aria-expanded]="!_isCollapsedInc" aria-controls="IncidentBlog">Incident
          <img [ngClass]="{'rotate0': _isCollapsedInc,'rotate1': !_isCollapsedInc}" src="./assets/event/dropdown.png" alt="">
          </button>
          <div id="IncidentBlog" class="notiblog-BG" [collapse]="_isCollapsedInc" [isAnimated]="true">
            
            <div class="detail-BG pointer" *ngFor="let i of inc_list" (click)="flytoI(i)">
              <div>
                <p>{{i.event_subtype_str||''}}</p>
                <span *ngIf="i.status==1" class="badge badge-danger"><img src="./assets/event/time.png" alt=""> รอตรวจสอบ</span>
                <span *ngIf="i.status==2" class="badge badge-warning"><img src="./assets/event/time.png" alt=""> ระหว่างดำเนินการ</span>
                <span *ngIf="i.status==3" class="badge badge-success"><img src="./assets/event/complete.png" alt=""> สิ้นสุด</span>
                
              </div>
              <div>
                <p>จุดเกิดเหตุ</p>
                <span>{{i.route_str||'------------------'}}</span>
              </div>
              <div>
                <p>ประเภทเหตุการณ์</p>
                <span>{{i.event_type_str||'------------------'}}</span>
              </div>
              <div>
                <p>วันเวลาที่เกิดเหตุการณ์</p>
                <span *ngIf="!i.occur_datetime">------------------</span>
                <span *ngIf="i.occur_datetime">{{i.occur_datetime | date:'dd/MMM/yyyy HH:mm'}}</span>
              </div>
              <div *ngIf="i.status!=3">
                <button class="btn btn-primary mt-2 w-100" (click)="openEffectModal(i)">ดำเนินการ</button>
              </div>
            </div>
          </div>
          
          <hr>
          <button type="button" class="btn notiHead-BT" 
          (click)="_isCollapsedWz = !_isCollapsedWz;_isCollapsedAcc=true;_isCollapsedInc=true;"
          [attr.aria-expanded]="!_isCollapsedWz" aria-controls="workzoneBlog">Work Zone
          <img [ngClass]="{'rotate0': _isCollapsedWz,'rotate1': !_isCollapsedWz}" src="./assets/event/dropdown.png" alt="">
          </button>
          <div id="workzoneBlog" class="notiblog-BG" [collapse]="_isCollapsedWz" [isAnimated]="true">
            
            <div class="detail-BG pointer" *ngFor="let i of iwz_list" (click)="flytoI(i)">
              <div>
                <p>{{i.event_subtype_str||''}}</p>
                
                <span *ngIf="i.status==1" class="badge badge-danger"><img src="./assets/event/time.png" alt=""> รอตรวจสอบ</span>
                <span *ngIf="i.status==2" class="badge badge-warning"><img src="./assets/event/time.png" alt=""> ระหว่างดำเนินการ</span>
                <span *ngIf="i.status==3" class="badge badge-success"><img src="./assets/event/complete.png" alt=""> สิ้นสุด</span>
              </div>
              <div>
                <p>จุดเกิดเหตุ</p>
                <span>{{i.route_str||'------------------'}}</span>
              </div>
              <div>
                <p>ประเภทเหตุการณ์</p>
                <span>{{i.event_type_str||'------------------'}}</span>
              </div>
              <div>
                <p>วันเวลาที่เกิดเหตุการณ์</p>
                <span *ngIf="!i.occur_datetime">------------------</span>
                <span *ngIf="i.occur_datetime">{{i.occur_datetime | date:'dd/MMM/yyyy HH:mm'}}</span>
              </div>
              <div *ngIf="i.status!=3">
                <button class="btn btn-primary mt-2 w-100" (click)="openEffectModal(i)">ดำเนินการ</button>
              </div>
            </div>
          </div>
        </div>
        <div id="filter-BG" class="filter-BG" [collapse]="_isCollapsedFilter" [isAnimated]="true">
          <div>
            ประเภทเหตุการณ์
            <kendo-multiselect [data]="groupedData"
            textField="name" [(ngModel)]="event_subtype_selected"
            valueField="event_subtype_id" [allowCustom]="false" class="form-control" placeholder="เลือกประเภท" (close)="onClose($event)">
            </kendo-multiselect>
          </div>
          <div>
            ช่วงเวลาเกิดเหตุ
            <div class="d-flex" style="gap:5px;">
              <kendo-datetimepicker class="form-control" [(ngModel)]="st_dt" [format]="'dd-MMM-yyyy HH:mm'" placeholder="เลือกช่วงเวลาเริ่มต้น"></kendo-datetimepicker>
              <kendo-datetimepicker class="form-control" [(ngModel)]="en_dt" [format]="'dd-MMM-yyyy HH:mm'" placeholder="เลือกช่วงเวลาสิ้นสุด"></kendo-datetimepicker>
            </div>
          </div>
          <div>
            สายทาง
            <select [(ngModel)]="st_road" class="form-control">
              <option value="" selected>เลือกสายทาง</option>
              <option *ngFor="let i of routeItems" [value]="i">{{i}}</option>
            </select>
          </div>
          <div>
            จุดเกิดเหตุ (เริ่มต้น)
            <div class="d-flex align-items-center">
              <input type="number" class="form-control" placeholder="Km" [(ngModel)]="st_km" [min]="0" [disabled]="!st_road" numbersOnly>
              +
              <input type="number" class="form-control" placeholder="m" [(ngModel)]="st_m" [min]="0" [max]="999" (focusout)="st_m = chkNumberm(st_m)" [disabled]="!st_km&&st_km!=0" numbersOnly>
              <kendo-combobox [data]="dirlist" textField="name"
              valueField="id" [allowCustom]="false" [(ngModel)]="st_dir" placeholder="เลือกทิศทาง" class="form-control">
              </kendo-combobox>
            </div>
          </div>
          <div>
            จุดเกิดเหตุ (สิ้นสุด)
            <div class="d-flex align-items-center">
              <input type="number" class="form-control" placeholder="Km" [(ngModel)]="en_km" [min]="0" [disabled]="!en_road" numbersOnly>
              +
              <input type="number" class="form-control" placeholder="m" [(ngModel)]="en_m" [min]="0" [max]="999" (focusout)="en_m = chkNumberm(en_m)" [disabled]="!en_km&&en_km!=0" numbersOnly>
              <kendo-combobox [data]="dirlist" textField="name"
              valueField="id" [allowCustom]="false" [(ngModel)]="en_dir" placeholder="เลือกทิศทาง" class="form-control">
              </kendo-combobox>
            </div>
          </div>
          <div class="d-flex">
            <div class="">
              แหล่งที่มาของข้อมูล
              <kendo-combobox [data]="srcItems" textField="descript"
              valueField="id" [allowCustom]="false" [(ngModel)]="src_selected" placeholder="แหล่งที่มาของข้อมูล" class="form-control">
              </kendo-combobox>
            </div>
            <div class="">
              สถานะของเหตุการณ์
              <kendo-multiselect [data]="status_list" textField="name" [(ngModel)]="Status_selected"
              valueField="id" [allowCustom]="false" placeholder="สถานะของเหตุการณ์" class="form-control"
              (filterChange)="FilterStatus($event)"
              (valueChange)="chgStatus($event)">
              </kendo-multiselect>
            </div>
          </div>
          <div class="search-BG">
            <button class="btn btn-primary" (click)="search();_chkType=0;">
              <img src="./assets/event/search.png" alt="">
              ค้นหา
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="category-BG">
  <div class="cate-BT" (click)="filterCategory(3)" [ngClass]="{'active': cateMarker[3]}" tooltip="ภาพจราจรจาก CCTV">
    <img src="./assets/event/pins/m_cctv.png" alt="">
  </div>
  <div class="cate-BT" (click)="filterCategory(4)" [ngClass]="{'active': cateMarker[4]}" tooltip="การให้ข้อมูลผู้ใข้ทาง">
    <img src="./assets/event/pins/m_monitor.png" alt="">
  </div>
  <div class="cate-BT" (click)="filterCategory(2)" [ngClass]="{'active': cateMarker[2]}" tooltip="ข้อมูลจราจรจาก Sensor">
    <img src="./assets/event/pins/m_info.png" alt="">
  </div>
  <div class="cate-BT" (click)="filterCategory(5)" [ngClass]="{'active': cateMarker[5]}" tooltip="ข้อมูลสภาพอากาศจาก Sensor">
    <img src="./assets/event/pins/m_weather.png" alt="">
  </div>
</div>
<div class="alert-BG">
  <alert type="danger" *ngFor="let i of al_list;index as idx">
    <div class="d-flex al-BG">
      <img src="./assets/event/icon-warning.png" height="20">
      <div>
        <h5>
          {{i.event_type_str||'การตรวจสอบเหตุการณ์'}}
        </h5>
        <p>
          {{i.route_str||''}}
        </p>
        <button class="btn btn-danger w-100" (click)="openEffectModal(i)">ดำเนินการ</button>
      </div>
      <button class="al-x-BT" (click)="rmalert(idx)">X</button>
    </div>
  </alert>
</div>
<div bsModal #posEvents="bs-modal" class="modal fade" tabindex="-1"
     role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog modal-sm sameLoc">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-child-name" class="modal-title pull-left">
          Events
          <small *ngIf="allEventsPos[0]?.route_str" class="d-block">{{allEventsPos[0].route_str}}</small>
        </h4>
        <button type="button" class="close pull-right btn-close xBT" aria-label="Close" (click)="hidePosEvents()">
          <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <button class="btn btn-outline-primary w-100 mb-2     text-left" *ngFor="let i of allEventsPos" (click)="openModal(i)">
          <p>
            <span *ngIf="i.event_type_str">{{i.event_type_str}}</span>
            <span *ngIf="i.event_subtype_str">{{i.event_subtype_str}}</span>
            <span *ngIf="i.display_name">
              <img [src]="i.icon_id" style="height: 30px;" alt="">
              {{i.display_name}}
            </span>
          </p>
          <small *ngIf="i.occur_datetime" class="d-block text-right">{{i.occur_datetime | date :'dd/MM/yyyy HH:mm:ss'}}</small>
        </button>
      </div>
    </div>
  </div>
</div>