import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import {
  Station,
  ReqAddStation,
} from '../../../models/assets-management/station';
import {
  StatusEnum,
  RouteDirectionEnum,
  StationTypeEnum,
  ActionEnum,
} from '../../../models/common-enum';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import * as _ from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';
import {
  MediaInfo,
  ReqAddImage,
  ReqAddPlaylist,
  PlaylistItem,
  PlaylistItemAdd,
} from 'src/app/models/sign-control/media';
import { SignTypeService } from '../../../services/sign-control/sign-type.service';
import { BoardsignService } from '../../../services/sign-control/boardsign.service';
import { PlaylistService } from '../../../services/sign-control/playlist.service';
import {
  MediaType,
  MediaBehaviorType,
} from '../../../models/sign-control/enum';
import { permission } from 'src/app/views/sign-control/_menu';
@Component({
  selector: 'app-playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.scss'],
})
export class PlaylistComponent implements OnInit {
  @ViewChild('swalSuccess', { static: false })
  private swalSuccess: SwalComponent;
  @ViewChild('swalError', { static: false }) private swalError: SwalComponent;
  @ViewChild('addPlaylistModal', { static: false })
  public addPlaylistModal: ModalDirective;
  @ViewChild('mediaListModal', { static: false })
  public mediaListModal: ModalDirective;
  @ViewChild('imInput') imInputVariable: ElementRef;
  @ViewChild('playlistInfoModal') public playlistInfoModal: ModalDirective;
  @ViewChild('confirmDeletePlaylistModal')
  public confirmDeletePlaylistModal: ModalDirective;

  statusEnum = StatusEnum;
  routeDirectionEnum = RouteDirectionEnum;
  stationTypeEnum = StationTypeEnum;
  mediaType = MediaType;

  currentPage: any = 1;
  itemsPerPage: any = 20;
  totalItems: any = 0;
  data_list: MediaInfo[] = [];

  is_loading: boolean = false;
  allowCustom: boolean = false;
  res_message: any;

  sign_type_list: any[] = [];
  data_sign_type: any[] = [];
  data_sign_type_modal: any[] = [];

  boardsign_list: any[] = [];
  data_boardsign_modal: any[] = [];
  boardsign_modal: any;

  ratio_list: string[] = [];
  data_ratio: string[] = [];
  size_list: string[] = [];
  data_size: string[] = [];

  use_type_list = [
    { id: 1, name: 'ประกาศ' },
    { id: 2, name: 'แจ้งเตือน' },
  ];
  status_list = [
    { id: StatusEnum.Active, name: 'Active' },
    { id: StatusEnum.Inactive, name: 'Inactive' },
  ];
  selectItem: any = {
    name: null,
  };
  search_name: string;
  search_sign_type: any;
  search_ratio: any;
  search_size: any;
  search_use_type: any;
  sign_type_modal: any;
  status_modal: any;
  use_type_modal: any;
  medie_sign_height: number = 100;
  select_plt_item: MediaInfo = null;
  action: number = 1; // 1:Add , 2:Edit
  set_sign_height: number = 50; // px
  set_sign_width: number = 50; // px

  is_public = true;

  isCreate: boolean;
  constructor(
    private commonService: CommonService,
    private signTypeService: SignTypeService,
    private playlistService: PlaylistService,
    private boardsignService: BoardsignService
  ) {
    this.commonService
      .activityLog(ActionEnum.Get, 'Sign Control ➡ Playlist')
      .subscribe(
        (res) => {},
        (error) => {
          console.log(error);
        }
      );
  }

  async ngOnInit() {
    this.is_loading = true;
    this.getPlaylist();
    this.getSignType();
    this.getBoardsign();
    this.getRatio();
    this.getSize();
    this.isCreate = await this.commonService
      .getPermission(permission.playlist.create_id)
      .toPromise();
  }
  isPublicChange(e) {
    this.playlist = [];
    this.playlist.push({ duration: null, media: null });
  }
  get get_sign_width() {
    if (this.sign_type_modal) {
      const wh = this.sign_type_modal.aspect_ratio.split(':');
      const w = +wh[0];
      const h = +wh[1];
      const r = w / h;
      return this.set_sign_height * r;
    } else {
      return this.set_sign_height;
    }
  }
  medie_sign_width(ratio?: string, heigh?: number) {
    if (heigh && ratio) {
      const wh = ratio.split(':');
      const w = +wh[0];
      const h = +wh[1];
      const r = w / h;
      return heigh * r;
    } else {
      return 0;
    }
  }
  getSignNameTextStyles(w?: number) {
    let styles = {
      width: w + 'px',
      'max-width': w + 'px',
      'white-space': 'nowrap',
      overflow: 'hidden',
      'text-overflow': 'ellipsis',
    };
    return styles;
  }

  handleValueStatusModal(value) {
    if (value) {
      this.status_modal = value;
    } else {
      this.status_modal = null;
    }
  }
  getSignType() {
    this.signTypeService.getSignType(null, null, 1).subscribe(
      (res) => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.sign_type_list = _.cloneDeep(res.data);
              this.data_sign_type = _.cloneDeep(res.data);
              this.data_sign_type_modal = _.cloneDeep(res.data);
            }
          }
        }
        //this.is_loading = false;
      },
      (error) => {
        //this.is_loading = false;
      }
    );
  }

  handleFilterSignType(value) {
    if (value.length > 0) {
      this.data_sign_type = this.sign_type_list.filter(
        (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    } else if (value.length == 0) {
      this.data_sign_type = _.cloneDeep(this.sign_type_list);
    } else {
      this.data_sign_type = [];
    }
  }
  handleValueSignType(value) {
    if (value) {
      this.search_sign_type = value;
    } else {
      this.search_sign_type = null;
    }
  }
  handleFilterSignTypeModal(value) {
    if (value.length > 0) {
      this.data_sign_type_modal = this.sign_type_list.filter(
        (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    } else if (value.length == 0) {
      this.data_sign_type_modal = _.cloneDeep(this.sign_type_list);
    } else {
      this.data_sign_type_modal = [];
    }
  }
  handleValueSignTypeModal(value) {
    if (value) {
      this.sign_type_modal = value;
    } else {
      this.sign_type_modal = null;
    }
    this.playlist = [];
    this.playlist.push({ duration: null, media: null });
  }

  handleValueUseType(value) {
    if (value) {
      this.search_use_type = value;
    } else {
      this.search_use_type = null;
    }
  }
  handleValueUseTypeModal(value) {
    if (value) {
      this.use_type_modal = value;
    } else {
      this.use_type_modal = null;
    }
  }
  getBoardsign() {
    this.boardsignService.getBoardsign(null, null, null, null, 1).subscribe(
      (res) => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.boardsign_list = _.cloneDeep(res.data);
              this.data_boardsign_modal = _.cloneDeep(res.data);
            }
          }
        }
      },
      (error) => {}
    );
  }

  handleFilterBoardsignModal(value) {
    if (value.length > 0) {
      this.data_boardsign_modal = this.boardsign_list.filter(
        (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    } else if (value.length == 0) {
      this.data_boardsign_modal = _.cloneDeep(this.boardsign_list);
    } else {
      this.data_boardsign_modal = [];
    }
  }
  handleValueBoardsignModal(value) {
    if (value) {
      this.boardsign_modal = value;
    } else {
      this.boardsign_modal = null;
    }
    this.playlist = [];
    this.playlist.push({ duration: null, media: null });
  }

  getRatio() {
    this.playlistService.getPlaylistRatio().subscribe(
      (res) => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.ratio_list = _.cloneDeep(res.data);
              this.data_ratio = _.cloneDeep(res.data);
            }
          }
        }
        //this.is_loading = false;
      },
      (error) => {
        //this.is_loading = false;
      }
    );
  }
  getSize() {
    this.commonService.getSubTypeSize().subscribe(
      (res:any) => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              let size_data : any = []
              for (const i of res.data) {
                size_data.push({
                  size : `${i.width}x${i.height}`,
                  width : i.width,
                  height : i.height,
                })
                i.size=`${i.width}x${i.height}`
              }
              size_data = _.uniqWith(size_data, _.isEqual);
              this.size_list = _.cloneDeep(size_data);
              this.data_size = _.cloneDeep(size_data);
            }
          }
        }
        //this.is_loading = false;
      },
      (error) => {
        //this.is_loading = false;
      }
    );
  }

  handleFilterRatio(value) {
    if (value.length > 0) {
      this.data_ratio = this.ratio_list.filter(
        (s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    } else if (value.length == 0) {
      this.data_ratio = _.cloneDeep(this.ratio_list);
    } else {
      this.data_ratio = [];
    }
  }

  handleValueRatio(value) {
    if (value) {
      this.search_ratio = value;
    } else {
      this.search_ratio = null;
    }
  }
  handleFilterSize(value) {
    if (value.length > 0) {
      this.data_size = this.size_list.filter(
        (s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    } else if (value.length == 0) {
      this.data_size = _.cloneDeep(this.size_list);
    } else {
      this.data_size = [];
    }
  }

  handleValueSize(value) {
    if (value) {
      this.search_size = value;
    } else {
      this.search_size = null;
    }
  }

  pageChanged(event: any): void {
    if (this.currentPage != event.page) {
      this.currentPage = event.page;
      this.getPlaylist();
    }
  }

  search() {
    this.currentPage = 1;
    this.getPlaylist();
  }

  getPlaylist() {
    this.data_list = [];
    let sign_type_id = null;
    let ratio = null;
    let use_type = null;
    let st_size : any = {};
    if (this.search_sign_type) sign_type_id = this.search_sign_type.id;
    if (this.search_ratio) ratio = this.search_ratio;
    if (this.search_size) {
      st_size.width = this.search_size.width;
      st_size.height = this.search_size.height;
    }
    if (this.search_use_type) use_type = this.search_use_type.id;

    this.playlistService
      .getPlaylist(
        this.currentPage,
        this.itemsPerPage,
        this.search_name,
        ratio,
        use_type,
        sign_type_id,
        1,null,null,null,st_size
      )
      .subscribe(
        (res) => {
          if (res) {
            if (res.code == 1) {
              if (res.data) {
                this.data_list = res.data.data;
                this.totalItems = res.data.total_items;
              } else {
                this.totalItems = 0;
              }
            } else {
              this.totalItems = 0;
            }
          } else {
            this.totalItems = 0;
          }
          this.is_loading = false;
        },
        (error) => {
          this.is_loading = false;
        }
      );
  }
  openAddImageModal() {
    this.selectItem = {
      name: null,
    };
    this.status_modal = this.status_list.find((x) => x.id == StatusEnum.Active);
    this.addPlaylistModal.show();
  }
  closeAddImage() {
    this.imInputVariable.nativeElement.value = '';
  }

  //----------------Playlist--------------------//
  playlist: any[] = [{ duration: null, media: null }];
  select_media_inx: any = null;
  is_loading_add: boolean = false;
  addPlaylist() {
    this.is_loading_add = true;
    let req = new ReqAddPlaylist();
    req.name = this.selectItem.name;
    if (this.is_public) {
      req.boardsign_id = null;
    } else {
      req.boardsign_id = this.boardsign_modal.id;
    }
    req.sign_type_id = this.sign_type_modal.id;
    if (this.use_type_modal) {
      req.use_type = this.use_type_modal.id;
    }
    req.media_type = MediaType.Playlist;
    req.status = this.status_modal.id;
    this.res_message = '';
    req.items = [];
    this.playlist.forEach((item) => {
      if (item.duration) {
        let img_item = new PlaylistItemAdd();
        img_item.duration = +item.duration;
        img_item.image_id = item.media.id;
        req.items.push(img_item);
      }
    });
    this.playlistService.addPlaylist(req).subscribe(
      (res) => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = {
              url: `${this.playlistService.domain}/playlist`,
              request: req,
              response: res,
            };
            this.commonService
              .activityLog(
                ActionEnum.Add,
                `Sign Control ➡ Playlist`,
                JSON.stringify(log_req_data)
              )
              .subscribe(
                (res) => {},
                (error) => {
                  console.log(error);
                }
              );
            this.res_message = `เพิ่ม Playlist สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading_add = false;
              this.getPlaylist();
            }, 100);
            this.addPlaylistModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => {
              this.swalError.fire();
              this.is_loading_add = false;
            }, 100);
          }
        } else {
          setTimeout(() => {
            this.swalError.fire();
            this.is_loading_add = false;
          }, 100);
        }
      },
      (error) => {
        console.log(error);
        if (error) {
          if (error.status == 403) {
          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => {
              this.swalError.fire();
            }, 1000);
          }
        } else {
          setTimeout(() => {
            this.swalError.fire();
          }, 1000);
        }
        this.is_loading_add = false;
      }
    );
  }
  editPlaylist() {
    this.is_loading_add = true;
    let req = new ReqAddPlaylist();
    req.name = this.selectItem.name;
    if (this.is_public) {
      req.boardsign_id = null;
    } else {
      req.boardsign_id = this.boardsign_modal.id;
    }
    req.sign_type_id = this.sign_type_modal.id;
    if (this.use_type_modal) {
      req.use_type = this.use_type_modal.id;
    }
    req.media_type = MediaType.Playlist;
    req.status = this.status_modal.id;
    this.res_message = '';
    req.items = [];
    req.playlist_id = this.select_plt_item.id;
    this.playlist.forEach((item) => {
      let img_item = new PlaylistItemAdd();
      if (item.id) {
        img_item.id = item.id;
      }
      if (item.duration) {
        img_item.duration = +item.duration;
      }
      img_item.duration = +item.duration;
      img_item.image_id = item.media.id;
      req.items.push(img_item);
    });

    this.playlistService.editPlaylist(req, this.select_plt_item.id).subscribe(
      (res) => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = {
              url: `${this.playlistService.domain}/playlist`,
              request: req,
              response: res,
            };
            this.commonService
              .activityLog(
                ActionEnum.Edit,
                `Sign Control ➡ Playlist`,
                JSON.stringify(log_req_data)
              )
              .subscribe(
                (res) => {},
                (error) => {
                  console.log(error);
                }
              );
            this.res_message = `แก้ไข Playlist สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading_add = false;
              this.getPlaylist();
            }, 100);
            this.addPlaylistModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => {
              this.swalError.fire();
              this.is_loading_add = false;
            }, 100);
          }
        } else {
          setTimeout(() => {
            this.swalError.fire();
            this.is_loading_add = false;
          }, 100);
        }
      },
      (error) => {
        console.log(error);
        if (error) {
          if (error.status == 403) {
          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => {
              this.swalError.fire();
            }, 1000);
          }
        } else {
          setTimeout(() => {
            this.swalError.fire();
          }, 1000);
        }
        this.is_loading_add = false;
      }
    );
  }

  closeAddPlaylist() {
    this.selectItem = {
      name: null,
    };
    this.status_modal = this.status_list.find((x) => x.id == StatusEnum.Active);
    this.sign_type_modal = null;
    this.playlist = [];
    this.playlist.push({ duration: null, media: null });
    this.select_plt_item = null;
    this.clickSelectMedia = false;
    this.boardsign_modal = null;
    this.is_public = true;
  }
  addList() {
    let g = { duration: null, media: null };
    this.playlist.push(g);
  }

  deleteList(item: any) {
    const index: number = this.playlist.indexOf(item);
    if (index !== -1) {
      this.playlist.splice(index, 1);
    }
  }

  clickSelectMedia = false;
  clickSelectMediaBoard = false;
  toggle = false;
  select_media_boardsign_id = null;
  openMediaModal(inx: number) {
    if (this.is_public) {
      if (this.sign_type_modal) {
        this.select_media_boardsign_id = null;
        this.select_media_inx = inx;
        this.toggle = true;
        this.mediaListModal.show();
      } else {
        this.clickSelectMedia = true;
      }
    } else {
      if (this.sign_type_modal && this.boardsign_modal) {
        this.select_media_boardsign_id = this.boardsign_modal.id;
        this.select_media_inx = inx;
        this.toggle = true;
        this.mediaListModal.show();
      } else {
        if (!this.sign_type_modal) this.clickSelectMedia = true;
        if (!this.boardsign_modal) this.clickSelectMediaBoard = true;
      }
    }
  }
  closeMediaModal(e) {
    if (e) {
      this.playlist.forEach((item, index) => {
        if (index == this.select_media_inx) {
          item.media = e;
        }
      });
    }
    this.mediaListModal.hide();
    this.toggle = false;
    this.select_media_inx = null;
  }

  btAddPlaylistDisable() {
    if (
      this.selectItem.name &&
      this.sign_type_modal &&
      this.status_modal != null &&
      this.playlist.length > 0
    ) {
      const find = this.playlist.find(
        (x) => x.media != null && x.duration != null && x.duration != ''
      );
      if (find) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
  openAddPlaylistModal() {
    this.action = 1;
    this.addPlaylistModal.show();
  }
  //------------------------------Edit/Delete--------------------------//
  unsub_playlist: any = null;
  openPlaylistInfoModal(plt: MediaInfo) {
    this.unsub_playlist = false;
    this.select_plt_item = plt;
    this.playlistInfoModal.show();
  }
  openEditPlaylistModal(plt: MediaInfo) {
    this.selectItem.name = plt.name;
    this.sign_type_modal = this.data_sign_type_modal.find(
      (x) => x.id == plt.sign_type_id
    );
    this.use_type_modal = this.use_type_list.find((x) => x.id == plt.use_type);
    this.status_modal = this.status_list.find((x) => x.id == plt.status);
    this.playlist = [];
    plt.items.forEach((item) => {
      let g = {
        duration: item.duration,
        media: item.boardsign_image,
        id: item.id,
      };
      this.playlist.push(g);
    });
    if (plt.boardsign_id) {
      this.is_public = false;
      this.boardsign_modal = this.boardsign_list.find(
        (x) => x.id == plt.boardsign_id
      );
    } else {
      this.is_public = true;
    }
    this.action = 2;
    this.addPlaylistModal.show();
    this.playlistInfoModal.hide();
  }
  OpenConfirmDeleteMediaModal() {
    this.confirmDeletePlaylistModal.show();
    this.playlistInfoModal.hide();
  }
  closePlaylistInfoModal() {
    if (
      !this.addPlaylistModal.isShown &&
      !this.confirmDeletePlaylistModal.isShown
    ) {
      this.select_plt_item = null;
      console.log('close');
    }
    this.unsub_playlist = true;
  }
  deletePlaylist(ply: MediaInfo) {
    this.is_loading = true;
    let req = new ReqAddImage();
    req.status = StatusEnum.Delete;
    this.playlistService.deletePlaylistEditStatus(req, ply.id).subscribe(
      (res) => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = {
              url: `${this.playlistService.domain}/playlist/${ply.id}`,
              request: req,
              response: res,
            };
            this.commonService
              .activityLog(
                ActionEnum.Delete,
                `Sign Control ➡ Playlist`,
                JSON.stringify(log_req_data)
              )
              .subscribe(
                (res) => {},
                (error) => {
                  console.log(error);
                }
              );
            this.res_message = `ลบ Playlist สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getPlaylist();
            }, 100);
            this.confirmDeletePlaylistModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => {
              this.swalError.fire();
              this.is_loading = false;
            }, 100);
          }
        } else {
          setTimeout(() => {
            this.swalError.fire();
            this.is_loading = false;
          }, 100);
        }
      },
      (error) => {
        console.log(error);
        if (error) {
          if (error.status == 403) {
          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            setTimeout(() => {
              this.swalError.fire();
            }, 1000);
          }
        } else {
          setTimeout(() => {
            this.swalError.fire();
          }, 1000);
        }
        this.is_loading = false;
      }
    );
  }
  //-----------------------------------------------------------------//
}
