<div class="d-flex">
  <div #SearchPenal class="search-penal bg-gray-700 scrollbar-samll-style d-flex"
    [ngClass]="(SearchPenal.offsetWidth>search_list.length*518)?'justify-content-center':'justify-content-start'">
    <div *ngFor="let item of search_list; let i = index"
      class="d-flex align-items-center justify-content-center bg-gray-800 mr-2 ml-2 pr-1 mt-1 mb-1">
      <div class="d-flex align-items-center justify-content-center pl-1 pt-2 pb-2">
        <div class="ml-1 mr-1 nowrap">
          หมายเลขทางหลวง
        </div>
        <div>
          <input type="number" [(ngModel)]="item.route" name="text-route" [maxlength]="4" [min]="0"
            class="form-control input-km text-center" placeholder="">
        </div>

        <div class="ml-2 mr-1 nowrap">
          กิโลเมตร เริ่มต้น
        </div>
        <div>
          <input type="number" [(ngModel)]="item.st_km" name="text-st-km" [maxlength]="5" [min]="0"
            class="form-control input-km text-center" placeholder="เริ่มต้น">
        </div>
        <div class="ml-2 mr-1 nowrap">
          สิ้นสุด
        </div>
        <div>
          <input type="number" [(ngModel)]="item.en_km" name="text-en-km" [maxlength]="5" [min]="0"
            class="form-control input-km text-center" placeholder="สิ้นสุด">
        </div>

      </div>
      <div class="d-flex align-items-start h-100"><i title="ลบการค้นหาเส้นทางนี้" (click)="deleteSearch(item)"
          class="fa fa-times text-danger pointer"></i>
      </div>
    </div>
  </div>
  <div class="pl-2 pr-1 d-flex align-items-center justify-content-center"><button title="เพิ่มการค้นหาเส้นทาง"
      type="button" class="btn btn-sm btn-pill btn-success m-1" (click)="addSearch()"><i
        class="fa fa-plus pointer"></i></button></div>

  <div class="pl-1 pr-2 d-flex align-items-center justify-content-center">
    <button [disabled]="!this.search_list" type="button" class="btn btn-sm btn-primary bt-search"
      (click)="search(search_list, true)"> <i class="fa fa-search text-white" style="font-size:16px"></i></button>
  </div>
</div>

<div #Container class="traffic-layer d-flex scrollbar-style" style="background-color: black;"
  (window:resize)="onResize($event)">
  <div #Road class="position-reletive" [style.width.px]="km_width_px*road_list.length" style="overflow-x: hidden;">
    <div class="position-absolute" [style.left.px]="km_width_px*(i)" [style.width.px]="km_width_px"
      *ngFor="let item of road_list; let i = index;let last = last">

      <div [style.height.px]="((ContainerHeight-
        (this.road_height))/2)>0?
        ((ContainerHeight-(this.road_height))/2):0" style="width: 100%;"></div>

      <div [style.height.px]="lane_height+10"></div>
      <div [style.height.px]="lane_height+10" style="width: 100%;" class="road-bg-dark"></div>
      <div #FrontageLanesOutBG *ngFor="let a of item?.ft_lanes_out_bg;" [style.height.px]="lane_height"
        style="width: 100%;" class="road-bg-dark"></div>
      <div [style.height.px]="road_border_ft" style="width: 100%;" class="road-border-line"></div>

      <div #FrontageLanesOut [style.height.px]="lane_height"
        [style.top.px]="(((lane_height)*max_ft_lane_out)+km_point_height+center_km_height)+((lane_height)*i2)"
        class="road-lane-in" *ngFor="let l of item?.ft_lanes_out; let i2 = index;let first = first;">
        <div *ngIf="!first" class="d-flex align-items-center justify-content-around line-dashed-panel">
          <div [style.width.px]="km_width_px/15" class="line-dashed" *ngFor="let b of gen_line; let i3 = index"></div>
        </div>
      </div>

      <div #MainLanesOutBG *ngFor="let b of item?.lanes_out_bg;" [style.height.px]="lane_height" style="width: 100%;"
        class="road-bg-dark"></div>

      <div #GapOut [style.height.px]="road_gap" style="width: 100%;" class="road-bg-dark"></div>
      <div [style.height.px]="road_border_main" style="width: 100%;" class="road-border-line"></div>
      <div #MainLanesOut [style.height.px]="lane_height" [style.top.px]="((lane_height)*i2)+km_point_height"
        class="road-lane-out" *ngFor="let l of item?.lanes_out; let i2 = index;let first = first;">
        <div *ngIf="!first" class="d-flex align-items-center justify-content-around line-dashed-panel">
          <div [style.width.px]="km_width_px/15" class="line-dashed" *ngFor="let b of gen_line; let i3 = index"></div>
        </div>
      </div>

      <div #CenterYellowLine [style.height.px]="center_km_height"
        [style.top.px]="((lane_height)*max_lane_out)+km_point_height"
        class="d-flex align-items-center justify-content-center road-center-line">
      </div>

      <div #MainLanesIn [style.height.px]="lane_height"
        [style.top.px]="(((lane_height)*max_lane_in)+km_point_height+center_km_height)+((lane_height)*i2)"
        class="road-lane-in" *ngFor="let l of item?.lanes_in; let i2 = index;let first = first;">
        <div *ngIf="!first" class="d-flex align-items-center justify-content-around line-dashed-panel">
          <div [style.width.px]="km_width_px/15" class="line-dashed" *ngFor="let b of gen_line; let i3 = index"></div>
        </div>
      </div>
      <div [style.height.px]="road_border_main" style="width: 100%;" class="road-border-line"></div>
      <div #GapIN [style.height.px]="road_gap" style="width: 100%;" class="road-bg-dark"></div>

      <div #MainLanesInBG *ngFor="let c of item?.lanes_in_bg;" [style.height.px]="lane_height" style="width: 100%;"
        class="road-bg-dark"></div>

      <div #FrontageLanesIn [style.height.px]="lane_height"
        [style.top.px]="(((lane_height)*max_ft_lane_in)+km_point_height+center_km_height)+((lane_height)*i2)"
        class="road-lane-in" *ngFor="let l of item?.ft_lanes_in; let i2 = index;let first = first;">
        <div *ngIf="!first" class="d-flex align-items-center justify-content-around line-dashed-panel">
          <div [style.width.px]="km_width_px/15" class="line-dashed" *ngFor="let b of gen_line; let i3 = index"></div>
        </div>
      </div>
      <div [style.height.px]="road_border_ft" style="width: 100%;" class="road-border-line"></div>
      <div #FrontageLanesInBG *ngFor="let d of item?.ft_lanes_in_bg;" [style.height.px]="lane_height"
        style="width: 100%;" class="road-bg-dark"></div>
      <div [style.height.px]="lane_height+10" style="width: 100%;" class="road-bg-dark"></div>
      <div [style.height.px]="lane_height+10"></div>
    </div>

    <div *ngFor="let item of vertical_line; let i = index">
      <div [style.width.px]="km_width_px" [style.height.px]="this.road_height - ((lane_height+10)*2)" [style.top.px]="(((ContainerHeight-
      (this.road_height))/2)>0?
      ((ContainerHeight-(this.road_height))/2):0) + (lane_height+10)" [style.left.px]="item.i*km_width_px"
        class="ver-line position-absolute">
        <div class="d-flex align-items-center justify-content-center h-100" [style.font-size.px]="">
          <div>
            <div class="d-flex align-items-center justify-content-center">
              <div class="triangle-left"></div>
              <div class="route-sign d-flex align-items-center justify-content-center">
                <div class="route-sign- d-flex align-items-center justify-content-center font-1">
                  <div class="text-center">
                    <div class="d-flex justify-content-center">
                      <div class="dott-t"></div>
                    </div>
                    ทล.{{item.route1}}
                    <div class="d-flex justify-content-center">
                      <div class="dott-b"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div [style.height.px]="(4* this.lane_height)+ this.center_km_height"></div>
            <div class="d-flex align-items-center justify-content-center">
              <div class="route-sign d-flex align-items-center justify-content-center">
                <div class="route-sign- d-flex align-items-center justify-content-center font-1">
                  <div class="text-center">
                    <div class="d-flex justify-content-center">
                      <div class="dott-t"></div>
                    </div>
                    ทล.{{item.route2}}
                    <div class="d-flex justify-content-center">
                      <div class="dott-b"></div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="triangle-right"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngFor="let item of traffic_road; let i = index;let first = first;">
      <div class="position-absolute traffic-opacity" [style.background-color]="item?.color" [style.top.px]="item.y + (((ContainerHeight-
      (this.road_height))/2)>0?
      ((ContainerHeight-(this.road_height))/2):0)" [style.left.px]="item.x" [style.width.px]="km_width_px"
        [style.height.px]="lane_height">
      </div>
    </div>

    <div *ngFor="let item of intersection; let i = index;let first = first;">
      <div *ngIf="item.img_link != null && item.st_x != null && item.st_y != null" class="position-absolute"
        [style.top.px]="item.y + (((ContainerHeight-
      (this.road_height))/2)>0?
      ((ContainerHeight-(this.road_height))/2):0)" [style.left.px]="item.x"><img [src]="item.img_link"></div>
    </div>

    <div *ngFor="let item of event_list; let i = index;">
      <div *ngIf="item?.icon != null && item?.dir != null" class="position-absolute" [style.top.px]="item?.y + (((ContainerHeight-
      (this.road_height))/2)>0?
      ((ContainerHeight-(this.road_height))/2):0)" [style.left.px]="(item?.index
      *km_width_px) + (item?.x)"><img title="{{item?.km}}+{{item?.m | number:'3.0-0'}}" class="pointer" [src]="item?.icon"
          [width]="this.icon_w" [heigh]="this.icon_w" (click)="openModal(item)">
      </div>
    </div>

    <div *ngFor="let item of road_list_all; let i = index" class="position-absolute" [style.top.px]="(((ContainerHeight-
          (this.road_height))/2)>0?
          ((ContainerHeight-(this.road_height))/2):0)+((
          (((this.max_ft_lane_out + this.max_lane_out) * this.lane_height) + ((this.lane_height + 10)*2) + (this.road_border_ft) +
          (this.road_border_main) + (this.road_gap) +
          (this.center_km_height/2)))) - (item.km%10 == 0 ?25:10)"
      [style.left.px]="i*km_width_px - (item.km%10 == 0 ?25:10)">
      <div title="ทางหลวงหมายเลข {{item?.route}} กิโลเมตรที่ {{item?.km}}"
        class="pointer d-flex align-items-center justify-content-center"
        [ngClass]="item.km%10 == 0 ? 'point-km-big' : 'point-km'">
        <div *ngIf="item.km%10 == 0">{{item?.km}}</div>
      </div>
    </div>
  </div>
</div>