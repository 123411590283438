import { Component, OnInit } from '@angular/core';
import { DeviceService } from 'src/app/services/user/device.service';
import { FuncService } from 'src/app/services/user/func.service';
import { perlist } from './data'
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PerDetailComponent } from './per-detail/per-detail.component';
import { ImportFileComponent } from './import-file/import-file.component';
import { ReportService } from 'src/app/services/user/report.service';
import { CommonService } from 'src/app/services/common.service';
import { ActionEnum } from 'src/app/models/common-enum';
import { ApiService } from 'src/app/services/user/api.service';
@Component({
  selector: 'app-per-measurement',
  templateUrl: './per-measurement.component.html',
  styleUrls: ['./per-measurement.component.scss']
})
export class PerMeasurementComponent implements OnInit {

  modalRef: BsModalRef;
  allowCustom: boolean = false
  route_list: any;
  setRouteList: any;
  route_id: number;
  station_list: any = perlist

  total: number = 0;
  currentPage: number = 1;
  itemsPerPage: number = 15;
  start_km: number = null;
  start_m: number = null;
  end_km: number = null;
  end_m: number = null;
  constructor(
    private devS: DeviceService,
    private func: FuncService,
    private router: Router,
    private modalService: BsModalService,
    private rp_S: ReportService,
    private commonService: CommonService,
    private apiService: ApiService) {
    this.commonService.activityLog(ActionEnum.Get, 'Traffic Information ➡ รายงานรายคันจากจุดสำรวจ').subscribe(res => { }, error => { console.log(error); });
  }

  ngOnInit(): void {
    this.getData()
  }
  async getData() {
    // let res : any = await this.getOnlyRoute()
    this.getPerStation()
    let res: any = await this.devS.getRoute()
    this.route_list = res
    this.setRouteList = this.route_list
  }
  async getPerStation() {
    let data: any = {}
    if (this.route_id) data.route = this.route_id
    if (this.start_km || this.start_km == 0) data.st_km = this.start_km
    if (this.start_m || this.start_m == 0) data.st_m = this.start_m
    if (this.end_km || this.end_km == 0) data.en_km = this.end_km
    if (this.end_m || this.end_m == 0) data.en_m = this.end_m
    let res = await this.rp_S.getPerStation(data)
    if (res) this.station_list = res ; this.total = this.station_list.length
    
  }
  FilterRoute(evt: any) {
    if (evt.length > 0) {
      this.setRouteList = this.route_list.filter(res => res.includes(evt))
    } else if (evt.length == 0) {
      this.setRouteList = this.route_list
    } else {

    }
  }
  async rmlist(id: number) {
    let result = await this.func.alertpopup('<h4>คุณต้องการลบใช่หรือไม่</h4>', `<i class="icon-info mr-2"></i>ลบ Station`, 1);
    if (result) {
      let res = await this.rp_S.rmPerStation(id)
      if (res) {
        this.func.alertpopup(`Now processing your data number ${id} is success`);
        let request_data = {url:`${this.apiService.api.perStaion}/${id}}`};
        this.commonService.activityLog(ActionEnum.Delete, 'Traffic Information ➡ รายงานรายคันจากจุดสำรวจ', JSON.stringify(request_data)).subscribe(res => { }, error => { console.log(error); });
        this.getPerStation();}
    }
  }
  gotoSensor(data) {
    this.router.navigate(['/traffictool/sensor-measurement-per', { id: data.id }]);
    // this.router.navigate(['/traffictool/sensor-measurement'], { queryParams: {id: data.id}});
  }
  openImportfile(data) {
    const initialState = { values: data }
    this.modalRef = this.modalService.show(ImportFileComponent, { initialState, class: 'w-80', backdrop: 'static' });
    this.modalRef.content.event.subscribe(data => {

      if (data) this.getPerStation()
    });
  }

  openModal(data?: any) {
    const initialState = { values: data }
    this.modalRef = this.modalService.show(PerDetailComponent, { initialState, class: 'w-80', backdrop: 'static' });
    this.modalRef.content.event.subscribe(data => {
      // this.getData()
      if (data) this.getData()
    });
  }
}
