<div class="modal-header">
    <h4>ตั้งค่าเงื่อนไข การตรวจจับน้ำฝน</h4>
    <button type="button" class="close pull-right x-BT" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body rainConfig-BG">
    <div class="pos-BG">
        <p>จุดติดตั้งอุปกรณ์</p>
        <button class="pos-BT" *ngFor="let i of this.values.device_loc">{{i.location_name||''}}</button>
    </div>
    <div class="rainfall-BG">
        <p class="m-0 d-flex">
            <span class="w-50" style="padding-left: 150px;">RainFall (MM/hr.)</span>
            <span class="w-50">Speed commend (km/hr)</span>
        </p>
        <div>
            <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">Light Rain</span>
                </div>
                <input type="number" numbersOnly class="form-control" [(ngModel)]="rainWeight[0]" [readOnly]="values?.id">
                <input type="number" numbersOnly class="form-control" [(ngModel)]="rainSpeed[0]" [readOnly]="values?.id">
            </div>

            <!-- <label for="r1" class="pointer chk-BG mr-3" *ngIf="!values?.id">
                <img src="./assets/event/{{rainalert[0]==1? '':'un'}}chk.png" alt="">
                <input type="checkbox" name="rainConfig" id="r1" [(ngModel)]="rainalert[0]" (change)="chgChk">
                Alert
            </label> -->
            <label for="icon1" class="upload-BG" [ngClass]="{'pointer': !values?.id}">
                Icon
                <img *ngIf="rainIcon[0]" [src]="rainIcon[0]" alt="upload">
                <img *ngIf="!rainIcon[0]" src="./assets/event/upload-s.png" alt="upload">
                <input id="icon1" type="file" class="form-control" accept="image/*" (change)="ImgBase64($event,0)" onerror="this.src='assets/noimage.png'" [disabled]="values?.id">
            </label>
        </div>
        <div>
            <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">Moderate Rain</span>
                </div>
                <input type="number" numbersOnly class="form-control" [(ngModel)]="rainWeight[1]" [readOnly]="values?.id">
                <input type="number" numbersOnly class="form-control" [(ngModel)]="rainSpeed[1]" [readOnly]="values?.id">
            </div>
            <!-- <label for="r2" class="pointer chk-BG mr-3" *ngIf="!values?.id">
                <img src="./assets/event/{{rainalert[1]==1? '':'un'}}chk.png" alt="">
                <input type="checkbox" name="rainConfig" id="r2" [(ngModel)]="rainalert[1]" (change)="chgChk">
                Alert
            </label> -->
            <label for="icon2" class="upload-BG" [ngClass]="{'pointer': !values?.id}">
                Icon
                <img *ngIf="rainIcon[1]" [src]="rainIcon[1]" alt="upload">
                <img *ngIf="!rainIcon[1]" src="./assets/event/upload-s.png" alt="upload">
                <input id="icon2" type="file" class="form-control" accept="image/*" (change)="ImgBase64($event,1)" onerror="this.src='assets/noimage.png'">
            </label>
        </div>
        <div>
            <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">Heavy Rain</span>
                </div>
                <input type="number" numbersOnly class="form-control" [(ngModel)]="rainWeight[2]" [readOnly]="values?.id">
                <input type="number" numbersOnly class="form-control" [(ngModel)]="rainSpeed[2]" [readOnly]="values?.id">
            </div>
            <!-- <label for="r3" class="pointer chk-BG mr-3" *ngIf="!values?.id">
                <img src="./assets/event/{{rainalert[2]==1? '':'un'}}chk.png" alt="">
                <input type="checkbox" name="rainConfig" id="r3" [(ngModel)]="rainalert[2]" (change)="chgChk">
                Alert
            </label> -->
            <label for="icon3" class="upload-BG" [ngClass]="{'pointer': !values?.id}">
                Icon
                <img *ngIf="rainIcon[2]" [src]="rainIcon[2]" alt="upload">
                <img *ngIf="!rainIcon[2]" src="./assets/event/upload-s.png" alt="upload">
                <input id="icon3" type="file" class="form-control" accept="image/*" (change)="ImgBase64($event,2)" onerror="this.src='assets/noimage.png'">
            </label>
        </div>
        <div>
            <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">Very Heavy Rain</span>
                </div>
                <input type="number" numbersOnly class="form-control" [(ngModel)]="rainWeight[3]" [readOnly]="values?.id">
                <input type="number" numbersOnly class="form-control" [(ngModel)]="rainSpeed[3]" [readOnly]="values?.id">
            </div>
            <!-- <label for="r4" class="pointer chk-BG mr-3" *ngIf="!values?.id">
                <img src="./assets/event/{{rainalert[3]==1? '':'un'}}chk.png" alt="">
                <input type="checkbox" name="rainConfig" id="r4" [(ngModel)]="rainalert[3]" (change)="chgChk">
                Alert
            </label> -->
            <label for="icon4" class="upload-BG" [ngClass]="{'pointer': !values?.id}">
                Icon
                <img *ngIf="rainIcon[3]" [src]="rainIcon[3]" alt="upload">
                <img *ngIf="!rainIcon[3]" src="./assets/event/upload-s.png" alt="upload">
                <input id="icon4" type="file" class="form-control" accept="image/*" (change)="ImgBase64($event,3)" onerror="this.src='assets/noimage.png'">
            </label>
        </div>
    </div>
    <div>
        Interval Time (Minute)
        <input type="number" numbersOnly class="form-control text-left" style="max-width:200px" [(ngModel)]="intervalTime" [readOnly]="values?.id">
    </div>
</div>
<div class="modal-footer">
    <button *ngIf="!values?.id" class="btn btn-primary save-BT" (click)="save()">บันทึก</button>
    <button *ngIf="values?.id" class="btn btn-primary save-BT" (click)="close()">ปิด</button>
</div>
