import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FuncService } from 'src/app/services/user/func.service'
import { GroupModalComponent } from './group-modal/group-modal.component';
import { GroupService } from 'src/app/services/user/group.service'
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { CommonService } from 'src/app/services/common.service';
import { permission } from 'src/app/views/user-management/menu';
import { ActionEnum } from 'src/app/models/common-enum';
import { ApiService } from 'src/app/services/user/api.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit {

  @ViewChild('swalSuccessRmGroup', { static: false }) private swalSuccessRmGroup: SwalComponent;
  modalRef: BsModalRef;
  constructor(
    private func: FuncService,
    private modalService: BsModalService,
    private grpS: GroupService,
    private commonService: CommonService,
    private apiService: ApiService,
  ) {
    this.commonService.activityLog(ActionEnum.Get, 'User Management ➡ กลุ่มผู้ใช้งาน').subscribe(res => { }, error => { console.log(error); });
  }

  list: any = [];
  total: number = 0;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  g_name: string = ''
  hashtag: string = ''
  id: number

  selected: string;
  selectedOption: any;

  isAdd: boolean = false;
  isEdit: boolean = false;
  async ngOnInit() {
    this.getData();
    this.isAdd = await this.commonService.getPermission(permission.user_group.add_id).toPromise();
    this.isEdit = await this.commonService.getPermission(permission.user_group.edit_id).toPromise();

  }
  openModal(data?: any) {
    const initialState = {
      values: data
    };
    this.modalRef = this.modalService.show(GroupModalComponent, { initialState, class: 'w-80' });
    this.modalRef.content.event.subscribe(data => {
      // if(data) 
      this.getData()
      // return resolve(data);
    });
  }
  async getData(data?: any) {
    this.list = await this.grpS.getGroup(data);
    this.total = this.list.length
  }
  search() {
    let params: any = {}
    if (this.g_name && !this.id) params.name = this.g_name
    if (this.id) params.id = this.id
    if (this.hashtag) params.hashtag = this.hashtag
    console.log(params)
    this.getData(params)
  }
  pageChanged(evt?) {
    this.currentPage = evt.page
  }

  setPage(pageNo: number): void {
    this.currentPage = pageNo;
  }
  async rmlist(id) {
    let result = await this.func.alertpopup('<h4>คุณต้องการลบใช่หรือไม่</h4>', `<i class="icon-info mr-2"></i>ลบกลุ่มผู้ใช้งาน`, 1);
    if (result) {
      let res: any = await this.grpS.rmGroup({ id: id })
      if (res.id) {
        setTimeout(() => {
          this.swalSuccessRmGroup.fire()
        }, 1000)
        let request_data = { url: `${this.apiService.api.group}/${id}}` };
        this.commonService.activityLog(ActionEnum.Delete, 'User Management ➡ กลุ่มผู้ใช้งาน', JSON.stringify(request_data)).subscribe(res => { }, error => { console.log(error); });
        this.getData()
      }
    }
  }
  blur($event) {
    this.id = null
  }

  onSelect(event: TypeaheadMatch): void {
    if (this.g_name) {
      this.id = event.item.id;
    } else {
      this.id = null
    }
  }
}
