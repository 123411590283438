<div class="row p-3">
  <div class="col-lg-5 col-md-5 col-sm-6">
    <div class="row">
      <div
        class="col-xl-3 col-lg-4 col-md-4 col-sm-12 justify-content-md-end justify-content-lg-end justify-content-xl-end align-items-center d-flex">
        ชื่อผู้ใช้งาน :</div>
      <div class="col-lg-8 col-md-8 col-sm-12">

        <input [typeahead]="users_list" [(ngModel)]="name" typeaheadOptionField="name"
          (typeaheadOnSelect)="onSelect($event)" class="form-control">
        <!-- <input autocomplete="nope" type="text" placeholder="" maxlength="100" [(ngModel)]="name"
                    class="k-textbox form-control w-100" /> -->
      </div>
    </div>
  </div>
  <div class="col-lg-5 col-md-5 col-sm-6">
    <div class="row">
      <div
        class="col-xl-3 col-lg-4 col-md-4 col-sm-12 justify-content-md-end justify-content-lg-end justify-content-xl-end align-items-center d-flex">
        หน่วยงาน :</div>
      <div class="col-lg-8 col-md-8 col-sm-12">

        <input [typeahead]="ins_list" [(ngModel)]="ins_name" typeaheadOptionField="name"
          (typeaheadOnSelect)="onSelect($event)" (focusout)="blur($event)" class="form-control">
        <!-- <input autocomplete="nope" type="text" placeholder="" maxlength="100" [(ngModel)]="ins_id"
                    class="k-textbox form-control w-100" /> -->
      </div>
    </div>
  </div>
  <div class="col-lg-5 col-md-5 col-sm-6">
    <div class="row">
      <div
        class="col-xl-3 col-lg-4 col-md-4 col-sm-12 justify-content-md-end justify-content-lg-end justify-content-xl-end align-items-center d-flex">
        Hashtag :</div>
      <div class="col-lg-8 col-md-8 col-sm-12">
        <input autocomplete="nope" type="text" placeholder="" maxlength="100" [(ngModel)]="hashtag"
          class="k-textbox form-control w-100" />
      </div>
    </div>
  </div>
  <div class="col-lg-7 col-md-7 d-flex align-items-end justify-content-end">
    <div class="mt-1">
      <button type="button" (click)="search()" class="btn btn-primary pull-right text-white"> <i
          class="fa fa-search"></i>
        ค้นหา </button>
    </div>
  </div>

  <div class="col-12 mt-3 table-header pl-3 pr-3 pt-2 pb-2 d-flex align-items-center justify-content-between">
    <div class="font-2">
      <i class="fa fa-bars mr-2"></i> ผู้ใช้งาน
    </div>
    <button *ngIf="isAdd" (click)="openModal()" type="button" class="btn btn-primary text-white">
      <i class="fa fa-plus"></i>
      เพิ่ม</button>
  </div>


  <div class="col-12 mt-3">
    <table class="table-main table table-outline mb-0">
      <thead class="thead-dark" style="background-color: black;">
        <tr>
          <th class="text-center text-light table-header"><i class="fa fa-hashtag"></i></th>
          <th class="text-center text-light table-header">ชื่อ</th>
          <th class="text-center text-light table-header">หน่วยงาน</th>
          <th class="text-center text-light table-header">Create date</th>
          <th *ngIf="isEdit" class="text-center text-light table-header">Action</th>
        </tr>
      </thead>
      <tbody>

        <tr *ngIf="!users_list || users_list?.length < 1">
          <td colspan="10" style="height: 50px;">
            ไม่พบข้อมูล
          </td>
        </tr>
        <tr
          *ngFor="let i of users_list | slice: (currentPage-1) * itemsPerPage : (currentPage-1) * itemsPerPage + itemsPerPage;index as idx">
          <td>
            {{(idx+1)+(itemsPerPage*(currentPage-1))}}
          </td>
          <td>{{i.firstname||''}} {{i.lastname||''}}</td>
          <td>{{i.ins_name||''}}</td>
          <td>{{i.create_datetime | date:'dd/MM/yyyy HH:mm:ss':'+0700'}}</td>
          <td *ngIf="isEdit">
            <button class="ml-1 mr-1 btn btn-warning" (click)="openModal(i)">
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            </button>
            <button class="ml-1 mr-1 btn btn-danger" (click)="rmlist(i.id)">
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button>
          </td>
        </tr>
        <tr>
          <td colspan="12" class="table-footer">
            <div class="table-footer-inside d-flex align-items-center justify-content-between">
              <div class="ml-3 pt-3">

                <pagination [(ngModel)]="currentPage" [totalItems]="users_list.length" [itemsPerPage]="itemsPerPage"
                  (pageChanged)="pageChanged($event)" previousText="&lsaquo;" nextText="&rsaquo;" [boundaryLinks]="true"
                  firstText="&laquo;" lastText="&raquo;">></pagination>

              </div>
              <div></div>
              <div class="mr-3 ml-2">total: {{total||0}}</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<swal #swalSuccessRm title="<div class='text-light'>ลบผู้ใช้สำเร็จ</div>" type="success" text="" icon="success">
</swal>
