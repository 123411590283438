import { Component, OnInit,EventEmitter } from '@angular/core';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EventService } from 'src/app/services/user/event.service';
@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.scss']
})
export class AddEventComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();
  values: any;
  imgIcon : any
  type_list : any = []
  type_id : any = ''
  auto_detection : string
  short_name : string
  name : string
  att_file : string
  constructor(
    public bsModalRef: BsModalRef,
    private eS : EventService,
  ) { }

  ngOnInit(): void {
    this.getData()
  }
  async getData(){
    let res : any = await this.eS.getEvtType();
    if(res.length) this.type_list = res
    if(this.values){
      // let f_type = this.type_list.filter((v)=>v.id==this.values.event_type_id)
      console.log(this.values.event_type_id,this.values)
      // if(f_type) this.type_id = f_type.id
      this.type_id = this.values.event_type_id
      this.short_name = this.values.short_name ||''
      this.auto_detection = this.values.auto_detection ||''
      this.short_name = this.values.short_name ||''
      this.name = this.values.name ||''
      this.imgIcon = this.values.icon_url ||''
      this.att_file = this.values.att_file ||''
    }
  }
  // uploadImgs
  ImgBase64(evt?: any){
    var reader = new FileReader();
    reader.readAsDataURL(evt.target.files[0]);
    reader.onload = async (_event) => {
      this.compressImage(reader.result, 119, 114).then(async compressed => {
        // this.resizedBase64 = compressed;
        // console.log(compressed)
        let res : any = await this.eS.uploadIconImg({image:compressed})
        if(res) this.imgIcon = res.img_url 
      })
    }
  }
  compressImage(src, newX, newY) {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const elem = document.createElement('canvas');
        elem.width = newX;
        elem.height = newY;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, newX, newY);
        const data = ctx.canvas.toDataURL();
        res(data);
      }
      img.onerror = error => rej(error);
    })
  }
  DocBase64(evt?: any){
    var reader = new FileReader();
    reader.readAsDataURL(evt.target.files[0]);
    reader.onload = async (_event) => {
      let res : any = await this.eS.uploadDoc({data:reader.result})
      console.log(res)
      if(res) this.att_file = res.img_url 
    }
  }
  async save(){
    let data : any = {event_type_id:Number(this.type_id)}
    if(this.short_name) data.short_name = this.short_name
    if(this.auto_detection) data.auto_detection = this.auto_detection
    if(this.short_name) data.short_name = this.short_name
    if(this.name) data.name = this.name
    if(this.imgIcon) data.icon_url = this.imgIcon
    if(this.att_file) data.att_file = this.att_file
    if(this.values?.id) data.id = this.values.id
    console.log(this.att_file)
    console.log(data)
    let res : any = await this.eS.addEvtSubType(data)
    if(res) this.confirm()
  }
  close() {
    this.bsModalRef.hide();
  }
  confirm() {
    this.event.emit(true);
    this.bsModalRef.hide();
  }
  cancel() {
    this.event.emit(false);
    this.bsModalRef.hide();
  }
}
