<div class="modal-header">
    <h4>{{values?.device_name}}</h4>
    <button type="button" class="close pull-right x-BT" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="model-body p-3">
    <p>ระบบ : {{values?.group_name}}</p>
    <p>จุดติดตั้ง : {{values?.station_name}}</p>
    <p>ประเภทการติดตั้ง  : {{values?.structure_type_name}}</p>
    <div class="mark-BG">
        <div>
            <img src="./assets/temperature.png" alt="">
        </div>
        <div class="mr-4">{{vsw?.temp_main}}</div>
        <div>
            <img src="./assets/power.png" alt="">
        </div>
        <div>{{vsw?.status_module_name}}</div>
    </div>
</div>
<div class="model-footer text-right p-3">
    <p class="m-0">เมื่อ {{vsw?.updated_datetime | date:'dd/MM/yyyy HH:mm:ss':'+0700'}}</p>
</div>
