<div class="modal-header">
    <h4 class="text-Color">ตั้งค่าเงื่อนไข ความเร็วเฉลี่ยลงลงจากช่วงเวลาปกติ</h4>
    <button type="button" class="close pull-right x-BT" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body rainConfig-BG">
    <div class="pos-BG">
        <p>จุดติดตั้งอุปกรณ์</p>
        <button class="pos-BT" *ngFor="let i of this.values.device_loc">{{i.location_name||''}}</button>
    </div>
    <div class="rainfall-BG">
        <p class="m-0">กรุณาตรวจสอบเงื่อนไขในการตรวจจับ</p>
        <div>
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">ร้อยละคลาดเคลื่อนจากค่า Standard Deviation</span>
                </div>
                <input type="number" numbersOnly class="form-control" [(ngModel)]="normalTrafficSd"
                    [readOnly]="values?.id" max="100" placeholder="กรุณาระบุร้อยละความคลาดเคลื่อน (0-100)">
            </div>
        </div>
        <div>
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">ร้อยละคลาดเคลื่อนจากค่า Minimum Speed</span>
                </div>
                <input type="number" numbersOnly class="form-control" [(ngModel)]="minimunSpeed"
                    [readOnly]="values?.id" max="100" placeholder="กรุณาระบุร้อยละความคลาดเคลื่อน (0-100)">
            </div>
        </div>
        <div>
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">ร้อยละคลาดเคลื่อนจากค่า Normal Traffic</span>
                </div>
                <input type="number" numbersOnly class="form-control" [(ngModel)]="normalTrafficSpeedDiff"
                    [readOnly]="values?.id" max="100" placeholder="กรุณาระบุร้อยละความคลาดเคลื่อน (0-100)">
            </div>
        </div>
        <div>
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">ร้อยละคลาดเคลื่อนจากค่า Free Flow Speed</span>
                </div>
                <input type="number" numbersOnly class="form-control" [(ngModel)]="freeFlowTrafficSpeedDiff"
                    [readOnly]="values?.id" max="100" placeholder="กรุณาระบุร้อยละความคลาดเคลื่อน (0-100)">
            </div>
        </div>
    </div>
    <!-- <div>
        <label for="r1" class="pointer chk-BG mr-3" *ngIf="!values?.id">
            <img src="./assets/event/{{alert==1? '':'un'}}chk.png" alt="">
            <input type="checkbox" name="config" id="r1" [(ngModel)]="alert" (change)="chgChk">
        </label>
        <label for="icon1" class="upload-BG" [ngClass]="{'pointer': !values?.id}">
            Icon
            <img *ngIf="icon" [src]="icon" alt="upload">
            <img *ngIf="!icon" src="./assets/event/upload-s.png" alt="upload">
            <input id="icon1" type="file" class="form-control" accept="image/*" (change)="ImgBase64($event)" onerror="this.src='assets/noimage.png'" [disabled]="values?.id">
        </label>
    </div> -->
</div>
<div class="modal-footer">
    <button *ngIf="!values?.id" class="btn btn-primary save-BT" (click)="save()">บันทึก</button>
    <button *ngIf="values?.id" class="btn btn-primary save-BT" (click)="close()">ปิด</button>
</div>