<div class="animated fadeIn m-4">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body bg-gray-900">
          <div class="d-flex justify-content-center align-items-center flex-wrap">
            <div class="d-flex align-items-center mr-2">
              <div class="mr-2" style="width: 55px;">
                ทิศทาง :
              </div>
              <div class="btn-group btn-group-toggle" data-toggle="buttons">
                <label class="btn btn-outline-primary" [(ngModel)]="dir" [btnRadio]="directionEnum.IN"
                  (click)="changeDir(directionEnum.IN)">ขาเข้า</label>
                <label class="btn btn-outline-primary" [(ngModel)]="dir" [btnRadio]="directionEnum.OUT"
                  (click)="changeDir(directionEnum.OUT)">ขาออก</label>
              </div>
            </div>
            <div class="d-flex align-items-center ml-2">
              <div class="mr-2" style="width: 80px;">
                จุดตำแหน่ง :
              </div>
              <kendo-combobox class="wwww" [data]="data_sta" [allowCustom]="allowCustom" [suggest]="true"
                [textField]="'name'" [valueField]="'id'" [value]="sta" (filterChange)="handleFilter($event)"
                [filterable]="true" (valueChange)="handleValue($event)" [placeholder]="'ทั้งหมด'">
              </kendo-combobox>
              <div class="ml-2">
                <button [disabled]="!(dir && sta)" type="button" class="btn btn-primary" style="min-width: 80px;"
                  (click)="search()">ค้นหา</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="values_fromEvt">
        <button class="xBT" (click)="event.emit(true);bsModalRef.hide()">&times;</button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 col-lg-6 col-xl-5">
      <div class="row row-eq-height">
        <div class="col-sm-12 col-md-4">
          <div class="card text-white" style="background-color: #ff8f00;">
            <div class="card-body value-BG">
              <div class="d-flex">
                <div>
                  <div *ngIf="this.data_stat?.flow != null" class="h4 mb-0">{{this.data_stat?.flow | number:'0.0-2'}}</div>
                  <div *ngIf="this.data_stat?.flow == null" class="h4 mb-0">-</div>
                </div>
                <div class="h1 text-muted text-right">
                  <i class="fa fa-road"></i>
                </div>
              </div>
              <div class="d-flex">
                <div class="text-muted text-uppercase font-weight-bold">Flow</div> <span
                  class="text-muted ml-1">(Vec/h)</span>
              </div>
            </div>
          </div>
        </div>
        <!--/.col-->
        <div class="col-sm-12 col-md-4">
          <div class="card text-white" style="background-color: #F6750F;">
            <div class="card-body value-BG">
              <div class="d-flex">
                <div *ngIf="this.data_stat?.speed != null" class="h4 mb-0">{{this.data_stat?.speed | number:'0.0-2'}}
                </div>
                <div *ngIf="this.data_stat?.speed == null" class="h4 mb-0">-</div>

                <div class="h1 text-muted text-right">
                  <i class="icon-speedometer"></i>
                </div>
              </div>
              <div class="d-flex flex-wrap">
                <div class="text-muted text-uppercase font-weight-bold">Speed</div> <span
                  class="text-muted ml-1">(Km/h)</span>
              </div>

              <!-- <div class="progress progress-white progress-xs mt-3">
                <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
              </div> -->
            </div>
          </div>
        </div>
        <!--/.col-->

        <div class="col-sm-12 col-md-4">
          <div class="card text-white" style="background-color: #EC5B1E;">
            <div class="card-body value-BG">
              <div class="d-flex">
                <div *ngIf="this.data_stat?.occupancy != null" class="h4 mb-0">
                  {{this.data_stat?.occupancy | number:'0.0-2'}}
                </div>
                <div *ngIf="this.data_stat?.occupancy == null" class="h4 mb-0">-</div>
                <div class="text-right">
                  <img src="assets/img/ic_occupancy.png" width="35" style="opacity: 0.75;height: 30px;margin-top: 6px;margin-bottom: 14px;" />
                </div>
              </div>
              <div class="d-flex flex-wrap">
                <div class="text-muted text-uppercase font-weight-bold">Occupancy<span
                  class="text-muted ml-1">(%)</span></div> 
              </div>

              <!-- <div class="progress progress-white progress-xs mt-3">
                <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
              </div> -->
            </div>
          </div>
        </div>
        <!--/.col-->

      </div>
      <div class="row" *ngIf="data_donut.length>0">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="card text-white bg-gray-900">
            <div class="card-body">
              <div class="w-100 h-100 text-center d-flex align-items-center justify-content-center h4 font-4"
                *ngIf="data_donut.length==0" style="min-height: 300px;">
                ไม่พบข้อมูล
              </div>
              <kendo-chart [style.width.%]="100" [style.color]="'white'"
                *ngIf="data_donut.length>0">
                <kendo-chart-tooltip color="#000" format="{0} %"></kendo-chart-tooltip>
                <kendo-chart-area background="none" [margin]="0">
                </kendo-chart-area>
                <kendo-chart-title text="ปริมาณจราจรแยกตามประเภท (คัน) ย้อนหลัง 1 ชั่วโมง"></kendo-chart-title>
                <kendo-chart-series>
                  <kendo-chart-series-item [autoFit]="autofit" type="donut" [data]="data_donut"
                    categoryField="veh_class_name"
                    field="per" [startAngle]="100" color="dataItem.color">
                    <kendo-chart-series-item-labels [content]="labelContent" color="#fff" background="none" position="outsideEnd">
                    </kendo-chart-series-item-labels>
                  </kendo-chart-series-item>
                </kendo-chart-series>
                <kendo-chart-legend [position]="'top'" [visible]="false"></kendo-chart-legend>
              </kendo-chart>
            </div>
          </div>
        </div>
      </div>
      <!-- volume month pu year -->
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="card text-white bg-gray-900">
            <div class="card-body">
              <kendo-chart>
                <kendo-chart-area background="none" [margin]="0" [height]="data_donut.length>0?255:325">
                </kendo-chart-area>
                <kendo-chart-title text="Volume"></kendo-chart-title>
                <kendo-chart-category-axis>
                  <kendo-chart-category-axis-item [categories]="arr_one_year_month">
                  </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-series>
                  <kendo-chart-series-item color="#ff8f00" type="column" [data]="arr_one_year_value">
                  </kendo-chart-series-item>
                </kendo-chart-series>
                <kendo-chart-tooltip [visible]="true" color="#000" format="{0:N0}"></kendo-chart-tooltip>
              </kendo-chart>
            </div>
          </div>
        </div>
      </div>
      <!-- volume 3 year pu -->
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="card text-white bg-gray-900">
            <div class="card-body">
              <kendo-chart>
                <kendo-chart-area background="none" [margin]="0" [height]="data_donut.length>0?255:325">
                </kendo-chart-area>
                <kendo-chart-title text="Volume"></kendo-chart-title>
                <kendo-chart-category-axis>
                  <kendo-chart-category-axis-item [categories]="arr_three_year">
                  </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-series>
                  <kendo-chart-series-item color="#ff8f00" type="column" [data]="arr_three_year_value">
                  </kendo-chart-series-item>
                </kendo-chart-series>
                <kendo-chart-tooltip [visible]="true" color="#000" format="{0:N0}"></kendo-chart-tooltip>
              </kendo-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-6 col-xl-7">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="card text-white bg-gray-900">
            <div class="card-body">
              <kendo-chart>
                <kendo-chart-area background="none" [margin]="0" [height]="343">
                </kendo-chart-area>
                <kendo-chart-title text="Flow (Veh / Hr)"></kendo-chart-title>
                <kendo-chart-category-axis>
                  <kendo-chart-category-axis-item [categories]="volume_line?.categories" [title]="{ text: '' }"
                    [plotBands]="volume_plot_current">
                  </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-series>
                  <kendo-chart-series-item name="Max" type="line" color="#ff6358" [style]="style"
                    [data]="volume_line.max" [dashType]="dashType" [markers]="marker">
                    <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                  </kendo-chart-series-item>
                  <kendo-chart-series-item name="Average" type="line" color="#78d237" [style]="style"
                    [data]="volume_line.avg" [dashType]="dashType" [markers]="marker">
                    <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                  </kendo-chart-series-item>
                  <kendo-chart-series-item name="Min" type="line" color="#ffd246" [style]="style"
                    [data]="volume_line.min" [dashType]="dashType" [markers]="marker">
                    <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                  </kendo-chart-series-item>
                  <kendo-chart-series-item name="Current" type="line" [style]="style" color="#28b4c8" [data]="volume_line.current" [markers]="marker">
                    <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                  </kendo-chart-series-item>
                </kendo-chart-series>
                <kendo-chart-legend [position]="'bottom'" [visible]="true"></kendo-chart-legend>
                <kendo-chart-tooltip [visible]="true"></kendo-chart-tooltip>
              </kendo-chart>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="card text-white bg-gray-900">
            <div class="card-body">
              <kendo-chart>
                <kendo-chart-area background="none" [margin]="0" [height]="343">
                </kendo-chart-area>
                <kendo-chart-title text="Occupancy (%)"></kendo-chart-title>
                <kendo-chart-category-axis>
                  <kendo-chart-category-axis-item [categories]="occupancy_line?.categories" [title]="{ text: '' }"
                    [plotBands]="occupancy_plot_current">
                  </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-series>
                  <kendo-chart-series-item name="Max" type="line" color="#ff6358" [style]="style"
                    [data]="occupancy_line.max" [dashType]="dashType" [markers]="marker">
                    <kendo-chart-tooltip color="#000" format="{0:N2}"></kendo-chart-tooltip>
                  </kendo-chart-series-item>
                  <kendo-chart-series-item name="Average" type="line" color="#78d237" [style]="style"
                    [data]="occupancy_line.avg" [dashType]="dashType" [markers]="marker">
                    <kendo-chart-tooltip color="#000" format="{0:N2}"></kendo-chart-tooltip>
                  </kendo-chart-series-item>
                  <kendo-chart-series-item name="Min" type="line" color="#ffd246" [style]="style"
                    [data]="occupancy_line.min" [dashType]="dashType" [markers]="marker">
                    <kendo-chart-tooltip color="#000" format="{0:N2}"></kendo-chart-tooltip>
                  </kendo-chart-series-item>
                  <kendo-chart-series-item name="Current" type="line" [style]="style" color="#28b4c8" [data]="occupancy_line.current" [markers]="marker">
                    <kendo-chart-tooltip color="#000" format="{0:N2}"></kendo-chart-tooltip>
                  </kendo-chart-series-item>
                </kendo-chart-series>
                <kendo-chart-legend [position]="'bottom'" [visible]="true"></kendo-chart-legend>
                <kendo-chart-tooltip [visible]="true"></kendo-chart-tooltip>
              </kendo-chart>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="card text-white bg-gray-900">
            <div class="card-body">
              <kendo-chart>
                <kendo-chart-area background="none" [margin]="0" [height]="343">
                </kendo-chart-area>
                <kendo-chart-title text="Speed (Km / Hr)"></kendo-chart-title>
                <kendo-chart-category-axis>
                  <kendo-chart-category-axis-item [categories]="speed_line?.categories" [title]="{ text: '' }"
                    [plotBands]="speed_plot_current">
                  </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-series>
                  <kendo-chart-series-item name="Max" type="line" color="#ff6358" [style]="style"
                    [data]="speed_line.max" [dashType]="dashType" [markers]="marker">
                    <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                  </kendo-chart-series-item>
                  <kendo-chart-series-item name="Average" type="line" color="#78d237" [style]="style"
                    [data]="speed_line.avg" [dashType]="dashType" [markers]="marker">
                    <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                  </kendo-chart-series-item>
                  <kendo-chart-series-item name="Min" type="line" color="#ffd246" [style]="style"
                    [data]="speed_line.min" [dashType]="dashType" [markers]="marker">
                    <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                  </kendo-chart-series-item>
                  <kendo-chart-series-item name="Current" type="line" [style]="style" color="#28b4c8" [data]="speed_line.current" [markers]="marker">
                    <kendo-chart-tooltip color="#000" format="{0:N0}"></kendo-chart-tooltip>
                  </kendo-chart-series-item>
                </kendo-chart-series>
                <kendo-chart-legend [position]="'bottom'" [visible]="true"></kendo-chart-legend>
                <kendo-chart-tooltip [visible]="true"></kendo-chart-tooltip>
              </kendo-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>