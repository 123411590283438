import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MultiSelectComponent } from "@progress/kendo-angular-dropdowns";
import * as mapboxgl from 'mapbox-gl';
// import * as MapboxglSpiderifier from 'mapboxgl-spiderifier';
// import * as MapboxglSpiderifier from 'mapbox-gl';
const MapboxglSpiderifier = require('mapboxgl-spiderifier');
import { environment as env } from 'src/environments/environment';
import { EventService } from 'src/app/services/user/event.service';
import { StatusEnum } from 'src/app/models/common-enum';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { EventModalComponent } from '../event/event-modal/event-modal.component';
import * as _ from 'lodash';
import { interval } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { ActionEnum } from 'src/app/models/common-enum';
import { DeviceService } from 'src/app/services/user/device.service';
import * as turf_bbox from '@turf/bbox';
import * as mm from 'moment';
import { DetailEffectComponent } from '../detail-effect/detail-effect.component';
import { EventDetailMapComponent } from '../event-detail-map/event-detail-map.component';
import { GroupResult, groupBy } from '@progress/kendo-data-query';
import { FuncService } from 'src/app/services/user/func.service';

@Component({
  selector: 'app-event-map',
  templateUrl: './event-map.component.html',
  styleUrls: ['./event-map.component.scss']
})
export class EventMapComponent implements OnInit, OnDestroy {
  @ViewChild('posEvents', { static: false }) posEvents?: ModalDirective;
  @ViewChild("multiselect") public route_m!: MultiSelectComponent;
  subscription: any;
  modalRef: BsModalRef;
  
  map: any;
  static mapSp: any;
  static spiderifier: any;
  static SPIDERFY_FROM_ZOOM = 10;
  eventListSpFeatures : any[] = []

  routeItems: Array<string> = [];
  route: any = [];
  route_select : any[] = []
  srcItems: Array<string> = [];
  src_selected : any
  sTypeItems: any = [];
  sType: any = [];
  sType_select : any[] = []
  StatusItems: any = [];
  Status_selected: any = [];
  Status_select : any[] = []
  
  eventListFeatures : any[] = []
  lists:any[]=[]
  st_road? : any = ''
  st_km? : number
  st_m? : number
  st_dir? : number
  en_road? : any = ''
  en_km? : number
  en_m? : number
  en_dir? : number
  event_subtype_selected? : any = []
  event_src_selected? : any = []
  occur_datetime? : any
  ll_min : any = [100,13]
  ll_max : any = [100,13]
  loading : boolean = true
  setInterval
  st_dt : any
  en_dt : any
  public allowCustom = true;
  dirlist : any[] = [
    {id:1,name:'ขาเข้า'},
    {id:2,name:'ขาออก'},
    {id:3,name:'สองทิศทาง'},
  ]
  status_list : any[] = [
    {id:1,name:'รอการตรวจสอบ/ยืนยัน'},
    {id:2,name:'ระหว่างดำเนินการ'},
    {id:3,name:'สิ้นสุดเหตุการณ์'},
  ]
  _open:boolean = true
  isCollapsed:boolean = true
  _chkType : number = 1
  // _chkCategory : number = 1
  _isCollapsedFilter:boolean = true
  _isCollapsedAcc:boolean = false
  _isCollapsedInc:boolean = true
  _isCollapsedWz:boolean = true
  al_list : any = []

  evtNum : any

  acc_list : any[] = []
  inc_list : any[] = []
  iwz_list : any[] = []
  loc_samepoint : any[] = []
  loc_dup : any [] = []
  allEventsPos : any [] = []
  pathMarker : any [] = []
  cateMarker : any = [null,null,true,true,true,true]
  public groupedData: GroupResult[]

  audio = new Audio()
  constructor(
    private func: FuncService, 
    private eS: EventService, 
    private modalService: BsModalService, 
    private commonService: CommonService) {
    
  }

  ngOnInit(): void {
    sessionStorage.removeItem('event_id')
    // this.initMap();
    // this.getEventMap();
    this.getRoute();
    this.getSrc();
    this.getsType();
    this.getIconMarker();
    this.getStatus();
    this.initMap();
    this.getNoti()
    this.contEvt()
    // this.initMapSpder();
    this.setInterval = setInterval(() => {
      this.getNoti(true)
      this.contEvt()
      // this.search()
    }, 50000);
    // this.subscription = interval(1 * 60000).subscribe(async (x) => {
    //   await this.getEventMap();
    //   this.updateMap()
    // });

    
    // let map:any = document.getElementById('map-event')
    // map.style.width = this.map_current

  }
  ngOnDestroy() {
    this.audio.pause();
    this.audio.currentTime = 0;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.setInterval) {
      clearInterval(this.setInterval);
    }
  }
  rmalert(idx:number){
    this.al_list.splice(idx, 1);
  }
  async contEvt(){
    let res : any = await this.eS.getEvtCount()
    if(res) this.evtNum = res
  }
  async getNoti(_sound?:boolean){
    let res : any = await this.eS.getEvtNoti()
    this.sound()
    if(res.length) {
      this.al_list = res
      if(_sound) this.sound()
    }
  }
  sound(){
    // var audio = new Audio();
    this.audio.src = "../../../assets/event/noti3.mp3";
    // audio.src = "../../../assets/audio/noti2.wav";
    this.audio.load();
    this.audio.play();
  //   let audio = new Audio();
  // audio.load();
  // audio.play();
  }
  async getInc(){
    let res : any = await this.eS.getmapEvt({event_type_id:1})
  }
  async getAcc(){
    let res : any = await this.eS.getmapEvt({event_type_id:10})
  }
  async getIwz(){
    let res : any = await this.eS.getmapEvt({event_type_id:[2,3,4,5,6,7,8,9,11,12]})
  }
  async eventMap(data:any){
    this.eventListFeatures = []
    this.loc_samepoint = []
    this.eventListSpFeatures = []
    this.lists = []
    this.inc_list = []
    this.acc_list = []
    this.iwz_list = []
    // this.cateMarker = [null,null,true,true,true,true]
    let res : any =await this.eS.getmapEvt(data)
    // if(!res.length) return
    // let res : any = await this.eS.getEvt(data)
    
    if(res){
      this.lists = _.cloneDeep(res)
      for (const i of res) {
        // right lists
        switch(i.event_type_id){
          case 1: {this.acc_list.push(i); break;}
          case 10: {this.iwz_list.push(i); break;}
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 7:
          case 8:
          case 9:
          case 11:
          case 12:
            {this.inc_list.push(i); break;}
          default: 
        }
        
        if(!i.lat || !i.lng) continue;
        // if(i.lng-0.2<=this.ll_min[0]) this.ll_min[0] = i.lng - 0.2
        // if(i.lat-0.2<=this.ll_min[1]) this.ll_min[1] = i.lat - 0.2
        // if(i.lng+0.2>this.ll_max[0]) this.ll_max[0] = i.lng + 0.2
        // if(i.lat+0.2>this.ll_max[1]) this.ll_max[1] = i.lat + 0.2
        // i.typeMarker = (this.getRandomInt(8))+1
        // spider
        // this.eventListSpFeatures.push(this.geo_feature(i))
        i.i_offset = [0,-140]
        i.i_size = 0.4
        if(i.display_type==1) {i.i_offset = [-10,-70];i.i_size=0.4}
        if(i.display_type==2) {i.i_offset = [-40,40];i.i_size=0.4}
        if(i.display_type==3) {i.i_offset = [-60,-30];i.i_size=0.4}
        if(i.display_type==4) {i.i_offset = [35,40];i.i_size=0.4}
        if(i.display_type==5) {i.i_offset = [50,-30 ];i.i_size=0.4}
        // i.i_offset = 'bottom'
        // if(i.display_type==1) i.i_offset = "top-left"
        // if(i.display_type==2) i.i_offset = "bottom-left"
        // if(i.display_type==3) i.i_offset = "top"
        // if(i.display_type==4) i.i_offset = "top-right"
        // if(i.display_type==5) i.i_offset = "bottom-right"
        this.eventListFeatures.push(this.geo_feature(i))
        // -------------------------------------
        
        // console.log("ID :",i.id,i.route," lat:",i.lat," lng:",i.lng)
        // let chkSame : any = _.filter(this.loc_samepoint,{lat:i.lat,lng:i.lng})
        // console.log("LOCSAME",this.loc_samepoint)
        // console.log("CHK SAME",chkSame)
        // if(chkSame.length){
        //   let idx : number = _.findIndex(this.loc_samepoint, {lat:i.lat,lng:i.lng,})
        //   console.log("---------------------",idx)
        //   if(idx||idx==0){
        //     this.loc_samepoint[idx].data.push(i)
        //   }
        // }
        // if(!chkSame.length){
        //   this.loc_samepoint.push({
        //     lat:i.lat,
        //     lng:i.lng,
        //     id:'cluster'+i.id,
        //     Evt_id:i.id,
        //     data : [i],
        //   })
        // }
      }
      // for (const i of this.loc_samepoint) {
      //   i.length = i.data.length
         
      //   if(i.data.length>1) i.event_subtype_id = 'more'
      //   // i.typeMarker = (i.data.length>1)? 'more':(this.getRandomInt(8))+1
      //   this.eventListFeatures.push(this.geo_feature(i))
      // }
      this.updateMap();
      // this.updateMapSp()
      this.filterCategory()
      this.loading = false
    }else{this.loading = false}
  }
  getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  async getRoute() {
    let res : any = await this.eS.getRoute();
    if(res){
      this.routeItems = res
      this.route_select = _.cloneDeep(this.routeItems);
    }
  }
  async getSrc() {
    let res : any = await this.eS.getSrc();
    if(res){
      res.push({id:5,descript:'แจ้งเตือนผ่านเจ้าหน้าทั้งหมด'})
      this.srcItems = res
      // this.src_selected = _.cloneDeep(this.srcItems);
    }
  }
  async getsType(){
    let res : any = await this.eS.getmapEvtSubType({})
    if(res){
      // this.sTypeItems = res
      // for (const i of res) {
      //   for (const j of i.subtype) {
      //     this.pathMarker.push({stype:j.event_subtype_id,icon_url:j.icon_url})
      //     this.sTypeItems.push({
      //       event_subtype_id:j.event_subtype_id,
      //       name: j.event_subtype_name, 
      //       category: 'type', 
      //       subcategory: i.event_type_name,
      //       event_type_id: i.event_type_id,
      //     })
          
      //   }
      // }
      this.groupedData = groupBy(this.sTypeItems, [{field: 'subcategory'}]);
      this.sType_select = _.cloneDeep(this.sTypeItems);
    }
  }
  async getIconMarker(){
    this.pathMarker = []
    let res : any = await this.eS.geticonMarker({})
    if(res){
      res = _.uniqBy(res, 'id');
      // console.log(res)
      // res.map(x => x.icon_url.replace("http:", "https:"))
      for (const i of res) {
        if(i&&i.icon_url) {
          if(i.icon_url.match('http:')) i.icon_url = i.icon_url.replace(/http:/gi,'https:')
          this.pathMarker.push(i)
        }
      }
      // console.log(this.pathMarker)
    }
  }
  async getStatus(){
    let res : any = await this.eS.getmapEvtStatus({})
    if(res){
      this.StatusItems = res
      this.Status_select = _.cloneDeep(this.StatusItems);
    }
  }
  chgRoute(evt:any){
    this.routeItems = _.cloneDeep(this.route_select)
    this.routeItems = this.routeItems.filter((val:any)=>val.toLowerCase().indexOf(evt.toLowerCase()) !== -1)
    this.route_m.toggle(true);
  }
  chgsType(evt:any){
    this.sTypeItems = _.cloneDeep(this.sType_select)
    this.sTypeItems = this.sTypeItems.filter((val:any)=>val.name.toLowerCase().indexOf(evt.toLowerCase()) !== -1)
  }
  FilterStatus(evt:any){
    this.StatusItems = _.cloneDeep(this.status_list)
    this.StatusItems = this.StatusItems.filter((val:any)=>val.name.toLowerCase().indexOf(evt.toLowerCase()) !== -1)
  }
  chgStatus(evt:any){
    // if((evt)) this.Status_selected = evt
    // this.StatusItems = _.cloneDeep(this.status_list)
    // this.StatusItems = this.StatusItems.filter((val:any)=>val.name.toLowerCase().indexOf(evt.toLowerCase()) !== -1)
  }
  chkNumberm(evt?:any){
    let result = Number(evt)
    if(result>1000) result=999
    if(result<0) result=0
    return result
  }
  search(){
    // this.loading = true
    let data : any = {
      // page:1,limit:100000000,status:'1,2,3'
      // status:'1,2',
    }
    // if(this.route.length) data.route = this.route.map((x:any)=>Number(x))
    if(this.st_road||Number(this.st_road)===0) data.st_road = Number(this.st_road)
    if(this.st_km||this.st_km==0) data.st_km = this.st_km
    if(this.st_m||this.st_m==0) data.st_m = this.st_m
    if(this.st_dir) data.st_dir = Number(this.st_dir)
    if(this.st_road&&this.st_km&&this.st_m&&this.st_dir){
    }
    if(this.en_road||Number(this.en_road)===0) data.en_road = Number(this.en_road)
    if(this.en_road&&this.en_km&&this.en_m&&this.en_dir){
    }
    if(this.en_km||this.en_km==0) data.en_km = this.en_km
    if(this.en_m||this.en_m==0) data.en_m = this.en_m
    if(this.en_dir) data.en_dir = Number(this.en_dir)
    // if(this.sType.length) data.subtype_id = this.sType.map((x:any) => x.id);
    if(this.event_subtype_selected.length) data.event_subtype_id = this.event_subtype_selected.map((x:any) => x.event_subtype_id);
    if(this.Status_selected.length) data.Status_selected = this.Status_selected.map((x:any) => x.id);

    if(this.occur_datetime){
      data.st_dt = mm(this.occur_datetime[0]).format("DD/MM/YYYY")
      data.en_dt = mm(this.occur_datetime[1]).format("DD/MM/YYYY")
    }
    if(this.st_dt) data.st_dt = mm(this.st_dt).toISOString()
    if(this.en_dt) data.en_dt = mm(this.en_dt).toISOString()
    // if(this.st_dt) data.st_dt = mm(this.st_dt).format("DD/MM/YYYY HH:mm:ss")
    // if(this.en_dt) data.en_dt = mm(this.en_dt).format("DD/MM/YYYY HH:mm:ss")
    if(this.src_selected) data.event_src_id = this.src_selected.id
    if(this.src_selected?.id==5) data.event_src_id = '1,2,3'
    this.eventMap(data)
  }
  conclude(){
    let data = {
      st_dt : mm().subtract(1,'day').toISOString(),
      en_dt : mm().toISOString(),
      // st_dt : mm().format("DD/MM/YYYY HH:mm:ss"),
      // en_dt : mm().subtract(1,'day').format("DD/MM/YYYY HH:mm:ss"),
    }
    console.log(data)
    this.eventMap(data)
  }
  searchStatus(data:any){
    this.eventMap(data)
  }
  flytoI(i:any){
    if(i.lng&&i.lat) this.map.flyTo({ center: [i.lng,i.lat], zoom: 12 });
    // if(i.lng&&i.lat) EventMapComponent.mapSp.flyTo({ center: [i.lng,i.lat], zoom: 12 });
    if(!i.lng||!i.lat) this.func.alertError("No location")
  }
  //Map ---------------------------------------
  async filterCategory(number?:any){
    this.cateMarker[number] = !this.cateMarker[number]
    // if(this.cateMarker[number]) this.map.setLayoutProperty('MarkerLayer', 'visibility', 'visible');
    // if(!this.cateMarker[number]) this.map.setLayoutProperty('MarkerLayer', 'visibility', 'none');
    let me : any = [1]
    for (const key in this.cateMarker) {
      if(this.cateMarker[key]) me.push(key)
    }
    // let fil = _.filter(this.lists,{display_type:3,display_type:4})
    let fil = _.filter(this.lists,function(c) {
      // return c.display_type == 3||c.display_type == 4;
      return me.find(x => x == c.display_type);
  });

  this.eventListFeatures = []
    for (const i of fil) {
      // i.typeMarker = (this.getRandomInt(8))+1
      i.i_offset = [0,-140]
      i.i_size = 0.4
      if(i.display_type==1) {i.i_offset = [-10,-70];i.i_size=0.4}
      if(i.display_type==2) {i.i_offset = [-40,40];i.i_size=0.4}
      if(i.display_type==3) {i.i_offset = [-60,-30];i.i_size=0.4}
      if(i.display_type==4) {i.i_offset = [35,40];i.i_size=0.4}
      if(i.display_type==5) {i.i_offset = [50,-30 ];i.i_size=0.4}
      this.eventListFeatures.push(this.geo_feature(i))
    }
    
    this.updateMap(false);
  }
  async initMap(){
    this.map = new mapboxgl.Map({
      accessToken:env.mapbox.accessToken,
      container: 'map-event',
      // style: env.mapbox.style,
      style: 'mapbox://styles/mapbox/dark-v10',
      zoom: 8,
      center: [100.523186, 13.736717],
    });
    // this.spiderifier = new MapboxglSpiderifier(this.map, {
    //   onClick: function(e, spiderLeg){
    //     console.log('Clicked on ', spiderLeg);
    //   },
    //   markerWidth: 40,
    //   markerHeight: 40,
    // });
    this.map.dragRotate.disable();

    this.map.on('load', (event:any) => {
      
      this.map.setPaintProperty('water', 'fill-color', '#000000');

      this.map.loadImage('assets/img/event/1.png', (error:any, image:any) => {
        if (error) { throw error };
        this.map.addImage(`pin-`, image);
      });
      this.map.loadImage('assets/img/event/1.png', (error:any, image:any) => {
        if (error) { throw error };
        this.map.addImage(`pin-null`, image);
      });
      // for(let i = 1;i<=8;i++){
      //   this.map.loadImage(`assets/event/marker-${i}.png`, (error:any, image:any) => {
      //     if (error) { throw error };
      //     this.map.addImage(`pin-${i}`, image);
      //   });
      // }
      // console.log(this.pathMarker)
      for (const i of this.pathMarker) {
        let icon_market = i.icon_url ||'./assets/event/icon-warning.png'
        // let icon_market = './assets/event/icon-warning.png'
        this.map.loadImage(icon_market, (error:any, image:any) => {
          if (error) { throw error };
          this.map.addImage(`pin-${i.id}`, image);
        });
      }

      this.map.loadImage('assets/event/marker-more.png', (error:any, image:any) => {
        if (error) { throw error };
        this.map.addImage(`pin-more`, image);
      });
      // -----------------------
      this.map.addSource('MarkersEvt', {
        "type": 'geojson', 
        "data": {
          "type": "FeatureCollection",
          "features": [
            {
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "Point",
                "coordinates": [100.946564,13.951342]
              }
            }
          ]
        }
      });
      this.map.addSource('MarkersEvtMore', {
        "type": 'geojson', 
        "data": {
          "type": "FeatureCollection",
          "features": [
            // {
            //   "type": "Feature",
            //   "properties": {},
            //   "geometry": {
            //     "type": "Point",
            //     "coordinates": [0,0]
            //   }
            // }
          ]
        }
      });
      // -----------------------
      
      this.map.addLayer({
        // id: "MarkerLayer",
        id: "MarkerLayer",
        source: 'MarkersEvt',
        type: 'symbol',
        layout: {
          'icon-allow-overlap': true,
          'icon-image': `pin-{icon_id}`,
          'symbol-avoid-edges': true,
          'icon-ignore-placement': true,
          // 'icon-image': 'pin-event{src_type_id}{event_subtype_id}',
          // "icon-size": 0.2,
          "icon-size": ['get', 'i_size'],
          //'text-offset': [0, 0],
          // "icon-offset": [0, -140],
          "icon-offset": ['get', 'i_offset'],
          // "icon-offset": [
          //   "case",
          //   ["==", ["get", "icon_id"], "marker_purple"],
          //   ["literal", [0, -140]],
          //   ["literal", [0, 0]],
          // ],
          // "icon-anchor":'center',
          //'text-field': '{route}',
          //'text-size': 12,
          'text-allow-overlap': true,
          'text-transform': 'uppercase',
        },
        paint: {
          "text-color": "#ffffff"
        },
        // "filter": ["==", "$type", "Point"],
      });
      this.map.addLayer({
        id: "MarkerMore",
        source: 'MarkersEvtMore',
        type: 'symbol',
        layout: {
          'icon-allow-overlap': true,
          'icon-image': `pin-more`,
          'symbol-avoid-edges': true,
          'icon-ignore-placement': true,
          "icon-size": 0.1,
          "icon-offset": [0, -140],
          'text-allow-overlap': true,
          'text-transform': 'uppercase',
        },
        paint: {
          "text-color": "#ffffff"
        },
      });
      
      // this.eventMap({page:1,limit:100000000,status:'1,2,3'})
      this.eventMap({status:'1'})
      
      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false, anchor: 'top', offset: [0, -75]
      });
      // ---------------------------------------
      this.map.on('click', "MarkerLayer", (event: any) => {
        this.map.flyTo({ center: event.features[0].geometry.coordinates, zoom: 12 });
        let p = event.features[0].properties;
        // if(JSON.parse(p.data)){ 
          // p = JSON.parse(p.data)
          // if(p.length==1) this.openModal(p[0]);
          this.openModal(p);
          
          if(p.length>1){
            this.allEventsPos = p
            this.showPosEvents()
          } 
        // }
      });
      this.map.on('mouseenter', "MarkerLayer", (e:any) => {
        this.map.getCanvas().style.cursor = 'pointer';
        let p : any = e.features[0].properties;
        let htmltext
        // if(JSON.parse(p.data)){ 
          // p = JSON.parse(p.data)
          if(p) {
            // p=p[0] 
            // console.log(p)
            htmltext = `<div class="font-3" style="padding-top: 10px;padding-left: 10px;padding-right: 10px;"><h6>${p.display_name||''}<br><small>(${p.lat},${p.lng})</smasll></h6></div>`;
          }
          // if(p){
          //   htmltext = `<div class="font-3" style="padding-top: 10px;padding-left: 10px;padding-right: 10px;"><h6>
          //   Here has --ID ${p.id}-- event.</h6></div>`
          // } 
        // }
        
        popup.setLngLat(e.features[0].geometry.coordinates)
          .setHTML(htmltext)
          .addTo(this.map);
      });
      this.map.on('mouseleave', "MarkerLayer", (e:any) => {
      //   this.map.getCanvas().style.cursor = '';
        // popup.remove();
      });
      // this.map.on('click', "MarkerMore", (event: any) => {
      //   console.log("click")
      //   // this.map.flyTo({ center: event.features[0].geometry.coordinates, zoom: 12 });
      //   // const p = event.features[0].properties;
      //   // this.openModal(p);
      //   this.map.setLayoutProperty("MarkerLayer", 'visibility', 'none');
      // });
      // this.map.on('mouseenter', "MarkerMore", (e:any) => {
      //   this.map.getCanvas().style.cursor = 'pointer';
      //   const p = e.features[0].properties;
      //   console.log(e.features[0].properties)
      //   const htmltext = `
      //   <div class="font-3" style="padding-top: 10px;padding-left: 10px;padding-right: 10px;"><h6>${p.event_type_str&&p.event_type_str!=='null'? p.event_type_str:"No subtype"}</h6>
      //   </div>`;
      //   popup.setLngLat(e.features[0].geometry.coordinates)
      //     .setHTML(htmltext)
      //     .addTo(this.map);
      // });
      // this.map.on('mouseleave', "MarkerMore", (e:any) => {
      //   this.map.getCanvas().style.cursor = '';
      //   // popup.remove();
      // });
      // ---------------------------------------

      // this.setInterval = setInterval(() => {
      // }, 100);
      this.map.resize()

      // this.map.invalidateSize()
    })
  }
  mapResize1(){
    setTimeout(() => {
      this.map.resize()
    }, 1000);
  }
  geo_feature(vals:any){
    return {
      "type": "Feature",
      "properties": vals,
      "geometry": {
        "type": "Point",
        "coordinates": [vals.lng,vals.lat]
      }
    }
    
  }
  updateMap(_zoom?:boolean) {
    if (this.map.getSource('MarkersEvt') != null) {
      this.map.getSource('MarkersEvt').setData({
        'type': 'FeatureCollection',
        'features': this.eventListFeatures
      });

      let bbox = turf_bbox.default({
        type: 'FeatureCollection',
        features: this.eventListFeatures
      });
      // console.log(bbox);
      // this.map.fitBounds([
      //   this.ll_min, // southwestern corner of the bounds
      //   this.ll_max // northeastern corner of the bounds
      // ]);
      if(!_zoom){
      }else{
        this.map.fitBounds(bbox, {
          padding: { top: 60, bottom: 60, left: 60, right: 60 }
        });
      }

    }
    this.loading = false
  }

  resize(){
    this.map.resize()
  }
  // -----------------------------------
  
  openModal(data?: any) {
    const initialState = {
      values: data,
      // list: this.list,
      // inc_type: this.allType
    };
    /**
     * displayType = {
        EVENT: 1,
        SMC: 2,
        CCTV: 3,
        BOARD_SIGN: 4,
        WEATHER: 5
    }
     */
    if(data.display_type==1) data.typeMarker=1
    if(data.display_type==2) data.typeMarker=6
    if(data.display_type==3) data.typeMarker=8
    if(data.display_type==4) data.typeMarker=7
    if(data.display_type==5) data.typeMarker=5
    // if(data.display_type==1) data.typeMarker=7
    this.modalRef = this.modalService.show(EventDetailMapComponent, { initialState, class: `markerD-BG ${data.device_sub_type_id==1035||data.typeMarker==6? 'vw-50':''}`, backdrop: 'static',id:1 });
    this.modalRef.content.event.subscribe(data => {
      // if (data) this.getEventMap();
    });
  }
  openEffectModal(data?: any) {
    let initialState = {
      values: data,
      // list: this.list,
      // inc_type: this.allType
    };
    // 
      // data.st_dt = mm(this.occur_datetime[0]).format("DD/MM/YYYY")
      // data.en_dt = mm(this.occur_datetime[1]).format("DD/MM/YYYY")
    this.modalRef = this.modalService.show(DetailEffectComponent, { initialState, class: 'w-80', backdrop: 'static',id:2 });
    this.modalRef.content.event.subscribe(data => {
      if (data) {
        switch(this._chkType){
          case 1 : this.searchStatus({status:'1'}); break;
          case 2 : this.searchStatus({status:'2'}); break;
          case 3 : this.searchStatus({status:'1,2'})
          // case 4 : this.searchStatus({status:'1,2'})
        }
      }
    });
  }
  showPosEvents(): void {
    this.posEvents?.show();
  }
 
  hidePosEvents(): void {
    this.posEvents?.hide();
  }
  public onClose(event: any) {
    // if (this.isCloseDisabled) {
      // event.preventDefault();
    // }
  }
  // -------------------------------------
  async initMapSpder(){
    
    // EventMapComponent.mapSp = new mapboxgl.Map({
     EventMapComponent.mapSp = new mapboxgl.Map({
        accessToken:env.mapbox.accessToken,
        container: 'map-event2',
        // style: env.mapbox.style,
        style: 'mapbox://styles/mapbox/dark-v10',
        zoom: 8,
        center: [100.523186, 13.736717],
      });
      EventMapComponent.spiderifier = new MapboxglSpiderifier(EventMapComponent.mapSp, {
        animate: true,
        animationSpeed: 200,
        customPin: true,
        onClick: function(e: any, spiderLeg: any){
          this.openModal(spiderLeg.feature)
        }.bind(this),
        initializeLeg: initializeSpiderLeg,
        circleFootSeparation: 50,
        // markerWidth: 80,
        // markerHeight: 80,
      })
      EventMapComponent.mapSp.dragRotate.disable();

      // -------------------
      await this.eventMap({status:'1'})
      let dataGeo : any = {
        "type": "FeatureCollection",
        "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
        features: this.eventListSpFeatures
      }
      // -------------------
      EventMapComponent.mapSp.on('load', (event: any) => {
        //------------------------------------------ 
        
      // EventMapComponent.mapSp.loadImage('assets/img/event/1.png', (error:any, image:any) => {
      //   if (error) { throw error };
      //   EventMapComponent.mapSp.addImage(`pin-`, image);
      // });
      // EventMapComponent.mapSp.loadImage('assets/img/event/1.png', (error:any, image:any) => {
      //   if (error) { throw error };
      //   EventMapComponent.mapSp.addImage(`pin-null`, image);
      // });
      // for (const i of this.pathMarker) {
      //   EventMapComponent.mapSp.loadImage(i.icon_url, (error:any, image:any) => {
      //     if (error) { throw error };
      //     EventMapComponent.mapSp.addImage(`pin-${i.stype}`, image);
      //   });
      // }
        //------------------------------------------ 

        EventMapComponent.mapSp.addSource('pins', {
          type: 'geojson',
          data: dataGeo,
          cluster: true,
          clusterMaxZoom: 14, // Max zoom to cluster points on
          clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
        });
  
        EventMapComponent.mapSp.addLayer({
          id: 'pins',
          type: 'symbol',
          source: 'pins',
          layout: {
            "icon-image": "marker-15",
            "icon-size": 0.2,
            // 'icon-image': `pin-{typeMarker}`,
          },
          'filter': ['all',['!has', 'point_count']]
        });
  
        EventMapComponent.mapSp.addLayer({
          'id': 'cluster-pins',
          'type': 'circle',
          'source': 'pins',
          'filter': ['all', ['has', 'point_count']],
          'paint': {
            'circle-color': {
              type: 'interval',
              property: "point_count",
              stops: [
                [0, '#51bbd6'],
                [20, '#f1f075'],
                [150, '#f28cb1']
              ]
            },
            'circle-radius': 18
          }
        });
  
        EventMapComponent.mapSp.addLayer({
          'id': 'cluster-pins-count',
          'type': 'symbol',
          'source': 'pins',
          'layout': {
            'text-field': '{point_count}',
            'text-font': [
              'DIN Offc Pro Medium',
              'Arial Unicode MS Bold'
            ],
            'text-size': 12
          }
        });
        EventMapComponent.mapSp.addLayer({
          id: 'unclustered-point',
          source: 'pins',
          type: 'symbol',
          filter: ['!', ['has', 'point_count']],
          layout: {
            'icon-allow-overlap': true,
            // 'icon-image': `pin-{typeMarker}`,
            'icon-image': `pin-{event_subtype_id}`,
            'symbol-avoid-edges': true,
            'icon-ignore-placement': true,
            // 'icon-image': 'pin-event{src_type_id}{event_subtype_id}',
            "icon-size": 0.2,
            //'text-offset': [0, 0],
            "icon-offset": [0, -140],
            //'text-field': '{route}',
            //'text-size': 12,
            'text-allow-overlap': true,
            'text-transform': 'uppercase',
          },
          paint: {
            "text-color": "#ffffff"
          },
        });
        // EventMapComponent.mapSp.addLayer({
        //   id: 'unclustered-point',
        //   type: 'circle',
        //   source: 'pins',
        //   filter: ['!', ['has', 'point_count']],
        //   paint: {
        //   'circle-color': '#11b4da',
        //   'circle-radius': 8,
        //   'circle-stroke-width': 4,
        //   'circle-stroke-color': '#fff'
        //   }
        // });
        EventMapComponent.mapSp.on('mousemove', mouseMove);
        // EventMapComponent.mapSp.on('click', mouseClick);
        EventMapComponent.mapSp.on('zoomstart', function(){
          EventMapComponent.spiderifier.unspiderfy();
        });

        // EventMapComponent.mapSp.on('click', (e:any,vals:any)=> {
        //   var features = EventMapComponent.mapSp.queryRenderedFeatures(e.point, {
        //     layers: ['unclustered-point']
        //   });
        // });
        EventMapComponent.mapSp.on('click','unclustered-point', (e:any,vals:any)=> {
          EventMapComponent.mapSp.getCanvas().style.cursor = 'pointer';
          // const coordinates = e.features[0].geometry.coordinates.slice();
          EventMapComponent.spiderifier.unspiderfy();
          var features = EventMapComponent.mapSp.queryRenderedFeatures(e.point, {
            layers: ['unclustered-point']
          });
          // console.log(features[0].properties)
          this.openModal(features[0].properties)
        });

        /**https://stackoverflow.com/questions/38651290/mapbox-get-list-of-points-by-click-on-cluster */
        EventMapComponent.mapSp.on('mouseenter', "unclustered-point", (e:any) => {
          EventMapComponent.mapSp.getCanvas().style.cursor = 'pointer';
        });
        // EventMapComponent.mapSp.on('mouseleave', "unclustered-point", (e:any) => {
        //     this.map.getCanvas().style.cursor = '';
        //     // popup.remove();
        // });
        EventMapComponent.mapSp.on('click', "cluster-pins", mouseClick);
      })
      function mouseClick(e: any) {
          var features = EventMapComponent.mapSp.queryRenderedFeatures(e.point, {
              layers: ['cluster-pins']
          });
    
          EventMapComponent.spiderifier.unspiderfy();
          if (!features.length) {
            return;
          } else if (EventMapComponent.mapSp.getZoom() < EventMapComponent.SPIDERFY_FROM_ZOOM) {
            
          } else {
            EventMapComponent.mapSp.getSource('pins').getClusterLeaves(
              features[0].properties.cluster_id,
              100,
              0,
              function(err: any, leafFeatures: any){
                if (err) {
                  return console.error('error while getting leaves of a cluster', err);
                }
                var markers = _.map(leafFeatures, function(leafFeature){
                  return leafFeature.properties;
                });
                EventMapComponent.spiderifier.spiderfy(features[0].geometry.coordinates, markers);
              }
            );
            
          }
        }
    
        function mouseMove(e: any) {
          var features = EventMapComponent.mapSp.queryRenderedFeatures(e.point, {
            layers: ['cluster-pins']
          });
          EventMapComponent.mapSp.getCanvas().style.cursor = (features.length) ? 'pointer' : '';
        }
        function initializeSpiderLeg(spiderLeg: any){
          var pinElem = spiderLeg.elements.pin;
          var feature = spiderLeg.feature;
          var popup: any;

          pinElem.innerHTML = `<img class="pin-marker" src="${spiderLeg.feature.icon_url||'https://www.clipartmax.com/png/middle/348-3482935_manager-clipart-incident-management-incident-reporting-icon.png'}"></img>`
        }
  }
  
  updateMapSp() {
    if (EventMapComponent.mapSp.getSource('pins') != null) {
      EventMapComponent.mapSp.getSource('pins').setData({
        'type': 'FeatureCollection',
        'features': this.eventListSpFeatures
      });

      let bbox = turf_bbox.default({
        type: 'FeatureCollection',
        features: this.eventListSpFeatures
      });
      EventMapComponent.mapSp.fitBounds(bbox, {
        padding: { top: 60, bottom: 60, left: 60, right: 60 }
      });

    }
    this.loading = false
  }
}
