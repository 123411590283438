<div class="modal-header">
    <h4>{{headName}}</h4>
    <button type="button" class="close pull-right x-BT" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div *ngIf="mT==1">
        <p>เลือกประเภทป้าย</p>
    </div>
    <div *ngIf="mT==1" class="d-flex mb-3">
        <label class="mr-4" for="t2">
            <input type="checkbox" name="signType" id="t2" [(ngModel)]="group_id[1]" [value]="1014"> VSW
        </label>
        <label class="mr-4" for="t1">
            <input type="checkbox" name="signType" id="t1" [(ngModel)]="group_id[0]" [value]="1013"> ATIS
        </label>
        <label class="mr-4" for="t3">
            <input type="checkbox" name="signType" id="t3" [(ngModel)]="group_id[2]" [value]="1012"> LMS
        </label>
        <label class="mr-4" for="t4">
            <input type="checkbox" name="signType" id="t4" [(ngModel)]="group_id[3]" [value]="1015"> VSW
        </label>
    </div>
    <div *ngIf="mT==1||mT==2">
        <p>รัศมีกิโลเมตร (กิโลเมตร)</p>
    </div>
    <div *ngIf="mT==1||mT==2">
        <input type="number" class="form-control" [(ngModel)]="radius" [min]="0"numbersOnly>
    </div>
    <div *ngIf="mT==1" class="text-right">
        <button class="btn btn-primary mt-2" (click)="save()" [disabled]="!group_id.length||!radius">ดำเนินการต่อ</button>
    </div>
    <div *ngIf="mT==2" class="text-right">
        <button class="btn btn-primary mt-2" (click)="openCCTVDetailModel({radius:radius})" [disabled]="!radius">ดำเนินการต่อ</button>
    </div>
    <div *ngIf="mT==3">
        <div class="form-BG">
            <div>
                <div>ประเภทของผลกระทบ</div>
                <select [(ngModel)]="effect_type_id" class="form-control">
                    <option value="" selected disabled>โปรดเลือกประเภทของผลกระทบ</option>
                    <option *ngFor="let i of effType_list" [value]="i.id">{{i.name||''}}</option>
                </select>
            </div>
            <div>
                <div>อื่นๆโปรดระบุ</div>
                <input type="text" class="form-control">
            </div>
            <div>
                <div>ช่วงเวลาเกิดเหตุ</div>
                <kendo-datetimepicker [(ngModel)]="occur_datetime" class="form-control" [format]="'dd-MMM-yyyy HH:mm'" placeholder="เลือกช่วงเวลาเกิดเหตุ"></kendo-datetimepicker>
            </div>
            <div>
                <div>หมายเลขช่องจราจร(ทางหลัก)<small>*นับจากไหล่ทาง</small></div>
                <!-- <input type="number" class="form-control" numbersOnly [max]="8" [(ngModel)]="lane" placeholder="กรุณากรอกเลน"> -->

                <kendo-multiselect [data]="[1,2,3,4,5,6,7,8,9,10]"
                 [(ngModel)]="mainline"
                 [allowCustom]="false" class="form-control" placeholder="เลือกเลน">
                </kendo-multiselect>
            </div>
            <div>
                <div>หมายเลขช่องจราจร(ทางขนาน)<small>*นับจากไหล่ทาง</small></div>
                <kendo-multiselect [data]="[1,2,3,4,5,6,7,8,9,10]"
                 [(ngModel)]="frontage"
                 [allowCustom]="false" class="form-control" placeholder="เลือกเลน">
                </kendo-multiselect>
            </div>
            <!-- <div>
                <div>หมายเลขช่องจราจร(นับจากไหล่ทาง)</div>
                <input type="number" class="form-control" numbersOnly [max]="8" [(ngModel)]="lane" placeholder="กรุณากรอกเลน">
            </div> -->
            <div>
                <div>เลือกพื้นที่การเกิดเหตุ</div>
                
                <select [(ngModel)]="effect_loc_type" class="form-control">
                    <option value="" selected disabled>โปรดเลือกพื้นที่การเกิดเหตุ</option>
                    <option [value]="1">แบบจุด</option>
                    <option [value]="2">แบบเส้น</option>
                </select>
            </div>
            <div *ngIf="effect_type_id==3">
                <div>ระดับความลึก (ซม.)</div>
                <input type="text" class="form-control" [(ngModel)]="depth">
            </div>
            
        </div>
        <div class="d-flex route-BG" *ngIf="effect_loc_type">
            <p class="">จุดเกิดเหตุ <span *ngIf="effect_loc_type==2">(เริ่มต้น)</span></p>
            <select [(ngModel)]="st_road" class="form-control">
                <option value="" selected disabled>หมายเลขสายทาง</option>
                <option *ngFor="let i of route_list" [value]="i">{{i}}</option>
            </select>
            <p class="">-</p>
            <input type="number" [(ngModel)]="st_km" class="form-control" placeholder="หลักกิโลเมตร" [disabled]="!st_road" (focusout)="getLoc(st_road,st_km,1)" numbersOnly>
            <p class="">+</p>
            <input type="number" [(ngModel)]="st_m" class="form-control" placeholder="หลักเมตร" (focusout)="st_m = chkNumberm(st_m)" [disabled]="!st_km&&st_km!=0" numbersOnly>
            <p class="">ทิศทาง</p>
            <select [(ngModel)]="st_direction" class="form-control">
                <option value="" selected disabled>เลือกทิศทาง</option>
                <option [value]="1">ขาเข้า</option>
                <option [value]="2">ขาออก</option>
            </select>
        </div>
        <div class="d-flex route-BG" *ngIf="effect_loc_type==2">
            <p class="">จุดเกิดเหตุ <span *ngIf="effect_loc_type==2">(สิ้นสุด)</span></p>
            <select [(ngModel)]="en_road" class="form-control">
                <option value="" selected disabled>หมายเลขสายทาง</option>
                <option *ngFor="let i of route_list" [value]="i">{{i}}</option>
            </select>
            <p class="">-</p>
            <input type="number" [(ngModel)]="en_km" class="form-control" placeholder="หลักกิโลเมตร" [disabled]="!en_road" (focusout)="getLoc(en_road,en_km,2)" numbersOnly>
            <p class="">+</p>
            <input type="number" [(ngModel)]="en_m" class="form-control" placeholder="หลักเมตร" (keyup)="en_m = chkNumberm(en_m)" [disabled]="!en_km&&en_km!=0" numbersOnly>
            <p class="">ทิศทาง</p>
            <select [(ngModel)]="en_direction" class="form-control">
                <option value="" selected disabled>เลือกทิศทาง</option>
                <option [value]="1">ขาเข้า</option>
                <option [value]="2">ขาออก</option>
            </select>
        </div>
        <div class="text-right">
            <button class="btn btn-success sendEffLog-BT" (click)="sendEffectLog()">ยืนยัน</button>
        </div>
    </div>
</div>