import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EventService } from 'src/app/services/user/event.service';
import * as _ from 'lodash';
import { CommonService } from 'src/app/services/common.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-route',
  templateUrl: './add-route.component.html',
  styleUrls: ['./add-route.component.scss']
})
export class AddRouteComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();
  public data_route: Array<string>;
  values: any
  route: any
  search_route: string;
  allowCustom: boolean = true
  st_km: number
  en_km: number
  constructor(
    public bsModalRef: BsModalRef,
    public eS: EventService,
    private commonService: CommonService,
  ) { }
  ngOnInit(): void {
    this.getRoute();
  }
  async getRoute() {
    this.commonService.getRoute().subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.data_route = _.cloneDeep(res.data);
            }
          }
        }
      });
  }
  handleValueRoute(value) {
    if (value) {
      this.search_route = value;
    } else {
      this.search_route = null;
    }
  }

  async save() {
    if (this.route == null && this.st_km == null && this.en_km == null) {
      this.close();
    }
    if (this.route != null && this.st_km != null && this.en_km != null) {
      let data: any = {
        config: {
          route: Number(this.route),
          st_km: this.st_km,
          en_km: this.en_km
        }
      }
      let res: any = await this.eS.addUpdateQueueingDetactionDevices(data)
      if (res) this.confirm();
    }
  }
  confirm() {
    this.event.emit(true);
    this.bsModalRef.hide();
    window.location.reload();
  }

  close() {
    this.event.emit(false);
    this.bsModalRef.hide();
  }
}
