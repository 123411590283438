import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EventService } from 'src/app/services/user/event.service';

class dataConfig{
  normalTrafficSd: any
  maximumTraveltimeDiff: any
  normalTraveltimeDiff: any
  freeFlowTraveltimeDiff: any
  free_flow_tt_traveltime_diff : any
  free_flow_tt_traveltime_diff_level1 : any
  free_flow_tt_traveltime_diff_level2 : any
  free_flow_tt_traveltime_diff_level3 : any
  volume : any
}

@Component({
  selector: 'app-model-traveltime-detection',
  templateUrl: './model-traveltime-detection.component.html',
  styleUrls: ['./model-traveltime-detection.component.scss']
})
export class ModalTraveltimeDetectionComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();
  values: any;
  alert:any;
  icon: any;

  sendData : dataConfig[] = [new dataConfig(), new dataConfig()]
  sendData2 : any=[{},{}]
  _device_loc : any = []
  _isIn : boolean = false
  _isOut : boolean = false
  _chkHead : any = []
  constructor(
    public bsModalRef: BsModalRef,
    public eS : EventService,
  ) { }

  ngOnInit(): void {
    if(this.values.id) this.getTravalTimeDetactionDevices({zone_id:this.values.zone_id})
    if(this.values.device_loc){
      for (const i of this.values.device_loc) {
        if(i.dir==1) this._isIn=true
        if(!i.dir||i.dir==2) this._isOut=true
        this._device_loc.push(true)
      }
    }
    // console.log(_.uniq(this.values))
  }
  async getTravalTimeDetactionDevices(data:any){
    console.log(this.values)
    console.log(data)
    let res : any = await this.eS.getTravalTimeDetactionDevices(data)
    if(res){
      let d = (res.dir==1)? 1:0
    //   this.normalTrafficSd = res.normal_traffic_sd
    //   this.maximumTraveltimeDiff = res.maximum_traveltime_diff
    //   this.normalTraveltimeDiff = res.normal_traveltime_diff
    //   this.freeFlowTraveltimeDiff = res.free_flow_traveltime_diff
    //   this.icon = res.icon_url
    
      this._chkHead[0]=res.allow_normal_traffic_sd||false
      this._chkHead[1]=res.allow_maximum_traveltime_diff||false
      this._chkHead[2]=res.allow_normal_traveltime_diff||false
      this._chkHead[3]=res.allow_free_flow_traveltime_diff||false
      this._chkHead[4]=res.allow_free_flow_tt_traveltime_diff||false

      this.sendData[d].normalTrafficSd = res.normal_traffic_sd
      this.sendData[d].maximumTraveltimeDiff = res.maximum_traveltime_diff
      this.sendData[d].normalTraveltimeDiff = res.normal_traveltime_diff
      this.sendData[d].freeFlowTraveltimeDiff = res.free_flow_speed_traveltime_diff
      this.sendData[d].free_flow_tt_traveltime_diff_level1 = res.free_flow_tt_traveltime_diff_level1
      this.sendData[d].free_flow_tt_traveltime_diff_level2 = res.free_flow_tt_traveltime_diff_level2
      this.sendData[d].free_flow_tt_traveltime_diff_level3 = res.free_flow_tt_traveltime_diff_level3
      this.sendData[d].volume = res.volume
    }
  }
  ImgBase64(evt?: any,imgPath? : any){
    var reader = new FileReader();
    reader.readAsDataURL(evt.target.files[0]);
    reader.onload = async (_event) => {
      let res : any = await this.eS.uploadIconImg({image:reader.result})
      if(res) this.icon = res.img_url 
    }
  }
  chgChk(){
    console.log(this.alert)
  }
  // free_flow_tt_traveltime_diff_level2
  async save(){
    let data: any = []
    for (const i in this._device_loc) {
      if(this._device_loc[i]){
        let dir = this.values.device_loc[i].dir==1? 1:0
        data.push({
          zone_id:this.values.device_loc[i].zone_id,
          normal_traffic_sd:this.sendData[dir].normalTrafficSd,
          maximum_traveltime_diff:this.sendData[dir].maximumTraveltimeDiff,
          normal_traveltime_diff:this.sendData[dir].normalTraveltimeDiff,
          free_flow_speed_traveltime_diff:this.sendData[dir].freeFlowTraveltimeDiff,

          free_flow_tt_traveltime_diff_level1:this.sendData[dir].free_flow_tt_traveltime_diff_level1,
          free_flow_tt_traveltime_diff_level2:this.sendData[dir].free_flow_tt_traveltime_diff_level2,
          free_flow_tt_traveltime_diff_level3:this.sendData[dir].free_flow_tt_traveltime_diff_level3,
          volume:this.sendData[dir].volume,
          allow_normal_traffic_sd : this._chkHead[0]? 1 : 0,
          allow_maximum_traveltime_diff : this._chkHead[1]? 1 : 0,
          allow_normal_traveltime_diff : this._chkHead[2]? 1 : 0,
          allow_free_flow_traveltime_diff : this._chkHead[3]? 1 : 0,
          allow_free_flow_tt_traveltime_diff : this._chkHead[4]? 1 : 0,
        })
      }
    }
    let res : any = await this.eS.addUpdateTravalTimeDetactionDevices(data)
    if(res) this.confirm();
  }
  
  confirm() {
    this.event.emit(true);
    this.bsModalRef.hide();
  }
  close() {
    this.event.emit(false);
    this.bsModalRef.hide();
  }
}
