<div class="modal-header">
    <h4>CCTV</h4>
    <button type="button" class="close pull-right x-BT" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <table class="table table-hover table-outline mb-0 table-no-border">
        <thead>
            <tr>
                <th>Location</th>
                <th>Name</th>
                <th>Link</th>
            </tr>
        </thead>
        <tbody *ngIf="!cctvList.length">
            <tr>
                <td colspan="3">No data</td>
            </tr>
        </tbody>
        <tbody *ngIf="cctvList.length">
            <tr *ngFor="let i of cctvList">
                <td>{{i.location_name||''}}</td>
                <td>{{i.device_name||''}}</td>
                <td><a [href]="i.cctv_url"  target="_blank">Link</a></td>
            </tr>
            <!-- <tr>
                <td>R1-80+000 IN</td>
                <td>Fixed CCTV for Traffic Surevillance</td>
                <td><a href="javascript:" (click)="openVedio()">Link</a></td>
            </tr>
            <tr>
                <td>R1-80+000 IN</td>
                <td>Fixed CCTV for Traffic Surevillance</td>
                <td><a href="http://180.180.242.208:1935/TOC/TOC_210.stream/playlist.m3u8" target="_blank">Link</a></td>
            </tr> -->
        </tbody>
    </table>
</div>
