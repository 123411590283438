import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DeviceService } from 'src/app/services/user/device.service';
import { FuncService } from 'src/app/services/user/func.service';
@Component({
  selector: 'app-cctv-detail',
  templateUrl: './cctv-detail.component.html',
  styleUrls: ['./cctv-detail.component.scss']
})
export class CctvDetailComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();
  values: any;
  cctvList : any = []
  constructor(
    public bsModalRef: BsModalRef,
    private dS : DeviceService,
    private func : FuncService,
  ) { }

  ngOnInit(): void {
    console.log(this.values)
    this.getCCTV(this.values)
  }
  async getCCTV(params:any){
    let res : any = await this.dS.getCctv(params)
    console.log(res)
    if(res.length) this.cctvList = res
  }
  openVedio(data?:any){
    console.log(data)
    this.func.alertpopup(`
    <video width="352" height="288" src="http://180.180.242.208:1935/TOC/TOC_210.stream/playlist.m3u8" controls autoplay></video>`,"Vedio")
  }
}
