import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { EventComponent } from './event/event.component';
import { IncidentTypeComponent } from './incident-type/incident-type.component';
import { EventMapComponent } from './event-map/event-map.component';
import { SettingComponent } from './setting/setting.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ConfigRainComponent } from './config-rain/config-rain.component';
import { ConfigSpeedDirectionComponent } from './config-speed-direction/config-speed-direction.component';
import { ConfigFlowDetectionComponent } from './config-flow-detection/config-flow-detection.component';
import { ConfigOccupanyDetectionComponent } from './config-occupany-detection/config-occupany-detection.component';
import { ConfigTraveltimeDetectionComponent } from './config-traveltime-detection/config-traveltime-detection.component';
import { ConfigQueueingDetectionComponent } from './config-queueing-detection/config-queueing-detection.component';
export const routes: Routes = [{
  path: '',
  redirectTo: 'map',
  pathMatch: 'full',
},
{
  path: '',
  component: HomeComponent,
  children: [
    {
      path: 'list',
      component: EventComponent,
    },
    {
      path: 'map',
      component: EventMapComponent,
    },
    {
      path: 'setting',
      component: SettingComponent,
    },
    {
      path: 'dashboard',
      component: DashboardComponent,
    },
    {
      path: 'rain-config',
      component: ConfigRainComponent,
    },
    {
      path: 'speed-direction-config',
      component: ConfigSpeedDirectionComponent,
    },
    {
      path: 'flow-detection-config',
      component: ConfigFlowDetectionComponent,
    },
    {
      path: 'occupany-detection-config',
      component: ConfigOccupanyDetectionComponent,
    },
    {
      path: 'traveltime-detection-config',
      component: ConfigTraveltimeDetectionComponent,
    },
    {
      path: 'queueing-detection-config',
      component: ConfigQueueingDetectionComponent,
    },
    // {
    //   path: 'incident/type',
    //   component: IncidentTypeComponent,
    // },
    
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EventRoutingModule { }
