import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiResponse, PagableData } from '../../models/common';
import { BoardsignInfo, AddBoardsign, ReqAddSign, Structure, ReqAutoSuggestion } from '../../models/sign-control/boardsign';
import { AtisInfo, ReqAddAtis } from '../../models/sign-control/atis';
import * as _ from 'lodash';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import { StatusEnum } from '../../models/common-enum';

@Injectable({
  providedIn: 'root'
})
export class ATISService {

  domain: any = environment.api.domain_sign_control;
  uri = 'atis';
  t = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjIjoiNWIwZTQzODM0NTc3OTQxOTAxMWU2Yzk1IiwicyI6WyJUTUMiXSwiciI6Ikh3SnVpNkRmdUxEZ05TM1lyUWZtNnZIV2hqWU1qeE02VjFtTDFCV0JvbWVTIiwiaWF0IjoxNjA3OTM0MDgwfQ.9MhE1LIdqTx1xiG6lspi_QOWF6YP7EiDhQ6v3y9KOu8`;
  constructor(private http: HttpClient, private authService: AuthService) {
    this.domain = environment.api.domain_sign_control;
  }
  getOldAtis(): Observable<ApiResponse<any>> {
    const url = `https://boardsign-mng.shw.transcodeglobal.com/boardsign/tig?t=${new Date().getTime()}`;
    //const url = `https://boardsign-mng.shw.transcodeglobal.com/atis?t=${new Date().getTime()}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.t}`
      })
    };
    return this.http.get<ApiResponse<AtisInfo[]>>(url, httpOptions);
  }
  getAtis(page?: number, limit?: number, name?: string, sign_type_id?: number, route?: string, short?: number, status?: number,
    st_km?: number, en_km?: number, is_dir_in?: boolean, is_dir_out?: boolean)
    : Observable<ApiResponse<PagableData<AtisInfo[]>>> {
    const url = `${this.domain}/${this.uri}`;
    let params = new HttpParams();
    if (!_.isNil(page)) {
      params = params.append('page', page.toString());
    }
    if (!_.isNil(limit)) {
      params = params.append('limit', limit.toString());
    }
    if (!_.isNil(name)) {
      params = params.append('name', name.toString());
    }
    if (!_.isNil(sign_type_id)) {
      params = params.append('sign_type_id', sign_type_id.toString());
    }
    if (!_.isNil(route)) {
      params = params.append('route', route.toString());
    }
    if (!_.isNil(short)) {
      params = params.append('short', short.toString());
    }
    if (!_.isNil(status)) {
      params = params.append('status', status.toString());
    }
    if (!_.isNil(st_km)) {
      params = params.append('st_km', st_km.toString());
    }
    if (!_.isNil(en_km)) {
      params = params.append('en_km', en_km.toString());
    }
    if (!_.isNil(is_dir_in)) {
      params = params.append('is_dir_in', is_dir_in.toString());
    }
    if (!_.isNil(is_dir_out)) {
      params = params.append('is_dir_out', is_dir_out.toString());
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<PagableData<AtisInfo[]>>>(url, httpOptions);

    // let url1 = 'assets/data/atis.json';
    // return this.http.get<ApiResponse<AtisInfo[]>>(url1);
  }

  getAtisById(atis_id?: number): Observable<ApiResponse<any>> {
    const uri = `${this.uri}/${atis_id}`;
    const url = `${this.domain}/${uri}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.get<ApiResponse<AtisInfo[]>>(url, httpOptions);
  }

  addAtis(req: ReqAddAtis): Observable<ApiResponse<any>> {
    const url = `${this.domain}/${this.uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.post<ApiResponse<any>>(url, req, httpOptions);
  }
  editAtis(req: ReqAddAtis, id?: number): Observable<ApiResponse<any>> {
    const uri = `${this.uri}/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }
  deleteAtis(id: number): Observable<ApiResponse<any>> {
    const uri = `${this.uri}/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.delete<ApiResponse<any>>(url, httpOptions);
  }
  deleteAtisEditStatus(req: ReqAddAtis, id?: number): Observable<ApiResponse<any>> {
    req.status = StatusEnum.Delete;
    const uri = `${this.uri}/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }
  changeAitsMode(atis_id?: number, mode?: number): Observable<ApiResponse<any>> {
    const uri = `${this.uri}/${atis_id}/mode`;
    const url = `${this.domain}/${uri}`;
    const req = { mode: mode };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.post<ApiResponse<any>>(url, req, httpOptions);
  }
  updateManualAitsImage(atis_id?: number, img_url?: string, width?: number, height?: number, content_type?: string, media_type?: number, behavior_type?: number, status?: number): Observable<ApiResponse<any>> {
    const uri = `${this.uri}/${atis_id}/manual`;
    const url = `${this.domain}/${uri}`;
    const req = {
      image_manual: img_url,
      width: width,
      height: height,
      content_type: content_type,
      media_type: media_type,
      behavior_type: behavior_type,
      status: status
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.post<ApiResponse<any>>(url, req, httpOptions);
  }
}
