
import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { interval } from 'rxjs';
import * as _ from 'lodash';
import { CommonService } from 'src/app/services/common.service';
import { EventService } from 'src/app/services/user/event.service';
import { CorridorControlService } from 'src/app/services/corridor-control/corridor-control.service';
import { RouteDirectionEnum } from 'src/app/models/common-enum';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { EventDetailMapComponent } from '../../event/event-detail-map/event-detail-map.component';

@Component({
  selector: 'app-corridor-control-new-version',
  templateUrl: './corridor-control-new-version.component.html',
  styleUrls: ['./corridor-control-new-version.component.scss']
})
export class CorridorControlNewVersionComponent implements OnInit, OnDestroy {
  // frontage road ทางคู่ขนาน

  traffic_api = 'https://uat-api.shw.transcode.co.th/api/tt-analytic-service/road-analytic?json=1';

  @ViewChild('Road') elementView: ElementRef;

  modalRef: BsModalRef;

  data_key = 'SHW-CORRIDOR-SEARCH';
  ContainerHeight: number;
  direction = RouteDirectionEnum;
  search_list = [
    {
      route: 1,
      st_km: 50,
      en_km: 100
    }
  ];

  search_list_default = [
    {
      route: 1,
      st_km: 49,
      en_km: 125
    },
    {
      route: 2,
      st_km: 1,
      en_km: 111
    },
  ];

  config_road = [];

  gen_line = [];
  lane_height: number = 26;
  sign_height: number = 20;
  center_km_height: number = 7;
  km_point_height: number = 20;
  //max_lane: number = 3;
  km_width_px = 80;
  road_gap = 6;
  road_border_main = 3;
  road_border_ft = 4;
  road_height = 0;

  road_list: any[] = [];
  road_list_all: any[] = [];
  vertical_line: any[] = [];
  road_layer_height: number;
  max_lane_out = 0;
  max_lane_in = 0;
  max_ft_lane_out = 0;
  max_ft_lane_in = 0;

  intersection = [];
  traffic_road = [];
  traffic_data = [];
  null_data_color = '#7CFC00';
  no_data_color = '#7CFC00';

  data_route: any[] = [1, 2];
  route_list: any[] = [1, 2];

  min: number
  max: number
  per_road = JSON.parse(localStorage.permission_road)
  route: any;
  allowCustom = true;
  st_km: any;
  en_km: any;
  is_search = false;
  subscription: any;

  show_event = true;
  constructor(private commonService: CommonService, private corridorService: CorridorControlService, private eventService: EventService, private modalService: BsModalService) { }

  async ngOnInit() {
    this.road_list = [];
    for (let i = 1; i <= 6; i++) {
      this.gen_line.push(i);
    }
    if (this.show_event) this.getIconMarker();
    let search_log: any = localStorage.getItem(this.data_key);
    if (search_log) {
      this.search_list = JSON.parse(search_log);
    } else {
      this.search_list = _.cloneDeep(this.search_list_default);
    }
    //await this.getRoutes();
    await this.getRoadway(this.search_list);

    this.subscription = interval(120000).subscribe((x) => {
      this.getTraffic();
      if (this.show_event) this.getEvent(this.event_req);
    });
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async search(req, isClick?: boolean) {
    if (isClick) {
      localStorage.setItem(this.data_key, JSON.stringify(req));
      this.is_search = true;
      setTimeout(() => {
        this.is_search = false;
      }, 30000);
    }
    await this.getRoadway(req);
  }

  addSearch() {
    let g = {
      route: null,
      st_km: null,
      en_km: null
    };
    this.search_list.push(g);
  }

  deleteSearch(item: any) {
    const index: number = this.search_list.indexOf(item);
    if (index !== -1) {
      this.search_list.splice(index, 1);
    }
  }
  async setRoad() {
    this.max_lane_out = _.max(this.config_road.map(x => x.out_m_lane));
    this.max_lane_in = _.max(this.config_road.map(x => x.in_m_lane));
    this.max_ft_lane_out = _.max(this.config_road.map(x => x.out_ft_lane));
    this.max_ft_lane_in = _.max(this.config_road.map(x => x.in_ft_lane));
    this.road_list = [];
    this.vertical_line = [];
    let vertical_index = 0;
    for (let i = 0; i < this.search_list.length; i++) {
      for (let j = this.search_list[i].st_km; j <= this.search_list[i].en_km; j++) {
        let corr = this.config_road.find(x => x.route == this.search_list[i].route && x.km == j);

        let lanes_out = [];
        let lanes_in = [];
        let ft_lanes_out = [];
        let ft_lanes_in = [];

        let lanes_out_bg = [];
        let lanes_in_bg = [];
        let ft_lanes_out_bg = [];
        let ft_lanes_in_bg = [];
        if (corr) {
          lanes_out = await this.genLane(corr.out_m_lane, true);
          lanes_in = await this.genLane(corr.in_m_lane);
          ft_lanes_out = await this.genLane(corr.out_ft_lane, true);
          ft_lanes_in = await this.genLane(corr.in_ft_lane);

          lanes_out_bg = await this.genLane(this.max_lane_out - corr.out_m_lane);
          lanes_in_bg = await this.genLane(this.max_lane_in - corr.in_m_lane);

          ft_lanes_out_bg = await this.genLane(this.max_ft_lane_out - corr.out_ft_lane);
          ft_lanes_in_bg = await this.genLane(this.max_ft_lane_in - corr.in_ft_lane);

        } else {
          lanes_out_bg = await this.genLane(this.max_lane_out);
          lanes_in_bg = await this.genLane(this.max_lane_in);

          ft_lanes_out_bg = await this.genLane(this.max_ft_lane_out);
          ft_lanes_in_bg = await this.genLane(this.max_ft_lane_in);

        }
        const item = { route: this.search_list[i].route, km: j, lanes_out: lanes_out, lanes_in: lanes_in, ft_lanes_out: ft_lanes_out, ft_lanes_in: ft_lanes_in, lanes_out_bg, lanes_in_bg, ft_lanes_out_bg, ft_lanes_in_bg };
        this.road_list.push(item);

        if (i < this.search_list.length - 1 && j == this.search_list[i].en_km) {
          vertical_index = vertical_index + (this.search_list[i].en_km - this.search_list[i].st_km);
          if (i != 0) vertical_index = vertical_index + 1;
          const line = { route1: this.search_list[i].route, last_km_route1: this.search_list[i].en_km, route2: this.search_list[i + 1].route, first_km_route2: this.search_list[i + 1].st_km, i: vertical_index }
          this.vertical_line.push(line);
        }
      }
    }
    setTimeout(() => {
      this.road_height = ((this.max_ft_lane_in + this.max_ft_lane_out + this.max_lane_out + this.max_lane_in) * this.lane_height) +
        ((this.lane_height + 10) * 4) + (this.road_border_ft * 2) + (this.road_border_main * 2) + (this.road_gap * 2) + this.center_km_height;
      this.road_list_all = _.cloneDeep(this.road_list);
      this.road_list.splice(-1);
      this.getTraffic();
    }, 100);

  }

  setIntersection() {
    this.intersection = this.intersection.filter(x => x.st_x != null && x.st_y != null && x.img_link != null);
    for (let i = 0; i < this.intersection.length; i++) {
      let find = this.road_list.findIndex(x => x.km == this.intersection[i].st_km && x.route == this.intersection[i].route);
      if (find) {
        this.intersection[i]["x"] = (find * this.km_width_px) - this.intersection[i].st_x;

        if (this.intersection[i].st_direction == 2 && this.intersection[i].st_m_or_ft == 2) {
          let count = this.max_ft_lane_out - this.intersection[i].st_lane;
          this.intersection[i]["y"] = (count * this.lane_height) + ((this.lane_height + 10) * 2) + 4;
        }

        if (this.intersection[i].st_direction == 2 && this.intersection[i].st_m_or_ft == 1) {

          let count = this.max_lane_out - this.intersection[i].st_lane + (this.max_ft_lane_out);
          this.intersection[i]["y"] = (count * this.lane_height) + ((this.lane_height + 10) * 2) + this.road_border_ft + this.road_border_main + this.road_gap;
        }

        if (this.intersection[i].st_direction == 1 && this.intersection[i].st_m_or_ft == 1) {

          this.intersection[i]["y"] = ((this.intersection[i].st_lane + this.max_ft_lane_out + this.max_lane_out - 1) * this.lane_height) + ((this.lane_height + 10) * 2) + this.road_border_ft + this.road_border_main + this.road_gap + this.center_km_height;
        }

        if (this.intersection[i].st_direction == 1 && this.intersection[i].st_m_or_ft == 2) {
          this.intersection[i]["y"] = ((this.intersection[i].st_lane + this.max_ft_lane_out + this.max_lane_out + this.max_lane_in - 1) * this.lane_height) + ((this.lane_height + 10) * 2) + this.road_border_ft + (this.road_border_main * 2) + (this.road_gap * 2) + this.center_km_height;
        }

        this.intersection[i]["y"] = this.intersection[i]["y"] - this.intersection[i].st_y;
        this.intersection[i]["img_link"] = this.intersection[i]["img_link"] + `?t=${new Date().getTime()}`;

      }
    }
  }

  genLane(lane_num?: number, reverse?: boolean) {
    return new Promise<number[]>((reslove, reject) => {
      let lanes: number[] = [];
      for (let l = 1; l <= lane_num; l++) {
        lanes.push(l);
      }
      if (reverse) lanes.reverse();
      return reslove(lanes);

    })
  }

  async getRoutes() {
    this.corridorService.getRoute().subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.route_list = _.cloneDeep(res.data);
              this.data_route = _.cloneDeep(res.data);
            }
          }
        }
      },
      error => { });
  }

  async getRoadway(req) {
    this.corridorService.getRoadway(req).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.config_road = _.cloneDeep(res.data);
              this.setRoad();
              setTimeout(() => {
                this.getIntersection(req);
                if (this.show_event) this.getEvent(this.event_req);
              }, 500);
            }
          }
        }
      },
      error => { });
  }

  async getIntersection(req) {
    this.corridorService.getIntersection(req).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.intersection = _.cloneDeep(res.data);
              this.setIntersection();
            }
          }
        }
      },
      error => { });
  }

  async getTraffic() {
    this.traffic_data = [];
    this.commonService.getTraffic().subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.traffic_data = res.data;

            }
          }
        }
        this.setTraffic();
      },
      error => { this.setTraffic(); });
  }

  setTraffic() {
    this.traffic_road = [];
    for (let i = 0; i < this.road_list.length; i++) {
      let find_vertical_line = this.vertical_line.find(x => x.i == i);
      if (find_vertical_line) {

      } else {
        let find = this.traffic_data.find(x => x.route == this.road_list[i].route && x.km == this.road_list[i].km && x.dir == this.direction.OUT);
        let color_out = _.cloneDeep(this.no_data_color);
        if (find) {
          if (find) {
            if (find.color_spd != null) {
              if (find.color_spd.length == 9) {
                color_out = `#${find.color_spd.substr(3)}`;
              } else {
                color_out = find.color_spd;
              }
            } else {
              color_out = _.cloneDeep(this.null_data_color);
            }
          } else {
            color_out = _.cloneDeep(this.null_data_color);
          }
        }

        let find_in = this.traffic_data.find(x => x.route == this.road_list[i].route && x.km == this.road_list[i].km && x.dir == this.direction.IN);
        let color_in = _.cloneDeep(this.no_data_color);
        if (find_in) {
          if (find_in) {
            if (find_in.color_spd != null) {
              if (find_in.color_spd.length == 9) {
                color_in = `#${find_in.color_spd.substr(3)}`;
              } else {
                color_in = find_in.color_spd;
              }
            } else {
              color_in = _.cloneDeep(this.null_data_color);
            }
          } else {
            color_in = _.cloneDeep(this.null_data_color);
          }
        }
        const lanes_out = this.road_list[i].lanes_out;
        const lanes_in = this.road_list[i].lanes_in;
        for (let j = 0; j < lanes_out.length; j++) {
          let count = this.max_lane_out - lanes_out[j] + (this.max_ft_lane_out);
          let y = (count * this.lane_height) + ((this.lane_height + 10) * 2) + this.road_border_ft + this.road_border_main + this.road_gap;
          let lo = { route: this.road_list[i].route, km: this.road_list[i].km, dir: this.direction.OUT, index: i, color: color_out, x: i * this.km_width_px, y: y, lane: lanes_out[j] };
          this.traffic_road.push(lo);
        }
        for (let j = 0; j < lanes_in.length; j++) {
          let y = ((lanes_in[j] + this.max_ft_lane_out + this.max_lane_out - 1) * this.lane_height) + ((this.lane_height + 10) * 2) + this.road_border_ft + this.road_border_main + this.road_gap + this.center_km_height;
          let li = { route: this.road_list[i].route, km: this.road_list[i].km, dir: this.direction.IN, index: i, color: color_in, x: i * this.km_width_px, y: y, lane: lanes_in[j] };
          this.traffic_road.push(li);
        }
      }

    }
  }

  ngAfterViewInit() {
    this.ContainerHeight = this.elementView.nativeElement.offsetHeight;
  }

  onResize(event) {
    this.ContainerHeight = event.target.innerHeight;
  }

  //-------------------------------------------------------------------//
  // Event icon display_type
  // 1. 119x114 center 60x105
  // 2. 122x126 center 91x15
  // 3. 119x114 center 110x71
  // 4. 124x126 center 27x17
  // 5. 120x115 center 9x75

  icon_display_type = [
    {
      display_type: 1,
      width: 119,
      height: 114,
      center_x: 60,
      center_y: 105
    },
    {
      display_type: 2,
      width: 122,
      height: 126,
      center_x: 91,
      center_y: 15
    },
    {
      display_type: 3,
      width: 119,
      height: 114,
      center_x: 110,
      center_y: 71
    },
    {
      display_type: 4,
      width: 124,
      height: 126,
      center_x: 27,
      center_y: 17
    },
    {
      display_type: 5,
      width: 120,
      height: 115,
      center_x: 9,
      center_y: 75
    }
  ]

  pathMarker = [];
  event_list = [];
  icon_w = 36;
  event_req = { status: [1, 2] };
  async getEvent(data: any) {
    let res: any = await this.eventService.getmapEvt(data);
    let list = [];
    this.event_list = [];
    if (res) {
      list = _.cloneDeep(res);
      const h_out = (this.lane_height + 10);
      const h_in = ((((this.max_ft_lane_in + this.max_ft_lane_out + this.max_lane_out +
        this.max_lane_in) * this.lane_height) + ((this.lane_height + 10) * 3) + (this.road_border_ft * 2) +
        (this.road_border_main * 2) + (this.road_gap * 2) +
        this.center_km_height));
      for (let i = 0; i < this.road_list.length; i++) {
        let e_km_list = list.filter(x => parseInt(x.route.toString()) == parseInt(this.road_list[i].route.toString()) && x.km == this.road_list[i].km);
        if (e_km_list.length) {
          for (let j = 0; j < e_km_list.length; j++) {
            let e = e_km_list[j];
            e["x"] = 0;
            e["y"] = 0;
            let find_icon = this.pathMarker.find(x => x.id == e_km_list[j].icon_id);
            if (find_icon) {

              if (find_icon.icon_url != null && find_icon.icon_url != "") {
                e["icon"] = find_icon.icon_url;
                let find_c = this.icon_display_type.find(x => x.display_type == e.display_type);
                if (find_c) {
                  e["center_x"] = (this.icon_w / find_c.width) * find_c.center_x;
                  e["center_y"] = (this.icon_w / find_c.width) * find_c.center_y;

                } else {
                  e["center_x"] = this.icon_w / 2;
                  e["center_y"] = this.icon_w;
                }
              } else {
                e["icon"] = './assets/event/icon-warning.png';
                e["center_x"] = this.icon_w / 2;
                e["center_y"] = this.icon_w / 2;
              }
            } else {
              e["icon"] = 'assets/img/event/1.png';
              e["center_x"] = this.icon_w / 2;
              e["center_y"] = this.icon_w / 2;
            }
            e["index"] = i;
            e["y"] = e["y"] - e["center_y"];
            e["x"] = e["x"] - e["center_x"] + ((e.m / 1000) * this.km_width_px);
            if (e.dir == this.direction.OUT) { e["y"] = e["y"] + h_out };
            if (e.dir == this.direction.IN) { e["y"] = e["y"] + h_in };
            if (e.dir == this.direction.OUT || e.dir == this.direction.IN) {
              this.event_list.push(e);
            }

          }
        }

      }
    }
  }

  async getIconMarker() {
    this.pathMarker = []
    let res: any = await this.eventService.geticonMarker({})
    if (res) {
      for (const i of res) {
        this.pathMarker.push(i)
      }
    }
  }

  openModal(data?: any) {
    const initialState = {
      values: data,
      // list: this.list,
      // inc_type: this.allType
    };
    /**
     * displayType = {
        EVENT: 1,
        SMC: 2,
        CCTV: 3,
        BOARD_SIGN: 4,
        WEATHER: 5
    }
     */
    if (data.display_type == 1) data.typeMarker = 1
    if (data.display_type == 2) data.typeMarker = 6
    if (data.display_type == 3) data.typeMarker = 8
    if (data.display_type == 4) data.typeMarker = 7
    if (data.display_type == 5) data.typeMarker = 5
    // if(data.display_type==1) data.typeMarker=7
    this.modalRef = this.modalService.show(EventDetailMapComponent, { initialState, class: `markerD-BG ${data.device_sub_type_id == 1035 || data.typeMarker == 6 ? 'vw-50' : ''}`, backdrop: 'static', id: 1 });
    this.modalRef.content.event.subscribe(data => {
      // if (data) this.getEventMap();
    });
  }

}

