<div class="modal-header">
    <h4 class="text-Color">ตั้งค่าเงื่อนไข ระยะเวลาเดินทางหรือความเร็วเฉลี่ยในแต่ละช่วงถนนเพิ่มขึ้นมากกว่าช่วงเวลาปกติ</h4>
    <button type="button" class="close pull-right x-BT" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="d-flex f-wrap">
    <div class="modal-body w-100">
        <div class="pos-BG">
            <button class="pos-BT m-0" *ngFor="let i of this.values.device_loc;index as idx" [ngClass]="{'active': _device_loc[idx]}" (click)="_device_loc[idx] = !_device_loc[idx]">{{i.zone_id||''}}</button>
        </div>
    </div>
    <div class="modal-body rainConfig-BG w-50">
        <div class="pos-BG">
            <p class="title-txt">จุดติดตั้งอุปกรณ์ทิศทางขาเข้า</p>
            <!-- <button class="pos-BT" *ngFor="let i of this.values.devdevice_idice_loc">{{i||''}}</button> -->
        </div>
        <div class="rainfall-BG ">
            <p>กรุณาตรวจสอบเงื่อนไขในการตรวจจับ</p>
            <div>
                <div class="input-group">
                    <div (click)="_chkHead[0]=!_chkHead[0]" class="chk-BG">
                        <input type="checkbox" [(ngModel)]="_chkHead[0]" [readOnly]="values?.id">
                        <img *ngIf="_chkHead[0]" src="./assets/event/cb-chk.png" height="20">
                        <img *ngIf="!_chkHead[0]" src="./assets/event/cb-unchk.png" height="20">
                    </div>
                    <div class="input-group-prepend pointer" (click)="_chkHead[0]=!_chkHead[0]">
                        <span class="input-group-text">ร้อยละคลาดเคลื่อนจากค่า Standard Deviation</span>
                    </div>
                    <input type="number" numbersOnly class="form-control" [(ngModel)]="sendData[1].normalTrafficSd" [disabled]="!_isIn"
                        [readOnly]="values?.id" max="100" placeholder="กรุณาระบุร้อยละความคลาดเคลื่อน (0-100)">
                </div>
            </div>
            <div>
                <div class="input-group">
                    <div (click)="_chkHead[1]=!_chkHead[1]" class="chk-BG">
                        <input type="checkbox" [(ngModel)]="_chkHead[1]" [readOnly]="values?.id">
                        <img *ngIf="_chkHead[1]" src="./assets/event/cb-chk.png" height="20">
                        <img *ngIf="!_chkHead[1]" src="./assets/event/cb-unchk.png" height="20">
                    </div>
                    <div class="input-group-prepend pointer" (click)="_chkHead[1]=!_chkHead[1]">
                        <span class="input-group-text">ร้อยละคลาดเคลื่อนจากค่า Maximum Travel Time</span>
                    </div>
                    <input type="number" numbersOnly class="form-control" [(ngModel)]="sendData[1].maximumTraveltimeDiff" [disabled]="!_isIn"
                        [readOnly]="values?.id" max="100" placeholder="กรุณาระบุร้อยละความคลาดเคลื่อน (0-100)">
                </div>
            </div>
            <div>
                <div class="input-group">
                    <div (click)="_chkHead[2]=!_chkHead[2]" class="chk-BG">
                        <input type="checkbox" [(ngModel)]="_chkHead[2]" [readOnly]="values?.id">
                        <img *ngIf="_chkHead[2]" src="./assets/event/cb-chk.png" height="20">
                        <img *ngIf="!_chkHead[2]" src="./assets/event/cb-unchk.png" height="20">
                    </div>
                    <div class="input-group-prepend pointer" (click)="_chkHead[2]=!_chkHead[2]">
                        <span class="input-group-text">ร้อยละคลาดเคลื่อนจากค่า Normal Travel Time</span>
                    </div>
                    <input type="number" numbersOnly class="form-control" [(ngModel)]="sendData[1].normalTraveltimeDiff" [disabled]="!_isIn"
                        [readOnly]="values?.id" max="100" placeholder="กรุณาระบุร้อยละความคลาดเคลื่อน (0-100)">
                </div>
            </div>
            <div>
                <div class="input-group">
                    <div (click)="_chkHead[3]=!_chkHead[3]" class="chk-BG">
                        <input type="checkbox" [(ngModel)]="_chkHead[3]" [readOnly]="values?.id">
                        <img *ngIf="_chkHead[3]" src="./assets/event/cb-chk.png" height="20">
                        <img *ngIf="!_chkHead[3]" src="./assets/event/cb-unchk.png" height="20">
                    </div>
                    <div class="input-group-prepend pointer" (click)="_chkHead[3]=!_chkHead[3]">
                        <span class="input-group-text">ร้อยละคลาดเคลื่อนจากค่า Travel Time ที่ Free Flow Speed</span>
                    </div>
                    <input type="number" numbersOnly class="form-control" [(ngModel)]="sendData[1].freeFlowTraveltimeDiff" [disabled]="!_isIn"
                        [readOnly]="values?.id" max="100" placeholder="กรุณาระบุร้อยละความคลาดเคลื่อน (0-100)">
                </div>
            </div>
        </div>
        <div class="rainfall-BG ">
            <div class="d-flex">
                <div (click)="_chkHead[4]=!_chkHead[4]" class="chk-BG">
                    <input type="checkbox" [(ngModel)]="_chkHead[4]" [readOnly]="values?.id">
                    <img *ngIf="_chkHead[4]" src="./assets/event/cb-chk.png" height="20">
                    <img *ngIf="!_chkHead[4]" src="./assets/event/cb-unchk.png" height="20">
                </div>
                <p class="mb-0">ร้อยละความคลาดเคลื่อนจากค่า Free Flow Travel Time</p>
            </div>
            <div>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">ระดับที่ 1</span>
                    </div>
                    <input type="number" numbersOnly class="form-control" [(ngModel)]="sendData[1].free_flow_tt_traveltime_diff_level1" [disabled]="!_isIn"
                        [readOnly]="values?.id" max="100" placeholder="กรุณาระบุร้อยละความคลาดเคลื่อน (0-100)">
                </div>
            </div>
            <div>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">ระดับที่ 2</span>
                    </div>
                    <input type="number" numbersOnly class="form-control" [(ngModel)]="sendData[1].free_flow_tt_traveltime_diff_level2" [disabled]="!_isIn"
                        [readOnly]="values?.id" max="100" placeholder="กรุณาระบุร้อยละความคลาดเคลื่อน (0-100)">
                </div>
            </div>
            <div>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">ระดับที่ 3</span>
                    </div>
                    <input type="number" numbersOnly class="form-control" [(ngModel)]="sendData[1].free_flow_tt_traveltime_diff_level3" [disabled]="!_isIn"
                        [readOnly]="values?.id" max="100" placeholder="กรุณาระบุร้อยละความคลาดเคลื่อน (0-100)">
                </div>
            </div>
            <div>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">จำนวนยานพาหนะในช่องเวลา 5 นาที (Count)</span>
                    </div>
                    <input type="number" numbersOnly class="form-control" [(ngModel)]="sendData[1].volume" [disabled]="!_isIn"
                        [readOnly]="values?.id" max="100" placeholder="กรุณาระบุร้อยละความคลาดเคลื่อน (0-100)">
                </div>
            </div>
        </div>
    </div>
<!-- </div>
<div class="w-50"> -->
    <div class="modal-body rainConfig-BG w-50">
        <div class="pos-BG"><p class="title-txt">จุดติดตั้งอุปกรณ์ทิศทางขาออก</p></div>
        <div class="rainfall-BG ">
            <p>กรุณาตรวจสอบเงื่อนไขในการตรวจจับ</p>
            <div>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">ร้อยละคลาดเคลื่อนจากค่า Standard Deviation</span>
                    </div>
                    <input type="number" numbersOnly class="form-control" [(ngModel)]="sendData[0].normalTrafficSd" [disabled]="!_isOut"
                        [readOnly]="values?.id" max="100" placeholder="กรุณาระบุร้อยละความคลาดเคลื่อน (0-100)">
                </div>
            </div>
            <div>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">ร้อยละคลาดเคลื่อนจากค่า Maximum Travel Time</span>
                    </div>
                    <input type="number" numbersOnly class="form-control" [(ngModel)]="sendData[0].maximumTraveltimeDiff" [disabled]="!_isOut"
                        [readOnly]="values?.id" max="100" placeholder="กรุณาระบุร้อยละความคลาดเคลื่อน (0-100)">
                </div>
            </div>
            <div>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">ร้อยละคลาดเคลื่อนจากค่า Normal Travel Time</span>
                    </div>
                    <input type="number" numbersOnly class="form-control" [(ngModel)]="sendData[0].normalTraveltimeDiff" [disabled]="!_isOut"
                        [readOnly]="values?.id" max="100" placeholder="กรุณาระบุร้อยละความคลาดเคลื่อน (0-100)">
                </div>
            </div>
            <div>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">ร้อยละคลาดเคลื่อนจากค่า Travel Time ที่ Free Flow Speed</span>
                    </div>
                    <input type="number" numbersOnly class="form-control" [(ngModel)]="sendData[0].freeFlowTraveltimeDiff" [disabled]="!_isOut"
                        [readOnly]="values?.id" max="100" placeholder="กรุณาระบุร้อยละความคลาดเคลื่อน (0-100)">
                </div>
            </div>
        </div>
        <div class="rainfall-BG ">
            <p>ร้อยละความคลาดเคลื่อนจากค่า Free Flow Travel Time</p>
            <div>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">ระดับที่ 1</span>
                    </div>
                    <input type="number" numbersOnly class="form-control" [(ngModel)]="sendData[0].free_flow_tt_traveltime_diff_level1" [disabled]="!_isOut"
                        [readOnly]="values?.id" max="100" placeholder="กรุณาระบุร้อยละความคลาดเคลื่อน (0-100)">
                </div>
            </div>
            <div>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">ระดับที่ 2</span>
                    </div>
                    <input type="number" numbersOnly class="form-control" [(ngModel)]="sendData[0].free_flow_tt_traveltime_diff_level2" [disabled]="!_isOut"
                        [readOnly]="values?.id" max="100" placeholder="กรุณาระบุร้อยละความคลาดเคลื่อน (0-100)">
                </div>
            </div>
            <div>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">ระดับที่ 3</span>
                    </div>
                    <input type="number" numbersOnly class="form-control" [(ngModel)]="sendData[0].free_flow_tt_traveltime_diff_level3" [disabled]="!_isOut"
                        [readOnly]="values?.id" max="100" placeholder="กรุณาระบุร้อยละความคลาดเคลื่อน (0-100)">
                </div>
            </div>
            <div>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">จำนวนยานพาหนะในช่องเวลา 5 นาที (Count)</span>
                    </div>
                    <input type="number" numbersOnly class="form-control" [(ngModel)]="sendData[0].volume" [disabled]="!_isOut"
                        [readOnly]="values?.id" max="100" placeholder="กรุณาระบุร้อยละความคลาดเคลื่อน (0-100)">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button *ngIf="!values?.id" class="btn btn-primary save-BT" (click)="save()">บันทึก</button>
    <button *ngIf="values?.id" class="btn btn-primary save-BT" (click)="close()">ปิด</button>
</div>