import {
  Component,
  OnInit,
  EventEmitter,
  ViewChild,
  Pipe,
  TemplateRef,
} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as mapboxgl from 'mapbox-gl';
import { environment as env } from 'src/environments/environment';
import { ManagePopupComponent } from '../manage-popup/manage-popup.component';
import { EventService } from 'src/app/services/user/event.service';
import { FuncService } from 'src/app/services/user/func.service';

import { GroupResult, groupBy } from '@progress/kendo-data-query';

import * as turf_bbox from '@turf/bbox';
import { SensorComponent } from '../../traffic-tool/sensor/sensor.component';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-detail-effect',
  templateUrl: './detail-effect.component.html',
  styleUrls: ['./detail-effect.component.scss'],
})
export class DetailEffectComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();
  values: any;
  modalRef: BsModalRef;

  map: any;
  menu: number = 1;
  _confirmEvt: number = 1;
  occur_datetime: any;
  event_type_id: any = '';
  event_subtype_id: any = '';
  location_type: any = '';
  event_src: any = '';
  other_src: any;
  sys_desc: any;
  basic_desc: any;
  st_road: any = '';
  st_km: any;
  st_m: any;
  st_direction: any = '';
  en_road: any = '';
  en_km: any;
  en_m: any;
  en_direction: any = '';
  type_list: any = [];
  subtype_list: any = [];
  groupSubtype: GroupResult[];
  src_list: any = [];
  route_list: any = [];
  imgsPath: any = [];
  imgs: any = [];
  coorFeatures: any = [];

  msg_list: any = [];
  msg: string = '';

  _success : boolean = false
  log_msg:any[] = []
  constructor(
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private eS: EventService,
    private func: FuncService
  ) {}

  ngOnInit(): void {
    // console.log("detaill-Eff",this.values)
    /**
     * displayType = {
        EVENT: 1,
        SMC: 2,
        CCTV: 3,
        BOARD_SIGN: 4,
        WEATHER: 5
    }
     */
    this.chkMap();
    this.getData();
    this.getLog();
    // setInterval(() => {
    //   this.getLog();
    // }, 10000);
  }
  async setForm() {
    if(this.values.status ==3) this._success = true
    let res: any = await this.eS.getEvtById(this.values.id);
    this.values = res;
    if (res.location_type) this.location_type = res.location_type;
    if (res.occur_datetime) this.occur_datetime = new Date(res.occur_datetime);
    if(this.type_list.length && res.event_type_id) this.event_type_id = res.event_type_id
    
    if(res.event_type_id){
      let filterVal = this.type_list.filter(
        (val: any) => val.event_type_id == res.event_type_id
        );
        if(filterVal.length) {
          this.event_type_id = filterVal[0] 
          this.subtype_list = filterVal[0].subtype
          if(res.event_subtype_id) {
            let filterSubtype = filterVal[0].subtype.filter(
              (val: any) => val.event_subtype_id == res.event_subtype_id
              );
            this.event_subtype_id = filterSubtype[0]||[]
          }
        }
        // if (filterVal.length) this.event_type_id = filterVal[0];
    }
    // if (res.event_subtype_id) {
    //   let filterVal = this.type_list.filter(
    //     (val: any) => val.event_subtype_id == res.event_subtype_id
    //   );
    //   if (filterVal.length) this.event_type_id = filterVal[0];
    // }
    this.st_road = String(res.st_road).padStart(4, '0');
    this.st_km = res.st_km;
    this.st_m = res.st_m;
    this.st_direction = res.st_direction;
    this.en_road = String(res.en_road).padStart(4, '0');
    this.en_km = res.en_km;
    this.en_m = res.en_m;
    this.en_direction = res.en_direction;
    this.other_src = res.other_src;
    this.sys_desc = res.sys_desc;
    this.basic_desc = res.basic_desc;
    this.event_src = res.event_src;
    this._confirmEvt = res.verify_result;
    if (res.img.length > 0) {
      this.imgs = res.img;
    }
  }
  async getData() {
    // let res :any = await this.eS.getEvtType()
    let res: any = await this.eS.getmapEvtSubType();
    if (res.length) {
      this.type_list = res
      // for (const i of res) {
      //   for (const j of i.subtype) {
      //     this.type_list.push({
      //       event_subtype_id: j.event_subtype_id,
      //       name: j.event_subtype_name,
      //       category: 'type',
      //       subcategory: i.event_type_name,
      //       event_type_id: i.event_type_id,
      //     });
      //   }
      //   // if(!i.subtype.length) this.type_list.push({
      //   //   event_subtype_id:null,
      //   //   name: '',
      //   //   category: 'type',
      //   //   subcategory: i.event_type_name,
      //   //   event_type_id: i.event_type_id,
      //   // })
      //   this.groupSubtype = groupBy(this.type_list, [{ field: 'subcategory' }]);
      // }
      // console.log("type_list",this.type_list);
      // console.log(this.groupSubtype);
    }
    let res_route: any = await this.eS.getRoute();
    // console.log(res_route)
    if (res_route.length) this.route_list = res_route;
    let res_src: any = await this.eS.getSrc();
    if (res_src.length) this.src_list = res_src;

    if (this.values) this.setForm();
  }
  chgType(evt:any){
    this.event_subtype_id = null
    this.subtype_list = (evt&&evt.subtype.length)? evt.subtype:[]
  }
  openImg(pathImg: string) {
    let html: string = `<img src="${pathImg}" alt="">`;
    this.func.alertpopup(html, `Img`);
  }
  async getLog() {
    let res: any = await this.eS.getEvtLog({ event_id: this.values.id });
    if (res.length) this.msg_list = res;
  }
  chkNumberm(evt?: any) {
    let result = Number(evt);
    if (result > 1000) result = 999;
    if (result < 0) result = 0;
    return result;
  }
  chkMap() {
    setTimeout(() => {
      if (this.map) this.map = undefined;
      if (!this.map && this.menu == 1) this.initMap();
    }, 500);
  }
  async chkConfirm() {
    if (this._confirmEvt == 1) {
      let res = await this.func.alertpopup(
        'ยินยันเหตุการณ์ใช่หรือไม่',
        'Warning',
        1
      );
      if (!res) this._confirmEvt = this.values.verify_result;
    }
    if (this._confirmEvt == 0) {
      let res = await this.func.alertpopup(
        'ตรวจสอบแล้วไม่พบเหตุการณ์ใช่หรือไม่',
        'Warning',
        1
      );
      if (!res) this._confirmEvt = this.values.verify_result;
    }
  }

  verify_method_list : any = []
  async openSendLog(template: TemplateRef<void>){

    this.func.alertLoading()
    this.modalRef = this.modalService.show(template,{id:8,class:'backdrop-g'});
    let res : any = await this.eS.getVerifyMethod()
    if(res.length) this.verify_method_list = res

    setTimeout(() => {this.func.alertClose()}, 1000);
  }
  async sendMsgLog(){
    this.func.alertLoading()
    // let txt = ['','เรียกดูจากกล้อง CCTV ใกล้เคียง','เรียกดูข้อมูลสภาพจราจร','ไม่มีข้อมูลให้ตรวจสอบ']
    // if(txt[this.log_msg]) this.sendMsg(txt[this.log_msg])
    let ids : any[] = []
    for (const i of this.verify_method_list) {
      if(i.checked) ids.push(i.id)
    }
    let res : any = await this.eS.updateEventVerifyMethod({
      id:this.values.id,
      verify_method_id:ids.toString()
    })
    if(res) {
      this.func.alertSuccess()
      this.closeModal(8)
      setTimeout(() => {this.func.alertClose()}, 1000);
    }
  }
  sendMsg(txt?:string) {
    let message : string = txt? txt : this.msg
    let res = this.eS.addEvtMsg({ event_id: this.values.id, text: message });
    if (res) {
      setTimeout(() => {
        this.getLog();
        this.msg = '';
      }, 500);
    }
  }
  closeModal(modalId?: number) {
    this.modalService.hide(modalId);
  }
  openManageModal(data?: any) {
    if (data) {
      if (!this.values) this.values = {};
      this.values.menuType = data;
    }
    const initialState = {
      values: this.values,
      // list: this.list,
      // inc_type: this.allType
    };

    this.modalRef = this.modalService.show(ManagePopupComponent, {
      initialState,
      class: `manage-BG ${data == 3 ? 'w-80' : ''}`,
      backdrop: 'static',
      id: 3,
    });
    this.modalRef.content.event.subscribe((data) => {
      if (data) this.getLog();
    });
  }
  close() {
    this.bsModalRef.hide();
  }

  confirm() {
    this.event.emit(true);
    this.bsModalRef.hide();
  }
  cancel() {
    this.event.emit(false);
    this.bsModalRef.hide();
  }
  _clickSave : boolean = false
  async save(_success?: boolean) {
    this._clickSave = true
    let data: any = {
      occur_datetime: this.occur_datetime || null,
      // event_type_id : Number(this.event_type_id) ||null,
      location_type: Number(this.location_type) || null,
      verify_result: Number(this._confirmEvt),
    };
    if (this.other_src) data.other_src = this.other_src || null;
    if (this.sys_desc) data.sys_desc = this.sys_desc || null;
    if (this.basic_desc) data.basic_desc = this.basic_desc || null;
    data.st_road = Number(this.st_road) || 0;
    data.st_km = Number(this.st_km) || 0;
    data.st_m = Number(this.st_m) || 0;
    data.st_direction = Number(this.st_direction);
    if (this.location_type == 2) {
      data.en_road = Number(this.en_road) || 0;
      data.en_km = Number(this.en_km) || 0;
      data.en_m = Number(this.en_m) || 0;
      data.en_direction = Number(this.en_direction);
    }
    if (this.event_src) data.event_src = Number(this.event_src);
    if (this.event_type_id) {
      data.event_type_id = Number(this.event_type_id.event_type_id);
      // data.event_subtype_id = Number(this.event_type_id.event_subtype_id);
    }
    // console.log(this.event_type_id)
    if(this.event_type_id&&this.event_subtype_id) data.event_subtype_id = Number(this.event_subtype_id.event_subtype_id)
    if (!this.values?.id) data.verify_result = 1;
    if (this.values && this.values.id) data.id = this.values.id;
    if (_success) data.status = 3;
    let res: any = await this.eS.addevt(data);
    if (res) {
      let resImgs : any = []
      if(this.imgsPath.length){
        for (const i of this.imgsPath) {
          let resImg : any = await this.eS.addImgEvt({
            event_id: res.id,
            image: i,
          });
          if(resImg&&resImg.code == 1) resImgs.push(resImg.data.img_url)
        }
      }
      console.log(resImgs)
      // for (const i of resImgs) {
        await this.eS.addImgEvtLog({
          event_id: res.id,
          image: resImgs,
        });
      // }
      this.func.alertSuccess();
      this.confirm();
    }
    this._clickSave = false
  }
  updateSuccess() {}
  async getReport() {
    console.log(this.values);
    // 45
    let res: any = await this.eS.getEvtReportpdf({ event_id: this.values.id });
    // let res : any = await this.eS.getEvtReportpdf({event_id:45})
    if (!res) this.func.alertError('Oops!! Sorry have no report.');
    if (res) {
    }
  }
  async getLoc(route: any, km: number, m: number, typeMarker: number) {
    let data: any = {};
    if (route || route == 0) data.route = Number(route);
    if (km || km == 0) data.km = Number(km);
    if (m || m == 0) data.m = Number(m);
    let resLoc: any = await this.eS.getLocationPlanet(data);
    if (resLoc) {
      this.setMarkerLoc(resLoc, typeMarker);
    }

    // {typeMarker:,lat:,lng}
    // this.coorFeatures.push(this.geo_feature(i))
  }
  setMarkerLoc(data: any, type: number) {
    data.typeMarker = type;
    this.coorFeatures[type - 1] = this.geo_feature(data);
    this.updateMap();
  }
  clearMarker2() {
    if (this.coorFeatures.length == 2) {
      this.coorFeatures = this.coorFeatures.slice(0, 1);
      console.log(this.coorFeatures);
      this.updateMap();
    }
  }
  openSensor(data?: any) {
    // data.station_id = 1
    const initialState = {
      values_fromEvt: data,
    };

    this.modalRef = this.modalService.show(SensorComponent, {
      initialState,
      class: 'markerD-BG w-80',
      backdrop: 'static',
      id: 5,
    });
    this.modalRef.content.event.subscribe((data) => {
      console.log(data);
      sessionStorage.removeItem('event_id');
      // if (data) this.getEventMap();
    });
  }
  //Map ---------------------------------------

  async initMap() {
    this.map = new mapboxgl.Map({
      accessToken: env.mapbox.accessToken,
      container: 'map-detail',
      // style: env.mapbox.style,
      style: 'mapbox://styles/mapbox/dark-v10',
      zoom: 8,
      center: [100.523186, 13.736717],
    });
    this.map.dragRotate.disable();

    this.map.on('load', (event: any) => {
      this.map.loadImage(
        'assets/event/Marker1.png',
        (error: any, image: any) => {
          if (error) {
            throw error;
          }
          this.map.addImage(`pin1`, image);
        }
      );
      this.map.loadImage(
        'assets/event/Marker2.png',
        (error: any, image: any) => {
          if (error) {
            throw error;
          }
          this.map.addImage(`pin2`, image);
        }
      );
      // -----------------------
      this.map.addSource('MarkersEvt', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'Point',
                coordinates: [100.946564, 13.951342],
              },
            },
          ],
        },
      });
      // -----------------------

      this.map.addLayer({
        id: 'MarkerLayer',
        source: 'MarkersEvt',
        type: 'symbol',
        layout: {
          'icon-allow-overlap': true,
          'icon-image': `pin{typeMarker}`,
          'symbol-avoid-edges': true,
          'icon-ignore-placement': true,
          // 'icon-image': 'pin-event{src_type_id}{event_subtype_id}',
          'icon-size': 0.1,
          //'text-offset': [0, 0],
          'icon-offset': [0, -20],
          //'text-field': '{route}',
          //'text-size': 12,
          'text-allow-overlap': true,
          'text-transform': 'uppercase',
        },
        paint: {
          'text-color': '#ffffff',
        },
        // "filter": ["==", "$type", "Point"],
      });
      this.map.resize();
      // this.map.flyTo({ center: [100.946564,13.951342], zoom: 12 });

      if (this.values) {
        let res = this.values;
        if (res.st_lat && res.st_lng) {
          this.setMarkerLoc({ lat: res.st_lat, lng: res.st_lng }, 1);
        }
        if (res.en_lat && res.en_lng) {
          this.setMarkerLoc({ lat: res.en_lat, lng: res.en_lng }, 2);
        }
      }
    });
  }
  updateMap() {
    if (this.map.getSource('MarkersEvt') != null) {
      this.map.getSource('MarkersEvt').setData({
        type: 'FeatureCollection',
        features: this.coorFeatures,
      });

      let bbox = turf_bbox.default({
        type: 'FeatureCollection',
        features: this.coorFeatures,
      });
      // console.log(bbox);
      // this.map.fitBounds([
      //   this.ll_min, // southwestern corner of the bounds
      //   this.ll_max // northeastern corner of the bounds
      // ]);
      if (this.coorFeatures.length > 1) {
        this.map.fitBounds(bbox, {
          padding: { top: 60, bottom: 60, left: 60, right: 60 },
        });
      } else {
        let p = this.coorFeatures[0].properties;
        this.map.flyTo({ center: [p.lng, p.lat], zoom: 12 });
      }
    }
    // this.loading = false
  }

  geo_feature(vals: any) {
    return {
      type: 'Feature',
      properties: vals,
      geometry: {
        type: 'Point',
        coordinates: [vals.lng, vals.lat],
      },
    };
  }

  // uploadImgs
  selectedFile: ImageSnippet;
  ImgBase64(evt?: any) {
    var reader = new FileReader();
    reader.readAsDataURL(evt.target.files[0]);
    // console.log(evt.target.files[0])
    if(evt.target.files[0].size>2000000) return this.func.alertError("Don't upload file more than 2MB.")
    reader.onload = (_event) => {
      // this.msg = "";
      // console.log(reader.result)
      // this.imgsPath[0] = reader.result;
      this.imgsPath.push(reader.result);
    };
  }
  rmImage(i:number){
    this.imgsPath.splice(i, 1)
  }
  async uploadFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();
    // const test_data1 = this.data
    reader.addEventListener('load', async (event: any) => {
      this.selectedFile = new ImageSnippet(event.target.result, file);
      // let res: any = (type == 1) ? await this.inc_S.uploadImages(this.selectedFile.file, "incident", "incident_media") : await this.inc_S.uploadImages(this.selectedFile.file, "incident", "incident_log_media")
      // if (res && res.img_urls.length) {
      //   for (const i of res.img_urls) {
      //     if (type == 1) {
      //       this.images_incdetail.push(i)
      //     } else {
      //       this.images.push(i)
      //     }
      //   }
      //   // this.data.product_img_url_1 = res.path

      //   // this.data.ShopOwner.shop_owner_img = res.path;
      //   // console.log(this.data.ShopOwner.shop_owner_img)
      // }
    });

    reader.readAsDataURL(file);
  }
}
class ImageSnippet {
  constructor(public src: string, public file: File) {}
}

@Pipe({ name: 'safeHtml' })
export class Safe {
  constructor(private sanitizer: DomSanitizer) {}

  transform(style) {
    return this.sanitizer.bypassSecurityTrustHtml(style);
    //return this.sanitizer.bypassSecurityTrustStyle(style);
    // return this.sanitizer.bypassSecurityTrustXxx(style); - see docs
  }
}
