<div class="modal-header">
    <div class="headstep-BG">
        <h4 class="modal-title pull-left">ตั้งค่าลำดับการแจ้งเตือน</h4>
    </div>
    <button type="button" class="close pull-right x-BT" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body detail-BG menu1">
    <div class="d-flex">
        <div>ลำดับที่ 1</div>
        <div>ระดับการแจ้งเตือน</div>
        <div>
            <select [(ngModel)]="criteria_l[0]" class="form-control" (change)="clearSelect();">
                <option value="" disabled selected>โปรดเลือกระดับ</option>
                <option [disabled]="!_chk['l1']" [value]="1">Light Rain</option>
                <option [disabled]="!_chk['l2']" [value]="2">Moderate Rain</option>
                <option [disabled]="!_chk['l3']" [value]="3">Heavy Rain</option>
                <option [disabled]="!_chk['l4']" [value]="4">Very Heavy Rain</option>
            </select>
        </div>
        <div>
            <button [disabled]="!criteria_l[0]" (click)="criteria_l[0]=null;clearSelect();" class="btn btn-outline-danger">Clear</button>
        </div>
    </div>
    <div class="d-flex">
        <div>ลำดับที่ 2</div>
        <div>ระดับการแจ้งเตือน</div>
        <div>
            <select [(ngModel)]="criteria_l[1]" class="form-control" (change)="clearSelect();">
                <option value="" disabled selected>โปรดเลือกระดับ</option>
                <option [disabled]="!_chk['l1']" [value]="1">Light Rain</option>
                <option [disabled]="!_chk['l2']" [value]="2">Moderate Rain</option>
                <option [disabled]="!_chk['l3']" [value]="3">Heavy Rain</option>
                <option [disabled]="!_chk['l4']" [value]="4">Very Heavy Rain</option>
            </select>
        </div>
        <div>
            <button [disabled]="!criteria_l[1]" (click)="criteria_l[1]=null;clearSelect();" class="btn btn-outline-danger">Clear</button>
        </div>
    </div>
    <div class="d-flex">
        <div>ลำดับที่ 3</div>
        <div>ระดับการแจ้งเตือน</div>
        <div>
            <select [(ngModel)]="criteria_l[2]" class="form-control" (change)="clearSelect();">
                <option value="" disabled selected>โปรดเลือกระดับ</option>
                <option [disabled]="!_chk['l1']" [value]="1">Light Rain</option>
                <option [disabled]="!_chk['l2']" [value]="2">Moderate Rain</option>
                <option [disabled]="!_chk['l3']" [value]="3">Heavy Rain</option>
                <option [disabled]="!_chk['l4']" [value]="4">Very Heavy Rain</option>
            </select>
        </div>
        <div>
            <button [disabled]="!criteria_l[2]" (click)="criteria_l[2]=null;clearSelect();" class="btn btn-outline-danger">Clear</button>
        </div>
    </div>
    <div class="d-flex">
        <div>ลำดับที่ 4</div>
        <div>ระดับการแจ้งเตือน</div>
        <div>
            <select [(ngModel)]="criteria_l[3]" class="form-control" (change)="clearSelect();">
                <option value="" disabled selected>โปรดเลือกระดับ</option>
                <option [disabled]="!_chk['l1']" [value]="1">Light Rain</option>
                <option [disabled]="!_chk['l2']" [value]="2">Moderate Rain</option>
                <option [disabled]="!_chk['l3']" [value]="3">Heavy Rain</option>
                <option [disabled]="!_chk['l4']" [value]="4">Very Heavy Rain</option>
            </select>
        </div>
        <div>
            <button [disabled]="!criteria_l[3]" (click)="criteria_l[3]=null;clearSelect();" class="btn btn-outline-danger">Clear</button>
        </div>
    </div>
    <div class="d-flex">
        <label for="confirm1">
            <img src="./assets/event/{{_confirmRain==1? '':'un'}}chk.png" alt="">
            <input type="radio" name="confirm" id="confirm1" [(ngModel)]="_confirmRain" value="1" (change)="_confirmRain=1;">
            สิ้นสุดเหตุการณ์เมื่อไม่ตรงตามเงื่อนไข
        </label>
        <label for="confirm2">
            <img src="./assets/event/{{_confirmRain==2? '':'un'}}chk.png" alt="">
            <input type="radio" name="confirm" id="confirm2" [(ngModel)]="_confirmRain" value="2" (change)="_confirmRain=2;">
            สิ้นสุดเหตุการณ์โดยเจ้าหน้าที่
        </label>
    </div>
    <p>หมายเหตุ ในกรณีเหตุการณ์ดังกล่าวมีการแจ้งเตือนในจุดนั้นๆ แล้ว และเหตุการณ์ยังไม่สิ้นสุด จะเป็นการเปลี่ยนระดับความรุนแรงตามลำดับ แต่จะไม่สร้างเหตุการณ์ใหม่</p>
</div>
<div class="modal-footer">
    <button class="btn btn-outline-success pl-3 pr-3" (click)="save()">บันทึก</button>
</div>
