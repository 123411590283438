export const dataHeatMap = {
    "type":"FeatureCollection","features":[
        {"type":"Feature","properties":{"dbh":0},"geometry":{"type":"Point","coordinates":[100.523186, 13.736717]}},
        {"type":"Feature","properties":{"dbh":12},"geometry":{"type":"Point","coordinates":[100.523186, 13.73671]}},
        {"type":"Feature","properties":{"dbh":6},"geometry":{"type":"Point","coordinates":[100.523186, 13.736718]}},
        {"type":"Feature","properties":{"dbh":2},"geometry":{"type":"Point","coordinates":[100.523186, 13.736715]}},
        {"type":"Feature","properties":{"dbh":0},"geometry":{"type":"Point","coordinates":[100.523185, 13.736717]}},
        {"type":"Feature","properties":{"dbh":12},"geometry":{"type":"Point","coordinates":[100.523186, 13.73671]}},
        {"type":"Feature","properties":{"dbh":6},"geometry":{"type":"Point","coordinates":[100.523185, 13.736718]}},
        {"type":"Feature","properties":{"dbh":2},"geometry":{"type":"Point","coordinates":[100.523186, 13.736715]}},
        {"type":"Feature","properties":{"dbh":0},"geometry":{"type":"Point","coordinates":[100.523184, 13.736717]}},
        {"type":"Feature","properties":{"dbh":12},"geometry":{"type":"Point","coordinates":[100.523186, 13.73671]}},
        {"type":"Feature","properties":{"dbh":6},"geometry":{"type":"Point","coordinates":[100.523184, 13.736718]}},
        {"type":"Feature","properties":{"dbh":2},"geometry":{"type":"Point","coordinates":[100.523186, 13.736715]}},
        {"type":"Feature","properties":{"dbh":0},"geometry":{"type":"Point","coordinates":[100.523183, 13.736717]}},
        {"type":"Feature","properties":{"dbh":12},"geometry":{"type":"Point","coordinates":[100.523186, 13.73671]}},
        {"type":"Feature","properties":{"dbh":6},"geometry":{"type":"Point","coordinates":[100.523183, 13.736718]}},
        {"type":"Feature","properties":{"dbh":2},"geometry":{"type":"Point","coordinates":[100.523186, 13.736715]}},
        {"type":"Feature","properties":{"dbh":0},"geometry":{"type":"Point","coordinates":[100.523182, 13.736717]}},
        {"type":"Feature","properties":{"dbh":12},"geometry":{"type":"Point","coordinates":[100.523186, 13.73671]}},
        {"type":"Feature","properties":{"dbh":6},"geometry":{"type":"Point","coordinates":[100.523182, 13.736718]}},
        {"type":"Feature","properties":{"dbh":2},"geometry":{"type":"Point","coordinates":[100.523186, 13.736715]}},
        {"type":"Feature","properties":{"dbh":0},"geometry":{"type":"Point","coordinates":[100.523181, 13.736717]}},
        {"type":"Feature","properties":{"dbh":12},"geometry":{"type":"Point","coordinates":[100.523186, 13.73671]}},
        {"type":"Feature","properties":{"dbh":6},"geometry":{"type":"Point","coordinates":[100.523181, 13.736718]}},
        {"type":"Feature","properties":{"dbh":2},"geometry":{"type":"Point","coordinates":[100.523186, 13.736715]}},
        {"type":"Feature","properties":{"dbh":0},"geometry":{"type":"Point","coordinates":[100.52318, 13.736717]}},
        {"type":"Feature","properties":{"dbh":12},"geometry":{"type":"Point","coordinates":[100.523186, 13.73671]}},
        {"type":"Feature","properties":{"dbh":6},"geometry":{"type":"Point","coordinates":[100.523181, 13.736718]}},
        {"type":"Feature","properties":{"dbh":2},"geometry":{"type":"Point","coordinates":[100.523186, 13.736715]}},
        {"type":"Feature","properties":{"dbh":0},"geometry":{"type":"Point","coordinates":[100.523179, 13.736717]}},
        {"type":"Feature","properties":{"dbh":12},"geometry":{"type":"Point","coordinates":[100.523186, 13.73671]}},
        {"type":"Feature","properties":{"dbh":6},"geometry":{"type":"Point","coordinates":[100.523186, 13.736718]}},
        {"type":"Feature","properties":{"dbh":2},"geometry":{"type":"Point","coordinates":[100.523186, 13.736715]}},
        {"type":"Feature","properties":{"dbh":2},"geometry":{"type":"Point","coordinates":[100.523186, 13.735715]}},
        {"type":"Feature","properties":{"dbh":2},"geometry":{"type":"Point","coordinates":[100.523186, 13.735715]}},
        {"type":"Feature","properties":{"dbh":2},"geometry":{"type":"Point","coordinates":[100.523186, 13.735715]}},
        {"type":"Feature","properties":{"dbh":2},"geometry":{"type":"Point","coordinates":[100.523186, 13.735715]}},
        {"type":"Feature","properties":{"dbh":2},"geometry":{"type":"Point","coordinates":[100.523186, 13.735715]}},
        {"type":"Feature","properties":{"dbh":2},"geometry":{"type":"Point","coordinates":[100.523186, 13.735715]}},
        {"type":"Feature","properties":{"dbh":2},"geometry":{"type":"Point","coordinates":[100.523186, 13.735715]}},
        {"type":"Feature","properties":{"dbh":2},"geometry":{"type":"Point","coordinates":[100.523186, 13.734715]}},
        {"type":"Feature","properties":{"dbh":2},"geometry":{"type":"Point","coordinates":[100.523186, 13.734715]}},
        {"type":"Feature","properties":{"dbh":2},"geometry":{"type":"Point","coordinates":[100.523186, 13.734715]}},
        {"type":"Feature","properties":{"dbh":2},"geometry":{"type":"Point","coordinates":[100.523186, 13.734715]}},
        {"type":"Feature","properties":{"dbh":2},"geometry":{"type":"Point","coordinates":[100.523186, 13.734715]}},
        {"type":"Feature","properties":{"dbh":2},"geometry":{"type":"Point","coordinates":[100.523186, 13.734715]}},
        {"type":"Feature","properties":{"dbh":2},"geometry":{"type":"Point","coordinates":[100.523186, 13.734715]}},
        {"type":"Feature","properties":{"dbh":2},"geometry":{"type":"Point","coordinates":[100.523186, 13.733715]}},
        {"type":"Feature","properties":{"dbh":2},"geometry":{"type":"Point","coordinates":[100.523186, 13.733715]}},
        {"type":"Feature","properties":{"dbh":2},"geometry":{"type":"Point","coordinates":[100.523186, 13.733715]}},
        {"type":"Feature","properties":{"dbh":2},"geometry":{"type":"Point","coordinates":[100.523186, 13.733715]}},
        {"type":"Feature","properties":{"dbh":2},"geometry":{"type":"Point","coordinates":[100.523186, 13.733715]}},
    ]
}
