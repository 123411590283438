import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiResponse, PagableData } from '../../models/common';
import { environment } from '../../../environments/environment';
import * as _ from 'lodash';
import { AuthService } from '../auth/auth.service';
import { MediaInfo, ReqAddImage } from '../../models/sign-control/media';
import { SignEventInfo, SignEventMedia, SignEventMediaSpecial, SignEventSignal, SignEventMediaTextItem, ConfigRoadByLane, ResSuggest, ReqAddSuggest } from '../../models/sign-control/sign-setting';
import { StatusEnum } from '../../models/common-enum';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignSettingService {

  domain: any = environment.api.domain_sign_control;
  //domain_traffic_tool: any = environment.api.domain_traffic_tool;
  domain_assets: any = environment.api.domain_assets;
  constructor(private http: HttpClient, private authService: AuthService) {
  }
  getEventByBoardsign(boardsign_id?: number, sign_id?: number): Observable<ApiResponse<SignEventInfo[]>> {
    let params = new HttpParams();
    if (!_.isNil(sign_id)) {
      params = params.append('sign_id', sign_id.toString());
    }

    const uri = `boardsign/${boardsign_id}/event`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<SignEventInfo[]>>(url, httpOptions);
  }

  getEventById(boardsign_id?: number, event_id?: number): Observable<ApiResponse<SignEventInfo[]>> {
    const uri = `boardsign/${boardsign_id}/event/${event_id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.get<ApiResponse<SignEventInfo[]>>(url, httpOptions);
  }
  addEvent(req: SignEventInfo, boardsign_id?: number): Observable<ApiResponse<any>> {
    const uri = `boardsign/${boardsign_id}/event`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.post<ApiResponse<any>>(url, req, httpOptions);
  }
  editEvent(req: SignEventInfo, boardsign_id?: number, event_id?: number): Observable<ApiResponse<any>> {
    const uri = `boardsign/${boardsign_id}/event/${event_id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }
  deleteEvent(boardsign_id?: number, event_id?: number): Observable<ApiResponse<any>> {
    const uri = `boardsign/${boardsign_id}/event/${event_id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.delete<ApiResponse<any>>(url, httpOptions);
  }
  deleteEventEditStatus(boardsign_id?: number, event_id?: number): Observable<ApiResponse<any>> {
    const uri = `boardsign/${boardsign_id}/event/${event_id}`;
    const url = `${this.domain}/${uri}`;
    const req = {status: StatusEnum.Delete};
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }

  getSmc(station_id?: number): Observable<ApiResponse<any[]>> {
    const uri = `device/smc`;
    const url = `${this.domain}/${uri}`;
    let params = new HttpParams();
    if (!_.isNil(station_id)) {
      params = params.append('station_id', station_id.toString());
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<any[]>>(url, httpOptions);
  }

  matchSmc(boardsign_id?: number, device_id?:number): Observable<ApiResponse<any>> {
    const uri = `boardsign/${boardsign_id}/matchSMC`;
    const url = `${this.domain}/${uri}`;
    const req = { device_id:device_id };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }

  getConfigRoad(device_id?: number): Observable<ApiResponse<ConfigRoadByLane[]>> {
    const uri = `device/${device_id}/smc-config-road`;
    const url = `${this.domain_assets}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.get<ApiResponse<ConfigRoadByLane[]>>(url, httpOptions);
  }

  addConfigRoad(device_id?:number, config?:ConfigRoadByLane[]): Observable<ApiResponse<any>> {
    const req = { config:config };
    const uri = `device/${device_id}/smc-config-road`;
    const url = `${this.domain_assets}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.post<ApiResponse<any>>(url, req, httpOptions);
  }

  getSuggestManagement(boardsign_id?: number, incident_type?: number): Observable<ApiResponse<ResSuggest>> {
    const uri = `boardsign/suggest/criteria`;
    const url = `${this.domain}/${uri}`;
    let params = new HttpParams();
    if (!_.isNil(boardsign_id)) {
      params = params.append('boardsign_id', boardsign_id.toString());
    }
    if (!_.isNil(incident_type)) {
      params = params.append('incident_type', incident_type.toString());
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<ResSuggest>>(url, httpOptions);
  }

  getSuggestIncType(): Observable<ApiResponse<ConfigRoadByLane[]>> {
    const uri = `boardsign/suggest/incident/type`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.get<ApiResponse<any[]>>(url, httpOptions);
  }
  addSuggest(req?:ReqAddSuggest[]): Observable<ApiResponse<any>> {
    const uri = `boardsign/suggest/criteria`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.post<ApiResponse<any>>(url, req, httpOptions);
  }
  editSuggest(req?:ReqAddSuggest[]): Observable<ApiResponse<any>> {
    const uri = `boardsign/suggest/criteria`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }
}
