<div class="modal-header">
  <h4 class="modal-title pull-left">ผู้ใช้งาน</h4>
  <button type="button" class="close pull-right text-white" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body text-center">
  <div class="row">
    <div class="col-6">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <p class="input-group-text">Username <span class="red-txt"> *</span></p>
        </div>
        <input type="text" class="form-control" [(ngModel)]="username" [disabled]="_isDisabled">
      </div>
    </div>
    <div class="col-6">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <p class="input-group-text">Password <span class="red-txt" *ngIf="!_isDisabled"> *</span></p>
        </div>
        <input type="pass" class="form-control" [(ngModel)]="password">
      </div>
    </div>
    <div class="col-6">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <p class="input-group-text">ชื่อ <span class="red-txt"> *</span></p>
        </div>
        <input type="text" class="form-control" [(ngModel)]="firstname">
      </div>
    </div>
    <div class="col-6">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <p class="input-group-text">นามสกุล <span class="red-txt"> *</span></p>
        </div>
        <input type="text" class="form-control" [(ngModel)]="lastname">
      </div>
    </div>
    <div class="col-6">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <p class="input-group-text">หน่วยงาน <span class="red-txt"> *</span></p>
        </div>
        <!-- <input [typeahead]="ins_list" [(ngModel)]="ins_name" typeaheadOptionField="name" (focusout)="blur($event)"
          (typeaheadOnSelect)="onSelect($event)" class="form-control"> -->
        <kendo-combobox [data]="ins_list" [textField]="'name'" [valueField]="'id'" [(ngModel)]="ins_bindding"
          (valueChange)="handleValueIns($event)" (filterChange)="handleFilterIns($event)" class="form-control">
        </kendo-combobox>

        <!-- <kendo-multiselect class="form-control" [data]="group" [textField]="'name'" [valueField]="'id'"
          [placeholder]="'โปรดระบุ'" [filterable]="true" [(ngModel)]="group_binding"
          (valueChange)="handleValueGroup($event)" (filterChange)="handleFilterGroup($event)">
        </kendo-multiselect> -->
      </div>
    </div>
    <div class="col-6">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text">ตำแหน่ง</span>
        </div>
        <input type="text" class="form-control" [(ngModel)]="rank">
      </div>
    </div>
    <div class="col-6">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text">Email</span>
        </div>
        <input type="text" class="form-control" [(ngModel)]="email">
      </div>
    </div>
    <div class="col-6">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text">เบอร์โทร</span>
        </div>
        <input type="text" class="form-control" [(ngModel)]="tel">
      </div>
    </div>
    <div class="col-6">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text">กลุ่มผู้ใช้งาน</span>
        </div>

        <kendo-multiselect class="form-control" [data]="group" [textField]="'name'" [valueField]="'id'"
          [filterable]="true" [(ngModel)]="group_binding" (valueChange)="handleValueGroup($event)"
          (valueChange)="handleFilterGroup($event)" (removeTag)="rm_group_list($event)">
        </kendo-multiselect>


        <!-- <input [typeahead]="group" [(ngModel)]="group_id" typeaheadOptionField="name" (focusout)="g_blur($event)"
          (typeaheadOnSelect)="onSelectGroup($event)" class="form-control"> -->
      </div>
    </div>
    <div class="col-6">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text">Hashtag</span>
        </div>
        <input type="text" class="form-control" [(ngModel)]="hashtag">
      </div>
    </div>
    <div class="col-12 text-left">
      <p class="mr-2">
        กลุ่มผู้ใช้งาน
        <span class="mr-1" *ngIf="group_list.length == 0"> ไม่มีค่า </span>
        <span *ngFor="let i of group_list" class="mr-1 badge badge-pill badge-success group-BG"
          (click)="rm_group_list(i.group_id)">{{i.group_name}} <span>x</span></span>
        <!-- <span *ngFor="let i of group_list" class="mr-1 badge badge-pill badge-success group-BG"
          (click)="rmUserGroup(i.group_id)">{{i.group_name}} <span>x</span></span> -->
      </p>
      <!-- <button class="btn btn-success">+</button> -->
    </div>
  </div>
</div>
<div class="modal-footer">
  <!-- <button type="button" class="btn btn-primary btn-sm" (click)="config.animated = !config.animated">{{config.animated ? 'Disable' : 'Enable'}} animation</button> -->
  <button type="button" class="btn btn-danger" (click)="close()">ปิด</button>
  <!-- <button type="button" class="btn btn-danger" (click)="cancel()">No</button> -->
  <button type="button" class="btn btn-info" (click)="save()"
    [disabled]="(!username || !this.firstname || !this.lastname || !ins_id || (!this.password && !this.values)) ? 'disabled': null">ยืนยัน</button>
</div>

<swal #swalSuccess title="<div class='text-light'>เพิ่มผู้ใช้สำเร็จ</div>" type="success" text="" icon="success"></swal>
<swal #swalSuccessEdit title="<div class='text-light'>แก้ไขผู้ใช้สำเร็จ</div>" type="success" text="" icon="success">
</swal>
<!-- <swal #swalConfirm title="<div class='text-light'>ต้องการเพิ่มผู้ใช้ ?</div>" type="warning" text="" icon="warning"
  (confirm)="is_confirm($event)" [swalOptions]="alertOption"></swal> -->
<!-- <swal #swalError title="<div class='text-light'>ไม่สำเร็จ</div>"
  html="<div class='text-light bg-gray-900'>{{res_message}}</div>" type="error" icon="error"></swal> -->
